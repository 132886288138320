import React from 'react';
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/material';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Tab from '@mui/joy/Tab';
import Alerts from './Alerts';
import Changelogs from './Changelogs';

const NotificationDrawer = ({ openAlertsSidebar, toggleDrawer }) => {
  const [index, setIndex] = React.useState(0);

  const onToggleDrawer = () => {
    toggleDrawer(false);
  };

  const onChangeHandler = (event, value) => {
    setIndex(value as number);
  };

  return (
    <Drawer open={openAlertsSidebar} onClose={onToggleDrawer} anchor="right">
      <Tabs sx={{ width: '380px', padding: '15px' }} defaultValue={index} onChange={onChangeHandler}>
        <TabList variant="plain" color="neutral" sx={{ marginBottom: '15px' }}>
          <Tab variant={index === 0 ? 'soft' : 'plain'}>ALERTS</Tab>
          <Tab variant={index === 1 ? 'soft' : 'plain'}>CHANGELOG</Tab>
        </TabList>

        <TabPanel value={0}>
          <Alerts />
        </TabPanel>
        <TabPanel value={1}>
          <Box>
            <Changelogs />
          </Box>
        </TabPanel>
      </Tabs>
    </Drawer>
  );
};

export default NotificationDrawer;
