import React, { useMemo, useState } from 'react';
import { Tooltip } from '@mui/joy';
import { Box, Chip, Menu, MenuItem } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { observer } from 'mobx-react-lite';
import { PlanningCalendarModalClass } from '../class/PlanningCalendarModalClass';

interface IProps {
  instance: PlanningCalendarModalClass;
}
const UsedProductBlock = ({ instance }: IProps) => {
  const {
    usedCampaignMetricsList,
    usedGlobalProductList,
    setUnUsedGlobalProduct,
    setUsedGlobalProduct,
    unUsedGlobalProductList,
  } = instance;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [IsOpenUnUsed, setIsOpenUnUsed] = useState(false);

  const onOpenAddHandler = (value: boolean) => (event) => {
    setIsOpenUnUsed(value);
    if (value) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const onSelectElementsHandler = (id: number) => () => {
    onOpenAddHandler(false)(null);
    setUsedGlobalProduct(id);
  };

  const onDelete = (id: number) => () => {
    setUnUsedGlobalProduct(id);
  };

  const usedProductInCampaignMetricMap = useMemo(
    () =>
      usedCampaignMetricsList.reduce(
        (
          usedCampaignMetricState: { campaignMetricTitleList: string[]; productId: number; productTitle: string }[],
          usedCampaignMetric,
        ) => {
          usedCampaignMetric.productList.forEach((usedProduct) => {
            const productId = usedProduct.product.id;
            const campaignMetricTitle = usedCampaignMetric.title;
            const productTitle = usedProduct.product.title;

            const alreadyExists = usedCampaignMetricState[productId];
            if (alreadyExists) {
              alreadyExists.campaignMetricTitleList.push(campaignMetricTitle);
            } else {
              usedCampaignMetricState[productId] = {
                campaignMetricTitleList: [campaignMetricTitle],
                productId,
                productTitle,
              };
            }
          });
          return usedCampaignMetricState;
        },
        [],
      ),
    [usedCampaignMetricsList],
  );

  const { filteredUnUsedProductList } = useMemo(() => {
    const usedProductSet = new Set();

    usedGlobalProductList.forEach((usedProduct) => {
      usedProductSet.add(usedProduct.product.id);
    });

    const unUsedProductList = unUsedGlobalProductList.filter(
      (unUsedProduct) =>
        !(usedProductSet.has(unUsedProduct.product.id) || usedProductInCampaignMetricMap[unUsedProduct.product.id]),
    );

    return {
      filteredUnUsedProductList: unUsedProductList,
    };
  }, [usedProductInCampaignMetricMap, usedGlobalProductList, unUsedGlobalProductList]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography component="span" id="PlanningCalendarModal_Media" sx={{ fontWeight: 600 }}>
          Products
        </Typography>
        <Button
          variant="plain"
          color="primary"
          onClick={onOpenAddHandler(true)}
          disabled={!filteredUnUsedProductList.length}
        >
          + Add
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: '10px', margin: '10px 0', flexWrap: 'wrap' }}>
        {Object.values(usedProductInCampaignMetricMap).map((usedProduct) => (
          <Tooltip
            title={
              <Box
                sx={{
                  display: 'flex',
                  gap: '5px',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                }}
              >
                <span>Used in:</span>
                {usedProduct.campaignMetricTitleList.map((campaignMetricTitle) => (
                  <span
                    key={`PlanningCalendarModal_usedCampaignMetricProductList_Chip_${usedProduct.productTitle}_${usedProduct.productId}_${campaignMetricTitle}`}
                  >
                    {campaignMetricTitle}
                  </span>
                ))}
              </Box>
            }
            placement="bottom"
            size="md"
            variant="outlined"
            color="neutral"
            arrow
            key={`PlanningCalendarModal_usedCampaignMetricProductList_Chip_${usedProduct.productTitle}_${usedProduct.productId}`}
            enterDelay={300}
          >
            <Chip label={`${usedProduct.productTitle}`} />
          </Tooltip>
        ))}
        {usedGlobalProductList.map((usedProduct) => (
          <Chip
            key={`PlanningCalendarModal_usedProductList_Chip_${usedProduct.product.title}_${usedProduct.product.id}`}
            label={`${usedProduct.product.title}`}
            variant="outlined"
            onDelete={onDelete(usedProduct.product.id)}
          />
        ))}
        <Menu
          id="Button-on-OpenAddUsedCampaignBlock-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={IsOpenUnUsed}
          onClose={onOpenAddHandler(false)}
        >
          {filteredUnUsedProductList.map((option) => (
            <MenuItem
              key={`MenuItem_unUsedProductList_${option.product.id}_${option.product.title}`}
              onClick={onSelectElementsHandler(option.product.id)}
            >
              {option.product.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default observer(UsedProductBlock);
