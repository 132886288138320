import { makeObservable } from 'mobx';
import { BaseStoreAbstract } from './BaseStore.abstract';
import { CompanyClass } from '../slass/data/company/CompanyClass';
import * as companyService from '../service/companyService';

class CompanyStore extends BaseStoreAbstract<CompanyClass> {
  protected _mainDataClass = CompanyClass;

  protected _mainDataList: CompanyClass[] = [];

  protected readonly _serviceConductor = companyService;

  constructor() {
    super();
    makeObservable(this);
  }
}

export default CompanyStore;
