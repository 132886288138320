export const getValidNumber = (number = 0) => {
  const numberAsString = number.toString();
  if (numberAsString.includes('.')) {
    return number;
  }

  return Number(number || 0);
};

const validateNumber = (input) => {
  if (Number.isNaN(Number(input))) {
    return false;
  }

  return parseFloat(input) >= 0;
};

export default validateNumber;
