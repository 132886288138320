import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';

import {
  ICreateRequestCompany,
  ICreateResponseCompany,
  IRequestPatchCompany,
  IResponsePatchCompany,
  IResponseCompany,
} from '../interface/companyInterface';

export const create = (requestData: ICreateRequestCompany): Promise<IRequest<ICreateResponseCompany>> =>
  axiosInstance.post('/company/', requestData).then(({ data }) => data);

export const getList = (): Promise<IRequest<IResponseCompany[]>> =>
  axiosInstance.get('/company/').then(({ data }) => data);

export const getById = (id: number): Promise<IRequest<IResponseCompany>> =>
  axiosInstance.get(`/company/${id}/`).then(({ data }) => data);

export const patchById = (id: number, requestData: IRequestPatchCompany): Promise<IRequest<IResponsePatchCompany>> =>
  axiosInstance.patch(`/company/${id}/`, requestData).then(({ data }) => data);

export const deleteById = (id: number): AxiosPromise => axiosInstance.delete(`/company/${id}/`);

// export const changePlanningCalendarStartDay = (
//   startWeekdayDate: StartWeekDay,
//   companyId: number,
// ): Promise<IRequest<IResponseCreateShareLink>> =>
//   axiosInstance.post(`/company/${companyId}`, { calendar_weekday: startWeekdayDate }).then(({ data }) => data);
