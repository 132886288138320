import React, { CSSProperties, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/material';

const dropzone = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  justifyContent: 'center',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface IProps {
  style?: Record<string, string | number>;
  onDropCallback: any;
  permittedType: Record<string, string[]>;
}

const UploadFile = ({ style = {}, onDropCallback, permittedType }: IProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onDropCallback(acceptedFiles);
    },
    [onDropCallback],
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    multiple: false,
    onDrop,
    accept: permittedType,
  });

  const dropzoneStyle = useMemo(
    () =>
      ({
        ...dropzone,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...style,
      } as CSSProperties),
    [isFocused, isDragAccept, isDragReject, style],
  );

  return (
    <Box>
      <div {...getRootProps({ style: dropzoneStyle })}>
        <input {...getInputProps()} />
        <span style={{ fontWeight: 400, fontSize: '36px' }}>+</span>
      </div>
    </Box>
  );
};

export default UploadFile;
