import React from 'react';
import { Outlet } from 'react-router-dom';
import scss from './SettingsPage.module.scss';

const SettingsPage = () => (
  <div className={scss.wrapper}>
    <Outlet />
  </div>
);

export default SettingsPage;
