import { Box } from '@mui/material';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import React, { useMemo, useReducer, useRef } from 'react';
import { IBaseProductCategory } from '../../../interface/productCategoryInterface';
import MainStore from '../../../store/MainStore';
import { LoadingStatus } from '../../../interface';
import { ProductCategoryClass } from '../../../slass/data/product/ProductCategoryClass';

type State = Omit<IBaseProductCategory, 'slug' | 'id'>;
type ActionType = keyof State;
const productCategoryReducer = (state: State, action: { type: ActionType; payload: any }): State => {
  const { type, payload } = action;
  switch (type) {
    case 'title':
      return {
        ...state,
        title: payload,
      };
    // case 'description':
    //   return {
    //     ...state,
    //     description: payload,
    //   };
    default:
      return state;
  }
};

interface IProps {
  instance?: ProductCategoryClass;
  onCloseModal: () => void;
}

const ProductCategoryModal = ({ instance, onCloseModal }: IProps) => {
  const isEditableModeRef = useRef(!!instance);
  const { fetchCreate, loadingStatus } = MainStore.productCategoryStore;

  const initState: State = useMemo(
    () => ({
      title: instance?.title ?? '',
      description: instance?.description ?? '',
    }),
    [instance],
  );

  const [state, dispatch] = useReducer(productCategoryReducer, initState);

  const onHandleChangeInput = (type: ActionType) => (event) => {
    dispatch({
      type,
      payload: event.target.value,
    });
  };

  const onSaveButtonHandle = () => {
    const fn = instance?.fetchPatch || fetchCreate;
    fn(state).then((isSuccess) => {
      if (isSuccess) {
        onCloseModal();
      }
    });
  };

  const isLoading = (instance?.loadingStatus || loadingStatus) === LoadingStatus.LOADING;
  return (
    <>
      <Box sx={{ margin: '25px 0' }}>
        <TextField
          id="standard-basic"
          placeholder="Title"
          variant="standard"
          sx={{ width: '100%' }}
          value={state.title}
          onChange={onHandleChangeInput('title')}
        />
      </Box>
      {/*{isEditableModeRef.current && (*/}
      {/*  <Box sx={{ margin: '25px 0' }}>*/}
      {/*    <TextField*/}
      {/*      id="standard-basic"*/}
      {/*      label="Description"*/}
      {/*      multiline*/}
      {/*      variant="outlined"*/}
      {/*      rows={4}*/}
      {/*      sx={{ width: '100%' }}*/}
      {/*      value={state.description}*/}
      {/*      onChange={onHandleChangeInput('description')}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*)}*/}
      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          variant="solid"
          onClick={onSaveButtonHandle}
          // disabled={!Object.keys(instance.changedFields).length}
          loading={isLoading}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default ProductCategoryModal;
