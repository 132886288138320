import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { LoadingStatus } from '../../interface';
import Grid from '../../component/ui/grid/Grid';
import Loader from '../../component/ui/loader/Loader';
import DefaultModal from '../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../component/ui/default-modal/DefaultChildrenWrapperModal';
import DeleteModalContent from '../../component/ui/delete-modal-content/DeleteModalContent';
import useBaseGridBehavior from '../../utils/hooks/useBaseGridBehavior';
import useTableHeight from '../../utils/hooks/useTableHeight';
import MainStore from '../../store/MainStore';
import useColumns from './hook/useColumns';
import { CustomFieldClass } from '../../slass/data/custom-fields/CustomFieldClass';
import CustomFieldModal from './CustomFieldModal';
import useTableChangeOrder from '../../utils/hooks/useTableChangeOrder';
import useElementOrderInTable from '../../utils/hooks/useElementOrderInTable';

const CustomFieldsGrid = () => {
  const { state, action } = useBaseGridBehavior();
  const { selectedComponent, isOpenDeleteComponentModal, isOpenNewComponentModal, isOpenModal } = state;
  const { onDeleteComponentModal, onCloseModal, onOpenEditComponentModal, setIsOpenNewComponentModal } = action;

  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();

  const { loadingStatus, dataList, fetchDelete, fetchCreate } = MainStore.customFieldsStore;

  useEffect(() => {
    MainStore.customFieldsStore.fetchList();
    return () => MainStore.customFieldsStore.clear();
  }, []);

  const changeTableOrder = useTableChangeOrder(MainStore.customFieldsStore);
  const columns = useColumns({ onDeleteComponentModal, onOpenEditComponentModal, changeTableOrder });

  const orders = useElementOrderInTable(dataList);

  const row = dataList.reduce((prevState: { id: number; title: string }[], currentValue) => {
    const currentRow = {
      order: currentValue.order,
      id: currentValue.id,
      title: currentValue.title,
      maxOrder: orders.max,
      minOrder: orders.min,
    };
    prevState.push(currentRow);
    return prevState;
  }, []);

  const isOpenEditModal = (selectedComponent === 0 || selectedComponent) && isOpenModal;

  const customFieldInstance = useMemo(
    () => dataList?.find((customField) => customField.id === selectedComponent),
    [dataList, selectedComponent],
  );

  const modalTitle = (isOpenEditModal && 'Change Custom Field') || (isOpenNewComponentModal && 'Add Custom Field');

  const onAddCustomFieldHandler = () => {
    setIsOpenNewComponentModal(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '16px',
        }}
      >
        <Typography level="h3" component="h6">
          Custom Fields
        </Typography>
        <Button variant="solid" onClick={onAddCustomFieldHandler}>
          + Add Custom Field
        </Button>
      </Box>
      <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
        {componentHeight && loadingStatus !== LoadingStatus.LOADING ? (
          <Grid sellQuantity={sellQuantity} rows={row} columns={columns} />
        ) : (
          <Loader />
        )}

        <DefaultModal open={isOpenEditModal || isOpenNewComponentModal}>
          <DefaultChildrenWrapperModal modalTitle={modalTitle} onCloseModal={onCloseModal}>
            <CustomFieldModal
              instance={customFieldInstance}
              onCloseModalHandler={onCloseModal}
              dataList={dataList}
              fetchCreate={fetchCreate}
              loadingStatus={loadingStatus}
            />
          </DefaultChildrenWrapperModal>
        </DefaultModal>

        <DefaultModal open={isOpenDeleteComponentModal && !!customFieldInstance}>
          <DeleteModalContent<CustomFieldClass>
            onCloseModalHandler={onCloseModal}
            fetchDelete={fetchDelete}
            loadingStatus={loadingStatus}
            id={customFieldInstance?.id}
            title={customFieldInstance?.title}
          />
        </DefaultModal>
      </Box>
    </>
  );
};

export default observer(CustomFieldsGrid);
