import { makeObservable, observable, computed } from 'mobx';
import { IMarketingPillar } from '../../interface/calendarInterface';
import { MarketingChannelForCalendarClass } from './MarketingChannelForCalendarClass';

export class MarketingPillarForCalendarClass extends MarketingChannelForCalendarClass {
  @observable private _hasMarketingChannels: boolean;

  @observable private _marketingChannels: MarketingChannelForCalendarClass[] = [];

  constructor(data: IMarketingPillar) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
      id,
      title,
      marketing_campaigns,
      campaign_metrics,
      marketing_channels,
      has_marketing_channels,
      bg_color,
      calculated_budget,
      budget,
      order,
    } = data;
    super({ id, title, marketing_campaigns, campaign_metrics, bg_color, calculated_budget, budget, order });
    makeObservable(this);
    this._hasMarketingChannels = has_marketing_channels ?? false;
    this._marketingChannels =
      (marketing_channels &&
        Object.values(marketing_channels)
          ?.sort((a, b) => a.order - b.order)
          ?.map((channel) => new MarketingChannelForCalendarClass(channel))) ||
      [];
  }

  @computed get hasMarketingChannels() {
    return this._hasMarketingChannels;
  }

  @computed get marketingChannels() {
    return this._marketingChannels;
  }
}
