import { MainRoutesPath, PlanningRoutesPath, SettingsRoutesPath } from './router';

interface IBasePermission {
  id: number;
  name: string;
  codename: string;
  content_type: string;
}

export interface IResponsePermission extends IBasePermission {}

export interface IBaseGroupPermission {
  id: number;
  name: string;
  permissions: number[];
}

export interface IResponseGroupPermission extends IBaseGroupPermission {}

export interface IUpdateRequestGroupPermission {
  permissions: IBaseGroupPermission['permissions'];
}

export type PermissionType =
  | 'marketing_pillar.change_marketingpillar'
  | 'notification.change_notification'
  | 'planning_calendar.view_calendar'
  | 'planning_calendar.add_annotation'
  | 'planning_calendar.delete_annotationvalue'
  | 'planning_calendar.change_calendar'
  | 'marketing_campaign.change_marketingcampaignattachment'
  | 'marketing_campaign.change_marketingcampaignmetric'
  | 'marketing_campaign.delete_marketingcampaignmetricproductvalue'
  | 'campaign_metric.view_campaignmetrictotal'
  | 'planning_calendar.change_annotationvalue'
  | 'auth.change_permission'
  | 'planning_calendar.delete_calendar'
  | 'contenttypes.view_contenttype'
  | 'marketing_pillar.delete_marketingpillar'
  | 'contenttypes.change_contenttype'
  | 'sessions.view_session'
  | 'campaign_metric.change_campaignmetric'
  | 'marketing_campaign.add_marketingcampaignmetricproduct'
  | 'notification.view_notification'
  | 'marketing_campaign.add_marketingcampaignmetric'
  | 'token_blacklist.add_blacklistedtoken'
  | 'product.view_product'
  | 'marketing_campaign.view_marketingcampaign'
  | 'marketing_campaign.add_marketingcampaignmetricvalue'
  | 'admin.view_logentry'
  | 'auth.delete_group'
  | 'product.add_category'
  | 'planning_calendar.change_sharedcalendar'
  | 'user_activity_log.delete_useractivitilog'
  | 'campaign_metric.add_campaignmetric'
  | 'campaign_metric.change_campaignmetrictotal'
  | 'planning_calendar.add_calendar'
  | 'marketing_campaign.change_marketingcampaignmetricvalue'
  | 'competitor_calendar.change_competitorcalendar'
  | 'auth.view_permission'
  | 'marketing_campaign.change_marketingcampaignmetricproduct'
  | 'planning_calendar.view_annotationvalue'
  | 'company.view_company'
  | 'company.add_company'
  | 'marketing_campaign.view_marketingcampaignmetricproduct'
  | 'token_blacklist.change_blacklistedtoken'
  | 'marketing_campaign.delete_marketingcampaignmetric'
  | 'planning_calendar.change_annotation'
  | 'marketing_pillar.add_marketingpillar'
  | 'planning_calendar.view_annotation'
  | 'campaign_metric.add_campaignmetrictotal'
  | 'marketing_campaign.delete_marketingcampaignproduct'
  | 'user_activity_log.add_useractivitilog'
  | 'product.view_category'
  | 'product.add_product'
  | 'auth.delete_permission'
  | 'sessions.add_session'
  | 'contenttypes.add_contenttype'
  | 'competitor_calendar.view_competitorcalendar'
  | 'admin.add_logentry'
  | 'company.delete_company'
  | 'auth.add_permission'
  | 'user.delete_user'
  | 'campaign_metric.view_campaignmetric'
  | 'user.change_user'
  | 'marketing_campaign.view_marketingcampaignmetric'
  | 'marketing_campaign.view_marketingcampaignproduct'
  | 'notification.add_notification'
  | 'company.change_company'
  | 'sessions.delete_session'
  | 'marketing_campaign.change_marketingcampaignproduct'
  | 'marketing_campaign.add_marketingcampaignproduct'
  | 'marketing_campaign.delete_marketingcampaignattachment'
  | 'marketing_campaign.delete_marketingcampaign'
  | 'marketing_campaign.add_marketingcampaignattachment'
  | 'marketing_campaign.delete_marketingcampaignmetricproduct'
  | 'user.add_user'
  | 'token_blacklist.delete_blacklistedtoken'
  | 'auth.add_group'
  | 'marketing_campaign.view_marketingcampaignmetricproductvalue'
  | 'contenttypes.delete_contenttype'
  | 'marketing_pillar.view_marketingpillar'
  | 'competitor_calendar.add_competitorcalendar'
  | 'token_blacklist.delete_outstandingtoken'
  | 'token_blacklist.change_outstandingtoken'
  | 'marketing_campaign.change_marketingcampaign'
  | 'admin.delete_logentry'
  | 'token_blacklist.view_outstandingtoken'
  | 'planning_calendar.add_annotationvalue'
  | 'user_activity_log.view_useractivitilog'
  | 'product.change_product'
  | 'product.delete_product'
  | 'token_blacklist.add_outstandingtoken'
  | 'user.view_user'
  | 'marketing_campaign.view_marketingcampaignmetricvalue'
  | 'campaign_metric.delete_campaignmetrictotal'
  | 'token_blacklist.view_blacklistedtoken'
  | 'auth.view_group'
  | 'product.delete_category'
  | 'marketing_campaign.delete_marketingcampaignmetricvalue'
  | 'sessions.change_session'
  | 'planning_calendar.add_sharedcalendar'
  | 'planning_calendar.view_sharedcalendar'
  | 'campaign_metric.delete_campaignmetric'
  | 'auth.change_group'
  | 'admin.change_logentry'
  | 'planning_calendar.delete_annotation'
  | 'product.change_category'
  | 'competitor_calendar.delete_competitorcalendar'
  | 'marketing_campaign.add_marketingcampaign'
  | 'planning_calendar.delete_sharedcalendar'
  | 'marketing_campaign.add_marketingcampaignmetricproductvalue'
  | 'notification.delete_notification'
  | 'marketing_campaign.change_marketingcampaignmetricproductvalue'
  | 'user_activity_log.change_useractivitilog'
  | 'marketing_campaign.view_marketingcampaignattachment';

export const routerPermissionMap: Record<any, PermissionType[]> = {
  [MainRoutesPath.DASHBOARD_PAGE]: ['planning_calendar.view_calendar'],
  [MainRoutesPath.PLANNING_PAGE]: [
    'planning_calendar.view_calendar',
    'marketing_pillar.view_marketingpillar',
    'campaign_metric.view_campaignmetrictotal',
    'product.view_product',
    'product.view_category',
  ],
  [PlanningRoutesPath.CALENDAR]: ['planning_calendar.view_calendar'],
  [PlanningRoutesPath.MARKETING_PILLAR]: ['marketing_pillar.view_marketingpillar'],
  [PlanningRoutesPath.CAMPAIGN_METRICS]: ['campaign_metric.view_campaignmetrictotal'],
  [PlanningRoutesPath.CUSTOM_FIELDS]: ['planning_calendar.view_annotation'],
  [PlanningRoutesPath.PRODUCTS]: ['product.view_product', 'product.view_category'],
  [MainRoutesPath.CHANGELOG_PAGE]: ['user_activity_log.view_useractivitilog'],
  [MainRoutesPath.COMPETITORS_PAGE]: ['competitor_calendar.view_competitorcalendar'],
  [MainRoutesPath.SETTINGS_PAGE]: ['company.view_company', 'user.view_user'],
  [SettingsRoutesPath.COMPANIES]: ['company.view_company', 'user.view_user'],
  [SettingsRoutesPath.PERMISSIONS]: [],
};
