import React, { memo, useRef, useState } from 'react';
import { Box, Popover } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as CancelIcon } from '../../icon/Cancel.svg';
import scss from './PreviewImg.module.scss';

interface IProps {
  src: string;
  sx?: SxProps;
  alt?: string;
  onClick?: void | (() => any);
  onDeleteClick?: void | (() => any);
}
const PreviewImg = ({ src, sx = {}, alt = 'Preview', onClick, onDeleteClick }: IProps) => {
  const wrapperRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const onClickHandler = (clickFn) => () => {
    if (clickFn) {
      clickFn();
    }
  };

  const onContextMenu = (value: boolean) => (event) => {
    if (onDeleteClick) {
      event.preventDefault();
      setIsHovered(value);
    }
  };

  return (
    <>
      <Box
        ref={wrapperRef}
        sx={{ width: '110px', height: '80px', ...sx }}
        onClick={onClickHandler(onClick)}
        className={scss.wrapper}
        onContextMenu={onContextMenu(true)}
      >
        <img className={scss.img} src={src} alt={alt} />
      </Box>
      {wrapperRef && (
        <Popover
          open={!!isHovered}
          anchorEl={wrapperRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ClickAwayListener onClickAway={onContextMenu(false)}>
            <Box className={scss.deleteIcon} onClick={onClickHandler(onDeleteClick)}>
              <CancelIcon />
            </Box>
          </ClickAwayListener>
        </Popover>
      )}
    </>
  );
};

export default memo(PreviewImg);
