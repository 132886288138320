import React, { useRef } from 'react';
import { Box, Popover } from '@mui/material';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import { EventImpl } from '@fullcalendar/core/internal';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { MetricForCalendarClass } from '../../../slass/calendar/MetricForCalendarClass';
import {
  IUpdateCampaignMetricTotalValue,
  IUpdateProductsDataMarketingCampaignMetricValue,
  IUpdateRequestProductsDataValue,
  IUpdateResponseDataValue,
} from '../../../interface/marketingCampaignInterface';

import scss from '../Planning.module.scss';
import { patchProductsDataValueById } from '../../../service/marketingCampaignService';
import { updateEventWithoutGroupId } from '../../../component/planning-calendar/utils';
import { EventApi } from 'fullcalendar';

interface IProps {
  open: boolean;
  anchorEl: any;
  event: EventImpl;
  calendarRef;
  onClose: () => void;
}

const PopperChangeMetric = ({ open, onClose, anchorEl, event, calendarRef }: IProps) => {
  const instance = event?.extendedProps?.instance ?? {};
  const { patchDataValueById, setValue, value, productList } = instance as MetricForCalendarClass;
  const calendar = calendarRef?.current?.calendar;

  const isProductsChange = !!productList?.length;

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;
  const inputRef = useRef(null);

  const updateCalendarData = (
    campaignMetricTotal: IUpdateResponseDataValue['campaign_metric_total'],
    marketingCampaignMetrics?: IUpdateProductsDataMarketingCampaignMetricValue[],
  ) => {
    const changeValue = (
      metric: IUpdateCampaignMetricTotalValue | IUpdateProductsDataMarketingCampaignMetricValue,
      idMarker = '',
    ) => {
      const foundEvent = calendar?.getEventById(`${metric.id}${idMarker}`);
      if (foundEvent) {
        foundEvent.extendedProps?.instance.setValue(metric.value);
        foundEvent.setProp('title', `${metric.value}`);
      }
    };

    campaignMetricTotal.forEach((metric) => {
      changeValue(metric, '_f_x');
    });

    marketingCampaignMetrics?.forEach((metric) => {
      changeValue(metric, '_f');
    });
  };

  const fetchToUpdate = async () => {
    if (event.title === value.toString()) {
      onClose();
      return;
    }
    const eventId = event.id;
    const eventCopyValue = event.title as unknown as number;
    // updateEventWithoutGroupId(() => event.setProp('title', `${value}`), event);
    // debugger;
    // @ts-ignore
    // event._def.title = value;
    // debugger;

    // event.setProp('groupId', null);
    console.time('startRerender');
    calendar.refetchEvents();
    console.timeEnd('startRerender');

    // debugger;
    try {
      onClose();
      const responseData = await patchDataValueById();
      // calendar?.getEventById(eventId)?.setProp('title', `${responseData.value}`);
      // updateEventWithoutGroupId(() => event.setProp('title', `${value}`), event);
      if (responseData instanceof Object && !!calendar) {
        updateCalendarData(responseData.campaign_metric_total);
      }
    } catch (e) {
      setValue(eventCopyValue);
      calendar.refetchEvents();
      // updateEventWithoutGroupId(() => event.setProp('title', `${eventCopyValue}`), event);
    }
  };

  const fetchToMultiUpdate = async () => {
    const eventId = event.id;
    const eventCopyValue = event.title;

    const requestData = productList.reduce(
      (productState: { data: IUpdateRequestProductsDataValue[]; preUpdateValue: number }, product) => {
        const productPrice = product.price;
        productState.preUpdateValue += productPrice;
        productState.data.push({
          id: product.id,
          value: productPrice,
        });
        return productState;
      },
      { data: [], preUpdateValue: 0 },
    );

    event.setProp('title', `${requestData.preUpdateValue}`);
    try {
      onClose();
      const { data } = await patchProductsDataValueById(requestData.data);

      if (data instanceof Object) {
        const campaignMetricTotalList = Object.values(data.updated_data.campaign_metric_totals).flatMap(
          (campaignMetricTotal) => campaignMetricTotal.values,
        );

        const marketingCampaignMetrics = Object.values(data.updated_data.marketing_campaign_metrics).flat(1);

        updateCalendarData(campaignMetricTotalList, marketingCampaignMetrics);
      }
    } catch (e) {
      calendar?.getEventById(eventId)?.setProp('title', `${eventCopyValue}`);
    }
  };

  const updateFn = isProductsChange ? fetchToMultiUpdate : fetchToUpdate;

  const handleClickOutside = (eventClick) => {
    eventClick.stopPropagation();
    eventClick.preventDefault();

    if (inputRef.current && !inputRef.current.contains(eventClick.target) && event) {
      updateFn();
    }
  };

  // instance.marketingPillars.

  //
  // useEffect(() => {
  //   if (open && inputRef?.current) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   }
  //
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [open, handleClickOutside, inputRef?.current]);

  const onHandleChangeFieldValue = (inputEvent) => {
    setValue(inputEvent.target.value as number);
  };

  const onHandleKeyDown = (keyDownEvent: any) => {
    if (keyDownEvent.key === 'Enter') {
      updateFn();
      onClose();
    } else if (keyDownEvent.key === 'Escape') {
      // event.setProp('title', `${event.title}`);
      onClose();
    }
  };

  return (
    <Popover
      id={id}
      open={!!open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className={scss.popover}
    >
      <ClickAwayListener onClickAway={handleClickOutside} mouseEvent="onMouseDown" touchEvent="onTouchStart">
        <Box className={scss.popperChangeMetric_wrapper}>
          {isProductsChange ? (
            <>
              {productList.map((product) => (
                <Box
                  key={`PopperChangeMetric_${product.product.title}_${product.id}_${product.product.id}`}
                  className={scss.popperChangeMetric_productList}
                >
                  <span>{product.product.title}</span>
                  <TextField
                    ref={inputRef}
                    value={product.price}
                    variant="standard"
                    sx={{ maxWidth: '80px', position: 'relative' }}
                    onChange={(inputEvent) => product.setPrice(inputEvent.target.value)}
                    onKeyDown={onHandleKeyDown}
                  />
                </Box>
              ))}
            </>
          ) : (
            <TextField
              ref={inputRef}
              value={value}
              variant="standard"
              sx={{ maxWidth: '80px', position: 'relative' }}
              onChange={onHandleChangeFieldValue}
              onKeyDown={onHandleKeyDown}
              autoFocus
            />
          )}
        </Box>
      </ClickAwayListener>
    </Popover>
  );
};
export default observer(PopperChangeMetric);
