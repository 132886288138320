import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IMetric } from '../../interface/calendarInterface';
import { TypeOfCampaign, UnitOfCampaign } from '../../interface/campaignMetricInterface';
import { LoadingStatus } from '../../interface';
import { patchDataValueById } from '../../service/marketingCampaignService';
import validateNumber, { getValidNumber } from '../../utils/validateNumber';
import { IUpdateResponseDataValue } from '../../interface/marketingCampaignInterface';
import { ProductForCalendarClass } from './ProductForCalendarClass';
import { CampaignMetricsClass } from '../data/campaign-metrics/CampaignMetricsClass';

type ConstrictorData = Required<Pick<IMetric, 'id' | 'type' | 'unit'>> &
  Partial<IMetric> &
  Partial<CampaignMetricsClass>;

export class MetricForCalendarClass {
  @observable protected _loadingStatus = LoadingStatus.SUCCESS;

  private readonly _id: number;

  @observable private _title: string;

  private readonly _campaignMetricId: number;

  private readonly _campaignMetric: string;

  @observable protected _value: number;

  private _forDate: string;

  @observable protected _productList: ProductForCalendarClass[] = [];

  private readonly _type: TypeOfCampaign;

  private readonly _unit: UnitOfCampaign;

  constructor(data: ConstrictorData) {
    makeObservable(this);
    this._id = data.id;
    this._campaignMetricId = data.campaign_metric_id;
    this._productList = data.product_values?.map((product) => new ProductForCalendarClass(product)) || [];
    // @ts-ignore //todo write type
    this._campaignMetric = data.campaign_metric;
    this._value = data.value;
    this._forDate = data.for_date;
    this._unit = data.unit;
    this._type = data.type;
    this._title = data.title ?? '';
  }

  get id() {
    return this._id;
  }

  get campaignMetricId() {
    return this._campaignMetricId;
  }

  get campaignMetric() {
    return this._campaignMetric;
  }

  @computed get value() {
    return getValidNumber(this._value);
  }

  @computed get title() {
    return this._title ?? '';
  }

  @computed get productList() {
    return this._productList || [];
  }

  get forDate() {
    return this._forDate;
  }

  get type() {
    return this._type;
  }

  get unit() {
    return this._unit;
  }

  @action setTitle = (value: string) => {
    this._title = value;
  };

  @action setValue = (value: number | string) => {
    if (validateNumber(value) || value === '') {
      this._value = (value > 0 ? value : 0) as unknown as number;
    }
  };

  @action setProductList = (products: ProductForCalendarClass[]) => {
    this._productList = products;
  };

  setForDate = (value: string) => {
    this._forDate = value;
  };

  @action patchDataValueById = async (): Promise<IUpdateResponseDataValue | null> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await patchDataValueById(this._id, { value: Number(this._value) });

      runInAction(() => {
        this._value = data.value;
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return data;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return null;
    }
  };
}
