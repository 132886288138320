import { makeObservable, observable, runInAction, computed, action } from 'mobx';
import { UserClass } from '../slass/user/UserClass';
import { createUser, deleteUser, getUserById, getUsersList } from '../service/userServise';
import { ICreateUser, LoadingStatus } from '../interface';

class UserStore {
  @observable.deep private _users: UserClass[] = [];

  @observable private _loadingStatus: LoadingStatus = LoadingStatus.LOADING;

  constructor() {
    makeObservable(this);
  }

  @computed
  get userList() {
    return this._users;
  }

  @action
  clear = () => {
    this._users = [];
    this._loadingStatus = LoadingStatus.LOADING;
  };

  @action fetchUsers = async () => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getUsersList();

      const usersClass = data.map((user) => new UserClass(user));
      runInAction(() => {
        this._users = usersClass;
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
    }
  };

  fetchUserById = async (id: number) => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getUserById(id);

      const matchedUserIndex = this._users.findIndex((element) => element.id === data.id);

      runInAction(() => {
        if (~matchedUserIndex) {
          this._users[matchedUserIndex] = new UserClass(data);
        } else {
          this._users.push(new UserClass(data));
        }
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
    }
  };

  fetchCreateUser = async (createUserData: ICreateUser): Promise<boolean> => {
    try {
      const { data } = await createUser(createUserData);
      runInAction(() => {
        this._users.push(new UserClass(data));
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  fetchDeleteUser = async (id: number): Promise<boolean> => {
    try {
      const response = await deleteUser(id);
      if (response.status !== 204) {
        throw 'something wrong';
      }
      runInAction(() => {
        this._users = this._users.filter((user) => user.id !== id);
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @computed
  get loadingStatus() {
    return this._loadingStatus;
  }
}

export default UserStore;
