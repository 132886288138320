import dayjs from 'dayjs';
import { TotalsForCalendarClass } from '../../slass/calendar/TotalsForCalendarClass';
import { MarketingChannelForCalendarClass } from '../../slass/calendar/MarketingChannelForCalendarClass';
import { CalendarClass } from '../../slass/calendar/CalendarClass';
import { EventApi } from 'fullcalendar';

export const getResourceFromLocalStorage = () => JSON.parse(localStorage.getItem('calendarResource') || '{}');
export const setResourceFromLocalStorage = (json: any) =>
  localStorage.setItem('calendarResource', JSON.stringify(json));
//metricFromLocalStore = resourceFromLocalStorage[value.id]?.metricMap?.[metric.id]

// { [resource.id]: {
//                  metricMap: {
//                           [metric.id]: {
//                                          isHidden: boolean
//                           }
//                  }
//   }
// }

export const updateIsHidden = (
  json: any,
  resourceId: number | string,
  metricId: number | string,
  isHidden: boolean,
) => {
  if (!json[resourceId]) {
    json[resourceId] = {
      metricMap: {},
    };
  }

  if (!json[resourceId].metricMap[metricId]) {
    json[resourceId].metricMap[metricId] = {};
  }

  json[resourceId].metricMap[metricId].isHidden = isHidden;

  return json;
};

export const updateEventWithoutGroupId = (fn: Function, event: EventApi | null) => {
  event.setProp('groupId', undefined);
  // debugger;
  fn();
  // event.setProp('groupId', event.extendedProps.groupId);
};

export const getDataFromTotals = (instanceList: TotalsForCalendarClass[], preFooter: string) => {
  return instanceList.reduce(
    (state, currentValue, currentIndex) => {
      const isHeader = !!Number(currentValue.id) || !!Number(currentValue.id[0]);
      const orderSymbol = isHeader ? 'y' : 'x';

      // resource block
      const resourceTitle = {
        id: `${preFooter}_${orderSymbol}_${currentIndex}`,
        title: currentValue.title,
        eventClassNames: ['event-total', `${!currentIndex ? 'footer' : ''}`],
        extendedProps: {
          // isFooter: !isHeader,
        },
      };
      state.resources.push(resourceTitle);
      // event block
      currentValue.values.forEach((value) => {
        state.events.push({
          id: `${value.id}_f_x`,
          resourceId: resourceTitle.id,
          title: value.value,
          start: value.forDate,
          end: matchNextWeekDate(value.forDate),
          editable: false,
          extendedProps: {
            instance: value,
          },
        });
      });

      return state;
    },
    { resources: [], events: [] },
  );
};

export const compareCurrentDate = (
  dateStr: string,
  oldStartDate: Date,
  oldEndDate: Date,
): { startDate: Date; endDate: Date } => {
  const date = new Date(dateStr);
  let newStartDate = oldStartDate;
  let newEndDate = oldEndDate;

  if (!oldStartDate || date < oldStartDate) {
    newStartDate = date;
  }

  if (!oldEndDate || date > oldEndDate) {
    newEndDate = new Date(matchNextWeekDate(date));
  }

  return { startDate: newStartDate, endDate: newEndDate };
};

interface IResource {
  id: string;
  title: string;
  eventClassNames?: string[];
  extendedProps?: any;
  parentId?: string;
}

interface IEvents {
  id?: string;
  groupId?: any; //string | string[];
  resourceId: string;
  title: string;
  start: string;
  end: string;
  editable?: boolean;
  extendedProps?: Record<string, any>;
  durationEditable?: any;
  source?: any; //todo
  backgroundColor?: string;
}

interface IData {
  resources: IResource[];
  events: IEvents[];
}
// todo move to utils
export const matchNextWeekDate = (date: string | Date) => {
  const inputDate = date instanceof Date ? date : new Date(date);

  const outputDate = new Date(inputDate.getTime() + 6 * 24 * 60 * 60 * 1000);

  return outputDate.toISOString().substring(0, 10);
};

const CHART_NUMBER = 1040;

const charToNumber = (char: string) => char.charCodeAt(0) - CHART_NUMBER;

export const numberToChar = (num: number) => String.fromCharCode(CHART_NUMBER + num);

const titleToNumber = (title: string) => {
  let result = '';
  for (const char of title) {
    result += charToNumber(char);
  }
  return result;
};

const numberIdBuilder = (objects: any[]) => {
  let currentId = 1;
  for (const obj of objects) {
    obj.setIdForcalendar(currentId);
    currentId++;
  }
};

export const calendarDataBuilder = (instance: CalendarClass): IData => {
  const resourceFromLocalStorage = getResourceFromLocalStorage();
  // fix wrong display Pillars
  numberIdBuilder(instance.marketingPillars);
  //

  const dataFromMarketingPillars = instance.marketingPillars.reduce(
    (state: IData, currentValue, curIndex) => {
      const isPlanningCalendar = !!currentValue.id;
      ///
      const currentIndex = numberToChar(curIndex);
      ///

      // resource block
      let resourceTitle;
      if (!currentValue.hasMarketingChannels) {
        resourceTitle = {
          id: `${currentIndex}_${
            isPlanningCalendar ? numberToChar(currentValue.idForcalendar) : titleToNumber(currentValue.title)
          }_b`,
          title: currentValue.title,
          channel: '',
          eventClassNames: ['event-title'],
          eventBackgroundColor: currentValue.bgColor,
          extendedProps: {
            marketingPillar: currentValue,
          },
        };
        state.resources.push(resourceTitle);
      }

      //
      //

      const builder = (
        value: MarketingChannelForCalendarClass,
        parentResource: IResource,
        parentIndex: number | string,
        isChanel = false,
        isLastInChanel = false,
      ) => {
        const randomValueIdForCompetitor = Math.random();
        const resourceMetricsLastIndex = value.campaignMetricsList.length - 1;

        // usedDataList resource block ( protection against selecting an existing date)
        const usedDataList: string[] = [];

        // event block
        value.marketingCampaignsList?.forEach((activity) => {
          instance.setPushUsedCampaignTitle(activity.title); //? for what?

          usedDataList.push(activity.startDate);
          usedDataList.push(activity.endDate);

          const headerActivityId = `${parentResource.id}_${
            isPlanningCalendar ? activity.id : titleToNumber(activity.title)
          }`;

          const headerActivity = {
            id: headerActivityId,
            groupId: headerActivityId,
            resourceId: parentResource.id,
            title: activity.title,
            start: activity.startDate,
            end: activity.endDate,
            //for DnD
            editable: true, //!
            durationEditable: false,
            extendedProps: {
              marketingPillar: activity,
              eventInstanceList: [],
              groupId: headerActivityId,
            },
          };

          let headerActivityDate: { startDate?: Date; endDate?: Date } = {};

          activity.metricsList?.forEach((metric) => {
            if (!isPlanningCalendar) {
              headerActivityDate = compareCurrentDate(
                metric.forDate,
                headerActivityDate.startDate,
                headerActivityDate.endDate,
              );
            }

            const start = metric.forDate;
            const end = matchNextWeekDate(metric.forDate);
            // headerActivity.extendedProps.usedDataList.push(start, end); todo

            const eventId = `${metric.id}_f`;
            headerActivity.extendedProps.eventInstanceList.push({ eventId, instance: metric });

            // headerActivity.groupId.push(eventId);

            state.events.push({
              id: eventId,
              groupId: headerActivityId,
              resourceId: `${parentIndex}_${
                isPlanningCalendar
                  ? numberToChar(
                      value.campaignMetricsList?.find((campaignMetric) => campaignMetric.id === metric.campaignMetricId)
                        ?.order,
                    )
                  : metric.campaignMetric + randomValueIdForCompetitor
              }_f`,
              title: metric.value.toString(),
              start,
              end,
              editable: true, //!
              durationEditable: false,
              extendedProps: {
                instance: metric,
                groupId: headerActivityId,
                // to return on error DnD
                initDate: {
                  start,
                  end,
                },
              },
            });
          });

          if (!isPlanningCalendar) {
            headerActivity.start = dayjs(headerActivityDate.startDate).format('YYYY-MM-DD');
            headerActivity.end = dayjs(headerActivityDate.endDate).format('YYYY-MM-DD');
          }
          state.events.push(headerActivity);
        });

        const lastResource = state.resources.at(-1);

        // push for main resource block (marketing_pillar)
        lastResource.extendedProps.usedDataList = usedDataList;

        // resource block (campaign_metrics)
        value.campaignMetricsList?.forEach((metric, index) => {
          //if collapsed resource we don't build
          const metricFromLocalStore = resourceFromLocalStorage[value.id]?.metricMap?.[metric.id];
          if (!!metricFromLocalStore && metricFromLocalStore.isHidden) {
            // console.log(metricFromLocalStore.isHidden);
            parentResource.extendedProps.className = 'has-hidden-metric';
            return;
          }

          state.resources.push({
            id: `${parentIndex}_${
              isPlanningCalendar ? numberToChar(metric.order) : metric.title + randomValueIdForCompetitor
            }_f`,
            parentId: parentResource.id,
            title: metric.title,
            eventClassNames: resourceMetricsLastIndex === index ? ['event-data', 'event-end'] : ['event-data'],
            extendedProps: {
              isLast: resourceMetricsLastIndex === index,
              isChanel,
              isLastInChanel,
              usedDataList,
              // marketingPillarInstance for create new campaign in PlanningCalendarModalClass ( throw events)
              marketingPillarInstance: value,
            },
          });
        });
      };

      //
      //
      if (currentValue.hasMarketingChannels) {
        numberIdBuilder(currentValue.marketingChannels);
        currentValue.marketingChannels.forEach((marketingChannel, index) => {
          const isLastInChanel = currentValue.marketingChannels.length - 1 === index;

          const resourceChannelTitle = {
            id: `${currentIndex}_${numberToChar(marketingChannel.idForcalendar)}_d`,
            // parentId: resourceTitle.id,
            // "channel" - title for group channels (marketingPillar title)
            channel: !index ? currentValue.title : null,
            title: marketingChannel.title,
            eventClassNames: ['event-subTitle'],
            eventBackgroundColor: marketingChannel.bgColor,
            extendedProps: {
              ...(!index
                ? {
                    rowspan: currentValue.marketingChannels.reduce((prevValue, current) => {
                      //calculate rowspan with hidden blocks

                      prevValue +=
                        current.campaignMetricsList.filter((campaignMetric) => {
                          return !resourceFromLocalStorage[current.id]?.metricMap?.[campaignMetric.id]?.isHidden;
                        }).length + 1;

                      return prevValue;
                    }, 0),
                    rowSpanCollapsed: currentValue.marketingChannels.length,
                  }
                : {}),
              channelId: marketingChannel.id,
              marketingPillar: marketingChannel,
            },
          };
          //`${metric.id}`
          state.resources.push(resourceChannelTitle);

          builder(
            marketingChannel,
            resourceChannelTitle,
            `${marketingChannel.id || marketingChannel.idForcalendar}`,
            true,
            isLastInChanel,
          );
        });
      } else {
        builder(currentValue, resourceTitle, currentIndex);
      }

      //
      //

      return state;
    },
    { resources: [], events: [] },
  );

  // footer block
  const preFooter = numberToChar(instance.marketingPillars.length);
  const dataFromTotals = getDataFromTotals(instance.totals, preFooter);

  const returnedData = {
    events: dataFromMarketingPillars.events.concat(dataFromTotals.events),
    resources: dataFromMarketingPillars.resources.concat(dataFromTotals.resources),
  };

  const footerDivider = {
    id: `${preFooter}_w`,
    title: '',
    extendedProps: {
      isLast: true,
      isFooter: true,
    },
  };

  returnedData.resources.push(footerDivider);

  // customField block
  if (instance.annotations.length) {
    let largestAnnotationId = 0;

    instance.annotations.forEach((currentValue) => {
      if (currentValue.order > largestAnnotationId) {
        largestAnnotationId = currentValue.order;
      }

      // resource block
      const resourceTitle = {
        id: `@_${numberToChar(currentValue.order)}`,
        title: currentValue.title,
        eventClassNames: ['event-total', 'footer'],
        extendedProps: {
          // isFooter: !isHeader,
          annotationInstance: currentValue,
          usedDataList: [],
        },
      };

      // event block
      currentValue.values.forEach((value) => {
        const start = value.forDate;
        const end = matchNextWeekDate(value.forDate);

        // protection against selecting an existing date
        resourceTitle.extendedProps.usedDataList.push(start, end);

        returnedData.events.push({
          id: `@_${numberToChar(currentValue.id)}_1`,
          resourceId: resourceTitle.id,
          title: `${value.value}`,
          start,
          end,
          editable: false,
          extendedProps: {
            instance: value,
          },
        });
      });

      returnedData.resources.push(resourceTitle);
    });

    // headerDivider
    const headerDivider = {
      id: `@_${numberToChar(largestAnnotationId + 1)}`,
      title: '',
      extendedProps: {
        isLast: true,
        isFooter: true,
      },
    };

    returnedData.resources.push(headerDivider);
  }

  return returnedData;
};

// todo refactor
export const calendarDataBuilderResources = (instance: CalendarClass): IResource[] =>
  calendarDataBuilder(instance).resources;

export const calendarDataBuilderEvents = (instance: CalendarClass): IEvents[] => calendarDataBuilder(instance).events;
