import React, { useMemo } from 'react';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Icon } from '@mui/material';
import { addSlash, MainRoutesPath, PlanningRoutesPath, SettingsRoutesPath } from '../../../../interface/router';
import { routerPermissionMap } from '../../../../interface/permissionInterface';
import MainStore from '../../../../store/MainStore';

const useSidebarComponents = () => {
  const { isSuperuser } = MainStore.authStore;
  return useMemo(
    () => [
      {
        icon: (
          <Icon>
            <AutoGraphOutlinedIcon />
          </Icon>
        ),
        title: 'Dashboard',
        link: addSlash(MainRoutesPath.DASHBOARD_PAGE),
        children: [],
        hidden: isSuperuser,
        permission: routerPermissionMap[MainRoutesPath.DASHBOARD_PAGE],
      },
      {
        icon: (
          <Icon>
            <DateRangeOutlinedIcon />
          </Icon>
        ),
        title: 'Planning',
        link: addSlash(MainRoutesPath.PLANNING_PAGE),
        permission: routerPermissionMap[MainRoutesPath.PLANNING_PAGE],
        hidden: isSuperuser,
        children: [
          {
            title: 'Calendar',
            link: `${addSlash(MainRoutesPath.PLANNING_PAGE)}${addSlash(PlanningRoutesPath.CALENDAR)}`,
            permission: routerPermissionMap[PlanningRoutesPath.CALENDAR],
          },
          {
            title: 'Marketing Pillars',
            link: `${addSlash(MainRoutesPath.PLANNING_PAGE)}${addSlash(PlanningRoutesPath.MARKETING_PILLAR)}`,
            permission: routerPermissionMap[PlanningRoutesPath.MARKETING_PILLAR],
          },
          {
            title: 'Campaign Metrics',
            link: `${addSlash(MainRoutesPath.PLANNING_PAGE)}${addSlash(PlanningRoutesPath.CAMPAIGN_METRICS)}`,
            permission: routerPermissionMap[PlanningRoutesPath.CAMPAIGN_METRICS],
          },
          {
            title: 'Custom Fields',
            link: `${addSlash(MainRoutesPath.PLANNING_PAGE)}${addSlash(PlanningRoutesPath.CUSTOM_FIELDS)}`,
            permission: routerPermissionMap[PlanningRoutesPath.CUSTOM_FIELDS],
          },
          {
            title: 'Products',
            link: `${addSlash(MainRoutesPath.PLANNING_PAGE)}${addSlash(PlanningRoutesPath.PRODUCTS)}`,
            permission: routerPermissionMap[PlanningRoutesPath.PRODUCTS],
          },
        ],
      },
      {
        icon: (
          <Icon>
            <ManageHistoryIcon />
          </Icon>
        ),
        title: 'Changelog',
        link: addSlash(MainRoutesPath.CHANGELOG_PAGE),
        children: [],
        permission: routerPermissionMap[MainRoutesPath.CHANGELOG_PAGE],
      },
      {
        icon: (
          <Icon>
            <BackupTableOutlinedIcon />
          </Icon>
        ),
        title: 'Competitors',
        link: addSlash(MainRoutesPath.COMPETITORS_PAGE),
        hidden: isSuperuser,
        permission: routerPermissionMap[MainRoutesPath.COMPETITORS_PAGE],
        children: [
          //   {
          //     title: 'Calendar',
          //     link: `${addSlash(MainRoutesPath.COMPETITORS_PAGE)}${addSlash(CompetitorsRoutesPath.CURRENT_CALENDAR)}`,
          //     permission: routerPermissionMap[CompetitorsRoutesPath.CURRENT_CALENDAR],
          //   },
          //   {
          //     title: 'List of calendars',
          //     link: `${addSlash(MainRoutesPath.COMPETITORS_PAGE)}${addSlash(CompetitorsRoutesPath.LIST_OF_CALENDARS)}`,
          //     permission: routerPermissionMap[CompetitorsRoutesPath.LIST_OF_CALENDARS],
          //   },
        ],
      },
      {
        icon: (
          <Icon>
            <SettingsOutlinedIcon />
          </Icon>
        ),
        title: 'Settings',
        link: addSlash(MainRoutesPath.SETTINGS_PAGE),
        permission: routerPermissionMap[MainRoutesPath.SETTINGS_PAGE],
        children: [
          {
            title: 'Companies',
            link: `${addSlash(MainRoutesPath.SETTINGS_PAGE)}${addSlash(SettingsRoutesPath.COMPANIES)}`,
            permission: routerPermissionMap[SettingsRoutesPath.COMPANIES],
          },
          {
            title: 'Permissions',
            link: `${addSlash(MainRoutesPath.SETTINGS_PAGE)}${addSlash(SettingsRoutesPath.PERMISSIONS)}`,
            hidden: !isSuperuser,
            permission: [],
          },
        ],
      },
    ],
    [isSuperuser],
  );
};

export default useSidebarComponents;
