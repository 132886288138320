import React from 'react';
import { observer } from 'mobx-react-lite';
import MarketingPillarGrid from './MarketingPillarGrid';

const MarketingPillarsPage = () => (
  <section className="section">
    <MarketingPillarGrid />
  </section>
);

export default observer(MarketingPillarsPage);
