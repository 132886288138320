import { action, computed, makeObservable, observable } from 'mobx';
import {
  Channels,
  HasTotal,
  IRequestPatchCampaignMetricProps,
  MetricForCalculation,
  TotalTitle,
  TypeOfCampaign,
  UnitOfCampaign,
  Value,
} from '../../../interface/campaignMetricInterface';
import { BaseClass } from '../BaseClass';
import validateNumber, { getValidNumber } from '../../../utils/validateNumber';

export class CampaignMetricsClass extends BaseClass {
  @observable protected _type: TypeOfCampaign;

  @observable protected _unit: UnitOfCampaign;

  @observable protected _company: number;

  @observable protected _createdAt: Date;

  @observable protected _updatedAt: Date;

  @observable protected _metricForCalculation: MetricForCalculation;

  @observable protected _value: Value;

  // @observable protected _channels: Channels;

  @observable protected _hasTotal: HasTotal;

  @observable protected _totalTitle: TotalTitle;
  // @observable protected _totalTitle: TotalTitle;

  @observable protected _autoDistribute: boolean;

  @observable protected _order: number;

  constructor(props, serviceConductor) {
    super(serviceConductor);
    this.snakeToCamelBuilder(props);
    makeObservable(this);
  }

  @action protected _builder = (key, value) => {};

  @computed get autoDistribute() {
    return this._autoDistribute || false;
  }

  @computed get order() {
    return this._order;
  }

  @action setOrder = (order: number) => {
    this._order = order;
  };

  @action setAutoDistribute = (value: boolean) => {
    this._autoDistribute = value;
  };

  @computed get type() {
    return this._type;
  }

  @computed get unit() {
    return this._unit;
  }

  @computed get company() {
    return this._company;
  }

  @computed get metricForCalculation() {
    return this._metricForCalculation;
  }

  @computed get value() {
    return getValidNumber(this._value);
  }

  // @computed get channels() {
  //   return this._channels;
  // }

  @computed get hasTotal() {
    return this._hasTotal;
  }

  @computed get totalTitle() {
    return this._totalTitle;
  }

  @computed get createdAt() {
    return this._createdAt;
  }

  @computed get updatedAt() {
    return this._updatedAt;
  }

  @action setValue = (value: number | string) => {
    if (validateNumber(value) || value === '') {
      this._value = (value > 0 ? value : 0) as unknown as number;
    }
  };

  @action fetchPatch = async (data: IRequestPatchCampaignMetricProps) => this.fetchPatchBase(data);
}
