import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const historyPush = (path: string) => {
  if (/^(http(s)?:\/\/)/.test(path)) {
    if (path.startsWith(window.location.origin)) {
      const newPath = path.replace(window.location.origin, '');
      history.push(newPath);
    } else {
      window.location.href = path;
    }
  }
  history.push(path);
};

export default history;
