import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import scss from './Sidebar.module.scss';

import SidebarClass from '../../../store/ui/SidebarStore';
import useSidebarComponents from './hook/useSidebarComponents';
import SidebarExpand from './sidebar-expand/SidebarExpand';

const Sidebar = () => {
  const { isExpand } = SidebarClass;

  const sidebarComponents = useSidebarComponents();

  return (
    <nav className={cn(scss.wrapper, { [scss.isCollapseWrapper]: !isExpand })}>
      {isExpand ? <SidebarExpand sidebarComponents={sidebarComponents} /> : null}
    </nav>
  );
};

export default observer(Sidebar);
