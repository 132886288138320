import { useCallback, useState } from 'react';

const useBaseGridBehavior = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenNewComponentModal, setIsOpenNewComponentModal] = useState<boolean>(false);
  const [isOpenDeleteComponentModal, setIsOpenDeleteComponentModal] = useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState<string | number>(null);

  const onOpenEditComponentModal = useCallback(
    (id) => () => {
      setSelectedComponent(id);
      setIsOpenModal(true);
    },
    [],
  );

  const onDeleteComponentModal = useCallback(
    (id) => () => {
      setSelectedComponent(id);
      setIsOpenDeleteComponentModal(true);
    },
    [],
  );

  const onCloseModal = useCallback(() => {
    setIsOpenDeleteComponentModal(false);
    setIsOpenModal(false);
    setIsOpenNewComponentModal(false);
    setSelectedComponent(null);
  }, []);

  return {
    state: { isOpenModal, isOpenNewComponentModal, isOpenDeleteComponentModal, selectedComponent },
    action: {
      onOpenEditComponentModal,
      onDeleteComponentModal,
      onCloseModal,
      setIsOpenNewComponentModal,
    },
  };
};

export default useBaseGridBehavior;
