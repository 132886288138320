export class BaseMetricsForCalendarClass {
  protected _id: number;

  protected _title: string;

  constructor({ id, title }: { id: number; title: string }) {
    this._id = id;
    this._title = title;
  }

  public get id() {
    return this._id;
  }

  public get title() {
    return this._title;
  }
}
