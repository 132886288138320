import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { GridColDef } from '@mui/x-data-grid';
import { formatPrefix } from 'd3-format';
import { Box } from '@mui/material';
import IconButton from '@mui/joy/IconButton';
import dayjs from 'dayjs';
import useTableHeight from '../../../utils/hooks/useTableHeight';
import useBaseGridBehavior from '../../../utils/hooks/useBaseGridBehavior';
import MainStore from '../../../store/MainStore';
import Grid from '../../../component/ui/grid/Grid';
import Loader from '../../../component/ui/loader/Loader';
import { ReactComponent as EditIcon } from '../../../icon/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../icon/DeleteIcon.svg';
import DefaultModal from '../../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../../component/ui/default-modal/DefaultChildrenWrapperModal';
import { CompanyClass } from '../../../slass/data/company/CompanyClass';
import DeleteModalContent from '../../../component/ui/delete-modal-content/DeleteModalContent';
import CompanyModal from './CompanyModal';
import { startWeekDayTitleMap } from '../../../interface/calendarInterface';

interface IProps {
  companyList: CompanyClass[];
}
const CompaniesGrid = ({ companyList }: IProps) => {
  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();

  const { state, action } = useBaseGridBehavior();
  const { selectedComponent, isOpenDeleteComponentModal, isOpenNewComponentModal, isOpenModal } = state;
  const { onDeleteComponentModal, onCloseModal, onOpenEditComponentModal } = action;

  const { loadingStatus, fetchDelete } = MainStore.companyStore;

  const columns: GridColDef[] = useMemo(
    () => [
      // { field: 'id', headerName: 'ID', hideable: false },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 100,
        maxWidth: 300,
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        minWidth: 200,
        maxWidth: 400,
      },
      {
        field: 'createdAt',
        headerName: 'Created Date',
        minWidth: 150,
      },
      {
        field: 'budget',
        headerName: 'Budget',
        minWidth: 100,
        maxWidth: 200,
      },
      {
        field: 'calendarWeekday',
        headerName: 'Start Week Day',
        flex: 1,
        minWidth: 100,
      },
      // {
      //   field: 'calendarDaterange',
      //   headerName: 'Daterange',
      //   flex: 1,
      //   minWidth: 100,
      // },
      {
        field: 'edit',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params) => (
          <IconButton color="primary" variant="plain" disabled={false} onClick={onOpenEditComponentModal(params.id)}>
            <EditIcon />
          </IconButton>
        ),
      },
      {
        field: 'delete',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params) => (
          <IconButton color="danger" variant="plain" disabled={false} onClick={onDeleteComponentModal(params.id)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    ],
    [],
  );

  const row = companyList
    .reduce((prevState, currentValue) => {
      const currentRow = {
        id: currentValue.id,
        title: currentValue.title,
        description: currentValue.description,
        createdAt: dayjs(currentValue.createdAt, 'YYYY-MM-DD').format('MM-DD-YYYY'),
        budget: currentValue.budget
          ? formatPrefix('$.1', currentValue.budget)(currentValue.budget)
          : currentValue.budget,
        calendarWeekday: startWeekDayTitleMap[currentValue.calendarWeekday],
        // calendarDaterange: currentValue.calendarDaterange,

        // is_active: currentValue.isActive,
        // editUser: (
        //
        // ),
      };
      prevState.push(currentRow);
      return prevState;
    }, [])
    .sort((a, b) => a.id - b.id);

  const companyInstance = useMemo(
    () => companyList.find((product) => product.id === selectedComponent),
    [companyList, selectedComponent],
  );

  const isOpenEditModal = (selectedComponent === 0 || selectedComponent) && isOpenModal;

  return (
    <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
        {componentHeight ? <Grid sellQuantity={sellQuantity} rows={row} columns={columns} /> : <Loader />}

        <DefaultModal open={isOpenEditModal || isOpenNewComponentModal}>
          <DefaultChildrenWrapperModal modalTitle="Change Company" onCloseModal={onCloseModal}>
            <CompanyModal instance={companyInstance} onCloseModal={onCloseModal} />
          </DefaultChildrenWrapperModal>
        </DefaultModal>
        <DefaultModal open={isOpenDeleteComponentModal}>
          <DeleteModalContent<CompanyClass>
            onCloseModalHandler={onCloseModal}
            fetchDelete={fetchDelete}
            loadingStatus={loadingStatus}
            id={companyInstance?.id}
            title={companyInstance?.title}
          />
        </DefaultModal>
      </Box>
  );
};

export default observer(CompaniesGrid);
