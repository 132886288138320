const useTableChangeOrder =
  (
    store: any, //MarketingPillarStore | CampaignMetricsStore
  ) =>
  (direction: 'up' | 'down', id: number) => {
    const sortedStore = store.dataList.sort((a, b) => a - b);
    let currentElementIndex = 0;
    const currentElement = sortedStore.find((dataClass, index) => {
      currentElementIndex = index;
      return dataClass.id === id;
    });
    const neighborElement =
      direction === 'up' ? sortedStore[currentElementIndex - 1] : sortedStore[currentElementIndex + 1];

    store.changeOrder([
      { order: neighborElement.order, id: currentElement.id },
      { order: currentElement.order, id: neighborElement.id },
    ]);
  };

export default useTableChangeOrder;
