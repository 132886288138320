import dayjs from 'dayjs';
import { MarketingCampaignForCalendarClass } from '../../../slass/calendar/MarketingCampaignForCalendarClass';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';
import { MarketingChannelForCalendarClass } from '../../../slass/calendar/MarketingChannelForCalendarClass';
import { MarketingPillarForCalendarClass } from '../../../slass/calendar/MarketingPillarForCalendarClass';

export interface ICalculateBudgetTreemap {
  name: string;
  children: { name: string; children?: ICalculateBudgetTreemap; predicted?: number; actual?: number }[];
}

export interface IReturn {
  budget: {
    title: string;
    id: number;
    Predicted: number;
    Actual: number;
    campaignDuration: number;
    marketingCampaign: MarketingCampaignForCalendarClass;
    startDay: dayjs.Dayjs;
    endDay: dayjs.Dayjs;
    parentInstance: MarketingChannelForCalendarClass;
    marketingPillar: MarketingPillarForCalendarClass;
  }[];
  deadline: {
    day: string;
    value: number;
    labelList: string[];
  }[];
  budgetTreemap: ICalculateBudgetTreemap;
  actualBudget: number;
}
const calculateBudgetDeadlineData = (instance: CalendarClass): IReturn => {
  const data = instance.marketingPillars.reduce(
    (state, marketingPillar) => {
      // budgetTreemap
      const budgetTreemap = {
        name: marketingPillar.title,
        children: [],
      };

      const iterating = (
        marketingCampaignsList: MarketingCampaignForCalendarClass[],
        childrenBudgetTreemapArrayInstance = [],
        parentInstance: MarketingChannelForCalendarClass,
      ) => {
        marketingCampaignsList.forEach((marketingCampaign) => {
          const startDay = dayjs(marketingCampaign.startDate);
          const endDay = dayjs(marketingCampaign.endDate);

          const campaignDuration = endDay.diff(startDay, 'day');

          // budget
          state.budget.push({
            title: marketingCampaign.title,
            id: marketingCampaign.id,
            Predicted: marketingCampaign.budget,
            Actual: marketingCampaign.calculatedBudget,
            campaignDuration,
            marketingCampaign,
            startDay,
            endDay,
            parentInstance,
            marketingPillar,
          });

          // deadline
          if (marketingCampaign.deadlineDate?.length) {
            if (state.deadline.has(marketingCampaign.deadlineDate)) {
              const deadlineDate = state.deadline.get(marketingCampaign.deadlineDate);
              deadlineDate.value += 1;
              deadlineDate.labelList.push(marketingCampaign.title);
            } else {
              state.deadline.set(marketingCampaign.deadlineDate, {
                day: marketingCampaign.deadlineDate,
                value: 1,
                labelList: [marketingCampaign.title],
              });
            }
          }

          // budgetTreemap
          childrenBudgetTreemapArrayInstance?.push({
            name: marketingCampaign.title,
            predicted: marketingCampaign.budget,
            actual: marketingCampaign.calculatedBudget,
          });

          // actualBudget
          state.actualBudget += marketingCampaign.calculatedBudget;
        });
      };

      if (marketingPillar.hasMarketingChannels) {
        marketingPillar.marketingChannels.forEach((marketingChannel) => {
          const arrayInstance =
            budgetTreemap.children[budgetTreemap.children.push({ name: marketingChannel.title, children: [] }) - 1]
              .children;

          iterating(marketingChannel.marketingCampaignsList, arrayInstance, marketingChannel);
        });
      } else {
        iterating(marketingPillar.marketingCampaignsList, budgetTreemap.children, marketingPillar);
      }

      state.budgetTreemap.push(budgetTreemap);

      return state;
    },
    { budget: [], deadline: new Map(), budgetTreemap: [], actualBudget: 0 },
  );

  // convert deadline to array
  const deadlineList: IReturn['deadline'] = Array.from(data.deadline, ([_, value]) => value);

  return {
    budget: data.budget,
    deadline: deadlineList,
    budgetTreemap: data.budgetTreemap as unknown as ICalculateBudgetTreemap,
    actualBudget: data.actualBudget,
  };
};

export default calculateBudgetDeadlineData;
