import { computed, makeObservable, observable } from 'mobx';
import { ITotal } from '../../interface/calendarInterface';
import { TotalsEventClass } from './TotalsEventClass';

export class TotalsForCalendarClass {
  @observable private readonly _id: string;

  @observable private readonly _title: ITotal['title'] = '';

  @observable private readonly _values: TotalsEventClass[] = [];

  @observable private readonly _predictedValues: TotalsEventClass[] = [];

  constructor(props: ITotal, id) {
    makeObservable(this);
    this._id = id;
    this._title = props.title;
    this._values = Array.isArray(props.values)
      ? props.values.map((value) => new TotalsEventClass(value))
      : Object.entries(props.values).map(
          ([date, { value }]) => new TotalsEventClass({ for_date: date, value, id: Math.random() + value }),
        );
    this._predictedValues = props.predicted_values
      ? props.predicted_values.map((value) => new TotalsEventClass(value))
      : [];
  }

  @computed get title() {
    return this._title;
  }

  @computed get values() {
    return this._values;
  }

  @computed get predictedValues() {
    return this._predictedValues;
  }

  @computed get id() {
    return this._id;
  }
}
