import React, { memo, useCallback } from 'react';
import { DateSelectArg, DateSpanApi, DatesSetArg, EventClickArg, EventDropArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin, { EventDragStopArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { CalendarClass } from '../../slass/calendar/CalendarClass';
import { calendarDataBuilderEvents, calendarDataBuilderResources } from './utils';
import { DateUtils } from '../../utils/DateUtils';
import { startWeekDayTitleMap } from '../../interface/calendarInterface';
import { EventImpl } from '@fullcalendar/core/internal';

const renderEventContent = (props: any) => {
  return props.event.title;
};

export const findNextResource = (resourceId: string) => {
  return document.querySelector<HTMLElement>(
    `tr[role="row"] td[data-resource-id="${resourceId}"] + td[data-resource-id="${resourceId}"]`,
  );
};

export const findCurrentResource = (resourceId: string) => {
  return document.querySelector<HTMLElement>(`tr[role="row"] td[data-resource-id="${resourceId}"]`);
};

export const findFirstChildWithClass = (element: HTMLElement, childClass = 'fc-datagrid-cell-frame') => {
  if (element) {
    return element.querySelector(`.${childClass}`);
  }
  return null;
};

const monthsBetweenDates = (dateStart: string, dateEnd: string) => {
  const diff = Math.abs(new Date(dateStart).getTime() - new Date(dateEnd).getTime());

  const months = diff / (1000 * 60 * 60 * 24 * 30);

  return Math.round(months);
};

const PlanningCalendar = ({
  handleEventClick,
  instance,
  offsetHeight,
  onSelectHandler,
  calendarRef,
  datesSet,
  isShare = false,
  onCollapse,
  eventDrop,
  eventDragStop,
  eventResize,
  eventAllow,
}: {
  handleEventClick?: (clickInfo: EventClickArg) => void;
  instance: CalendarClass;
  offsetHeight: number;
  onSelectHandler?: (selectedEl: DateSelectArg) => void;
  calendarRef?: any;
  datesSet?: (datesSetArg: DatesSetArg) => void;
  isShare?: boolean;
  onCollapse: (instance: CalendarClass) => void;
  eventDrop?: (eventDropInfo: EventDropArg) => void;
  eventDragStop?: (eventDropInfo: EventDragStopArg) => void;
  eventResize?: (eventResizeInfo: EventResizeDoneArg) => void;
  eventAllow?: (span: DateSpanApi, movingEvent: EventImpl | null) => boolean;
}) => {
  const resourceRenderedEvent = (resource) => {
    const resourceId = resource.resource._resource.id;
    const nextEl = findNextResource(resourceId);

    if (!nextEl) {
      return;
    }

    const collapseCol = () => {
      resource.el.style.display = 'none';
      nextEl.setAttribute('colspan', '2');
      nextEl.style.width = '200%';
    };

    nextEl.setAttribute('title', resource.resource._resource.title);

    const setBackgroundColor = (element) => {
      if (element?.extendedProps?.marketingPillar) {
        const { bgColor } = element.extendedProps.marketingPillar;
        nextEl.style.backgroundColor = bgColor;
        element.setProp('eventBackgroundColor', bgColor);
        nextEl.style.backgroundColor = bgColor;
        if (element.extendedProps.className) {
          findFirstChildWithClass(findNextResource(resourceId))?.classList?.add(element.extendedProps.className);
        }
      }
    };

    if (resourceId.includes('b') && !resourceId.includes('c')) {
      nextEl.classList.add('resource-header');
      setBackgroundColor(resource.resource);
      collapseCol();
    } else if (resourceId.includes('d')) {
      nextEl.classList.add('resource-headerChannels');
      setBackgroundColor(resource.resource);
      if (!resource.resource._resource.extendedProps?.channel) {
        // hide channels column
        resource.el.style.display = 'none';
      }
      if (resource.resource._resource.extendedProps?.rowspan) {
        resource.el.setAttribute('rowspan', resource.resource._resource.extendedProps.rowspan);
        resource.el.classList.add('resource-headerChannelsWrapper');

        const headerChannelsWrapperBgColor = resource.resource._resource.extendedProps.marketingPillar.bgColor;
        resource.el.style.backgroundColor =
          headerChannelsWrapperBgColor.length === 7
            ? `${headerChannelsWrapperBgColor}80`
            : headerChannelsWrapperBgColor;
      }
    } else {
      if (!resource.resource._resource.extendedProps?.isChanel) {
        collapseCol();
      } else {
        if (
          resource.resource._resource.extendedProps?.isLast &&
          resource.resource._resource.extendedProps?.isLastInChanel
        ) {
          nextEl.classList.add('resource-subHeader-end');
        }
        resource.el.style.display = 'none';
      }

      nextEl.classList.add('resource-subHeader');
      if (resource.resource._resource.extendedProps?.isLast) {
        // nextEl.classList.add('resource-subHeader-end');
      } else if (resource.resource._resource.extendedProps.isFooter) {
        nextEl.classList.add('resource-subHeader-footer');
        collapseCol();
      }
    }
  };

  const titleHeaderDidMount = (element) => {
    element.el.setAttribute('colspan', '2');
  };

  const onSelectAllow = useCallback(
    (selectInfo: DateSpanApi) => {
      // protection against selecting an existing date
      if (selectInfo.resource.extendedProps.usedDataList) {
        const foundRangeList = DateUtils.findDatesInRange(
          selectInfo.resource.extendedProps.usedDataList,
          selectInfo.startStr,
          selectInfo.endStr,
        );

        if (foundRangeList.length) {
          return false;
        }
      }

      return (
        onSelectHandler &&
        !(
          selectInfo.resource.id.includes('x') ||
          selectInfo.resource.id.includes('y') ||
          selectInfo.resource.id.includes('w') ||
          selectInfo.resource.extendedProps.isFooter
        )
      );
    },

    [onSelectHandler],
  );

  //* handleResizeParent

  const handleResizeParent = useCallback((width) => {
    (document.querySelectorAll('.fc-timeline-lane.fc-resource') as NodeListOf<HTMLElement>).forEach((child) => {
      child.style.width = `${width}px`;
    });
  }, []);

  const calendarDidMount = useCallback(
    (datesSetArg: DatesSetArg) => {
      const fcTimelineBody = document.querySelector('.fc-timeline-body') as HTMLElement;
      setTimeout(() => {
        handleResizeParent(fcTimelineBody.clientWidth);
      }, 0);
      handleResizeParent(fcTimelineBody.clientWidth);
      if (datesSet) {
        datesSet(datesSetArg);
      }

      //change header "DATE" to StartDay
      const foundResourceDiv = document.querySelectorAll('[role="columnheader"]')?.[1];

      const foundResourceTitle = foundResourceDiv?.getElementsByTagName('span')?.[0];
      if (foundResourceTitle && ((instance.startWeekDay ?? false) || instance.startWeekDay === 0)) {
        foundResourceTitle.innerText = `${startWeekDayTitleMap[instance.startWeekDay]} - Start Day`;
      }

      //delete default header StartDay
      const foundEventDateBlock = document.querySelectorAll('.fc-timeline-header-row')?.[1]?.getElementsByTagName('a');

      if (foundEventDateBlock) {
        for (let ind = 0; ind < foundEventDateBlock.length; ind++) {
          const foundHeaderEventEl = foundEventDateBlock[ind];
          foundHeaderEventEl.innerText = `\u00A0 ${foundHeaderEventEl.innerText.match(/\d+/g)?.[0]} \u00A0`;
          // const closestAncestor = foundHeaderEventEl.closest('th') as HTMLElement;
          // if (closestAncestor) {
          //   closestAncestor.style.width = '48px';
          // }
        }
      }
      // resourceRenderedEvent()
    },
    [datesSet, handleResizeParent, instance],
  );

  //*

  return (
    <FullCalendar
      schedulerLicenseKey="0946664469-fcs-1687234197"
      ref={calendarRef}
      plugins={[resourceTimelinePlugin, interactionPlugin]}
      customButtons={{
        collapse: {
          text: 'Collapse',
          click: (mouseEvent, htmlElement) => {
            onCollapse(instance);
            // instance.setTotals();
            // calendarRef.current.getApi().refetchEvents();
            // if (htmlElement.textContent === 'Wrap') htmlElement.innerHTML = 'Unwrap';
            // else htmlElement.innerHTML = 'Wrap';
          },
          hint: 'Wrap/Unwrap Metrics',
        },
      }}
      headerToolbar={{
        left: isShare ? 'collapse' : 'prev,next,collapse',
        center: 'title',
        right: '',
      }}
      initialView="resourceTimelineWeek"
      fixedWeekCount
      showNonCurrentDates
      views={{
        resourceTimelineWeek: {
          type: 'timeline',
          duration: { months: monthsBetweenDates(instance.startDate, instance.endDate) },
          buttonText: 'week',
          hiddenDays: instance.hiddenDays,
          columnHeaderFormat: {},
        },
      }}
      initialDate={instance.startDate}
      resourceAreaWidth="300px"
      resourceAreaColumns={[
        {
          field: 'channel',
          headerContent: '',
        },
        {
          field: 'title',
          headerContent: 'Date',
          headerDidMount: titleHeaderDidMount,
        },
      ]}
      stickyHeaderDates
      resources={(fetchInfo, successCallback, failureCallback) => {
        successCallback(calendarDataBuilderResources(instance));
      }}
      events={(info, successCallback, failureCallback) => {
        successCallback(calendarDataBuilderEvents(instance));
      }}
      editable
      eventDurationEditable={false}
      eventStartEditable={!!eventDrop}
      eventResourceEditable={false}
      eventOverlap={false}
      eventDrop={(eventDropInfo) => {
        if (eventDrop) eventDrop(eventDropInfo);
      }}
      eventResize={(eventResizeInfo) => {
        if (eventResize) eventResize(eventResizeInfo);
      }}
      eventDragStart={(info) => {
        // console.log('eventDragStart', info);
      }}
      eventDragStop={(info) => {
        if (eventDragStop) eventDragStop(info);
      }}
      // @ts-ignore
      eventAllow={(dropInfo, draggedEvent) => {
        if (eventAllow) {
          return eventAllow(dropInfo, draggedEvent);
        } else {
          console.log('eventAllow');
          return false;
        }
      }}
      eventClick={(eventClickArg) =>
        eventClickArg.event.extendedProps?.instance?.type !== 'calculated_by_product' &&
        handleEventClick &&
        handleEventClick(eventClickArg)
      }
      resourceLabelDidMount={resourceRenderedEvent}
      eventContent={renderEventContent}
      height={offsetHeight || 'auto'}
      select={onSelectHandler}
      selectable
      selectAllow={onSelectAllow}
      datesSet={calendarDidMount}
      eventMinWidth={5}
    />
  );
};

export default memo(PlanningCalendar);
