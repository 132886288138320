import { Box } from '@mui/material';
import Typography from '@mui/joy/Typography';
import React, { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import useTableHeight from '../../utils/hooks/useTableHeight';
import Loader from '../../component/ui/loader/Loader';
import Grid from '../../component/ui/grid/Grid';
import useColumns from './hook/useColumns';
import useRows from './hook/useRows';
import DefaultModal from '../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../component/ui/default-modal/DefaultChildrenWrapperModal';
import JsonViewCompare from '../../component/NotificationDrawer/JsonViewCompare';

const ChangelogGrid = () => {
  const [viewData, setViewData] = useState<{ prevObjectData: Record<string, any>; objectData: Record<string, any> }>({
    prevObjectData: null,
    objectData: null,
  });
  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();

  const { rows, paginationModel, setPaginationModel, totalRowCount, isLoading } = useRows(sellQuantity);

  const onReadMore = (prevObjectData: Record<string, any>, objectData: Record<string, any>) => () => {
    setViewData({ objectData, prevObjectData });
  };

  const columns: GridColDef[] = useColumns({ onReadMore });

  const onCloseViewDataModal = () => {
    setViewData({ objectData: null, prevObjectData: null });
  };

  const [rowCountState, setRowCountState] = React.useState(totalRowCount || 0);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) => (totalRowCount !== undefined ? totalRowCount : prevRowCountState));
  }, [totalRowCount, setRowCountState]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '16px',
        }}
      >
        <Typography level="h3" component="h6">
          Changelog
        </Typography>
      </Box>
      <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
        {componentHeight ? (
          <Grid
            sellQuantity={sellQuantity}
            rows={rows}
            columns={columns}
            settings={{
              paginationModel,
              onPaginationModelChange: setPaginationModel,
              rowCount: rowCountState,
              paginationMode: 'server',
              loading: isLoading,
            }}
          />
        ) : (
          <Loader />
        )}

        <DefaultModal open={!!(viewData.objectData && viewData.prevObjectData)}>
          <DefaultChildrenWrapperModal
            modalTitle="Changelog"
            onCloseModal={onCloseViewDataModal}
            sx={{ width: '720px' }}
          >
            <JsonViewCompare newData={viewData.objectData} oldData={viewData.prevObjectData} />
          </DefaultChildrenWrapperModal>
        </DefaultModal>
      </Box>
    </>
  );
};

export default ChangelogGrid;
