import React from 'react';
import Typography from '@mui/joy/Typography';
import { Box, Paper, Stack } from '@mui/material';
import * as NivoColors from '@nivo/colors';
import PieChart from './PieChart';
import { IReturn } from '../utils/calculateBudgetDeadlineData';
import BarBlockWrapper from '../bar/BarBlockWrapper';
import scss from '../DashboardPage.module.scss';
import getDataElementIndexByList from '../../../utils/getDataElementIndexByList';
import Chip from '@mui/joy/Chip';

const nivoColors = NivoColors.colorSchemes.set2 as string[];

interface IProps {
  data: IReturn['budget'];
}
const CountDaysWrapper = ({ data }: IProps) => {
  const preparedData = data.map((dataElement, currentIndex) => {
    const marketingPillarTitle = dataElement.marketingPillar.title;
    const parentInstanceTitle =
      marketingPillarTitle === dataElement.parentInstance.title ? null : dataElement.parentInstance.title;

    const id = `${marketingPillarTitle}-${parentInstanceTitle}-${dataElement.title}${' '.repeat(currentIndex)}`;

    return {
      id,
      label: dataElement.title,
      value: dataElement.campaignDuration,
      date: `${dataElement.endDay.format('MM/DD')}- ${dataElement.startDay.format('MM/DD')}`,
      titlePillar: marketingPillarTitle,
      titleChannel: parentInstanceTitle,
    };
  });

  return (
    <BarBlockWrapper
      header={
        <Stack spacing={2}>
          <Typography level="h6" component="h6" sx={{ fontWeight: 500 }}>
            Duration of Сampaigns
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              overflowX: 'auto',
              overflowY: 'hidden',
              width: '100%',
              height: '40px',
            }}
          >
            {preparedData.map((prepData, index) => {
              const chipColor = getDataElementIndexByList<string>(index, nivoColors);
              return (
                <Chip
                  key={`MetricsWeeklyPerformanceChart_legend_${prepData.id + prepData.label}`}
                  variant="soft"
                  size="sm"
                  className={scss.totalsSelect}
                  sx={{ backgroundColor: `${chipColor}`, height: '24px' }}
                >
                  {prepData.label}
                </Chip>
              );
            })}
          </Box>
        </Stack>
      }
      chart={
        <PieChart
          data={preparedData}
          settings={{
            tooltip: ({ datum }) => (
              <Paper elevation={1} className={scss.chartTooltip}>
                <Box className={scss.chartTooltip_titleWrapper}>
                  <Box className={scss.chartTooltip_color} sx={{ backgroundColor: datum.color }} />
                  <span>{datum.data.label}</span>
                </Box>
                {datum.data.titlePillar && (
                  <Box>
                    Pillar: <span>{datum.data.titlePillar}</span>
                  </Box>
                )}
                {datum.data.titleChannel && (
                  <Box>
                    Channel: <span>{datum.data.titleChannel}</span>
                  </Box>
                )}
                {datum.data.date && (
                  <Box>
                    Date: <span>{datum.data.date}</span>
                  </Box>
                )}
                <Box>
                  Days: <span>{datum.value}</span>
                </Box>
              </Paper>
            ),
            arcLinkLabel: 'label',
            colors: nivoColors,
            enableArcLinkLabels: false,
          }}
        />
      }
      minWidth="450px"
      selectedTotalOptionList=""
    />
  );
};

export default CountDaysWrapper;
