import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';
import { ICreateUser, IUser } from '../interface';

interface IUpdate {
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
}
interface IFullUpdate {
  email: string;
  first_name: string;
  last_name: string;
}

export const getUsersList = (): Promise<IRequest<IUser[]>> => axiosInstance.get('/user/').then(({ data }) => data);
export const getUserById = (id: number): Promise<IRequest<IUser>> =>
  axiosInstance.get(`/user/${id}/`).then(({ data }) => data);
export const patchUser = (id: number, bodyData: IUpdate): Promise<IRequest<IUser>> =>
  axiosInstance
    .patch(`/user/${id}/`, {
      ...bodyData,
    })
    .then(({ data }) => data);

export const updateUser = (id: number, bodyData: IFullUpdate): Promise<IRequest<IUser>> =>
  axiosInstance
    .put(`/user/${id}/`, {
      ...bodyData,
    })
    .then(({ data }) => data);

export const createUser = (createUserData: ICreateUser): Promise<IRequest<IUser>> =>
  axiosInstance.post('/user/', createUserData).then(({ data }) => data);

export const deleteUser = (id: number): AxiosPromise => axiosInstance.delete(`/user/${id}/`);
