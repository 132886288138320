import { useMemo, useState } from 'react';
import { BaseClass } from '../../../../slass/data/BaseClass';

const useUsedAndUnUsed = <ClassType extends BaseClass>(iterableList: ClassType[], matchList: (number | string)[]) => {
  const { usedElements, unUsedElements } = useMemo(
    () =>
      iterableList?.reduce<{ usedElements: ClassType[]; unUsedElements: ClassType[] }>(
        (prevState, currentElement) => {
          if (matchList?.includes(currentElement.id)) {
            prevState.usedElements.push(currentElement);
          } else {
            prevState.unUsedElements.push(currentElement);
          }

          return prevState;
        },
        { usedElements: [], unUsedElements: [] },
      ),
    [matchList, iterableList],
  );

  const [unUsedElementsState, setUnUsedElementsState] = useState(unUsedElements);

  const unUsedElementsToOptions = useMemo(
    () => unUsedElementsState.map((element) => ({ title: element.title, id: element.id })),
    [unUsedElementsState],
  );

  return {
    setUnUsedElementsState,
    usedElementList: usedElements,
    unUsedElementsToOptions,
  };
};

export default useUsedAndUnUsed;
