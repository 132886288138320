import { ResponsivePie } from '@nivo/pie';

const PieChart = ({ data, settings }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 20, right: 0, bottom: 20, left: 10 }}
    // startAngle={-90}
    // endAngle={90}
    innerRadius={0.3}
    padAngle={0.2}
    cornerRadius={0}
    colors={{ scheme: 'set3' }}
    // arcLinkLabelsTextColor="#333333"
    // arcLinkLabelsOffset={-24}
    // arcLinkLabelsDiagonalLength={35}
    // arcLinkLabelsStraightLength={14}
    // arcLinkLabelsThickness={3}
    // arcLinkLabelsColor={{ from: 'color' }}
    // arcLabelsRadiusOffset={0.45}
    animate
    // legends={[
    //   {
    //     anchor: 'bottom',
    //     direction: 'row',
    //     justify: false,
    //     translateX: 0,
    //     translateY: 56,
    //     itemsSpacing: 0,
    //     itemWidth: 100,
    //     itemHeight: 18,
    //     itemTextColor: '#999',
    //     itemDirection: 'top-to-bottom',
    //     itemOpacity: 1,
    //     symbolSize: 18,
    //     symbolShape: 'circle',
    //   },
    // ]}
    {...settings}
  />
);

export default PieChart;
