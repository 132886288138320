import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { PlanningCalendarModalClass } from '../class/PlanningCalendarModalClass';
import MetricModalList from './MetricModalList';

interface IProps {
  instance: PlanningCalendarModalClass;
  onOpenElement: (id: number) => void;
}

const MetricModalListBlock = ({ instance, onOpenElement }: IProps) => {
  const campaignMetricHeader = useMemo(
    () => [
      { slug: 'title', title: 'Campaign metric' },
      { slug: 'type', title: 'Type' },
      { slug: 'part_of_budget', title: 'Part of budget' },
      { slug: 'value', title: 'Promoted products' },
    ],
    [],
  );

  const { setUnUsedCampaignMetric, unUsedCampaignMetricsList, usedCampaignMetricsList, setUsedCampaignMetric } =
    instance;

  return (
    <MetricModalList
      header={campaignMetricHeader}
      onRemoveElements={setUnUsedCampaignMetric}
      unUsedElementList={unUsedCampaignMetricsList}
      usedElementList={usedCampaignMetricsList}
      onSelectElements={setUsedCampaignMetric}
      onOpenElement={onOpenElement}
    />
  );
};

export default observer(MetricModalListBlock);
