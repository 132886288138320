import { action, computed, makeObservable, observable } from 'mobx';
import { ITotalValue } from '../../interface/calendarInterface';

export class TotalsEventClass {
  @observable protected _value: ITotalValue['value'];

  protected _forDate: ITotalValue['for_date'];

  protected _id: ITotalValue['id'];

  constructor(data: ITotalValue) {
    makeObservable(this);
    this._value = data.value;
    this._forDate = data.for_date;
    this._id = data.id;
  }

  get id() {
    return this._id;
  }

  get forDate() {
    return this._forDate;
  }

  @computed get value() {
    return this._value;
  }

  @action setValue = (value: number) => {
    this._value = value;
  };
}
