import React, { useState } from 'react';
import { Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Button from '@mui/joy/Button';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/joy/IconButton';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { EnumTypeOfCampaign } from '../../../interface/campaignMetricInterface';
import { SettingsMetricClass } from '../class/SettingsMetricClass';
import BooleanRenderCell from '../../../component/ui/grid/BooleanRenderCell';

const HEIGHT = 48;
const COEFFICIENT = 4.5;
const ITEM_HEIGHT = HEIGHT * COEFFICIENT;

interface IProps<Class> {
  header: { title: string; slug: string }[];
  unUsedElementList: Class[];
  usedElementList: Class[];
  onRemoveElements: (id: number) => void;
  onSelectElements: (id: number) => void;
  onOpenElement: (id: number) => void;
}

// eslint-disable-next-line react/function-component-definition
function MetricModalList<Class extends SettingsMetricClass>({
  header,
  unUsedElementList,
  usedElementList,
  onRemoveElements,
  onSelectElements,
  onOpenElement,
}: IProps<Class>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [IsOpenUnUsed, setIsOpenUnUsed] = useState(false);

  const onOpenAddHandler = (value: boolean) => (event) => {
    setIsOpenUnUsed(value);
    if (value) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const onSelectElementsHandler = (id: number) => () => {
    onOpenAddHandler(false)(null);
    onSelectElements(id);
  };

  const onRemoveElementsHandler = (id: number) => () => {
    onOpenAddHandler(false)(null);
    onRemoveElements(id);
  };

  const onOpenElementHandler = (id: number) => () => {
    onOpenAddHandler(false)(null);
    onOpenElement(id);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="MetricModalList">
          <TableHead>
            <TableRow>
              <TableCell />
              {header.map((head) => (
                <TableCell
                  key={`TableHead_${head.slug}_${head.title}`}
                  sx={{ padding: head.slug === 'title' ? '0 16px 0 0' : '6px 16px', fontWeight: 600 }}
                >
                  {head.title}
                </TableCell>
              ))}
              <TableCell align="right" sx={{ padding: '0 0 0 16px' }}>
                <Button
                  sx={{
                    fontWeight: 700,
                    fontSize: '22px',
                    lineHeight: '20px',
                  }}
                  size="sm"
                  variant="plain"
                  color="primary"
                  onClick={onOpenAddHandler(true)}
                  disabled={!unUsedElementList.length}
                >
                  +
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usedElementList.map((row) => (
              <TableRow key={`TableBody_${row.title}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ padding: '0 6px 0 0' }}>
                  <IconButton
                    color="info"
                    variant="plain"
                    size="sm"
                    onClick={onOpenElementHandler(row.id)}
                    sx={{ padding: 0, height: '100%', minWidth: '32px' }}
                  >
                    <ReadMoreIcon />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ padding: '0 16px 0 0' }}>
                  {row.title}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ padding: '0 16px' }}>
                  {EnumTypeOfCampaign[row.type]}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ padding: '0 16px' }}>
                  <BooleanRenderCell value={row.partOfBudget} />
                </TableCell>
                <TableCell component="th" scope="row" sx={{ padding: '0 16px' }}>
                  {row.productList.length
                    ? row.productList.map((product, index) => (
                        <span key={`MetricModalList_productList_${product.product.title}`}>
                          <span style={{ whiteSpace: 'nowrap' }}>{product.product.title}</span>
                          {index !== row.productList.length - 1 && ', '}
                        </span>
                      ))
                    : '-'}
                </TableCell>
                <TableCell align="right" sx={{ padding: '0 0 0 16px' }}>
                  <Button
                    sx={{
                      fontWeight: 700,
                      fontSize: '22px',
                      lineHeight: '20px',
                      width: '36px',
                    }}
                    size="sm"
                    variant="plain"
                    color="danger"
                    onClick={onRemoveElementsHandler(row.id)}
                  >
                    -
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="Button-on-OpenAddCampaignMetricsHandler-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={IsOpenUnUsed}
        onClose={onOpenAddHandler(false)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT,
          },
        }}
      >
        {unUsedElementList.map((option) => (
          <MenuItem key={`MenuItem_${option.id}_${option.title}`} onClick={onSelectElementsHandler(option.id)}>
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default observer(MetricModalList);
