import { action, computed, makeObservable, observable } from 'mobx';
import dayjs from 'dayjs';
import { ICompetitor, Source, Status } from '../../interface/competitorInterface';
import * as serviceConductor from '../../service/competitorService';
import { BaseClass } from '../data/BaseClass';

export class CompetitorClass extends BaseClass {
  protected _source: Source;

  protected _content: string;

  @observable protected _status: Status;

  protected _user: number;

  protected _company: number;

  protected _createdAt: string;

  protected _updatedAt: string;

  protected _contentParseError: unknown;

  constructor(data: ICompetitor) {
    super(serviceConductor);
    makeObservable(this);
    this.snakeToCamelBuilder(data);
  }

  get source() {
    return this._source;
  }

  get content() {
    return this._content;
  }

  @computed get status() {
    return this._status;
  }

  get user() {
    return this._user;
  }

  get company() {
    return this._company;
  }

  get createdAt() {
    return dayjs(this._createdAt).format('MM/DD/YYYY');
  }

  get updatedAt() {
    return dayjs(this._updatedAt).format(' MM/DD/YYYY');
  }

  get contentParseError() {
    return this._contentParseError;
  }

  @action setStatus = (status: Status) => {
    this._status = status;
  };

  @action protected _builder = (key, value) => {};

  @action fetchPatch = async (data) => this.fetchPatchBase(data);
}
