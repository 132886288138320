import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';
import {
  ICreateRequestProduct,
  ICreateResponseProduct,
  IRequestPatchProduct,
  IResponsePatchProduct,
  IResponseProduct,
} from '../interface/productInterface';

export const create = (requestData: ICreateRequestProduct): Promise<IRequest<ICreateResponseProduct>> =>
  axiosInstance.post('/product/', requestData).then(({ data }) => data);

export const getList = (): Promise<IRequest<IResponseProduct[]>> =>
  axiosInstance.get('/product/').then(({ data }) => data);

export const getById = (id: number): Promise<IRequest<IResponseProduct>> =>
  axiosInstance.get(`/product/${id}/`).then(({ data }) => data);

export const patchById = (id: number, requestData: IRequestPatchProduct): Promise<IRequest<IResponsePatchProduct>> =>
  axiosInstance.patch(`/product/${id}/`, requestData).then(({ data }) => data);

export const deleteById = (id: number): AxiosPromise => axiosInstance.delete(`/product/${id}/`);
