import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet } from 'react-router-dom';
import MainStore from '../store/MainStore';
import { LoadingStatus } from '../interface';
import Loader from '../component/ui/loader/Loader';

const ProtectedRoute = () => {
  const { authStore } = MainStore;
  const { loadingStatus, isAuth } = authStore;

  useEffect(() => {
    if (isAuth === null) {
      MainStore.authStore.init();
    }
  }, [isAuth]);

  if (loadingStatus === LoadingStatus.LOADING) {
    return <Loader />;
  }

  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default observer(ProtectedRoute);
