import React, { useCallback, useMemo, useState } from 'react';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import { Box, Input, Stack, Link } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Chip from '@mui/joy/Chip';
// import Link from '@mui/joy/Link';
import { observer } from 'mobx-react-lite';
import { ChipDelete } from '@mui/joy';
import UploadFile from '../../../component/upload/UploadFile';
import { IAttachment } from '../../../interface/marketingCampaignInterface';
import { INewLink } from '../class/PlanningCalendarModalClass';
import { validateUrl } from '../../../utils/validateUrl';
import { getDomainFromUrl } from '../../../utils/getDomainFromUrl';
import PreviewImg from '../../../component/preview/PreviewImg';
import scss from '../Planning.module.scss';

interface IProps {
  setFile: (file: any) => void;
  fileList: { file: File[]; url: string; description?: string }[];
  changeNewFileDescription: (file: File[], description?: string) => void;
  deleteNewFile: (url: string) => void;
  attachments: IAttachment[];
  changeNewLink: (index: number, data: Partial<INewLink>) => void;
  deleteAttachment: (id: number) => void;
  setNewLink: (data: INewLink) => void;
  newLinkList: INewLink[];
}
const UploadModalContent = ({
  setFile,
  fileList = [],
  changeNewFileDescription,
  deleteNewFile,
  newLinkList = [],
  attachments,
  changeNewLink,
  setNewLink,
  deleteAttachment,
}: IProps) => {
  const [numberLightbox, setNumberLightbox] = useState<number>(null);

  const onDropCallback = useCallback(
    (file) => {
      setFile(file);
    },
    [setFile],
  );
  const attachmentsFileList = useMemo(
    () =>
      attachments?.reduce((attachmentState, attachment) => {
        if (attachment.type === 'file') {
          attachmentState.push({
            url: attachment.file,
            type: 'photo',
            altTag: 'some image',
            id: attachment.id,
            title: attachment.description ?? '',
          });
        }
        return attachmentState;
      }, []),
    [attachments],
  );

  const linkAttachments = useMemo(() => attachments.filter((attachment) => attachment.type === 'embed'), [attachments]);

  const onChangeNewLinkHandler = (index: number, field: 'description' | 'link') => (event) => {
    const value = event.target.value as unknown as string;
    changeNewLink(index, { [field]: value });
  };

  const onSetNewLinkHandler = () => {
    setNewLink({ description: '', link: '' });
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography component="span" id="PlanningCalendarModal_Media" sx={{ fontWeight: 600 }}>
          Media
        </Typography>
        <Button variant="plain" onClick={onSetNewLinkHandler}>
          + Add
        </Button>
      </Box>
      <Box>
        <Stack spacing={1}>
          {newLinkList.map((newLink, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={`UploadModalContent_newLinkList${index}`} className={scss.elevation}>
              <Input
                value={newLink.description || ''}
                placeholder="description"
                onChange={onChangeNewLinkHandler(index, 'description')}
              />
              <Input
                value={newLink.link || ''}
                placeholder="url"
                onChange={onChangeNewLinkHandler(index, 'link')}
                error={newLink.link && !validateUrl(newLink.link)}
              />
            </Box>
          ))}
        </Stack>
        <Box sx={{ display: 'flex', gap: '10px', margin: '10px 0' }}>
          {linkAttachments.map((linkAttachment) => (
            <Chip
              sx={{ display: 'inline-flex', gap: '10px', color: '#1976d2', '--Chip-radius': '8px' }}
              key={linkAttachment.url}
              endDecorator={
                <ChipDelete
                  onDelete={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    deleteAttachment(linkAttachment.id);
                  }}
                />
              }
              variant="outlined"
            >
              <Link
                style={{ color: '#1976d2', fontSize: '16px' }}
                href={linkAttachment.url}
                target="_blank"
                rel="noreferrer"
              >
                {linkAttachment.description || getDomainFromUrl(linkAttachment.url)}
              </Link>
            </Chip>
          ))}
        </Box>
      </Box>
      <Box sx={{ margin: '25px 0', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        <UploadFile
          style={{ width: '110px', height: '80px' }}
          onDropCallback={onDropCallback}
          permittedType={{ 'image/*': [] }}
        />

        {(!!numberLightbox || numberLightbox === 0) && (
          <Box className={scss.reactImageVideoLightboxParent}>
            <ReactImageVideoLightbox
              style={{ zIndex: 1000 }}
              data={attachmentsFileList}
              startIndex={numberLightbox}
              showResourceCount
              onCloseCallback={() => setNumberLightbox(null)}
            />
          </Box>
        )}

        {attachmentsFileList?.map((image, index) => (
          <Box key={image.url} sx={{ width: '110px', overflow: 'hidden', overflowWrap: 'anywhere' }}>
            <>
              <PreviewImg
                src={image.url}
                onClick={() => setNumberLightbox(index)}
                onDeleteClick={() => deleteAttachment(image.id)}
              />
              <span
                style={{
                  color: 'rgba(113, 125, 150, 1)',
                  fontSize: '0.9em',
                }}
              >
                {image.title}
              </span>
            </>
          </Box>
        ))}
        {fileList?.map((newFile) => (
          <Box key={newFile.url} sx={{ width: '110px' }}>
            <PreviewImg
              src={newFile.url}
              sx={{ border: '3px solid #a0d15c' }}
              onDeleteClick={() => deleteNewFile(newFile.url)}
            />
            <Input
              value={newFile.description}
              placeholder="description"
              onChange={(event) => changeNewFileDescription(newFile.file, event.target.value)}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default observer(UploadModalContent);
