import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import validateNumber, { getValidNumber } from '../../utils/validateNumber';
import { ProductForCalendarClassBase } from './ProductForCalendarClassBase';
import {
  IProductMetricInResponseMarketingCampaign,
  IUpdateResponseDataValue,
} from '../../interface/marketingCampaignInterface';
import { patchDataValueById } from '../../service/marketingCampaignService';
import { ProductClass } from '../data/product/ProductClass';
import { IProductValueForMetric } from '../../interface/calendarInterface';
import { LoadingStatus } from '../../interface';

type ConstrictorData =
  | IProductMetricInResponseMarketingCampaign // todo refactor type
  | {
      product: ProductClass | IProductMetricInResponseMarketingCampaign['product'];
    }
  | IProductValueForMetric;

export class ProductForCalendarClass {
  @observable protected _loadingStatus = LoadingStatus.SUCCESS;

  private _copyDataForUpdate: IProductMetricInResponseMarketingCampaign;

  protected _id: number;

  @observable protected _autoDistribute: boolean;

  @observable protected _amount: number;

  @observable protected _price: number;

  @observable protected _product: ProductForCalendarClassBase;

  constructor(props: ConstrictorData) {
    makeObservable(this);
    const { product } = props;
    this._product = new ProductForCalendarClassBase(product);
    // @ts-ignore
    this._id = props.id; // @ts-ignore
    this._amount = props.amount; // @ts-ignore
    this._price = props.price ?? props.value; // @ts-ignore
    this._autoDistribute = props.auto_distribute; // @ts-ignore
    this._copyDataForUpdate = props;
  }

  @computed get price() {
    return getValidNumber(this._price);
  }

  @computed get id() {
    return this._id;
  }

  @computed get autoDistribute() {
    return this._autoDistribute || false;
  }

  @computed get amount() {
    return getValidNumber(this._amount);
  }

  @computed get product() {
    return this._product;
  }

  getDataForUpdate = () => {
    const data: any = {};
    if (this._copyDataForUpdate.price !== this.price) {
      data.price = this.price;
    }
    if (this._copyDataForUpdate.auto_distribute !== this.autoDistribute) {
      data.auto_distribute = this.autoDistribute;
      if (this.autoDistribute) {
        data.amount = this.amount;
      }
    }

    if (Object.keys(data).length) {
      data.product = this.product.id;
      return data;
    }

    return null;
  };

  @action setPrice = (value: number | string) => {
    if (validateNumber(value) || value === '') {
      this._price = (value > 0 ? value : 0) as unknown as number;
    }
  };

  @action setAmount = (value: number | string) => {
    if (validateNumber(value) || value === '') {
      this._amount = (value > 0 ? value : 0) as unknown as number;
    }
  };

  @action setAutoDistribute = (value: boolean) => {
    this._autoDistribute = value;
  };

  @action protected setCopyDataForUpdate = (dataForUpdate) => {
    this._copyDataForUpdate = dataForUpdate;
  };

  @action patchPriceById = async (): Promise<IUpdateResponseDataValue | number> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await patchDataValueById(this._id, { value: Number(this._price) });

      runInAction(() => {
        this._price = data.value;
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return data;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return this._price;
    }
  };
}
