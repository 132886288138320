import React, { useContext } from 'react';
import { Box, List } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import MarketingChannel from './MarketingChannel';
import { ChannelContext } from './MarketingPillarModal';

interface IProps {
  usedMarketingTitle: string[];
}
const MarketingChannelWrapper = ({ usedMarketingTitle }: IProps) => {
  const { setNewChannelList, newChannelList } = useContext(ChannelContext);
  const onSetNewChannelList = () => {
    const emptyChannel = { title: '', newChannelPseudoId: uuid() };
    setNewChannelList((prevState) => [...prevState, emptyChannel]);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className="labelSetting" component="span">
          Multiple Channels
        </Typography>
        <Button variant="plain" onClick={onSetNewChannelList}>
          + Add
        </Button>
      </Box>

      <List>
        {newChannelList.map((channel) => (
          <MarketingChannel
            key={`MarketingChannel_${channel.id || channel.newChannelPseudoId}_ListItem`}
            defaultTitle={channel.title}
            usedMarketingTitle={usedMarketingTitle}
            id={channel.id}
            newChannelPseudoId={channel.newChannelPseudoId}
          />
        ))}
      </List>
    </>
  );
};

export default observer(MarketingChannelWrapper);
