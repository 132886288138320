import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Checkbox from '@mui/joy/Checkbox';
import debounce from 'lodash.debounce';
import { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';
import { PermissionClass } from '../../../slass/permission/PermissionClass';
import { GroupPermissionClass } from '../../../slass/permission/GroupPermissionClass';
import transformString from './utils/transformString';

interface IProps {
  instance: PermissionClass;
  groupPermissionInstance: GroupPermissionClass;
}

const PermissionCheckbox = ({ instance, groupPermissionInstance }: IProps) => {
  const onChangePermission = (permissionId) => (event: any) => {
    if (event.target.checked) {
      groupPermissionInstance.pushPermissionList(permissionId);
    } else {
      groupPermissionInstance.deletePermissionList(permissionId);
    }
    debounce(groupPermissionInstance.patchGroupPermissionById, 500)();
  };
  const { name, color } = useMemo(() => {
    const text = transformString(instance.name);
    const checkboxColor: DefaultColorPalette = 'primary';
    // if (text.includes('delete')) {
    //   checkboxColor = 'danger';
    // } else if (text.includes('view')) {
    //   checkboxColor = 'info';
    // } else if (text.includes('change')) {
    //   checkboxColor = 'warning';
    // }
    return { name: text, color: checkboxColor };
  }, [instance]);

  return (
    <Checkbox
      label={name}
      color={color}
      checked={groupPermissionInstance.permissionList.includes(instance.id)}
      onChange={onChangePermission(instance.id)}
    />
  );
};

export default observer(PermissionCheckbox);
