import React from 'react';
import { ModalClose, Sheet } from '@mui/joy';
import { Avatar, Box, Button } from '@mui/material';
import Typography from '@mui/joy/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingStatus } from '../../../interface';
import { UserClass } from '../../../slass/user/UserClass';

const DeleteUserModal = ({
  onCloseModalHandler,
  fetchDeleteUser,
  loadingStatus,
  userInstance,
}: {
  onCloseModalHandler: () => void;
  fetchDeleteUser: (id: number) => Promise<boolean>;
  loadingStatus: any;
  userInstance: UserClass;
}) => {
  const onDeleteHandler = () => {
    fetchDeleteUser(userInstance.id).then((isSuccess) => {
      if (isSuccess) {
        onCloseModalHandler();
      }
    });
  };

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: 580,
        borderRadius: 'md',
        p: 3,
      }}
    >
      <ModalClose variant="plain" onClick={onCloseModalHandler} />
      <Typography component="h3" id="close-modal-title">
        Delete User
      </Typography>
      <Box sx={{ marginTop: '16px', marginBottom: '10px' }}>
        <Avatar />
      </Box>
      <Typography component="h3" id="close-modal-title">
        {`${userInstance?.firstName} ${userInstance?.lastName}`}
      </Typography>
      <Typography component="span" id="close-modal-title">
        Email: {userInstance?.email}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModalHandler}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={onDeleteHandler}
          loading={loadingStatus === LoadingStatus.LOADING}
        >
          Delete
        </LoadingButton>
      </Box>
    </Sheet>
  );
};

export default DeleteUserModal;
