import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import RouterComponent from './route/RouterComponent';
import scss from './App.module.scss';

const App = () => (
  <div className={scss.app}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider maxSnack={4}>
        <RouterComponent />
      </SnackbarProvider>
    </LocalizationProvider>
  </div>
);

export default App;
