export type TypeOfCampaign = 'custom' | 'system' | 'calculated_by_product';
export type UnitOfCampaign = 'number' | 'price';
export type HasTotal = boolean;
export type TotalTitle = string;
export type MetricForCalculation = number;
export type Value = number;
export type Channels = boolean;

export enum EnumUnitOfCampaign {
  number = 'number',
  price = '$',
}

export enum EnumTypeOfCampaign {
  custom = 'custom',
  system = 'system',
  calculated_by_product = 'calculated',
}
export interface IBaseCampaignMetric {
  title: string;
  description: string;
  type: TypeOfCampaign;
  unit: UnitOfCampaign;
}

export interface ICreateRequestCampaignMetric extends IBaseCampaignMetric {}

export interface ICreateResponseCampaignMetric extends IBaseCampaignMetric {
  company: number;
}

export interface ICampaignMetric extends IBaseCampaignMetric {
  id: 1;
  created_at: Date;
  updated_at: Date;
}

export interface ICampaignMetricForCalculation {
  id: number;
  title: string;
  type: TypeOfCampaign;
}

export interface IRequestPatchCampaignMetricProps extends Partial<IBaseCampaignMetric> {
  // company?: number;
  metric_for_calculation?: MetricForCalculation;
  value?: Value;
  // channels?: Channels;
  has_total?: HasTotal;
  total_title?: TotalTitle;
}

export const typeOfCampaignMap: Record<TypeOfCampaign, string> = {
  custom: 'custom',
  system: 'system',
  calculated_by_product: 'calculated',
};

export type TRequestSortingUpdate = {
  campaign_metric: number; //id;
  order: number;
}[]; // 2 elements that changed into each other

export type TResponseSortingUpdate = TRequestSortingUpdate;
