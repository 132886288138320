import { IResponsePermission } from '../../interface/permissionInterface';

export class PermissionClass {
  protected _id: number;

  protected _name: string;

  protected _codename: string;

  protected _contentType: string;

  constructor(data: IResponsePermission) {
    this._id = data.id;
    this._name = data.name;
    this._codename = data.codename;
    this._contentType = data.content_type;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name || '';
  }

  get codename() {
    return this._codename;
  }

  get contentType() {
    return this._contentType;
  }
}
