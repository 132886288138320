import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';
import {
  ICampaignMetric,
  ICreateRequestCampaignMetric,
  ICreateResponseCampaignMetric,
  ICampaignMetricForCalculation,
  TRequestSortingUpdate,
  TResponseSortingUpdate,
} from '../interface/campaignMetricInterface';

export const create = (requestData: ICreateRequestCampaignMetric): Promise<IRequest<ICreateResponseCampaignMetric>> =>
  axiosInstance.post('/campaign-metric/', requestData).then(({ data }) => data);

export const getList = (): Promise<IRequest<ICampaignMetric[]>> =>
  axiosInstance.get('/campaign-metric/').then(({ data }) => data);

export const getById = (id: number): Promise<IRequest<ICampaignMetric>> =>
  axiosInstance.get(`/campaign-metric/${id}/`).then(({ data }) => data);

export const patchById = (
  id: number,
  requestData: Record<string, any>,
): Promise<IRequest<ICreateResponseCampaignMetric>> =>
  axiosInstance.patch(`/campaign-metric/${id}/`, requestData).then(({ data }) => data);

export const deleteById = (id: number): AxiosPromise => axiosInstance.delete(`/campaign-metric/${id}/`);

export const getCampaignMetricForCalculation = (): Promise<IRequest<ICampaignMetricForCalculation[]>> =>
  axiosInstance.get('/campaign-metric/for_calculation/').then(({ data }) => data);

export const sorting = (requestData: TRequestSortingUpdate): Promise<IRequest<TResponseSortingUpdate>> =>
  axiosInstance.post('/campaign-metric/sorting_update/', requestData).then(({ data }) => data);
