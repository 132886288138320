import React, { useMemo } from 'react';
import IconButton from '@mui/joy/IconButton';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactComponent as EditIcon } from '../../../icon/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../icon/DeleteIcon.svg';
import BooleanRenderCell from '../../../component/ui/grid/BooleanRenderCell';
import { Box } from '@mui/material';

const useColumns = ({
  onDeleteComponentModal,
  onOpenEditComponentModal,
  changeTableOrder,
}: {
  onDeleteComponentModal: (id: string | number) => () => void;
  onOpenEditComponentModal: (id: string | number) => () => void;
  changeTableOrder: (direction: 'up' | 'down', id: number) => void;
}): GridColDef[] =>
  useMemo(
    () => [
      // {
      //   field: 'id',
      //   headerName: '№',
      //   maxWidth: 50,
      // },
      {
        field: 'order',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
            {params.row.minOrder !== params.row.order && (
              <IconButton
                color="neutral"
                variant="plain"
                sx={{
                  '--IconButton-size': '20px',
                  padding: '4px 4px 4px 0px',
                  transform: 'rotate(-90deg)',
                }}
                onClick={() => changeTableOrder('up', params.id as unknown as number)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24">
                  <path d="M21 12l-18 12v-24z" />
                </svg>
              </IconButton>
            )}
            {params.row.maxOrder !== params.row.order && (
              <IconButton
                color="neutral"
                variant="plain"
                sx={{
                  '--IconButton-size': '20px',
                  padding: '4px 4px 4px 0px',
                  transform: 'rotate(90deg)',
                }}
                onClick={() => changeTableOrder('down', params.id as unknown as number)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24">
                  <path d="M21 12l-18 12v-24z" />
                </svg>
              </IconButton>
            )}
          </Box>
        ),
      },
      {
        field: 'title',
        headerName: 'Name',
        flex: 1,
        minWidth: 100,
        maxWidth: 300,
        renderCell: (params) => <span title={params.value}>{params.value}</span>,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        minWidth: 200,
        maxWidth: 350,
      },
      {
        field: 'type',
        headerName: 'Type',
        minWidth: 200,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams) => <div style={{ textTransform: 'capitalize' }}>{params.value}</div>,
      },
      {
        field: 'unit',
        headerName: 'Unit',
        minWidth: 50,
      },
      // {
      //   field: 'isChannels',
      //   headerName: 'Channels',
      //   minWidth: 50,
      //   renderCell: (params) => <BooleanRenderCell value={params.value} />,
      // },
      {
        field: 'total',
        headerName: 'Total',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <>
            {params.value ? (
              <span title={params.value}>{params.value}</span>
            ) : (
              <BooleanRenderCell value={params.value} />
            )}
          </>
        ),
      },
      {
        field: 'edit',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <IconButton color="primary" variant="plain" disabled={false} onClick={onOpenEditComponentModal(params.id)}>
            <EditIcon />
          </IconButton>
        ),
      },
      {
        field: 'delete',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <IconButton color="danger" variant="plain" disabled={false} onClick={onDeleteComponentModal(params.id)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    ],
    [onDeleteComponentModal, onOpenEditComponentModal, changeTableOrder],
  );
export default useColumns;
