import { ResponsiveLine } from '@nivo/line';
import { memo } from 'react';

interface IProps {
  data: any[];
  settings?: any;
}

const MyResponsiveLine = ({ data, settings = {} }: IProps) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 110, bottom: 80, left: 60 }}
    axisTop={null}
    axisRight={null}
    enableSlices="x"
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -50,
    }}
    colors={{ scheme: 'set2' }}
    {...settings}
  />
);

export default memo(MyResponsiveLine);
