import React from 'react';
import CircularProgress from '@mui/joy/CircularProgress';
import scss from './Loader.module.scss';

interface IProps {
  size?: 'sm' | 'md' | 'lg';
}
const Loader = ({ size }: IProps) => (
  <div className={scss.loaderWrapper}>
    <CircularProgress color="primary" variant="soft" value={30} size={size} />
  </div>
);
export default Loader;
