import React, { memo, useEffect, useState } from 'react';
import MainStore from '../../store/MainStore';
import { TokensNameInStorage } from '../../interface';
import { useConnectWebsocket } from './hook/useConnectWebsocket';

const { REACT_APP_WEB_SOCKET_URL } = process.env;

const AlertsWebSockets = () => {
  const { addAlertItem, removeAlertItem, addChangelogItem, removeChangelogItem, clear } = MainStore.notificationStore;
  const [webSocketEndpointAlerts, setWebSocketEndpointAlerts] = useState(null);
  const [webSocketEndpointChangelogs, setWebSocketEndpointChangelogs] = useState(null);

  const tokensNameInStorage = sessionStorage.getItem(TokensNameInStorage.loginAsUserAccess);

  const tokken =
    tokensNameInStorage !== 'undefined' && !!tokensNameInStorage
      ? tokensNameInStorage
      : localStorage.getItem(TokensNameInStorage.access);

  useConnectWebsocket(webSocketEndpointAlerts, addAlertItem, removeAlertItem);
  useConnectWebsocket(webSocketEndpointChangelogs, addChangelogItem, removeChangelogItem);

  useEffect(() => {
    if (tokken) {
      clear();
      const webSocketEndpointAlerts = `${REACT_APP_WEB_SOCKET_URL}/notifications/?token=${tokken}`;
      setWebSocketEndpointAlerts(webSocketEndpointAlerts);
      const webSocketEndpointChangelogs = `${REACT_APP_WEB_SOCKET_URL}/user_activity_logs/?token=${tokken}`;
      setWebSocketEndpointChangelogs(webSocketEndpointChangelogs);
    }
  }, [tokken]);

  return null;
};

export default memo(AlertsWebSockets);
