import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import cn from 'classnames';
import Can from '../../../permission/Can';
import { PermissionType } from '../../../../interface/permissionInterface';
import scss from '../Sidebar.module.scss';

const AccordionTitle = ({ icon = null, text }: { icon?: ReactElement; text: string }) => (
  <>
    {icon && <ListItemIcon className={scss.accordionTitleIcon}>{icon}</ListItemIcon>}
    <ListItemText primary={text} className={scss.listItemText} />
  </>
);

const SidebarExpand = ({ sidebarComponents }) => {
  const location = useLocation();

  return (
    <List>
      {sidebarComponents.map((element) =>
        !element.hidden ? (
          <Can key={`Sidebar_${element.title}`} permissionList={element.permission as PermissionType[]}>
            <ListItem className={scss.listItem}>
              <Accordion expanded={!!element.children?.length && location.pathname.includes(element.link)}>
                <Link to={element.link}>
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={
                      !!element.children?.length && (
                        <Icon>
                          <ExpandMoreOutlinedIcon />
                        </Icon>
                      )
                    }
                  >
                    <ListItem
                      className={cn({
                        [scss.activeSubTab]: location.pathname.includes(element.link),
                      })}
                    >
                      <AccordionTitle icon={element.icon} text={element.title} />
                    </ListItem>
                  </AccordionSummary>
                </Link>
                {element?.children?.length ? (
                  <AccordionDetails>
                    <List>
                      {element.children.map((childrenEl) =>
                        !childrenEl.hidden ? (
                          <Can
                            key={`SidebarChildrenEl_${childrenEl.title}`}
                            permissionList={childrenEl.permission as PermissionType[]}
                          >
                            <Link to={childrenEl.link}>
                              <ListItem
                                className={cn(scss.subTab, {
                                  [scss.activeSubTab]: location.pathname.includes(childrenEl.link),
                                })}
                              >
                                <AccordionTitle text={childrenEl.title} />
                              </ListItem>
                            </Link>
                          </Can>
                        ) : null,
                      )}
                    </List>
                  </AccordionDetails>
                ) : null}
              </Accordion>
            </ListItem>
          </Can>
        ) : null,
      )}
    </List>
  );
};

export default SidebarExpand;
