const addHttpsToLink = (link: string) => {
  if (!link.startsWith('https://') && !link.startsWith('http://')) {
    if (link.startsWith('//')) {
      return `https:${  link}`;
    } 
      return `https://${  link}`;
    
  } 
    return link;
  
};

export default addHttpsToLink;
