import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { PermissionType } from '../../interface/permissionInterface';
import MainStore from '../../store/MainStore';
import findPermission from './findPermission';

interface IProps {
  children: ReactNode;
  permissionList: PermissionType[] | [];
}

const Can = ({ children, permissionList }: IProps) => {
  const { permissions } = MainStore.authStore;

  const foundPermission = findPermission(permissionList, permissions);

  if (foundPermission) {
    return <>{children}</>;
  }
  return <></>;
};

export default observer(Can);
