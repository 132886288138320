import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Alert, Box, Stack, Typography } from '@mui/material';
import MainStore from '../../store/MainStore';
import { getActivityLogById } from '../../service/changeServise';
import DefaultModal from '../ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../ui/default-modal/DefaultChildrenWrapperModal';
import JsonViewCompare from './JsonViewCompare';
import { BaseActivityLogInterface } from '../../interface/activityLogInterface';
import useIsMounted from '../../utils/hooks/useIsMounted';

const Changelogs = () => {
  const { isSuperuser } = MainStore.authStore;
  const { changelogList } = MainStore.notificationStore;

  const [activityData, setActivityData] = useState<Pick<BaseActivityLogInterface, 'object_data' | 'prev_object_data'>>({
    object_data: null,
    prev_object_data: null,
  });

  const isMounted = useIsMounted();

  const onClickChangelog = (id: number) => () => {
    if (!isSuperuser) {
      return;
    }
    getActivityLogById(id).then((activityLog) => {
      if (isMounted()) {
        setActivityData({
          prev_object_data: activityLog.data.prev_object_data,
          object_data: activityLog.data.object_data,
        });
      }
    });
  };

  const onCloseViewDataModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setActivityData({
      prev_object_data: null,
      object_data: null,
    });
  };

  return (
    <Box>
      <Stack sx={{ width: '100%' }} spacing={1}>
        {changelogList?.length > 0 &&
          changelogList.map((item) => {
            const color =
              (item.message_type === 'warning' && 'warning') ||
              (item.message_type === 'info' && 'info') ||
              (item.message_type === 'success' && 'success') ||
              'error';

            const date = dayjs(item.created_at).format('lll');
            return (
              <Alert
                key={`Changelog_${item.id}`}
                sx={{ cursor: isSuperuser ? 'pointer' : 'default' }}
                icon={false}
                severity={color}
                onClick={onClickChangelog(item.id)}
              >
                {item.message}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ mt: 1 }} variant="caption" component="p">
                    Action: {item.action}
                  </Typography>
                  <Typography sx={{ mt: 1 }} variant="caption" component="p">
                    Date: {date}
                  </Typography>
                </Box>
              </Alert>
            );
          })}
      </Stack>
      <DefaultModal open={!!(activityData.prev_object_data && activityData.object_data)}>
        <DefaultChildrenWrapperModal modalTitle="Changelog" onCloseModal={onCloseViewDataModal} sx={{ width: '720px' }}>
          <JsonViewCompare newData={activityData.object_data} oldData={activityData.prev_object_data} />
        </DefaultChildrenWrapperModal>
      </DefaultModal>
    </Box>
  );
};

export default Changelogs;
