import { action, computed, observable, runInAction } from 'mobx';
import { LoadingStatus } from '../interface';
import { BaseClass } from '../slass/data/BaseClass';

export abstract class BaseStoreAbstract<ClassType extends BaseClass> {
  @observable protected _mainDataList: ClassType[] = [];

  protected abstract _mainDataClass;

  protected abstract _serviceConductor;

  @observable protected _loadingStatus: LoadingStatus = LoadingStatus.LOADING;

  @computed
  get dataList(): ClassType[] {
    return this._mainDataList;
  }

  @computed
  get loadingStatus() {
    return this._loadingStatus;
  }

  @action clear = () => {
    this._mainDataList = [];
    this._loadingStatus = LoadingStatus.LOADING;
  };

  @action fetchDelete = async (id: number): Promise<ClassType[] | boolean> => {
    try {
      const response = await this._serviceConductor.deleteById(id);
      if (response.status !== 204) {
        throw 'something wrong';
      }
      runInAction(() => {
        this._mainDataList = this._mainDataList.filter((mainData) => mainData.id !== id);
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return this._mainDataList;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action fetchCreate = async (data): Promise<ClassType | boolean> => {
    // todo add type
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data: responseData } = await this._serviceConductor.create(data);
      runInAction(() => {
        this._mainDataList.push(new this._mainDataClass(responseData, this._serviceConductor));
        if (responseData.order) {
          // @ts-ignore
          this._mainDataList = this._mainDataList.sort((a, b) => a.order - b.order);
        }

        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return this._mainDataList[this._mainDataList.length - 1];
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action fetchById = async (id: number): Promise<ClassType[] | boolean> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await this._serviceConductor.getById(id);

      const matchedIndex = this._mainDataList.findIndex((element) => element.id === data.id);

      runInAction(() => {
        if (~matchedIndex) {
          this._mainDataList[matchedIndex] = new this._mainDataClass(data, this._serviceConductor);
        } else {
          this._mainDataList.push(new this._mainDataClass(data, this._serviceConductor));
        }
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return this._mainDataList;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action fetchList = async (): Promise<ClassType[] | boolean> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await this._serviceConductor.getList();
      runInAction(() => {
        let sortedData = data[0]?.order !== undefined && data[0]?.order !== null ? data.sort((a, b) => a - b) : data;

        this._mainDataList = sortedData.map((element) => new this._mainDataClass(element, this._serviceConductor));
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return this._mainDataList;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}
