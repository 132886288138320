import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';

const PADDING = 20;

interface IProps {
  header: React.ReactNode | string | number;
  chart: React.ReactNode;
  selectedTotalOptionList: any;
  minWidth?: string;
}

const BarBlockWrapper = ({ header, chart, selectedTotalOptionList, minWidth = 'auto' }: IProps) => {
  const containerRef = useRef(null);
  const firstElementRef = useRef(null);
  const [secondElementHeight, setSecondElementHeight] = useState(null);

  useLayoutEffect(() => {
    const containerHeight = containerRef.current?.offsetHeight;
    const secondElHeight = firstElementRef.current?.offsetHeight;
    const remainingHeight = containerHeight - secondElHeight - PADDING;

    setSecondElementHeight(remainingHeight);
  }, [selectedTotalOptionList]);

  return (
    <Box ref={containerRef} sx={{ height: '100%', width: '100%', minWidth }}>
      <Stack spacing={2}>
        <Box ref={firstElementRef}>{header}</Box>
        <Box sx={{ height: `${secondElementHeight}px` }}>{chart}</Box>
      </Stack>
    </Box>
  );
};

export default BarBlockWrapper;
