import React, { useMemo } from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { FormControl, FormLabel } from '@mui/material';
import { observer } from 'mobx-react-lite';
import MainStore from '../../../store/MainStore';

interface IProps {
  value: number;
  onChange: (e: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null, value: number) => void;
}

const SelectCompany = ({ value, onChange }: IProps) => {
  const { dataList } = MainStore.companyStore;

  const selectOptionList = useMemo(() => dataList.map((company) => ({ id: company.id, name: company.title })), [dataList]);

  return (
    <FormControl sx={{ width: 240 }}>
      <FormLabel id="select-SelectCompany-label" htmlFor="select-users-company-button">
        Company
      </FormLabel>
      <Select value={value} onChange={onChange} sx={{ alignSelf: 'start', width: 240 }} placeholder="Select Company">
        {selectOptionList.map((selectOption) => (
          <Option key={`User_SelectCompany_${selectOption.name}`} value={selectOption.id}>
            {selectOption.name}
          </Option>
        ))}
      </Select>
    </FormControl>
  );
};

export default observer(SelectCompany);
