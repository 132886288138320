import React from 'react';
import { Popover } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/joy/IconButton';
import { DateSelectArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { ReactComponent as SettingsIcon } from '../../../icon/Settings.svg';
import { ReactComponent as AddCircleIcon } from '../../../icon/AddCircleIcon.svg';
import scss from '../Planning.module.scss';

interface IProps {
  settingsNewCampaign: DateSelectArg & EventImpl;
  coordinates: { top: number; left: number };
  onCloseNewCampaignModal: () => void;
  onOpenNewCampaign: (info: any) => () => void;
  isEditCampaign: boolean;
}
const PopoverCampaignSettings = ({
  settingsNewCampaign,
  coordinates,
  onCloseNewCampaignModal,
  onOpenNewCampaign,
  isEditCampaign,
}: IProps) => (
  <Popover
    open={!!settingsNewCampaign && !!coordinates}
    anchorReference="anchorPosition"
    anchorPosition={coordinates}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    className={scss.settingsPopover}
  >
    <ClickAwayListener onClickAway={onCloseNewCampaignModal} mouseEvent="onMouseDown" touchEvent="onTouchStart">
      <IconButton
        color="primary"
        variant="plain"
        onClick={onOpenNewCampaign(settingsNewCampaign)}
        className={scss.settingsPopover_iconButton}
      >
        {isEditCampaign && <SettingsIcon />}
        {!isEditCampaign && !!coordinates && <AddCircleIcon className={scss.filledBlue} />}
      </IconButton>
    </ClickAwayListener>
  </Popover>
);

export default PopoverCampaignSettings;
