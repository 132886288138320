import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, FormControl, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import { CustomFieldClass } from '../../slass/data/custom-fields/CustomFieldClass';
import onHandleChangeInput from '../../utils/onHandleChangeInput';
import { IPatchRequestCustomField } from '../../interface/customFieldInterface';
import { LoadingStatus } from '../../interface';
import useCheckUsedTitle from '../../utils/hooks/useCheckUsedTitle';

interface IProps {
  instance?: CustomFieldClass;
  onCloseModalHandler: () => void;
  fetchCreate: (data: IPatchRequestCustomField) => Promise<boolean | CustomFieldClass>;
  dataList?: CustomFieldClass[];
  loadingStatus: LoadingStatus;
}
const CustomFieldModal = ({ onCloseModalHandler, instance, dataList, fetchCreate, loadingStatus }: IProps) => {
  const [title, setTitle] = useState(instance?.title ?? '');

  const usedTitleList = useMemo(() => dataList.map((customField) => customField.title), [dataList]);

  const isAlreadyUsedTitle = useCheckUsedTitle(instance?.title, title, usedTitleList);

  const isEmptyTitle = !title.length;

  const isError = isEmptyTitle || isAlreadyUsedTitle;

  const onSaveButtonHandle = () => {
    const fn = instance?.fetchPatch || fetchCreate;
    const stateForRequest: IPatchRequestCustomField = {
      title,
    };
    fn(stateForRequest).then((isSuccess) => {
      if (isSuccess) {
        onCloseModalHandler();
      }
    });
  };

  const isLoading = (instance?.loadingStatus || loadingStatus) === LoadingStatus.LOADING;

  return (
    <>
      <Box sx={{ margin: '25px 0' }}>
        <FormControl error={isError} sx={{ width: '100%' }}>
          <TextField
            id="CustomFieldModal-title"
            placeholder="Title"
            variant="standard"
            sx={{ width: '100%' }}
            value={title}
            onChange={onHandleChangeInput(setTitle)}
          />
          {isAlreadyUsedTitle && (
            <FormHelperText id="CustomFieldModal-title-error-by-isAlreadyUsedTitle">
              The channel title is already in use
            </FormHelperText>
          )}
          {isEmptyTitle && (
            <FormHelperText id="CustomFieldModal-title-error-by-isEmptyTitle">
              The channel title is required
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModalHandler}>
          Cancel
        </Button>
        <Button variant="solid" onClick={onSaveButtonHandle} disabled={isError} loading={isLoading}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default observer(CustomFieldModal);
