import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';
import {
  ICompetitor,
  ICompetitorCalendar,
  ICreateRequestCompetitor,
  ICreateResponseCompetitor,
  IPatchResponseCompetitorCalendar,
  PatchRequestCompetitorCalendar,
} from '../interface/competitorInterface';

export const create = ({
  content,
  source,
  title,
  description,
}: ICreateRequestCompetitor): Promise<IRequest<ICreateResponseCompetitor>> => {
  const bodyFormData = new FormData();
  const blob = new Blob(content, { type: content[0].type });
  const newFileByBlob = new File([blob], content[0].name);

  bodyFormData.append('content', newFileByBlob);
  bodyFormData.append('source', source);
  bodyFormData.append('title', title);
  bodyFormData.append('description', description);

  return axiosInstance.post('/competitor-calendar/', bodyFormData).then(({ data }) => data);
};

export const getList = (): Promise<IRequest<ICompetitor[]>> =>
  axiosInstance.get('/competitor-calendar/').then(({ data }) => data);

export const getSelected = (): Promise<IRequest<ICompetitorCalendar>> =>
  axiosInstance.get('/selected-competitor-calendar/').then(({ data }) => data);

// export const getById = (id: number): Promise<IRequest<ICompetitorCalendar>> =>
//   axiosInstance.get(`/company/${id}/`).then(({ data }) => data);

export const patchById = (
  id: number,
  requestData: PatchRequestCompetitorCalendar,
): Promise<IRequest<IPatchResponseCompetitorCalendar>> => {
  const bodyFormData = new FormData();
  Object.entries(requestData).forEach(([key, value]) => bodyFormData.append(key, `${value}`));

  return axiosInstance.patch(`/competitor-calendar/${id}/`, bodyFormData).then(({ data }) => data);
};

export const deleteById = (id: number): AxiosPromise => axiosInstance.delete(`/competitor-calendar/${id}/`);
