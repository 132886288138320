import * as yup from 'yup';

export enum FormFields {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export const MIN_PASSWORD_LENGTH = 6;

export const emailValidation = yup.string().email('Enter a valid email').required('Email is required');

export const passwordValidation = yup
  .string()
  .min(MIN_PASSWORD_LENGTH, `Password should be of minimum ${MIN_PASSWORD_LENGTH} characters length`)
  .required('Password is required');

export const loginValidationSchema = yup.object({
  [FormFields.EMAIL]: emailValidation,
  [FormFields.PASSWORD]: passwordValidation,
});

export const resetEmailValidationSchema = yup.object({
  [FormFields.EMAIL]: emailValidation,
});
