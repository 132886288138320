import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { getActivityLogList } from '../../../service/changeServise';
import useIsMounted from '../../../utils/hooks/useIsMounted';
import { IResponseActivityLogInterfaceList } from '../../../interface/activityLogInterface';

const useRows = (sellQuantity: number) => {
  const [isLoading, setIsLoading] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: null,
    page: 0,
  });

  const [data, setData] = useState<IResponseActivityLogInterfaceList>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (sellQuantity > 0) {
      setPaginationModel((prevState) => ({ ...prevState, pageSize: sellQuantity }));
    }
  }, [sellQuantity]);

  useEffect(() => {
    if (paginationModel.pageSize && !!(paginationModel.page + 1)) {
      setIsLoading(true);
      getActivityLogList(paginationModel.page + 1, paginationModel.pageSize)
        .then((activityResponse) => {
          if (isMounted()) {
            setData(activityResponse.data);
            setIsLoading(false);
          }
        })
        .finally(() => {
          if (isMounted()) {
            setIsLoading(false);
          }
        });
    }
  }, [paginationModel, isMounted]);

  const rows = useMemo(() => {
    if (data?.data) {
      return data.data.map((activityLog) => ({
        id: activityLog.id,
        description: activityLog.message,
        action: activityLog.action,
        date: dayjs(activityLog.created_at).format('lll'),
        prevObjectData: activityLog.prev_object_data,
        objectData: activityLog.object_data,
      }));
    }
    return [];
  }, [data]);

  return { rows, paginationModel, setPaginationModel, isLoading, totalRowCount: data?.total_items };
};

export default useRows;
