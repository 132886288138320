import useWebSocket from 'react-use-websocket';

export function useConnectWebsocket(endpoint, addItem, removeItem) {
  const { sendMessage } = useWebSocket(endpoint, {
    onOpen: () => {
      sendMessage(JSON.stringify({ command: 'list' }));
    },
    shouldReconnect: (closeEvent) => true,
    share: true,
    onMessage: (event: WebSocketEventMap['message']) => {
      const data = JSON.parse(event.data);
      if (data.action === 'create') {
        addItem(data.data);
      }
      if (data.action === 'delete') {
        removeItem(data.data.map((i: { id: number }) => i.id));
      }
    },
  });
}
