import React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from '@mui/material';

interface IProps {
  value: boolean;
  style?: React.CSSProperties;
}
const BooleanRenderCell = ({ value, style = {} }: IProps) => (
    <Box sx={{ ...style }}>
      {value ? <CheckCircleRoundedIcon sx={{ color: '#2CA24D' }} /> : <CancelIcon sx={{ color: '#FA5255' }} />}
    </Box>
  );

export default BooleanRenderCell;
