import { observable, action, computed, makeObservable } from 'mobx';

class SidebarStore {
  @observable private _isExpand = true;

  constructor() {
    makeObservable(this);
  }

  @computed
  get isExpand() {
    return this._isExpand;
  }

  @action
  changeExpand = () => {
    this._isExpand = !this._isExpand;
  };
}

export default new SidebarStore();
