import React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, MenuItem, Popover, Typography, Stack, Box } from '@mui/material';
import Avatar from '@mui/joy/Avatar';
import Badge from '@mui/joy/Badge';
import IconButton from '@mui/joy/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useNavigate } from 'react-router-dom';
import MainStore from '../../../store/MainStore';
import NotificationDrawer from '../../NotificationDrawer';
import scss from './Header.module.scss';
import ShareCalendarPopup from './ShareCalendarPopup';
import { addSlash, MainRoutesPath } from '../../../interface/router';
import BurgerButton from '../../ui/burger-menu/BurgerButton';

const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

interface IProps {
  isShowShare?: boolean;
}
const Header = ({ isShowShare = false }: IProps) => {
  const navigate = useNavigate();

  const { unauthenticated, isSuperuser, isLoginAsUserAccess, email } = MainStore.authStore;

  const [avatarAnchorEl, setAvatarAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenAvatar = Boolean(avatarAnchorEl);

  const [shareAnchorEl, setShareAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenShare = Boolean(shareAnchorEl);

  const [openAlertsSidebar, setOpenAlertsSidebar] = React.useState<boolean>(false);

  const { alertsCount } = MainStore.notificationStore;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAvatarAnchorEl(null);
  };

  const toggleDrawer = (open: boolean) => {
    setOpenAlertsSidebar(open);
  };

  const handleShareClickOutside = (eventClick?: any) => {
    eventClick?.stopPropagation();
    eventClick?.preventDefault();
    setShareAnchorEl(null);
  };

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setShareAnchorEl(event.currentTarget);
  };

  const onUnauthenticatedHandler = () => {
    unauthenticated();
    navigate(addSlash(MainRoutesPath.LOGIN_PAGE));
  };

  return (
    <>
      <header className={scss.wrapper}>
        <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
          <div className={scss.logo}>STRATAPLAN</div>
          {/*<BurgerButton />*/}
        </Box>

        {!isSuperuser && isLoginAsUserAccess && (
          <Typography
            component="h2"
            id="close-modal-title"
            sx={{ fontWeight: 600, fontSize: '20px', lineHeight: '32px', color: '#262b40' }}
          >
            {email}
          </Typography>
        )}

        <Stack direction="row" spacing={2}>
          {isShowShare && (
            <IconButton color="neutral" variant="plain" onClick={handleShareClick}>
              <ShareIcon />
            </IconButton>
          )}

          <IconButton
            color="info"
            variant="plain"
            onClick={() => setOpenAlertsSidebar(!openAlertsSidebar)}
            // sx={{ transform: 'translateY(5px)' }}
          >
            <Badge badgeContent={alertsCount} color="info">
              <NotificationsOutlinedIcon />
            </Badge>
          </IconButton>

          <IconButton
            color="primary"
            variant="plain"
            aria-controls={isOpenAvatar ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpenAvatar ? 'true' : undefined}
            onClick={handleClick}
          >
            <Avatar color="primary" sx={{ width: 30, height: 30 }} />
          </IconButton>
        </Stack>
        <Menu
          anchorEl={avatarAnchorEl}
          id="account-menu"
          open={isOpenAvatar}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={paperProps}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={onUnauthenticatedHandler}>Logout</MenuItem>
        </Menu>
        <Popover
          open={isOpenShare}
          anchorEl={shareAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleShareClickOutside}
        >
          <ShareCalendarPopup onClose={handleShareClickOutside} />
        </Popover>
      </header>
      <NotificationDrawer openAlertsSidebar={openAlertsSidebar} toggleDrawer={toggleDrawer} />
    </>
  );
};
export default observer(Header);
