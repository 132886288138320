import React from 'react';
import { Box, Popover } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import { observer } from 'mobx-react-lite';
import { StartWeekDay, startWeekDayTitleMap } from '../../../interface/calendarInterface';
import MainStore from '../../../store/MainStore';
import { patchById } from '../../../service/companyService';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';
import scss from '../Planning.module.scss';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  instance: CalendarClass;
  onUpdateCalendar: () => Promise<void>;
}
const PopoverChangeStartDay = ({ onClose, anchorEl, instance, onUpdateCalendar }: IProps) => {
  const onHandleKeyDown = (keyDownEvent: any) => {
    if (keyDownEvent.key === 'Escape') {
      onClose();
    }
  };

  const onHandleChange = async (event) => {
    try {
      await patchById(MainStore.authStore.currentCompanyId.id, {
        calendar_weekday: event.target.value as unknown as StartWeekDay,
      });
      const infoSnackbarKey = enqueueSnackbar(
        `Change of start day is successful.
We are updating the calendar!`,
        { variant: 'info' },
      );
      await onUpdateCalendar();
      closeSnackbar(infoSnackbarKey);
      enqueueSnackbar(`Calendar updated!`, { variant: 'success' });
    } catch (e) {}
  };

  return (
    <Popover
      id="PopoverChangeStartDay_Popover"
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onKeyDown={onHandleKeyDown}
      className={scss.popover}
    >
      <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
        <Box className={scss.popperChangeMetric_wrapper}>
          <FormControl>
            <FormLabel sx={{ marginBottom: '15px', fontSize: '1rem' }}>Change start day</FormLabel>
            <RadioGroup
              value={`${instance?.startWeekDay}`}
              name="PopoverChangeStartDay_radio-buttons-group"
              onChange={onHandleChange}
            >
              <Radio value={`${StartWeekDay.MONDAY}`} label={startWeekDayTitleMap[StartWeekDay.MONDAY]} size="md" />
              <Radio value={StartWeekDay.TUESDAY} label={startWeekDayTitleMap[StartWeekDay.TUESDAY]} size="md" />
              <Radio value={StartWeekDay.WEDNESDAY} label={startWeekDayTitleMap[StartWeekDay.WEDNESDAY]} size="md" />
              <Radio value={StartWeekDay.THURSDAY} label={startWeekDayTitleMap[StartWeekDay.THURSDAY]} size="md" />
              <Radio value={StartWeekDay.FRIDAY} label={startWeekDayTitleMap[StartWeekDay.FRIDAY]} size="md" />
              <Radio value={StartWeekDay.SATURDAY} label={startWeekDayTitleMap[StartWeekDay.SATURDAY]} size="md" />
              <Radio value={StartWeekDay.SUNDAY} label={startWeekDayTitleMap[StartWeekDay.SUNDAY]} size="md" />
            </RadioGroup>
          </FormControl>
        </Box>
      </ClickAwayListener>
    </Popover>
  );
};

export default observer(PopoverChangeStartDay);
