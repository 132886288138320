import React from 'react';
import CustomFieldsGrid from './CustomFieldsGrid';

const CustomFieldsPage = () => (
  <section className="section">
    <CustomFieldsGrid />
  </section>
);

export default CustomFieldsPage;
