import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Tab, Tabs } from '@mui/material';
import Button from '@mui/joy/Button';
import MainStore from '../../../store/MainStore';
import { LoadingStatus } from '../../../interface';
import Loader from '../../../component/ui/loader/Loader';
import ProductsGrid from './ProductsGrid';
import ProductsCategoryGrid from './ProductsCategoryGrid';
import DefaultModal from '../../../component/ui/default-modal/DefaultModal';
import ProductModal from './ProductModal';
import ProductCategoryModal from './ProductCategoryModal';
import DefaultChildrenWrapperModal from '../../../component/ui/default-modal/DefaultChildrenWrapperModal';

enum TubValue {
  PRODUCTS = 'Products',
  PRODUCTS_CATEGORY = 'Categories',
}

const PageNameMap = {
  [TubValue.PRODUCTS]: 'Product',
  [TubValue.PRODUCTS_CATEGORY]: 'Category',
};

const ModalTitleMap = {
  [TubValue.PRODUCTS]: 'Add Product',
  [TubValue.PRODUCTS_CATEGORY]: 'Add Category',
};

const ProductsPage = () => {
  const [tabValue, setTabValue] = useState(TubValue.PRODUCTS);
  const [isOpenNewComponentModal, setIsOpenNewComponentModal] = useState(false);

  const onChangeTabValue = (event: React.SyntheticEvent, newValue: TubValue) => {
    setTabValue(newValue);
  };

  const { loadingStatus: productLoadingStatus, dataList: productList } = MainStore.productStore;
  const { loadingStatus: productCategoryLoadingStatus, dataList: productCategoryList } = MainStore.productCategoryStore;
  const { loadingStatus: companyLoadingStatus, dataList: companyList } = MainStore.companyStore;
  useEffect(() => {
    MainStore.productStore.fetchList();
    MainStore.productCategoryStore.fetchList();
    MainStore.companyStore.fetchList();

    return () => {
      MainStore.productStore.clear();
      MainStore.productCategoryStore.clear();
      MainStore.companyStore.clear();
    };
  }, []);

  const isLoading =
    productLoadingStatus === LoadingStatus.LOADING &&
    productCategoryLoadingStatus === LoadingStatus.LOADING &&
    companyLoadingStatus === LoadingStatus.LOADING;

  const isError =
    productLoadingStatus === LoadingStatus.ERROR ||
    productCategoryLoadingStatus === LoadingStatus.ERROR ||
    companyLoadingStatus === LoadingStatus.ERROR;

  const isSuccess =
    productLoadingStatus === LoadingStatus.SUCCESS &&
    productCategoryLoadingStatus === LoadingStatus.SUCCESS &&
    companyLoadingStatus === LoadingStatus.SUCCESS;

  const onAddHandler = () => {
    setIsOpenNewComponentModal(true);
  };

  const onCloseModal = () => {
    setIsOpenNewComponentModal(false);
  };

  const isOpenProducts = tabValue === TubValue.PRODUCTS;

  const isOpenCategories = tabValue === TubValue.PRODUCTS_CATEGORY;

  const addButtonTitle = `+ Add ${PageNameMap[tabValue]}`;

  const addModalTitle = ModalTitleMap[tabValue];

  const isOpenModal = isOpenNewComponentModal && (isOpenProducts || isOpenCategories);

  return (
    <section className="section">
      {isError && <div>ERROR</div>}
      {isLoading && <Loader />}
      {isSuccess && (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              gap: '10px',
              marginBottom: '16px',
            }}
          >
            <Tabs value={tabValue} onChange={onChangeTabValue} className="tabs">
              <Tab
                sx={{
                  fontSize: '1.875rem',
                  lineHeight: '1.167',
                  letterSpacing: '-0.3px',
                  textTransform: 'capitalize',
                  padding: '0 16px',
                }}
                value={TubValue.PRODUCTS_CATEGORY}
                label={TubValue.PRODUCTS_CATEGORY}
              />
              <Tab
                sx={{
                  fontSize: '1.875rem',
                  lineHeight: '1.167',
                  letterSpacing: '-0.3px',
                  textTransform: 'capitalize',
                  padding: '0 16px',
                }}
                value={TubValue.PRODUCTS}
                label={TubValue.PRODUCTS}
              />
            </Tabs>

            <Button variant="solid" onClick={onAddHandler}>
              {addButtonTitle}
            </Button>
          </Box>
          {isOpenProducts && (
            <ProductsGrid
              productList={productList}
              companyList={companyList}
              productCategoryList={productCategoryList}
            />
          )}
          {isOpenCategories && (
            <ProductsCategoryGrid productCategoryList={productCategoryList} companyList={companyList} />
          )}
          <DefaultModal open={isOpenModal}>
            <DefaultChildrenWrapperModal modalTitle={addModalTitle} onCloseModal={onCloseModal}>
              {isOpenProducts ? (
                <ProductModal onCloseModal={onCloseModal} />
              ) : (
                <ProductCategoryModal onCloseModal={onCloseModal} />
              )}
            </DefaultChildrenWrapperModal>
          </DefaultModal>
        </>
      )}
    </section>
  );
};

export default observer(ProductsPage);
