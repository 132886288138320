import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AnnotationValueForCalendarClass } from './AnnotationValueForCalendarClass';
import { IAnnotations } from '../../interface/calendarInterface';
import { patchValueById } from '../../service/customFieldService';

export class AnnotationForCalendarClass {
  private readonly _id: number;

  private readonly _title: string;

  @observable private _values: AnnotationValueForCalendarClass[];

  protected _order: number;

  constructor(props: IAnnotations) {
    makeObservable(this);
    this._id = props.id;
    this._title = props.title;
    this._values =
      (props.values &&
        props.values
          .filter((value) => !!value.value && value.value !== '')
          .map((value) => new AnnotationValueForCalendarClass(value, this))) ||
      [];
    this._order = props.order;
  }

  @computed get values() {
    return this._values;
  }

  get title() {
    return this._title;
  }

  get id() {
    return this._id;
  }

  get order() {
    return this._order;
  }

  @action deleteValueByDate = (forDate: string) => {
    const foundValueIndex = this._values.findIndex((value) => value.forDate === forDate);

    if (~foundValueIndex) {
      this._values.splice(foundValueIndex, 1);
    }
  };

  @action pushValue = (value: string | number, forDate: string): AnnotationValueForCalendarClass => {
    this.deleteValueByDate(forDate);
    const newValue = new AnnotationValueForCalendarClass({ value, for_date: forDate }, this);
    this._values.push(newValue);
    return newValue;
  };

  @action deleteValue = async (forDate: string) => {
    try {
      this.spliceValue(forDate);
      await patchValueById(this._id, { value: '', for_date: forDate });
    } catch {}
  };

  @action createValue = async (value: string | number, forDate: string) => {
    try {
      await patchValueById(this._id, { value, for_date: forDate });
      return true;
    } catch {
      this.spliceValue(forDate);
      return false;
    }
  };

  @action spliceValue = (forDate: string) => {
    const foundAnnotationValueClassIndex = this._values.findIndex(
      (annotationValueClass) => annotationValueClass.forDate === forDate,
    );
    if (~foundAnnotationValueClassIndex) {
      runInAction(() => {
        this._values.splice(foundAnnotationValueClassIndex, 1);
      });
    }
  };
}
