import React, { useState } from 'react';
import { Box, List, ListItem, Menu, MenuItem, Typography } from '@mui/material';
import IconButton from '@mui/joy/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

const HEIGHT = 48;
const COEFFICIENT = 4.5;
const ITEM_HEIGHT = HEIGHT * COEFFICIENT;

type Id = string | number;

interface IProps {
  label: string;
  unUsedElementToOptionList: { id: Id; title: string }[];
  usedElementsIdList: Id[];
  comparisonList: { id: Id; title: string }[];
  onRemoveElements: (id: Id) => void;
  onSelectElements: (id: Id) => void;
}

const ModalList = ({
  label,
  unUsedElementToOptionList,
  usedElementsIdList,
  comparisonList,
  onRemoveElements,
  onSelectElements,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [IsOpenUnUsed, setIsOpenUnUsed] = useState(false);

  const onOpenAddHandler = (value: boolean) => (event) => {
    setIsOpenUnUsed(value);
    if (value) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const onSelectElementsHandler = (id: Id) => () => {
    onOpenAddHandler(false)(null);
    onSelectElements(id);
  };

  const onRemoveElementsHandler = (id: Id) => () => {
    onOpenAddHandler(false)(null);
    onRemoveElements(id);
  };

  return (
    <>
      <Box sx={{ padding: '0 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className="labelSetting" component="span">
          {label}
        </Typography>
        <IconButton
          sx={{
            fontWeight: 700,
            fontSize: '22px',
            lineHeight: '20px',
          }}
          variant="plain"
          onClick={onOpenAddHandler(true)}
          disabled={!unUsedElementToOptionList.length}
        >
          +
        </IconButton>
        <Menu
          id="Button-on-OpenAddCampaignMetricsHandler-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={IsOpenUnUsed}
          onClose={onOpenAddHandler(false)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT,
            },
          }}
        >
          {unUsedElementToOptionList.map((option) => (
            <MenuItem key={`MenuItem_${option.id}_${option.title}`} onClick={onSelectElementsHandler(option.id)}>
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <List>
        {usedElementsIdList.map((campaignId) => {
          const { title } = comparisonList.find((campaign) => campaign.id === campaignId);
          return (
            <ListItem
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                paddingLeft: 0,
                paddingRight: 0,
              }}
              key={`MarketingPillarModal_${title}_${campaignId}_ListItem`}
            >
              <Typography component="span">{title}</Typography>
              <ListItemSecondaryAction sx={{ right: 0 }}>
                <IconButton
                  sx={{
                    fontWeight: 700,
                    fontSize: '22px',
                    lineHeight: '20px',
                  }}
                  color="danger"
                  variant="plain"
                  size="md"
                  onClick={onRemoveElementsHandler(campaignId)}
                >
                  -
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
export default ModalList;
