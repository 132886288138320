import React, { useEffect, useRef, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import Button from '@mui/joy/Button';
import { observer } from 'mobx-react-lite';
import UsersGrid from './UsersGrid';
import Loader from '../../../component/ui/loader/Loader';
import DefaultModal from '../../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../../component/ui/default-modal/DefaultChildrenWrapperModal';
import MainStore from '../../../store/MainStore';
import { LoadingStatus } from '../../../interface';
import CompaniesGrid from './CompaniesGrid';
import CompanyModal from './CompanyModal';
import AddUserModal from './AddUserModal';
import Can from '../../../component/permission/Can';
import { PermissionType } from '../../../interface/permissionInterface';
import findPermission from '../../../component/permission/findPermission';

enum TubValue {
  COMPANIES = 'Companies',
  USERS = 'Users',
}

const PageNameMap = {
  [TubValue.COMPANIES]: 'Company',
  [TubValue.USERS]: 'User',
};

const UsersPage = () => {
  const { permissions } = MainStore.authStore;

  const isCanViewUser = useRef(findPermission(['user.view_user'], permissions));
  const isCanViewCompany = useRef(findPermission(['company.view_company'], permissions));

  const [tabValue, setTabValue] = useState(isCanViewCompany.current ? TubValue.COMPANIES : TubValue.USERS);
  const [isOpenNewComponentModal, setIsOpenNewComponentModal] = useState(false);

  const onChangeTabValue = (event: React.SyntheticEvent, newValue: TubValue) => {
    setTabValue(newValue);
  };

  const { loadingStatus: userLoadingStatus, userList, fetchCreateUser } = MainStore.userStore;
  const { loadingStatus: companyLoadingStatus, dataList: companyList } = MainStore.companyStore;

  useEffect(() => {
    if (isCanViewUser.current) {
      MainStore.userStore.fetchUsers();
    }

    if (isCanViewCompany.current) {
      MainStore.companyStore.fetchList();
    }

    return () => {
      MainStore.userStore.clear();
      MainStore.companyStore.clear();
      MainStore.permissionStore.clear();
    };
  }, [isCanViewUser, isCanViewCompany]);

  const isLoading =
    (isCanViewUser.current ? userLoadingStatus === LoadingStatus.LOADING : true) &&
    (isCanViewCompany.current ? companyLoadingStatus === LoadingStatus.LOADING : true);

  const isError = userLoadingStatus === LoadingStatus.ERROR || companyLoadingStatus === LoadingStatus.ERROR;

  const isSuccess =
    (isCanViewUser.current ? userLoadingStatus === LoadingStatus.SUCCESS : true) &&
    (isCanViewCompany.current ? companyLoadingStatus === LoadingStatus.SUCCESS : true);

  const onAddHandler = () => {
    setIsOpenNewComponentModal(true);
  };

  const onCloseModal = () => {
    setIsOpenNewComponentModal(false);
  };

  const isOpenUsers = tabValue === TubValue.USERS;

  const isOpenCompanies = tabValue === TubValue.COMPANIES;

  const addButtonTitle = `+ Add ${PageNameMap[tabValue]}`;

  const addModalTitle = `Add ${PageNameMap[tabValue]}`;

  const isOpenModal = isOpenNewComponentModal && (isOpenUsers || isOpenCompanies);

  const permission: PermissionType[] | [] = isOpenCompanies
    ? ['company.add_company']
    : isOpenUsers
    ? ['user.add_user']
    : [];

  return (
    <section className="section">
      {isError && <div>ERROR</div>}
      {isLoading && <Loader />}
      {isSuccess && (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              gap: '10px',
              marginBottom: '16px',
            }}
          >
            <Tabs value={tabValue} onChange={onChangeTabValue} className="tabs">
              {isCanViewCompany.current && (
                <Tab
                  sx={{
                    fontSize: '1.875rem',
                    lineHeight: '1.167',
                    letterSpacing: '-0.3px',
                    textTransform: 'capitalize',
                    padding: '0 16px',
                  }}
                  value={TubValue.COMPANIES}
                  label={TubValue.COMPANIES}
                />
              )}
              {isCanViewUser.current && (
                <Tab
                  sx={{
                    fontSize: '1.875rem',
                    lineHeight: '1.167',
                    letterSpacing: '-0.3px',
                    textTransform: 'capitalize',
                    padding: '0 16px',
                  }}
                  value={TubValue.USERS}
                  label={TubValue.USERS}
                />
              )}
            </Tabs>

            <Can permissionList={permission}>
              <Button variant="solid" onClick={onAddHandler}>
                {addButtonTitle}
              </Button>
            </Can>
          </Box>
          {isOpenUsers && <UsersGrid userList={userList} companyList={companyList} />}
          {isOpenCompanies && <CompaniesGrid companyList={companyList} />}
          <DefaultModal open={isOpenModal}>
            <DefaultChildrenWrapperModal modalTitle={addModalTitle} onCloseModal={onCloseModal}>
              {isOpenUsers ? (
                <AddUserModal
                  onCloseModalHandler={onCloseModal}
                  fetchCreateUser={fetchCreateUser}
                  loadingStatus={userLoadingStatus}
                />
              ) : (
                <CompanyModal onCloseModal={onCloseModal} />
              )}
            </DefaultChildrenWrapperModal>
          </DefaultModal>
        </>
      )}
    </section>
  );
};
export default observer(UsersPage);
