import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, FormControl, FormHelperText, MenuItem } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { CompanyClass } from '../../../slass/data/company/CompanyClass';
import onHandleChangeInput from '../../../utils/onHandleChangeInput';
import MainStore from '../../../store/MainStore';
import { LoadingStatus } from '../../../interface';
import { Range, startWeekDayTitleMap } from '../../../interface/calendarInterface';
import validateNumber, { getValidNumber } from '../../../utils/validateNumber';
import useCheckUsedTitle from '../../../utils/hooks/useCheckUsedTitle';

interface IProps {
  instance?: CompanyClass;
  onCloseModal: () => void;
}
const CompanyModal = ({ instance, onCloseModal }: IProps) => {
  const { fetchCreate, loadingStatus, dataList: companyList } = MainStore.companyStore;

  const [title, setTitle] = useState(instance?.title || '');
  const [description, setDescription] = useState(instance?.description || '');
  const [budget, setBudget] = useState(instance?.budget || 0);
  const [startWeekDay, setStartWeekDay] = useState(instance?.calendarWeekday ?? 0);
  const [dateRange, setDateRange] = useState<Range>('week');

  const onSetBudget = (value: number | string) => {
    if (validateNumber(value) || value === '') {
      const newBudget = getValidNumber((value > 0 ? value : 0) as unknown as number);
      setBudget(newBudget);
    }
  };

  const usedTitleList = useMemo(() => companyList.map((company) => company.title), [companyList]);

  const isAlreadyUsedTitle = useCheckUsedTitle(instance?.title, title, usedTitleList);

  const isEmptyTitle = !title.length;

  const isDisabledButton = isAlreadyUsedTitle || isEmptyTitle;

  const onSaveButtonHandle = () => {
    const fn = instance?.fetchPatch || fetchCreate;

    const uploadData: Record<string, any> = {};
    if (instance) {
      uploadData.title = title;
      uploadData.description = description;
      uploadData.budget = budget;
      uploadData.calendar_weekday = startWeekDay;
    } else {
      uploadData.title = title;
      uploadData.calendar_daterange = dateRange;
      uploadData.calendar_weekday = startWeekDay;
      uploadData.budget = budget;
    }

    fn(uploadData).then((isSuccess) => {
      if (isSuccess) {
        onCloseModal();
      }
    });
  };

  const isLoading = (instance?.loadingStatus || loadingStatus) === LoadingStatus.LOADING;

  return (
    <>
      <Box sx={{ margin: '0 0 25px' }}>
        <FormControl sx={{ position: 'relative', width: '100%' }} error={isAlreadyUsedTitle || isEmptyTitle}>
          <TextField
            id="CompanyModal_titleInput"
            placeholder="Title"
            variant="standard"
            sx={{ width: '100%' }}
            value={title}
            onChange={onHandleChangeInput(setTitle)}
          />
          {isAlreadyUsedTitle && (
            <FormHelperText
              id="CustomFieldModal-title-error-by-isAlreadyUsedTitle"
              sx={{ position: 'absolute', bottom: '-70%', left: 0 }}
            >
              The title is already in use
            </FormHelperText>
          )}
          {isEmptyTitle && (
            <FormHelperText
              id="CustomFieldModal-title-error-by-isEmptyTitle"
              sx={{ position: 'absolute', bottom: '-70%', left: 0 }}
            >
              The title is required
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      {instance && (
        <Box sx={{ margin: '25px 0' }}>
          <TextField
            id="standard-basic"
            label="Description"
            multiline
            variant="outlined"
            rows={4}
            sx={{ width: '100%' }}
            value={description}
            onChange={onHandleChangeInput(setDescription)}
          />
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', margin: '25px 0', gap: '40px' }}>
        <Typography component="h4" sx={{ display: 'inline-block' }}>
          Yearly Media Budget
        </Typography>
        <TextField
          id="CompanyModal_BudgetInput"
          // type="number"
          variant="standard"
          value={budget}
          onChange={onHandleChangeInput(onSetBudget)}
        />
      </Box>

      {/*{!instance && (*/}
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '25px 0', gap: '40px' }}>
        <Typography component="h4" sx={{ display: 'inline-block' }}>
          Start Calendar - Day of the Week
        </Typography>

        <Select
          labelId="labelId_Select_CompanyModal_startWeekDay"
          id="Select_CompanyModal_startWeekDay"
          value={startWeekDay}
          label={null}
          onChange={onHandleChangeInput(setStartWeekDay)}
          sx={{ height: '40px' }}
        >
          <MenuItem value={0}>{startWeekDayTitleMap[0]}</MenuItem>
          <MenuItem value={1}>{startWeekDayTitleMap[1]}</MenuItem>
          <MenuItem value={2}>{startWeekDayTitleMap[2]}</MenuItem>
          <MenuItem value={3}>{startWeekDayTitleMap[3]}</MenuItem>
          <MenuItem value={4}>{startWeekDayTitleMap[4]}</MenuItem>
          <MenuItem value={5}>{startWeekDayTitleMap[5]}</MenuItem>
          <MenuItem value={6}>{startWeekDayTitleMap[6]}</MenuItem>
        </Select>
      </Box>
      {/*)}*/}

      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button variant="solid" onClick={onSaveButtonHandle} loading={isLoading} disabled={isDisabledButton}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default observer(CompanyModal);
