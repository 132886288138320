import React from 'react';
import { Box, Stack } from '@mui/material';
import JoyLink from '@mui/joy/Link';
import { Link } from 'react-router-dom';
import scss from './NotFoundPage.module.scss';

const NotFoundPage = () => {
  return (
    <Box className={scss.main}>
      <Stack spacing={3}>
        <h1>Sorry, page not found!</h1>
        <Box className={scss.main__notFound}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
          spelling.
        </Box>
        <Box>
          <JoyLink
            color="primary"
            level="h4"
            underline="none"
            variant="solid"
            component={Link}
            to="/"
            sx={{ padding: '0.6em', borderRadius: '16px' }}
          >
            Let's go home
          </JoyLink>
        </Box>
      </Stack>
    </Box>
  );
};

export default NotFoundPage;
