import { ModalClose, Sheet } from '@mui/joy';
import { Box } from '@mui/material';
import Typography from '@mui/joy/Typography';
import React, { memo } from 'react';
import { SxProps } from '@mui/joy/styles/types/theme';

interface IProps {
  onCloseModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  modalTitle: string;
  children: JSX.Element;
  sx?: SxProps;
  parentRef?: any;
  childrenRef?: any;
}
const DefaultChildrenWrapperModal = ({
  onCloseModal,
  modalTitle,
  children,
  sx = {},
  parentRef,
  childrenRef,
}: IProps) => (
  <Sheet
    ref={parentRef}
    variant="outlined"
    sx={{
      width: 580,
      borderRadius: 'md',
      p: 3,
      maxHeight: '95vh',
      ...sx,
    }}
  >
    <ModalClose variant="plain" onClick={onCloseModal} />
    <Typography
      component="h2"
      id="close-modal-title"
      sx={{ margin: '0 0 25px', fontWeight: 600, fontSize: '20px', lineHeight: '32px' }}
    >
      {modalTitle}
    </Typography>
    <Box ref={childrenRef} sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
      {children}
    </Box>
  </Sheet>
);

export default memo(DefaultChildrenWrapperModal);
