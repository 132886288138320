import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { GridColDef } from '@mui/x-data-grid';
import { LoadingStatus } from '../../interface';
import Grid from '../../component/ui/grid/Grid';
import Loader from '../../component/ui/loader/Loader';
import useTableHeight from '../../utils/hooks/useTableHeight';
import MainStore from '../../store/MainStore';
import DeleteModalContent from '../../component/ui/delete-modal-content/DeleteModalContent';
import MarketingPillarModal from './MarketingPillarModal';
import useBaseGridBehavior from '../../utils/hooks/useBaseGridBehavior';
import DefaultModal from '../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../component/ui/default-modal/DefaultChildrenWrapperModal';
import useColumns from './hook/useColumns';
import { MarketingPillarClass } from '../../slass/data/marketing-pillars/MarketingPillarClass';
import useTableChangeOrder from '../../utils/hooks/useTableChangeOrder';
import useElementOrderInTable from '../../utils/hooks/useElementOrderInTable';
import BooleanRenderCell from '../../component/ui/grid/BooleanRenderCell';

const MarketingPillarGrid = () => {
  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();

  const { state, action } = useBaseGridBehavior();
  const { selectedComponent, isOpenDeleteComponentModal, isOpenNewComponentModal, isOpenModal } = state;
  const { onDeleteComponentModal, onCloseModal, onOpenEditComponentModal, setIsOpenNewComponentModal } = action;
  const {
    loadingStatus,
    dataList: marketingPillarList,
    fetchDelete: fetchDeleteMarketingPilar,
  } = MainStore.marketingPillarStore;

  useEffect(() => {
    MainStore.marketingPillarStore.fetchList();

    return () => {
      MainStore.marketingPillarStore.clear();
    };
  }, []);

  const changeTableOrder = useTableChangeOrder(MainStore.marketingPillarStore);

  const columns: GridColDef[] = useColumns({ onDeleteComponentModal, onOpenEditComponentModal, changeTableOrder });

  const marketingPillarInstance = useMemo(
    () => marketingPillarList.find((marketingPillar) => marketingPillar.id === selectedComponent),
    [marketingPillarList, selectedComponent],
  );
  const usedMarketingTitle: string[] = useMemo(
    () =>
      marketingPillarList.reduce((reduceState: string[], currentValue) => {
        reduceState.push(currentValue.title);
        currentValue.marketingChannels?.forEach((marketingChannel) => {
          reduceState.push(marketingChannel.title);
        });
        return reduceState;
      }, []),
    [marketingPillarList],
  );
  const orders = useElementOrderInTable(marketingPillarList);

  const row = marketingPillarList
    .reduce((prevState, currentValue) => {
      const currentRow = {
        order: currentValue.order,
        id: currentValue.id,
        title: currentValue.title,
        description: currentValue.description,
        multipleChannels:
          (currentValue.hasMarketingChannels &&
            currentValue.marketingChannels.map((channel) => channel.title).join(', ')) ||
          '-',
        yearly_budget: currentValue.yearlyBudget,
        maxOrder: orders.max,
        minOrder: orders.min,
        // campaign: currentValue.campaignMetrics.map((campaignId) => {
        //   const campaignTitle = campaignMetricsList.find((campaign) => campaign.id === campaignId)?.title;
        //   return { campaignTitle, key: `Chip_${campaignId}_${campaignTitle}` };
        // }),
      };
      prevState.push(currentRow);
      return prevState;
    }, [])
    .sort((a, b) => a.id - b.id);

  const onAddUserHandler = () => {
    setIsOpenNewComponentModal(true);
  };

  const isOpenEditModal = (selectedComponent === 0 || selectedComponent) && isOpenModal;
  const modalTitle =
    (isOpenEditModal && 'Change Marketing Pillar') || (isOpenNewComponentModal && 'Add Marketing Pillar');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '16px',
        }}
      >
        <Typography level="h3" component="h6">
          Marketing Pillars
        </Typography>
        <Button variant="solid" onClick={onAddUserHandler}>
          + Add Pillar
        </Button>
      </Box>
      <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
        {componentHeight &&
        loadingStatus !== LoadingStatus.LOADING &&
        {
          /* campaignMetricsLoadingStatus !== LoadingStatus.LOADING */
        } ? (
          <Grid sellQuantity={sellQuantity} rows={row} columns={columns} />
        ) : (
          <Loader />
        )}

        <DefaultModal open={isOpenEditModal || isOpenNewComponentModal}>
          <DefaultChildrenWrapperModal modalTitle={modalTitle} onCloseModal={onCloseModal}>
            <MarketingPillarModal
              propsInstance={marketingPillarInstance}
              onCloseModalHandler={onCloseModal}
              usedMarketingTitle={usedMarketingTitle}
            />
          </DefaultChildrenWrapperModal>
        </DefaultModal>

        <DefaultModal open={isOpenDeleteComponentModal}>
          <DeleteModalContent<MarketingPillarClass>
            onCloseModalHandler={onCloseModal}
            fetchDelete={fetchDeleteMarketingPilar}
            loadingStatus={loadingStatus}
            id={marketingPillarInstance?.id}
            title={marketingPillarInstance?.title}
          />
        </DefaultModal>
      </Box>
    </>
  );
};

export default observer(MarketingPillarGrid);
