import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/joy/Button';
import { Box } from '@mui/material';
import Typography from '@mui/joy/Typography';
import { LoadingStatus } from '../../interface';
import Grid from '../../component/ui/grid/Grid';
import Loader from '../../component/ui/loader/Loader';
import MainStore from '../../store/MainStore';
import useTableHeight from '../../utils/hooks/useTableHeight';
import CampaignMetricModal from './CampaignMetricModal';
import { EnumUnitOfCampaign, typeOfCampaignMap } from '../../interface/campaignMetricInterface';
import DefaultModal from '../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../component/ui/default-modal/DefaultChildrenWrapperModal';
import useBaseGridBehavior from '../../utils/hooks/useBaseGridBehavior';
import DeleteModalContent from '../../component/ui/delete-modal-content/DeleteModalContent';
import useColumns from './hook/useColumns';
import { CampaignMetricsClass } from '../../slass/data/campaign-metrics/CampaignMetricsClass';
import useTableChangeOrder from '../../utils/hooks/useTableChangeOrder';
import useElementOrderInTable from '../../utils/hooks/useElementOrderInTable';

const CampaignMetricsGrid = () => {
  const { state, action } = useBaseGridBehavior();
  const { selectedComponent, isOpenDeleteComponentModal, isOpenNewComponentModal, isOpenModal } = state;
  const { onDeleteComponentModal, onCloseModal, onOpenEditComponentModal, setIsOpenNewComponentModal } = action;

  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();

  const { fetchList, loadingStatus, dataList, fetchDelete, clear } = MainStore.campaignMetricsStore;

  useEffect(() => {
    fetchList();
    return () => clear();
  }, [fetchList, clear]);

  const orders = useElementOrderInTable(dataList);

  const row = dataList.reduce((prevState, currentValue) => {
    const currentRow = {
      order: currentValue.order,
      id: currentValue.id,
      title: currentValue.title,
      description: currentValue.description,
      type: typeOfCampaignMap[currentValue.type],
      unit: EnumUnitOfCampaign[currentValue.unit],
      total: currentValue.hasTotal && currentValue.totalTitle,
      maxOrder: orders.max,
      minOrder: orders.min,
    };
    prevState.push(currentRow);
    return prevState;
  }, []);

  const campaignMetricInstance = useMemo(
    () => dataList?.find((campaignMetric) => campaignMetric.id === selectedComponent),
    [dataList, selectedComponent],
  );

  const changeTableOrder = useTableChangeOrder(MainStore.campaignMetricsStore);

  const columns = useColumns({ onDeleteComponentModal, onOpenEditComponentModal, changeTableOrder });

  const onAddMetricHandler = () => {
    setIsOpenNewComponentModal(true);
  };

  const isOpenEditModal = (selectedComponent === 0 || selectedComponent) && isOpenModal;
  const modalTitle =
    (isOpenEditModal && 'Change Campaign Metric') || (isOpenNewComponentModal && 'Add Campaign Metric');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          gap: '10px',
          marginBottom: '16px',
        }}
      >
        <Typography level="h3" component="h6">
          Campaign Metrics
        </Typography>
        <Button variant="solid" onClick={onAddMetricHandler}>
          + Add Campaign Metric
        </Button>
      </Box>
      <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
        {componentHeight && loadingStatus !== LoadingStatus.LOADING ? (
          <Grid sellQuantity={sellQuantity} rows={row} columns={columns} />
        ) : (
          <Loader />
        )}

        <DefaultModal open={isOpenEditModal || isOpenNewComponentModal}>
          <DefaultChildrenWrapperModal modalTitle={modalTitle} onCloseModal={onCloseModal}>
            <CampaignMetricModal
              instance={campaignMetricInstance}
              onCloseModalHandler={onCloseModal}
              dataList={dataList}
            />
          </DefaultChildrenWrapperModal>
        </DefaultModal>

        <DefaultModal open={isOpenDeleteComponentModal && !!campaignMetricInstance}>
          <DeleteModalContent<CampaignMetricsClass>
            onCloseModalHandler={onCloseModal}
            fetchDelete={fetchDelete}
            loadingStatus={loadingStatus}
            id={campaignMetricInstance?.id}
            title={campaignMetricInstance?.title}
          />
        </DefaultModal>
      </Box>
    </>
  );
};

export default observer(CampaignMetricsGrid);
