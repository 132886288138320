const transformString = (str: string) => {
  const firstChar = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1);

  const replacedString = restOfString.replace(/_/g, ' ');

  return firstChar + replacedString;
};

export default transformString;
