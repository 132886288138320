import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Divider } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { Box, Paper } from '@mui/material';
import MainStore from '../../../store/MainStore';
import Loader from '../../../component/ui/loader/Loader';
import { LoadingStatus } from '../../../interface';
import PermissionSettingsBlock from './PermissionSettingsBlock';
import useTableHeight from '../../../utils/hooks/useTableHeight';

const PermissionsPage = () => {
  const { homeSvgViewerRef, componentHeight } = useTableHeight(true);

  const [groupPermissionId, setGroupPermissionId] = useState(1);

  const { groupPermissionLoadingStatus, groupPermissionList, permissionLoadingStatus } = MainStore.permissionStore;

  useEffect(() => {
    MainStore.permissionStore.fetchGroupPermissionList();
    MainStore.permissionStore.fetchPermissionList();

    return () => {
      MainStore.permissionStore.clear();
    };
  }, []);

  const isLoading =
    groupPermissionLoadingStatus === LoadingStatus.LOADING && permissionLoadingStatus === LoadingStatus.LOADING;

  const isError =
    groupPermissionLoadingStatus === LoadingStatus.ERROR || permissionLoadingStatus === LoadingStatus.ERROR;

  const isSuccess =
    groupPermissionLoadingStatus === LoadingStatus.SUCCESS && permissionLoadingStatus === LoadingStatus.SUCCESS;

  const selectOptionList = useMemo(
    () => groupPermissionList.map((groupPermission) => ({ id: groupPermission.id, name: groupPermission.name })),
    [groupPermissionList],
  );

  const selectGroupPermissionInstance = useMemo(
    () => groupPermissionList.find((groupPermission) => groupPermission.id === groupPermissionId),
    [groupPermissionId, groupPermissionList],
  );

  const onChangeGroupPermissionId = (
    e: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null,
    value: number,
  ) => {
    setGroupPermissionId(value);
  };

  return (
    <section className="section">
      <Typography level="h3" component="h1">
        Permissions
      </Typography>
      {isError && <div>ERROR</div>}
      {isLoading && <Loader />}

      <Box ref={homeSvgViewerRef} sx={{ marginTop: '10px' }}>
        {isSuccess && (
          <Paper
            elevation={2}
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              maxHeight: `${componentHeight + 22}px`,
            }}
          >
            <Select value={groupPermissionId} onChange={onChangeGroupPermissionId} sx={{ alignSelf: 'start' }}>
              {selectOptionList.map((selectOption) => (
                <Option key={`PermissionsPage_SelectRole_${selectOption.name}`} value={selectOption.id}>
                  {selectOption.name}
                </Option>
              ))}
            </Select>
            <Divider orientation="horizontal" />
            <PermissionSettingsBlock groupPermissionInstance={selectGroupPermissionInstance} />
          </Paper>
        )}
      </Box>
    </section>
  );
};

export default observer(PermissionsPage);
