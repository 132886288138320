import { action, computed, makeObservable, observable } from 'mobx';
import { ICreateResponseCustomField, IPatchRequestCustomField } from '../../../interface/customFieldInterface';

import { BaseClass } from '../BaseClass';

export class CustomFieldClass extends BaseClass {
  protected readonly _status: string;

  protected readonly _position: string;

  protected readonly _calendar: number;

  protected readonly _user: number;

  protected readonly _createdAt: string;

  @observable protected _updatedAt: string;

  @observable protected _order: number;

  constructor(props: ICreateResponseCustomField, serviceConductor) {
    super(serviceConductor);
    this.snakeToCamelBuilder(props);
    makeObservable(this);
  }

  @computed get order() {
    return this._order;
  }

  @action setOrder = (order: number) => {
    this._order = order;
  };

  @action protected _builder = (key, value) => {};

  @action fetchPatch = async (data: IPatchRequestCustomField) => this.fetchPatchBase(data);
}
