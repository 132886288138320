import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import dayjs from 'dayjs';
import { DateSelectArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { MarketingPillarClass } from '../../../slass/data/marketing-pillars/MarketingPillarClass';
import { CampaignMetricsClass } from '../../../slass/data/campaign-metrics/CampaignMetricsClass';
import { ProductClass } from '../../../slass/data/product/ProductClass';
import { LoadingStatus } from '../../../interface';
import {
  create,
  deleteAttachmentById,
  deleteById,
  deleteMetricById,
  deleteProductById,
  deleteProductFromMetricById,
  getById,
  patchById,
  uploadFile,
} from '../../../service/marketingCampaignService';
import {
  IAttachment,
  IResponseMarketingCampaign,
  IUpdateRequestMarketingCampaignValue,
} from '../../../interface/marketingCampaignInterface';
import validateNumber, { getValidNumber } from '../../../utils/validateNumber';
import { validateUrl } from '../../../utils/validateUrl';
import { ProductForCalendarClass } from '../../../slass/calendar/ProductForCalendarClass';
import { SettingsMetricClass } from './SettingsMetricClass';
import addHttpsToLink from '../../../utils/addHttpsToLink';

export interface INewLink {
  link: string;
  description: string;
}

export class PlanningCalendarModalClass {
  @observable protected _loadingStatus = LoadingStatus.SUCCESS;

  @observable protected _loadingInitStatus = LoadingStatus.SUCCESS;

  @observable private _copyDataForUpdate: IResponseMarketingCampaign = null;

  @observable protected _budget: number;

  protected _id: number;

  @observable protected _channelId: number;

  private readonly _marketingPillar: MarketingPillarClass = null;

  private readonly _campaignMetricsList: SettingsMetricClass[] = [];

  private readonly _globalProductList: ProductForCalendarClass[] = [];

  @observable private _usedCampaignMetricsList: SettingsMetricClass[] = [];

  @observable private _unUsedCampaignMetricsList: SettingsMetricClass[] = [];

  @observable private _usedGlobalProductList: ProductForCalendarClass[] = [];

  @observable private _unUsedGlobalProductList: ProductForCalendarClass[] = [];

  @observable private _title: string = '';

  @observable private _description: string = '';

  @observable private _startDate: dayjs.Dayjs;

  @observable private _endDate: dayjs.Dayjs;

  @observable private _deadlineDate: dayjs.Dayjs;

  @observable private _newFileList: { file: File[]; url: string; description: string }[] = [];

  @observable private _newLinkList: INewLink[] = [];

  @observable private _attachments: IAttachment[] = [];

  @observable private _deleteAttachmentIdList: number[] = [];

  constructor(
    data: DateSelectArg & EventImpl,
    campaignMetricsList: CampaignMetricsClass[],
    productList: ProductClass[],
  ) {
    makeObservable(this);

    const productClassList = productList.map((product) => new ProductForCalendarClass({ product }));
    const campaignMetricsClassList = campaignMetricsList.map(
      (campaignMetric) => new SettingsMetricClass(campaignMetric),
    );

    const extendedProps = data.resource?._resource?.extendedProps || data.extendedProps || {};
    const endDate = dayjs(data.endStr).subtract(1, 'day');
    this._startDate = dayjs(data.startStr);
    this._endDate = endDate;
    this._deadlineDate = endDate;
    this._marketingPillar = extendedProps.marketingPillar || extendedProps.marketingPillarInstance;
    this._campaignMetricsList = campaignMetricsClassList;
    this._globalProductList = productClassList;
    this._channelId = extendedProps.channelId;

    if (data.title && data.extendedProps) {
      this._initMarketingCampaign(data.extendedProps.marketingPillar.id);
    } else {
      this._unUsedGlobalProductList = productClassList;
      this._unUsedCampaignMetricsList = campaignMetricsClassList;
    }
  }

  @action private _initMarketingCampaign = async (id: number) => {
    this._loadingInitStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getById(id);
      const usedProductsIdList = [];
      const usedCampaignMetricsIdList = [];
      runInAction(() => {
        this._copyDataForUpdate = data;

        this._title = data.title;
        this._id = data.id;
        this._startDate = dayjs(data.start_date);
        this._endDate = dayjs(data.end_date);
        this._deadlineDate = (data.deadline_date && dayjs(data.deadline_date)) || null;
        this._budget = data.budget;
        this._description = data.description;
        this._usedGlobalProductList = data.products.map((product) => {
          usedProductsIdList.push(product.product.id);
          // @ts-ignore
          return new ProductForCalendarClass(product);
        });
        this._unUsedGlobalProductList = this._globalProductList.filter(
          (product) => !usedProductsIdList.includes(product.product.id),
        );
        this._usedCampaignMetricsList = data.metrics.map((metric) => {
          const metricInstance = this.getCampaignMetricById(metric.campaign_metric.id);
          metricInstance.setAutoDistribute(metric.auto_distribute);
          metricInstance.setValue(metric.value);
          metricInstance.setIdOfBunch(metric.id);
          metricInstance.setTitle(metric.campaign_metric.title);
          metricInstance.setPartOfBudget(metric.part_of_budget);
          metricInstance.setPredictedValue(metric.predicted_value);
          metricInstance.setProductList(metric.products.map((product) => new ProductForCalendarClass(product)));
          metricInstance.setCopyDataForUpdate(metric);
          usedCampaignMetricsIdList.push(metric.campaign_metric.id);
          return metricInstance;
        });

        this._unUsedCampaignMetricsList = this._campaignMetricsList.filter(
          (metric) => !usedCampaignMetricsIdList.includes(metric.id),
        );
        this._attachments = data.attachments ?? [];
        this._loadingInitStatus = LoadingStatus.SUCCESS;
      });
    } catch {
      this._loadingInitStatus = LoadingStatus.ERROR;
    }
  };

  @computed get loadingStatus() {
    return this._loadingStatus;
  }

  @computed get loadingInitStatus() {
    return this._loadingInitStatus;
  }

  get marketingPillar() {
    return this._marketingPillar;
  }

  @computed get copyDataForUpdate() {
    return this._copyDataForUpdate || {};
  }

  get id() {
    return this._id;
  }

  get campaignMetricsList() {
    return this._campaignMetricsList;
  }

  get globalProductList() {
    return this._globalProductList;
  }

  getCampaignMetricsById = (id: number) => this.campaignMetricsList.find((campaignMetric) => campaignMetric.id === id);

  @computed get deletedGlobalProductList(): number[] {
    return (
      this._copyDataForUpdate?.products?.reduce((state: number[], checkedProduct) => {
        const checkedProductId = checkedProduct.id;
        const used = ~this._usedGlobalProductList.findIndex((usedProduct) => usedProduct.id === checkedProductId);

        if (!used) {
          state.push(checkedProduct.id);
        }
        return state;
      }, []) || []
    );
  }

  @computed get isHasDeletedGlobalProduct() {
    return !!Object.keys(this.deletedGlobalProductList).length;
  }

  @computed get deletedMetricList() {
    return (
      this._copyDataForUpdate?.metrics?.reduce((state: number[], checkedMetric) => {
        const checkedMetricId = checkedMetric.campaign_metric.id;
        const used = ~this._usedCampaignMetricsList.findIndex(
          (usedCampaignMetric) => usedCampaignMetric.id === checkedMetricId,
        );
        if (!used) {
          state.push(checkedMetric.id);
        }
        return state;
      }, []) || []
    );
  }

  @computed get isHasDeletedMetric() {
    return !!Object.keys(this.deletedMetricList).length;
  }

  @computed get isHasDeletedMetricsProduct() {
    return !!this._usedCampaignMetricsList.find((usedCampaignMetric) => usedCampaignMetric.deletedProductList.length);
  }

  @computed get deletedAttachmentsList() {
    return this._deleteAttachmentIdList;
  }

  @computed get usedCampaignMetricsList() {
    return this._usedCampaignMetricsList;
  }

  @computed get unUsedCampaignMetricsList() {
    return this._unUsedCampaignMetricsList;
  }

  @computed get usedGlobalProductList() {
    return this._usedGlobalProductList;
  }

  @computed get unUsedGlobalProductList() {
    return this._unUsedGlobalProductList;
  }

  @computed get title() {
    return this._title;
  }

  @computed get budget() {
    return getValidNumber(this._budget);
  }

  @computed get description() {
    return this._description || '';
  }

  @computed get startDate() {
    return this._startDate;
  }

  @computed get endDate() {
    return this._endDate;
  }

  @computed get deadlineDate() {
    return this._deadlineDate;
  }

  @computed get newFileList() {
    return this._newFileList;
  }

  @computed get newLinkList() {
    return this._newLinkList;
  }

  @computed get attachments() {
    return this._attachments;
  }

  @computed get deletedAttachmentIdList() {
    return this._deleteAttachmentIdList;
  }

  getUsedCampaignMetricsListById = (id: number) => this._usedCampaignMetricsList.find((metric) => metric.id === id);

  getCampaignMetricById = (id: number) => this._campaignMetricsList.find((metric) => metric.id === id);

  checkIsNewCampaignMetric = (campaignMetricId) =>
    !~this._copyDataForUpdate.metrics.findIndex(
      (copyCampaignMetric) => copyCampaignMetric.campaign_metric.id === campaignMetricId,
    );

  @action setBudget = (value: string | number) => {
    if (validateNumber(value) || value === '') {
      this._budget = (value > 0 ? value : 0) as unknown as number;
    }
  };

  private _findByIdCallbackFunctionForList = (id: number) => (el: ProductForCalendarClass | SettingsMetricClass) => {
    const isProduct = el instanceof ProductForCalendarClass;
    return isProduct ? el.product.id === id : el.id === id;
  };

  @action private deleteFromList = (id: number, name: string) => {
    this[name] = this[name].filter((el) => !this._findByIdCallbackFunctionForList(id)(el));
  };

  @action private findById = <T extends ProductForCalendarClass | SettingsMetricClass>(id: number, list: T[]): T =>
    list.find(this._findByIdCallbackFunctionForList(id));

  @action private _setUnSetTemplate = (id: number, fieldFrom: string, fieldTo: string) => {
    const found = this.findById(id, this[fieldFrom]);
    if (found) {
      this[fieldTo].push(found);
      this.deleteFromList(id, fieldFrom);
    }
  };

  @action setUsedCampaignMetric = (id: number) => {
    this._setUnSetTemplate(id, '_unUsedCampaignMetricsList', '_usedCampaignMetricsList');
  };

  @action setUnUsedCampaignMetric = (id: number) => {
    this._setUnSetTemplate(id, '_usedCampaignMetricsList', '_unUsedCampaignMetricsList');
  };

  @action setUsedGlobalProduct = (id: number) => {
    this._setUnSetTemplate(id, '_unUsedGlobalProductList', '_usedGlobalProductList');
  };

  @action setUnUsedGlobalProduct = (id: number) => {
    this._setUnSetTemplate(id, '_usedGlobalProductList', '_unUsedGlobalProductList');
  };

  @action setTitle = (value: string) => {
    this._title = value;
  };

  @action setNewFile = (file: File[]) => {
    const url = URL.createObjectURL(file[0]);
    this._newFileList.push({ file, url, description: '' });
  };

  @action changeNewFileDescription = (file: File[], description) => {
    const foundNewFile = this._newFileList.find((newFile) => newFile.file === file);
    if (~foundNewFile) {
      foundNewFile.description = description;
    }
  };

  @action deleteNewFile = (delUrl: string) => {
    this._newFileList = this._newFileList.filter(({ url }) => url !== delUrl);
  };

  @action setNewLink = (data: INewLink) => {
    this._newLinkList.push(data);
  };

  @action changeNewLink = (index: number, data: Partial<INewLink>) => {
    this._newLinkList[index] = { ...this._newLinkList[index], ...data };
  };

  @action setDescription = (value: string) => {
    this._description = value;
  };

  @action setStartDate = (value: dayjs.Dayjs) => {
    this._startDate = value;
  };

  @action setEndDate = (value: dayjs.Dayjs) => {
    this._endDate = value;
  };

  @action setDeadlineDate = (value: dayjs.Dayjs) => {
    this._deadlineDate = value;
  };

  @action deleteAttachmentById = (id: number) => {
    this._deleteAttachmentIdList.push(id);
    this._attachments = this._attachments.filter((attachment) => attachment.id !== id);
  };

  private fetchAttachments = async (campaignId?: number) => {
    try {
      if (this._newFileList.length) {
        await Promise.all(
          this._newFileList.map((newFile) =>
            uploadFile({
              marketingCampaignId: campaignId || this._id,
              file: newFile.file,
              description: newFile.description ?? '',
            }),
          ),
        );
      }
      if (this._newLinkList.length) {
        await Promise.all(
          this._newLinkList.map(({ link, description }) =>
            link?.length && validateUrl(link)
              ? uploadFile({
                  marketingCampaignId: campaignId || this._id,
                  url: addHttpsToLink(link),
                  description,
                })
              : true,
          ),
        );
      }
    } catch {}
  };

  @action private _create = async (): Promise<boolean> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const requestData = {
        marketing_pillar: this._channelId || this.marketingPillar.id,
        title: this._title,
        description: this._description,
        budget: this._budget,
        start_date: this._startDate.format('YYYY-MM-DD'),
        end_date: this._endDate.format('YYYY-MM-DD'),
        deadline_date: this._deadlineDate.format('YYYY-MM-DD'),
        is_active: true,
        is_private: false,
        products_set: this._usedGlobalProductList.map((product) => ({
          product: product.product.id,
          price: product.price,
        })),
        campaign_metrics_set: this._usedCampaignMetricsList.map((campaignMetric) => ({
          campaign_metric: campaignMetric.id,
          auto_distribute: campaignMetric.autoDistribute || false,
          ...(campaignMetric.predictedValue ? { predicted_value: campaignMetric.predictedValue } : {}),
          ...(campaignMetric.partOfBudget ? { part_of_budget: campaignMetric.partOfBudget } : {}),
          ...(campaignMetric.autoDistribute ? { value: campaignMetric.value } : {}),
          ...(campaignMetric.changedProductList.length ? { products_set: campaignMetric.changedProductList } : {}),
        })),
      };

      const { data: createdData } = await create(requestData);
      await this.fetchAttachments(createdData.id);

      runInAction(() => {
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @computed get dataForUpdate(): IUpdateRequestMarketingCampaignValue {
    const requestData: IUpdateRequestMarketingCampaignValue = {};
    if (!this._copyDataForUpdate) {
      return requestData;
    }

    if (this._title !== this._copyDataForUpdate.title) {
      requestData.title = this._title;
    }
    if (this._description !== this._copyDataForUpdate.description) {
      requestData.description = this._description;
    }
    if (this._budget !== this._copyDataForUpdate.budget) {
      requestData.budget = this._budget;
    }
    if (this._startDate.format('YYYY-MM-DD') !== this._copyDataForUpdate.start_date) {
      requestData.start_date = this._startDate.format('YYYY-MM-DD');
    }
    if (this._endDate.format('YYYY-MM-DD') !== this._copyDataForUpdate.end_date) {
      requestData.end_date = this._endDate.format('YYYY-MM-DD');
    }
    if (this._deadlineDate && this._deadlineDate.format('YYYY-MM-DD') !== this._copyDataForUpdate.deadline_date) {
      requestData.deadline_date = this._deadlineDate.format('YYYY-MM-DD');
    }

    const updatedProductList = this._usedGlobalProductList.reduce(
      (state: { product: number; price: number }[], currentValue) => {
        const foundProduct = this._copyDataForUpdate.products.find(
          (product) => product.product.id === currentValue.product.id,
        );

        if (!foundProduct || foundProduct.price !== currentValue.price) {
          state.push({ product: currentValue.product.id, price: currentValue.price });
        }
        return state;
      },
      [],
    );
    if (updatedProductList.length) {
      requestData.products_set = updatedProductList;
    }

    const updatedMetricList = this._usedCampaignMetricsList.reduce((campaignMetricsState, campaignMetric) => {
      const dataForUpdate =
        campaignMetric.getDataForUpdate() ||
        (this.checkIsNewCampaignMetric(campaignMetric.id) && { campaign_metric: campaignMetric.id });

      if (dataForUpdate) {
        campaignMetricsState.push(dataForUpdate);
      }
      return campaignMetricsState;
    }, []);

    if (updatedMetricList.length) {
      requestData.campaign_metrics_set = updatedMetricList;
    }

    return requestData;
  }

  @computed get isHasDataForUpdate() {
    return !!Object.keys(this.dataForUpdate).length;
  }

  @action private _update = async (): Promise<boolean> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      if (this.isHasDataForUpdate) {
        await patchById(this._id, this.dataForUpdate);
      }
      if (this.isHasDeletedGlobalProduct) {
        await Promise.all(this.deletedGlobalProductList.map((deletedProductId) => deleteProductById(deletedProductId)));
      }

      if (this.isHasDeletedMetric) {
        await Promise.all(this.deletedMetricList.map((deletedMetricId) => deleteMetricById(deletedMetricId)));
      }
      //
      if (this.deletedAttachmentIdList.length) {
        await Promise.all(
          this.deletedAttachmentIdList.map((deletedAttachmentId) => deleteAttachmentById(deletedAttachmentId)),
        );
      }

      if (this.isHasDeletedMetricsProduct) {
        await Promise.all(
          this._usedCampaignMetricsList.flatMap((usedCampaignMetric) =>
            usedCampaignMetric.deletedProductList.map((productBunchId) => deleteProductFromMetricById(productBunchId)),
          ),
        );
      }
      //
      // if (this.isHasDeletedMetric) {
      //   await Promise.all(this.deletedMetricList.map((deletedMetricId) => deleteMetricById(deletedMetricId)));
      // }

      await this.fetchAttachments();

      runInAction(() => {
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch (e) {
      console.log(e);
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action save = async (): Promise<boolean> => {
    if (this._copyDataForUpdate || (this._newFileList.length && this._id)) {
      return this._update();
    }

    return this._create();
  };

  @action fetchDelete = async (id: number): Promise<boolean> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      await deleteById(id);
      runInAction(() => {
        this._loadingStatus = LoadingStatus.SUCCESS;
      });

      return true;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}
