import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import dayjs from 'dayjs';
import { LoadingStatus } from '../interface';
import { CalendarClass } from '../slass/calendar/CalendarClass';
import {
  createSharePlanningCalendarLink,
  getPlanningCalendar,
  getPlanningCalendarShareCode,
  getSharePlanningCalendar,
} from '../service/calendarService';
import { IResponsePlanningCalendarShareCode, StartWeekDay } from '../interface/calendarInterface';

class CalendarStore {
  @observable private _calendarList: CalendarClass[] = [];

  @observable private _shareCodeList: IResponsePlanningCalendarShareCode[] = [];

  @observable private _loadingStatus: LoadingStatus = LoadingStatus.LOADING;

  @observable private _shareLoadingStatus: LoadingStatus = LoadingStatus.SUCCESS;

  constructor() {
    makeObservable(this);
  }

  @action
  fetchCalendar = async (startDate?, endDate?) => {
    const date = startDate && endDate ? null : dayjs();
    const calendarStartDate = startDate || date.startOf('year').format('YYYY-MM-DD');
    const calendarEndDate = endDate || date.endOf('year').format('YYYY-MM-DD');

    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getPlanningCalendar(calendarStartDate, calendarEndDate);
      runInAction(() => {
        // @ts-ignore
        this._calendarList = [new CalendarClass(data)];
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
    }
  };

  @action
  fetchShareCalendar = async (shareId: string) => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getSharePlanningCalendar(shareId);
      runInAction(() => {
        // @ts-ignore
        this._calendarList = [new CalendarClass(data)];
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
    }
  };

  @action
  createShareCalendarLink = async (startDate: string, endDate: string) => {
    this._shareLoadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await createSharePlanningCalendarLink(startDate, endDate);
      runInAction(() => {
        this._shareCodeList = [{ id: null, code: data.code, end_date: endDate, start_date: startDate }];
        this._shareLoadingStatus = LoadingStatus.SUCCESS;
      });
      return this._shareCodeList;
    } catch (e) {
      this._shareLoadingStatus = LoadingStatus.ERROR;
      return null;
    }
  };

  @action
  fetchPlanningCalendarShareCode = async () => {
    this._shareLoadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getPlanningCalendarShareCode();
      runInAction(() => {
        this._shareCodeList = data;
        this._shareLoadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch (e) {
      this._shareLoadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action clear = () => {
    this._calendarList = [];
    this._shareCodeList = [];
    this._loadingStatus = LoadingStatus.LOADING;
    this._shareLoadingStatus = LoadingStatus.SUCCESS;
  };

  @computed
  get calendarList() {
    return this._calendarList;
  }

  @computed
  get loadingStatus() {
    return this._loadingStatus;
  }

  @computed
  get shareLoadingStatus() {
    return this._shareLoadingStatus;
  }

  @computed
  get shareCodeList() {
    return this._shareCodeList;
  }
}

export default CalendarStore;
