import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseMarketingClass } from './BaseMarketingClass';
import { HasMarketingChannels, ICreateRequestMarketingChannel } from '../../../interface/marketingPilarInterface';
import { LoadingStatus } from '../../../interface';
import { MarketingChannelClass } from './MarketingChannelClass';

export class MarketingPillarClass extends BaseMarketingClass {
  @observable protected _hasMarketingChannels: HasMarketingChannels;

  @observable protected _marketingChannels: MarketingChannelClass[] = [];

  constructor(props, serviceConductor) {
    super(serviceConductor);
    this.snakeToCamelBuilder(props);
    makeObservable(this);
  }

  @computed get hasMarketingChannels() {
    return this._hasMarketingChannels;
  }

  @computed get marketingChannels() {
    return this._marketingChannels?.length ? this._marketingChannels : [];
  }

  @action protected _builder = (key, value) => {
    if (key === 'marketing_channels') {
      this._marketingChannels = value.map(
        (marketingChannel) => new MarketingChannelClass(marketingChannel, this._serviceConductor),
      );
    }
  };

  @action deleteMarketingChannel = async (id: number): Promise<boolean> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { status } = await this._serviceConductor.deleteById(id);
      if (status !== 204) {
        throw 'something wrong';
      }
      runInAction(() => {
        this._marketingChannels = this._marketingChannels.filter((marketingChannel) => marketingChannel.id !== id);
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      // return this._marketingChannels;
      return true;
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action createMarketingChannel = async (data: Omit<ICreateRequestMarketingChannel, 'parent'>) => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data: responseData } = await this._serviceConductor.createMarketingChannel({ ...data, parent: this.id });
      runInAction(() => {
        if (!this._marketingChannels?.length) {
          this._marketingChannels = [];
        }
        this._marketingChannels.push(new MarketingChannelClass(responseData, this._serviceConductor));
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}
