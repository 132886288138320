import { makeObservable } from 'mobx';
import { ProductCategoryClass } from '../slass/data/product/ProductCategoryClass';
import { BaseStoreAbstract } from './BaseStore.abstract';
import * as productCategoryService from '../service/productCategoryService';

class ProductCategoryStore extends BaseStoreAbstract<ProductCategoryClass> {
  protected readonly _mainDataClass = ProductCategoryClass;

  // protected _mainDataList: ProductCategoryClass[] = [];

  protected readonly _serviceConductor = productCategoryService;

  constructor() {
    super();
    makeObservable(this);
  }
}

export default ProductCategoryStore;
