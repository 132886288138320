import React from 'react';
import { observer } from 'mobx-react-lite';
import UploadModalContent from './UploadModalContent';
import { PlanningCalendarModalClass } from '../class/PlanningCalendarModalClass';

interface IProps {
  instance: PlanningCalendarModalClass;
}

const UploadModalContentBlock = ({ instance }: IProps) => {
  const {
    deleteAttachmentById,
    deleteNewFile,
    newLinkList,
    changeNewLink,
    setNewLink,
    changeNewFileDescription,
    newFileList,
    attachments,
    setNewFile,
  } = instance;

  return (
    <UploadModalContent
      deleteAttachment={deleteAttachmentById}
      changeNewLink={changeNewLink}
      setNewLink={setNewLink}
      deleteNewFile={deleteNewFile}
      setFile={setNewFile}
      fileList={newFileList}
      attachments={attachments}
      newLinkList={newLinkList}
      changeNewFileDescription={changeNewFileDescription}
    />
  );
};

export default observer(UploadModalContentBlock);
