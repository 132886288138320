import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import onHandleChangeInput from '../../utils/onHandleChangeInput';
import UploadFile from '../../component/upload/UploadFile';
import MainStore from '../../store/MainStore';
import { LoadingStatus } from '../../interface';

interface IProps {
  onCloseModalHandler: () => void;
}
const CompetitorModal = ({ onCloseModalHandler }: IProps) => {
  const { fetchCreate, loadingStatus, setLoadingStatus } = MainStore.competitorStore;
  const [file, setFile] = useState<File[]>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const isEmptyTitle = !title.length;
  const isDisabledButton = isEmptyTitle || !file;

  const onDropCallback = (newFile: File[]) => {
    setFile(newFile);
  };
  useEffect(() => {
    setLoadingStatus(LoadingStatus.SUCCESS);
  }, [setLoadingStatus]);

  const onSaveButtonHandle = () => {
    const source = file[0].type === 'text/csv' ? 'TEMPLATE' : 'EXCEL';
    fetchCreate({ title, description, source, content: file }).then((isSuccess) => {
      if (isSuccess) {
        onCloseModalHandler();
      }
    });
  };

  return (
    <>
      <Box sx={{ margin: '25px 0 30px' }}>
        <FormControl error={isEmptyTitle} sx={{ width: '100%' }}>
          <TextField
            id="Title-standard-basic"
            placeholder="Title"
            variant="standard"
            sx={{ width: '100%' }}
            value={title}
            onChange={onHandleChangeInput(setTitle)}
          />
          {isEmptyTitle && (
            <FormHelperText id="title-error-by-isEmptyTitle" sx={{ position: 'absolute', bottom: '-70%', left: 0 }}>
              The title is required
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box sx={{ margin: '25px 0' }}>
        <TextField
          id="Description-standard-basic"
          label="Description"
          multiline
          variant="outlined"
          rows={4}
          sx={{ width: '100%' }}
          value={description}
          onChange={onHandleChangeInput(setDescription)}
        />
      </Box>

      <Box sx={{ margin: '25px 0' }}>
        <UploadFile
          style={{ width: '100%', height: '100px' }}
          permittedType={{ 'text/csv': [] }}
          onDropCallback={onDropCallback}
        />
        <Box sx={{ margin: '15px 0', minHeight: '2em' }}>
          <Typography component="span" sx={{ fontWeight: 600 }}>
            {file?.[0]?.name || ''}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModalHandler}>
          Cancel
        </Button>
        <Button
          variant="solid"
          onClick={onSaveButtonHandle}
          disabled={isDisabledButton}
          loading={loadingStatus === LoadingStatus.LOADING}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default observer(CompetitorModal);
