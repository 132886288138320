import { computed, observable, action, makeObservable } from 'mobx';
import { IResponseProduct } from '../../../interface/productInterface';
import { ProductBaseClass } from './ProductBaseClass';

type ConstrictorData = Required<Pick<IResponseProduct, 'id' | 'title' | 'description' | 'categories'>> &
  Partial<IResponseProduct>;

export class ProductClass extends ProductBaseClass {
  @observable protected _categories: number[];

  constructor(props: ConstrictorData, serviceConductor: any) {
    super(serviceConductor);
    this.snakeToCamelBuilder(props);
    makeObservable(this);
  }

  @computed get categories() {
    return this._categories;
  }

  @action protected _builder = (key, value) => {};

  @action fetchPatch = async (data) => this.fetchPatchBase(data);
}
