import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/joy/Typography';
import { formatPrefix } from 'd3-format';
import BarBlockWrapper from '../bar/BarBlockWrapper';

import scss from '../DashboardPage.module.scss';

const CountChart = ({ title, value, color = 'rgba(0, 0, 0, 0.87)' }) => (
    <BarBlockWrapper
      header={
        <Typography level="h6" component="h6" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
      }
      chart={
        <Box className={scss.countChart_content} style={{ color }}>
          {/*<span  >*/}
          {value}
          {/*</span>*/}
        </Box>
      }
      selectedTotalOptionList=""
    />
  );

export default CountChart;
