import React, { memo, useMemo } from 'react';
import { Box, Paper } from '@mui/material';
import Typography from '@mui/joy/Typography';
import { BarTooltipProps } from '@nivo/bar/dist/types/types';
import BarBlockWrapper from './BarBlockWrapper';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';
import BarChart from './BarChart';
import scss from '../DashboardPage.module.scss';
import { IReturn } from '../utils/calculateBudgetDeadlineData';

interface IProps {
  data: IReturn['budget'];
  instance?: CalendarClass;
}

const keys = ['Total', 'Allocated'];

interface IAddedData {
  title: string;
  id: string;
  Predicted: number;
  Actual: number;
  label: string;
  titlePillar?: string;
  titleChannel?: string;
  date?: string;
}

const CampaignBudgetWrapper = ({ data, instance }: IProps) => {
  const addedData: IAddedData[] = useMemo(() => {
    if (instance && data) {
      const dataWithYearly = data
        .reduce((state, dataElement, currentIndex) => {
          const marketingPillarTitle = dataElement.marketingPillar.title;
          const prevStateElement = state[state.length - 1];
          if (!prevStateElement || !prevStateElement.title.includes(marketingPillarTitle)) {
            state.push({
              Total: dataElement.marketingPillar.budget,
              Allocated: dataElement.marketingPillar.calculatedBudget,
              title: marketingPillarTitle,
              id: marketingPillarTitle,
              label: marketingPillarTitle,
            });
          }

          // show only companies with budgets
          if (!(dataElement.Predicted + dataElement.Actual)) {
            return state;
          }

          const parentInstanceTitle =
            marketingPillarTitle === dataElement.parentInstance.title ? null : dataElement.parentInstance.title;

          const title = `${marketingPillarTitle}-${parentInstanceTitle}-${dataElement.title}`;

          state.push({
            Total: dataElement.Predicted,
            Allocated: dataElement.Actual,
            date: `${dataElement.endDay.format('MM/DD')}- ${dataElement.startDay.format('MM/DD')}`,
            titlePillar: marketingPillarTitle,
            titleChannel: parentInstanceTitle,
            title,
            label: dataElement.title,
            id: `${' '.repeat(currentIndex)}${dataElement.title}`,
          });

          return state;
        }, [])
        .reverse();

      dataWithYearly.push({
        title: 'Yearly Budget',
        id: 'Yearly Budget',
        Total: instance.budget,
        Allocated: instance.calculatedBudget,
        label: 'Yearly Budget',
      });
      return dataWithYearly;
    }
    return data;
  }, [data, instance]);

  return (
    <BarBlockWrapper
      header={
        <Typography level="h6" component="h6" sx={{ fontWeight: 500 }}>
          Media Budget
        </Typography>
      }
      chart={
        <BarChart
          data={addedData}
          keys={keys}
          axisBottomLegend=""
          axisLeftLegend=""
          settings={{
            groupMode: 'grouped',
            indexBy: 'id',
            layout: 'horizontal',
            margin: { top: 0, right: 80, bottom: 60, left: 150 },
            legends: [
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 105,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
              },
            ],
            tooltip: (props: BarTooltipProps<IAddedData>) => (
              <Paper elevation={1} className={scss.chartTooltip}>
                <Box className={scss.chartTooltip_titleWrapper}>
                  <Box className={scss.chartTooltip_color} sx={{ backgroundColor: props.color }} />
                  <span>{props.data.label}</span>
                </Box>
                {props.data.titlePillar && (
                  <Box>
                    Pillar: <span>{props.data.titlePillar}</span>
                  </Box>
                )}
                {props.data.titleChannel && (
                  <Box>
                    Channel: <span>{props.data.titleChannel}</span>
                  </Box>
                )}
                {props.data.date && (
                  <Box>
                    Date: <span>{props.data.date}</span>
                  </Box>
                )}
                <Box>
                  Value: <span>{props.value}</span>
                </Box>
              </Paper>
            ),
          }}
        />
      }
      minWidth="450px"
      selectedTotalOptionList=""
    />
  );
};

export default memo(CampaignBudgetWrapper);
