import React from 'react';
import Button from '@mui/joy/Button';
import { observer } from 'mobx-react-lite';
import { ModalClose } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { UserClass } from '../../../slass/user/UserClass';
import ChangePasswordForm from '../../../component/auth/ChangePasswordForm';

const UserModalChangePassword = ({
  userInstance,
  onCloseModalHandler,
  setIsChangePassword,
}: {
  userInstance: UserClass;
  onCloseModalHandler: () => void;
  setIsChangePassword: (value: boolean) => void;
}) => {
  const onSubmitHandler = (value: string) => {
    return userInstance.fetchChangePassword(value).then((data) => {
      if (data) {
        onCloseModalHandler();

        return true;
      }
      return false;
    });
  };

  const onGoBack = () => {
    setIsChangePassword(false);
  };

  return (
    <>
      <ModalClose variant="plain" onClick={onCloseModalHandler} />
      <Typography
        component="h3"
        id="close-modal-title"
        sx={{ margin: '0 0 25px', fontWeight: 600, fontSize: '20px', lineHeight: '32px' }}
      >
        {`Change ${userInstance.firstName} ${userInstance.lastName} password`}
      </Typography>
      <ChangePasswordForm
        cancelComponent={
          <Button onClick={onGoBack} variant="soft" color="primary">
            Go back
          </Button>
        }
        onSubmitHandler={onSubmitHandler}
      />
    </>
  );
};

export default observer(UserModalChangePassword);
