import React from 'react';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';
import ListOfCalendarGrid from './ListOfCalendarGrid';
import DataUpload from './DataUpload';

const ListOfCalendarPage = () => (
  <section className="section">
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        gap: '10px',
        marginBottom: '16px',
      }}
    >
      <Typography level="h3" component="h6">
        List of calendars
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <DataUpload />
      <ListOfCalendarGrid />
    </Box>
  </section>
);

export default ListOfCalendarPage;
