import React, { memo, useEffect, useMemo, useState } from 'react';
import { Box, FormControlLabel, FormGroup, Popover } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ColorResult, TwitterPicker } from 'react-color';
import scss from '../Planning.module.scss';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import { StartWeekDay, startWeekDayTitleMap } from '../../../interface/calendarInterface';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';
import Checkbox from '@mui/joy/Checkbox';
import { ResourceApi } from '@fullcalendar/resource';
import { CampaignMetricForCalendarClass } from '../../../slass/calendar/CampaignMetricForCalendarClass';
import {
  getResourceFromLocalStorage,
  setResourceFromLocalStorage,
  updateIsHidden,
} from '../../../component/planning-calendar/utils';
import { refreshResources } from '../../../utils/hooks/useCollapseCalendarButton';

const colorList = [
  '#f26753',
  '#f79c7e',
  '#f8a99d',
  '#f9b565',
  '#f9f49d',
  '#fffcc9',
  '#b9d494',
  '#a0d15c',
  '#91cd91',
  '#96c69f',
  '#75ccd0',
  '#90d4d6',
  '#9ebbe2',
  '#8297bd',
  '#a498b8',
  '#b497e8',
  '#e6ebed',
];

interface IProps {
  colorAnchor: PointerEvent;
  onClose: () => void;
  onSelect: (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedColor?: string;
  instance: CalendarClass;
  resourceByCalendar: ResourceApi;
  calendarRef;
}
const PopoverResourceSettings = ({
  colorAnchor,
  onClose,
  onSelect,
  selectedColor,
  instance,
  resourceByCalendar,
  calendarRef,
}: IProps) => {
  const [color, setColor] = useState('#ffffff');

  useEffect(() => {
    if (!!colorAnchor && selectedColor) {
      setColor(selectedColor);
    }
  }, [colorAnchor, selectedColor]);

  const onHandleChange = (resourceId: number, metricId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isHidden = !e.target.checked;
    const resourceFromLocalStorage = getResourceFromLocalStorage() || {};
    setResourceFromLocalStorage(updateIsHidden(resourceFromLocalStorage, resourceId, metricId, isHidden));
    refreshResources(calendarRef);
  };

  const checkboxList: { id: number; title: string; isHidden: boolean }[] = useMemo(() => {
    if (!resourceByCalendar) {
      return [];
    }

    const resourceFromLocalStorage = getResourceFromLocalStorage() || {};

    return resourceByCalendar.extendedProps.marketingPillar.campaignMetricsList.map((campaignMetric) => ({
      id: campaignMetric.id,
      title: campaignMetric.title,
      isHidden:
        resourceFromLocalStorage[resourceByCalendar.extendedProps.marketingPillar.id]?.metricMap?.[campaignMetric.id]
          ?.isHidden,
    }));
  }, [resourceByCalendar]);

  return (
    <Popover
      open={!!colorAnchor}
      anchorReference="anchorPosition"
      anchorPosition={{ top: colorAnchor?.clientY, left: colorAnchor?.clientX }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={scss.popover}
    >
      <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
        <Box className={scss.popoverResourceSettings_wrapper}>
          <Box className={scss.popoverResourceSettings_displayedMetricsWrapper}>
            <FormLabel sx={{ fontWeight: 600, fontSize: '15px' }}>Displayed metrics</FormLabel>
            {checkboxList.map((checkbox) => (
              <Checkbox
                key={`PopoverResourceSettings_${resourceByCalendar.extendedProps.marketingPillar.id}_${checkbox.id}`}
                label={checkbox.title}
                size="sm"
                defaultChecked={!checkbox.isHidden}
                className={scss.popoverResourceSettings_displayedMetricsChecbox}
                onChange={onHandleChange(resourceByCalendar.extendedProps.marketingPillar.id, checkbox.id)}
              />
            ))}
          </Box>

          <Box className={scss.popoverResourceSettings_colorWrapper}>
            <FormLabel sx={{ fontWeight: 600, fontSize: '15px' }}>Color</FormLabel>
          </Box>
          <TwitterPicker onChange={onSelect} colors={colorList} color={color} />
        </Box>
      </ClickAwayListener>
    </Popover>
  );
};
export default memo(PopoverResourceSettings);
