import { action, makeObservable, runInAction } from 'mobx';
import * as campaignMetricsService from '../service/campaignMetricsService';
import { CampaignMetricsClass } from '../slass/data/campaign-metrics/CampaignMetricsClass';
import { BaseStoreAbstract } from './BaseStore.abstract';
import { TRequestSortingUpdate } from '../interface/campaignMetricInterface';
import { LoadingStatus } from '../interface';

class CampaignMetricsStore extends BaseStoreAbstract<CampaignMetricsClass> {
  // protected _mainDataList: CampaignMetricsClass[] = [];

  protected readonly _mainDataClass = CampaignMetricsClass;

  protected _serviceConductor = campaignMetricsService;

  constructor() {
    super();
    makeObservable(this);
  }

  @action changeOrder = async (orders: { order: number; id: number }[]) => {
    orders.forEach((orderObj) => {
      this._mainDataList.find((marketingPillar) => marketingPillar.id === orderObj.id).setOrder(orderObj.order);
    });
    const prepareData: TRequestSortingUpdate = orders.map((order) => ({
      order: order.order,
      campaign_metric: order.id,
    }));
    this._mainDataList = this._mainDataList.sort((a, b) => a.order - b.order);

    try {
      await this._serviceConductor.sorting(prepareData);
      runInAction(() => {
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return this._mainDataList;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}

export default CampaignMetricsStore;
