import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import Typography from '@mui/joy/Typography';
import MainStore from '../../../store/MainStore';
import { PermissionClass } from '../../../slass/permission/PermissionClass';
import { GroupPermissionClass } from '../../../slass/permission/GroupPermissionClass';
import PermissionCheckbox from './PermissionCheckbox';
import transformString from './utils/transformString';

interface IProps {
  groupPermissionInstance: GroupPermissionClass;
}

const PermissionSettingsBlock = ({ groupPermissionInstance }: IProps) => {
  const { permissionList } = MainStore.permissionStore;

  const permissionObject = useMemo(() => permissionList.reduce((state: Record<string, PermissionClass[]>, permission) => {
      if (state[permission.contentType]) {
        state[permission.contentType].push(permission);
      } else {
        state[permission.contentType] = [permission];
      }

      return state;
    }, {}), [permissionList]);

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 10,
        overflowY: 'scroll',
        overflowX: 'hidden',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      }}
    >
      {Object.entries(permissionObject).map(([key, value]) => (
        <Box
          sx={{ display: 'flex', gap: 3, flexDirection: 'column', flexWrap: 'wrap' }}
          key={`PermissionSettingsBlock_permissionObject_title_${key}`}
        >
          <Typography level="h6" component="h6">
            {transformString(key)}
          </Typography>
          <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
            {value.map((permission) => (
              <PermissionCheckbox
                key={`PermissionSettingsBlock_permissionObject_value_${permission.name}_${permission.id}`}
                instance={permission}
                groupPermissionInstance={groupPermissionInstance}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default observer(PermissionSettingsBlock);
