import React, { useMemo } from 'react';
import Typography from '@mui/joy/Typography';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import LineChart from './LineChart';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';
import BarBlockWrapper from '../bar/BarBlockWrapper';
import scss from '../DashboardPage.module.scss';
import { Box, Paper } from '@mui/material';

interface IProps {
  data;
}

const TotalWeeklySalesLiftWrapper = ({ data }: IProps) => {
  return (
    <BarBlockWrapper
      header={
        <Typography level="h6" component="h6" sx={{ fontWeight: 500 }}>
          Total Weekly Sales Performance Lift
        </Typography>
      }
      chart={
        <LineChart
          data={data}
          settings={{
            margin: { top: 10, right: 160, bottom: 60, left: 50 },
            legends: [
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 130,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
              },
            ],
            sliceTooltip: ({ slice }) => {
              return (
                <Paper elevation={2} className={scss.chartTooltip}>
                  {slice.points
                    .map((point) => {
                      return (
                        <Box
                          key={`TotalWeeklySalesLiftWrapper_tooltip_${point.serieId}_${point.borderColor}`}
                          className={scss.chartTooltip}
                          sx={{ flexDirection: 'row', padding: '4px' }}
                        >
                          <Box className={scss.chartTooltip_titleWrapper}>
                            <Box className={scss.chartTooltip_color} sx={{ backgroundColor: point.borderColor }} />
                            <span>{point.serieId}:</span>
                          </Box>
                          <Box>
                            <span>{point.data.y}</span>
                          </Box>
                        </Box>
                      );
                    })
                    .reverse()}
                </Paper>
              );
            },
          }}
        />
      }
      minWidth="650px"
      selectedTotalOptionList=""
    />
  );
};

export default observer(TotalWeeklySalesLiftWrapper);
