import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FormControl, FormControlLabel, FormGroup, FormLabel, Switch } from '@mui/material';
import MainStore from '../../../store/MainStore';
import { LoadingStatus } from '../../../interface';
import { UserClass } from '../../../slass/user/UserClass';
import Loader from '../../../component/ui/loader/Loader';
import { GroupPermissionClass } from '../../../slass/permission/GroupPermissionClass';

interface IProps {
  instance: UserClass;
}
const UserGroups = ({ instance }: IProps) => {
  const { groupPermissionLoadingStatus, groupPermissionList } = MainStore.permissionStore;

  useEffect(() => {
    if (!groupPermissionList.length) {
      MainStore.permissionStore.fetchGroupPermissionList();
    }
  }, [groupPermissionList]);

  const controlСheck = (permission: GroupPermissionClass) => !!~instance.groupList.findIndex((groupId) => permission.id === groupId);

  const onHandleChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newGroupList = checked ? [...instance.groupList, id] : instance.groupList.filter((groupId) => groupId !== id);
    instance.setGroup(newGroupList);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Groups</FormLabel>
      <FormGroup>
        {groupPermissionLoadingStatus === LoadingStatus.LOADING && <Loader />}
        {groupPermissionLoadingStatus === LoadingStatus.SUCCESS &&
          groupPermissionList.map((groupPermission) => (
            <FormControlLabel
              key={`UserGroups_${groupPermission.name + groupPermission.id}`}
              control={
                <Switch
                  checked={controlСheck(groupPermission)}
                  onChange={onHandleChange(groupPermission.id)}
                  name={groupPermission.name}
                />
              }
              label={groupPermission.name}
            />
          ))}
      </FormGroup>
    </FormControl>
  );
};

export default observer(UserGroups);
