import React, { useState } from 'react';
import { Box, FilledInput, FormControl, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import scss from '../../page/auth/LoginPage.module.scss';
import { FormFields, loginValidationSchema } from './constant/changeLoginConstant';
import Button from '@mui/joy/Button';
import { useFormik } from 'formik';
import { ReactComponent as Visibility } from '../../icon/Visibility.svg';
import { ReactComponent as VisibilityOff } from '../../icon/VisibilityOff.svg';

const ChangePasswordForm = ({
  cancelComponent,
  onSubmitHandler,
}: {
  cancelComponent?: any;
  onSubmitHandler: (value: string) => Promise<Boolean>;
}) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      [FormFields.PASSWORD]: '',
      [FormFields.CONFIRM_PASSWORD]: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSubmitHandler(values[FormFields.PASSWORD]).then((data) => {
        if (!data) {
          formik.setSubmitting(false);
        }
      });
    },
  });

  const { handleSubmit, values, handleBlur, handleChange, errors, touched } = formik;

  const onChangeIsShowPasswordHandler = () => {
    setIsShowPassword((value) => !value);
  };

  const isPasswordError = touched.password && Boolean(errors.password);

  const isPasswordConfirmError = touched.confirmPassword && Boolean(errors.confirmPassword);

  const buttonDisabled =
    !values[FormFields.PASSWORD].length ||
    isPasswordError ||
    !values[FormFields.CONFIRM_PASSWORD].length ||
    isPasswordConfirmError;

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <FormControl error={isPasswordError} sx={{ width: '100%' }}>
        <FormHelperText id="component-Password-text" className={scss.inputLabel}>
          Password
        </FormHelperText>
        <FilledInput
          id={FormFields.PASSWORD}
          name={FormFields.PASSWORD}
          type={isShowPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={handleChange}
          onBlur={handleBlur(FormFields.PASSWORD)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={onChangeIsShowPasswordHandler} edge="end">
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {isPasswordError && (
          <FormHelperText id="component-error-text" className={scss.formHelperText}>
            {formik.touched.password && formik.errors.password}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl error={isPasswordConfirmError} sx={{ width: '100%' }}>
        <FormHelperText id="component-Password-text" className={scss.inputLabel}>
          Confirm Password
        </FormHelperText>
        <FilledInput
          id={FormFields.CONFIRM_PASSWORD}
          name={FormFields.CONFIRM_PASSWORD}
          type="password"
          value={formik.values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur(FormFields.CONFIRM_PASSWORD)}
        />
        {isPasswordConfirmError && (
          <FormHelperText id="component-error-text" className={scss.formHelperText}>
            {formik.touched.confirmPassword && formik.errors.confirmPassword}
          </FormHelperText>
        )}
      </FormControl>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          marginTop: '36px',
          gap: '10px',
        }}
      >
        {cancelComponent || <span />}
        <Button type="submit" variant="solid" disabled={buttonDisabled} loading={formik.isSubmitting}>
          Save New Password
        </Button>
      </Box>
    </form>
  );
};

export default ChangePasswordForm;
