import { ResponsiveCalendar } from '@nivo/calendar';
import { CalendarDatum, DateOrString } from '@nivo/calendar/dist/types/types';

interface IProps {
  from: DateOrString;
  to: DateOrString;
  data: CalendarDatum[];
  settings?: any;
}

const CalendarChart = ({ data, from, to, settings = {} }: IProps) => (
    <ResponsiveCalendar
      data={data}
      from={from}
      to={to}
      emptyColor="#eeeeee"
      colors={['#FF9192', '#FA5255', '#D3232F']}
      margin={{ top: 20, right: 0, bottom: 0, left: 20 }}
      yearSpacing={40}
      monthBorderColor="#ffffff"
      dayBorderWidth={2}
      dayBorderColor="#ffffff"
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'row',
          translateY: 36,
          itemCount: 4,
          itemWidth: 42,
          itemHeight: 36,
          itemsSpacing: 14,
          itemDirection: 'right-to-left',
        },
      ]}
      {...settings}
    />
  );

export default CalendarChart;
