import { PermissionType } from '../../interface/permissionInterface';
import MainStore from '../../store/MainStore';

const findPermission = (permissionList: PermissionType[], storePermissions?: PermissionType[]) => {
  const { permissions: permissionsStore } = MainStore.authStore;

  return permissionList.length
    ? permissionList.find((permission) => (storePermissions || permissionsStore)?.includes(permission))
    : true;
};

export default findPermission;
