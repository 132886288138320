import axios, { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
// eslint-disable-next-line import/no-cycle
import MainStore from '../store/MainStore';
import { HttpResponseStatus, REFRESH_ENDPOINT, AUTH_ENDPOINT, TokensNameInStorage } from '../interface';

export interface IRequest<Data> {
  success: number;
  data: Data;
  error: {};
}

const { REACT_APP_BASE_URL } = process.env;

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: REACT_APP_BASE_URL,
});

const checkToLoginAsUserAccess = () => {
  const loginAsUserAccess = sessionStorage.getItem(TokensNameInStorage.loginAsUserAccess);
  if (loginAsUserAccess !== 'undefined' && !!loginAsUserAccess) {
    sessionStorage.removeItem(TokensNameInStorage.loginAsUserAccess);
    MainStore.authStore.unauthenticated();
  }
};

const getAccess = () => {
  try {
    const loginAsUserAccess = sessionStorage.getItem(TokensNameInStorage.loginAsUserAccess);
    if (loginAsUserAccess !== 'undefined' && !!loginAsUserAccess) {
      return loginAsUserAccess;
    }
    return localStorage.getItem(TokensNameInStorage.access);
  } catch {
    return '';
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getAccess();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    enqueueSnackbar('Something wrong', { variant: 'error' });
    return Promise.reject(error);
  },
);

const errorNotification = (errorData: string | string[] | Record<string | number, string>) => {
  const iterObject = (errorData: Record<string | number, string>) => {
    for (const key in errorData) {
      // if it's an error code, we don't let it go
      if (!Number(errorData[key])) {
        enqueueSnackbar(`${errorData[key]}`, { variant: 'error' });
      }
    }
  };

  if (typeof errorData === 'string') {
    enqueueSnackbar(errorData, { variant: 'error' });
  } else if (Array.isArray(errorData)) {
    errorData.forEach((item) => errorNotification(item));
  } else if (typeof errorData === 'object') {
    iterObject(errorData);
  } else {
    enqueueSnackbar('Something wrong', { variant: 'error' });
  }
};

let usedRequests = [];

axiosInstance.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  async (error: AxiosError) => {
    const { response } = error;
    const originalRequest = error.config;

    if (
      (response.status === HttpResponseStatus.UNAUTHORIZED || // @ts-ignore
        response.data?.error?.['0'] === 'Token is invalid or expired') &&
      // || response.status === HttpResponseStatus.NOT_FOUND
      error.config?.url !== AUTH_ENDPOINT
    ) {
      try {
        checkToLoginAsUserAccess();
        if (!usedRequests.length) {
          usedRequests.push(originalRequest);
          const refreshResponse = await axios.post<any>(`${REACT_APP_BASE_URL}${REFRESH_ENDPOINT}`, {
            refresh: localStorage.getItem(TokensNameInStorage.refresh),
          });
          localStorage.setItem(TokensNameInStorage.access, refreshResponse.data.data.access);
          // todo ???
          axiosInstance.interceptors.request.use((config) => {
            const accessToken = localStorage.getItem(TokensNameInStorage.access);
            config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
          });

          await Promise.all(
            usedRequests.map((originalUsedRequests, index) => {
              if (!index) {
                return true;
              }
              return axiosInstance.request(originalUsedRequests);
            }),
          );
          usedRequests = [];
        } else {
          usedRequests.push(originalRequest);
        }
      } catch (e) {
        MainStore.authStore.unauthenticated();
        usedRequests = [];
        return Promise.reject(response);
      }
    } else {
      if (
        (response.status === HttpResponseStatus.UNAUTHORIZED || response.status === HttpResponseStatus.NOT_FOUND) &&
        error.config.url === AUTH_ENDPOINT
      ) {
        MainStore.authStore.unauthenticated();
      } else {
        // @ts-ignore
        if (response.data?.error) {
          // @ts-ignore
          errorNotification(response.data?.error);
        } else {
          enqueueSnackbar(response.status === HttpResponseStatus.FORBIDDEN ? 'Forbidden' : 'Something wrong', {
            variant: 'error',
          });
        }
        return Promise.reject(response);
      }
    }
  },
);

export default axiosInstance;
