import { action, computed, makeObservable, observable } from 'mobx';
import { BaseClass } from '../BaseClass';
import {
  CreatedAt,
  UpdatedAt,
  DeletedAt,
  IRequestPatchCompany,
  Slug,
  CompetitorFor,
} from '../../../interface/companyInterface';

export class CompanyClass extends BaseClass {
  @observable protected _slug: Slug;

  protected _createdAt: CreatedAt;

  @observable protected _updatedAt: UpdatedAt;

  protected _deletedAt: DeletedAt;

  @observable protected _competitorFor: CompetitorFor;

  @observable protected _budget: number;

  protected _calendarWeekday: number;

  protected _calendarDaterange: string;

  constructor(props, serviceConductor) {
    super(serviceConductor);
    this.snakeToCamelBuilder(props);
    makeObservable(this);
  }

  get createdAt() {
    return this._createdAt;
  }

  get updatedAt() {
    return this._updatedAt;
  }

  @computed
  get competitorFor() {
    return this._competitorFor;
  }

  @computed
  get budget() {
    return this._budget || 0;
  }

  get calendarWeekday() {
    return this._calendarWeekday;
  }

  get calendarDaterange() {
    return this._calendarDaterange;
  }

  @action protected _builder = (key, value) => {};

  @action fetchPatch = async (data: IRequestPatchCompany) => this.fetchPatchBase(data);
}
