import { action, computed, makeObservable, observable } from 'mobx';
import { MetricForCalendarClass } from '../../../slass/calendar/MetricForCalendarClass';
import { CampaignMetricsClass } from '../../../slass/data/campaign-metrics/CampaignMetricsClass';
import { ProductForCalendarClass } from '../../../slass/calendar/ProductForCalendarClass';
import { HasTotal, TotalTitle } from '../../../interface/campaignMetricInterface';
import { IMetricInResponseMarketingCampaign } from '../../../interface/marketingCampaignInterface';
import validateNumber, { getValidNumber } from '../../../utils/validateNumber';

export class SettingsMetricClass extends MetricForCalendarClass {
  private _copyDataForUpdate: IMetricInResponseMarketingCampaign;

  private _idOfBunch: number;

  @observable private _partOfBudget: boolean;

  private readonly _hasTotal: HasTotal;

  private readonly _totalTitle: TotalTitle;

  private readonly _metricForCalculation: number;

  @observable protected _autoDistribute: boolean;

  @observable private _predictedValue: number;

  constructor(data: CampaignMetricsClass) {
    super(data);
    makeObservable(this);
    this._hasTotal = data.hasTotal;
    this._totalTitle = data.totalTitle;
    this._metricForCalculation = data.metricForCalculation;
  }

  get hasTotal() {
    return this._hasTotal;
  }

  get totalTitle() {
    return this._totalTitle;
  }

  get metricForCalculation() {
    return this._metricForCalculation;
  }

  get idOfBunch() {
    return this._idOfBunch;
  }

  @computed get partOfBudget() {
    return this._partOfBudget;
  }

  @computed get predictedValue() {
    return getValidNumber(this._predictedValue);
  }

  get deletedProductList() {
    return (
      this._copyDataForUpdate?.products?.reduce((state: number[], checkedProduct) => {
        const checkedProductOfBunchId = checkedProduct.id;
        const used = ~this.productList.findIndex((usedProduct) => usedProduct.id === checkedProductOfBunchId);
        if (!used) {
          state.push(checkedProductOfBunchId);
        }
        return state;
      }, []) || []
    );
  }

  get changedProductList() {
    return this.productList.reduce((productSate, product) => {
      const dataForUpdate = product.getDataForUpdate();
      if (dataForUpdate) {
        productSate.push(dataForUpdate);
      }
      return productSate;
    }, []);
  }

  getDataForUpdate = () => {
    const data: any = {};

    if (this._copyDataForUpdate?.part_of_budget !== this.partOfBudget) {
      data.part_of_budget = this.partOfBudget;
    }

    if (this._copyDataForUpdate?.auto_distribute !== this._autoDistribute) {
      data.auto_distribute = this._autoDistribute;
    }

    if (this._copyDataForUpdate?.predicted_value !== this.predictedValue && this.type === 'calculated_by_product') {
      data.predicted_value = this.predictedValue;
    }

    if (this._autoDistribute) {
      data.value = this._value;
    } else if (this.changedProductList.length) {
      data.products_set = this.changedProductList;
    }

    if (Object.keys(data).length) {
      // data.campaign_metric = this._idOfBunch;
      data.campaign_metric = this.id;
      return data;
    }
    return null;
  };

  @action pushProduct = (product: ProductForCalendarClass) => {
    this._productList.push(product);
  };

  @action deleteProductById = (id: ProductForCalendarClass['id']) => {
    const foundIndex = this._productList.findIndex((product) => product.product.id === id);
    if (~foundIndex) {
      this._productList.splice(foundIndex, 1);
    }
  };

  @action setPartOfBudget = (value: boolean) => {
    this._partOfBudget = value;
  };

  setIdOfBunch = (id) => {
    this._idOfBunch = id;
  };

  @computed get autoDistribute() {
    return this._autoDistribute;
  }

  @action setAutoDistribute = (value: boolean) => {
    this._autoDistribute = value;
  };

  @action setCopyDataForUpdate = (dataForUpdate: IMetricInResponseMarketingCampaign) => {
    this._copyDataForUpdate = dataForUpdate;
  };

  @action setPredictedValue = (value: number | string) => {
    if (validateNumber(value) || value === '') {
      this._predictedValue = (value > 0 ? value : 0) as unknown as number;
    }
  };
}
