import React, { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Grid from '../../../component/ui/grid/Grid';
import Loader from '../../../component/ui/loader/Loader';
import useTableHeight from '../../../utils/hooks/useTableHeight';
import { ProductClass } from '../../../slass/data/product/ProductClass';
import useBaseGridBehavior from '../../../utils/hooks/useBaseGridBehavior';
import { ProductCategoryClass } from '../../../slass/data/product/ProductCategoryClass';
import DefaultModal from '../../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../../component/ui/default-modal/DefaultChildrenWrapperModal';
import ProductModal from './ProductModal';
import MainStore from '../../../store/MainStore';
import { CompanyClass } from '../../../slass/data/company/CompanyClass';
import useColumnsProduct from './hook/useColumnsProduct';
import DeleteModalContent from '../../../component/ui/delete-modal-content/DeleteModalContent';

interface IProps {
  productList: ProductClass[];
  productCategoryList: ProductCategoryClass[];
  companyList: CompanyClass[];
}

const ProductsGrid = ({ productList, productCategoryList, companyList }: IProps) => {
  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();
  const { state, action } = useBaseGridBehavior();
  const { selectedComponent, isOpenDeleteComponentModal, isOpenNewComponentModal, isOpenModal } = state;
  const { onDeleteComponentModal, onCloseModal, onOpenEditComponentModal } = action;

  const { loadingStatus, fetchDelete } = MainStore.productStore;

  const columns: GridColDef[] = useColumnsProduct({ onDeleteComponentModal, onOpenEditComponentModal });

  const rows = productList
    .reduce((currentState, currentValue) => {
      const row = {
        id: currentValue.id,
        title: currentValue.title || '',
        // description: currentValue.description,
        company: companyList.find((company) => company.id === currentValue.company)?.title || '',
        categories: currentValue.categories?.map((categoryId) => {
          const productCategoryTitle = productCategoryList.find(
            (productCategory) => productCategory.id === categoryId,
          )?.title;
          return { productCategoryTitle, key: `ChipProduct_${categoryId}_${productCategoryTitle}` };
        }),
      };
      currentState.push(row);
      return currentState;
    }, [])
    .sort((a, b) => a.id - b.id);

  const productInstance = useMemo(
    () => productList.find((product) => product.id === selectedComponent),
    [productList, selectedComponent],
  );

  const isOpenEditModal = (selectedComponent === 0 || selectedComponent) && isOpenModal;

  return (
    <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
      {componentHeight ? <Grid sellQuantity={sellQuantity} columns={columns} rows={rows} /> : <Loader />}

      <DefaultModal open={isOpenEditModal || isOpenNewComponentModal}>
        <DefaultChildrenWrapperModal modalTitle="Change Product" onCloseModal={onCloseModal}>
          <ProductModal instance={productInstance} onCloseModal={onCloseModal} />
        </DefaultChildrenWrapperModal>
      </DefaultModal>
      <DefaultModal open={isOpenDeleteComponentModal}>
        <DeleteModalContent<ProductClass>
          onCloseModalHandler={onCloseModal}
          fetchDelete={fetchDelete}
          loadingStatus={loadingStatus}
          id={productInstance?.id}
          title={productInstance?.title}
        />
      </DefaultModal>
    </Box>
  );
};

export default observer(ProductsGrid);
