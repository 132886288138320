import React, { useMemo, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/joy/IconButton';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import Chip from '@mui/joy/Chip';
import { ReactComponent as EditIcon } from '../../../icon/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../icon/DeleteIcon.svg';
import Loader from '../../../component/ui/loader/Loader';
import { Status } from '../../../interface/competitorInterface';

const UploadIcon = ({ content, title }: { content: string; title: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadFile = () => {
    setIsLoading(true);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', content, true);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const newFile = new File([blob], title, { type: blob.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(newFile);
        link.download = title;
        link.click();
      } else {
        enqueueSnackbar('Something wrong', { variant: 'error' });
      }
      setIsLoading(false);
    };

    xhr.send();
  };

  return (
    <IconButton color="neutral" variant="plain" onClick={downloadFile} disabled={isLoading}>
      {isLoading ? <Loader size="sm" /> : <FileDownloadOutlinedIcon />}
    </IconButton>
  );
};

const useColumns = ({
  onDeleteComponentModal,
  onOpenEditComponentModal,
  onOpenViewModal,
}: {
  onDeleteComponentModal: (id: string | number) => () => void;
  onOpenEditComponentModal: (id: string | number) => () => void;
  onOpenViewModal: (id: string | number) => () => void;
}): GridColDef[] =>
  useMemo(
    () => [
      // { field: 'id', headerName: 'ID', hideable: false },
      {
        field: 'title',
        headerName: 'Calendar',
        flex: 1,
        minWidth: 180,
        maxWidth: 300,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        minWidth: 180,
        maxWidth: 300,
      },
      {
        field: 'source',
        headerName: 'Source',
        minWidth: 50,
        maxWidth: 100,
      },
      {
        field: 'lastModifiedDate',
        headerName: 'Last modified date',
        minWidth: 150,
        maxWidth: 250,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) => {
          const color =
            (params.value as Status) === 'PROCESSED'
              ? 'success'
              : (params.value as Status) === 'SELECTED'
              ? 'info'
              : 'danger';
          const value =
            (params.value as Status) === 'PROCESSED'
              ? 'READY'
              : (params.value as Status) === 'SELECTED'
              ? 'LAST VIEWED'
              : 'ERROR';
          return (
            <Chip
              variant="soft"
              color={color}
              sx={{
                '--Chip-radius': '4px',
                '--Chip-paddingInline': '8px',
              }}
            >
              {value}
            </Chip>
          );
        },
      },

      {
        field: 'view',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <IconButton
            color="info"
            variant="plain"
            disabled={params.row.status === 'FAILED'}
            onClick={onOpenViewModal(params.id)}
          >
            <OpenInNewOutlinedIcon />
          </IconButton>
        ),
      },
      {
        field: 'download',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <UploadIcon content={params.value.content} title={params.value.title} />
        ),
      },
      {
        field: 'edit',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <IconButton color="primary" variant="plain" disabled={false} onClick={onOpenEditComponentModal(params.id)}>
            <EditIcon />
          </IconButton>
        ),
      },
      {
        field: 'delete',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <IconButton color="danger" variant="plain" disabled={false} onClick={onDeleteComponentModal(params.id)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    ],
    [onDeleteComponentModal, onOpenEditComponentModal, onOpenViewModal],
  );
export default useColumns;
