import React, { useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, FormControlLabel, Input, InputAdornment, Switch } from '@mui/material';
import cn from 'classnames';
import { PlanningCalendarModalClass } from '../class/PlanningCalendarModalClass';
import scss from './MetricModalSettings.module.scss';
import PromotedProductModalList from './PromotedProductModalList';
import { EnumUnitOfCampaign } from '../../../interface/campaignMetricInterface';
import onHandleChangeInput from '../../../utils/onHandleChangeInput';

interface IProps {
  instance: PlanningCalendarModalClass;
  id: number;
}
const MetricModalSettings = ({ instance, id }: IProps) => {
  const campaignMetricRef = useRef(instance.getCampaignMetricsById(id));
  const { globalProductList, campaignMetricsList } = instance;

  const {
    totalTitle,
    unit,
    metricForCalculation,
    autoDistribute,
    setAutoDistribute,
    productList: currentProductList,
    pushProduct,
    deleteProductById,
    value: metricValue,
    setValue,
    setPartOfBudget,
    partOfBudget,
    predictedValue,
    setPredictedValue,
  } = campaignMetricRef.current;

  const promotedProductHeader = useMemo(
    () => [
      { slug: 'title', title: 'Promoted product' },
      { slug: 'value', title: 'Auto distribute' },
      { slug: 'value', title: 'Amount' },
      { slug: 'price', title: 'Price' },
    ],
    [],
  );

  const type = useMemo(() => {
    if (~metricForCalculation && campaignMetricRef.current.type === 'calculated_by_product') {
      return `Calculated by ${instance.getCampaignMetricsById(metricForCalculation)?.title || 'product'}`;
    }
    return campaignMetricRef.current.type;
  }, [instance, metricForCalculation, campaignMetricRef]);

  const unUsedProductList = useMemo(() => {
    const usedIdList: number[] = currentProductList.map((currentProduct) => currentProduct.product.id);
    return globalProductList.filter((globalProduct) => !usedIdList.includes(globalProduct.product.id));
  }, [globalProductList, currentProductList.length]);

  const onSetProduct = (globalProductId: number) => {
    const foundGlobalProduct = globalProductList.find((globalProduct) => globalProduct.product.id === globalProductId);
    if (foundGlobalProduct) {
      pushProduct(foundGlobalProduct);
    }
  };

  const forCountingMetric = useMemo(
    () => campaignMetricsList.find((campaignMetric) => campaignMetric.metricForCalculation === id),
    [campaignMetricsList, id],
  );

  return (
    <>
      <Box className={scss.grid}>
        <span>Type</span>
        <span className={cn(scss.disabled, scss.type)}>{type}</span>
        <span>Symbol</span>
        <span className={scss.disabled}>{EnumUnitOfCampaign[unit]}</span>
        <span>Totals</span>
        <span className={scss.disabled}>{totalTitle}</span>
        {campaignMetricRef.current.type !== 'calculated_by_product' ? (
          <>
            <span>Used for counting</span>
            <span className={scss.disabled}>{forCountingMetric ? forCountingMetric.title : '-'}</span>

            {!forCountingMetric && (
              <>
                <span className="labelSetting">Part of budget</span>
                <Switch checked={partOfBudget} onChange={(e, value) => setPartOfBudget(value)} />
                <span className="labelSetting">Metric Value</span>
                <Box className={scss.metricValueWrapper}>
                  <FormControlLabel
                    className="labelSetting"
                    value="start"
                    control={<Switch checked={autoDistribute} onChange={(e, value) => setAutoDistribute(value)} />}
                    label="Auto distribute"
                    labelPlacement="start"
                  />

                  <Input
                    disabled={!autoDistribute}
                    className={scss.input}
                    id="MetricModalSettings_TextField_Global_Metric_Value"
                    value={metricValue}
                    onChange={onHandleChangeInput(setValue)}
                  />
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <span className="labelSetting">Projected Weekly Sales</span>
            <Input
              className={scss.predicted_input}
              id="MetricModalSettings_TextField_predictedValue_Value"
              value={predictedValue}
              onChange={onHandleChangeInput(setPredictedValue)}
              startAdornment={unit === 'price' ? <InputAdornment position="start">$</InputAdornment> : null}
            />
          </>
        )}
      </Box>
      {forCountingMetric && (
        <Box sx={{ margin: '25px 0' }}>
          {campaignMetricRef.current.type !== 'calculated_by_product' && (
            <PromotedProductModalList
              header={promotedProductHeader}
              onRemoveElements={deleteProductById}
              unUsedElementList={unUsedProductList}
              usedElementList={currentProductList}
              onSelectElements={onSetProduct}
              metric={campaignMetricRef.current}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default observer(MetricModalSettings);
