import { useMemo } from 'react';

const useElementOrderInTable = (dataList: any[]): { max: number; min: number } => {
  return useMemo(() => {
    if (!dataList?.length) {
      return { max: 0, min: 0 };
    }
    const orders: number[] = dataList.map((dataEl) => dataEl.order);

    return { max: Math.max(...orders), min: Math.min(...orders) };
  }, [dataList]);
};

export default useElementOrderInTable;
