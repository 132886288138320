import { action, computed, observable } from 'mobx';
import {
  ICreatedResponseMarketingPillar,
  IBaseMarketingPillar,
  IRequestPatchMarketingPillar,
} from '../../../interface/marketingPilarInterface';
import { BaseClass } from '../BaseClass';

export class BaseMarketingClass extends BaseClass {
  protected _isActive: IBaseMarketingPillar['is_active'];

  protected _isPrivate: IBaseMarketingPillar['is_private'];

  @observable protected _yearlyBudget: IBaseMarketingPillar['yearly_budget'];

  @observable protected _autoBudgetDistribute: IBaseMarketingPillar['auto_budget_distribute'];

  // @observable protected _campaignMetrics: IBaseMarketingPillar['campaign_metrics'];

  protected _calendar: ICreatedResponseMarketingPillar['calendar'];

  protected _user: ICreatedResponseMarketingPillar['user'];

  @observable protected _order: number;

  protected constructor(serviceConductor) {
    super(serviceConductor);
  }

  get isActive() {
    return this._isActive;
  }

  get isPrivate() {
    return this._isPrivate;
  }

  @computed get order() {
    return this._order;
  }

  @computed get yearlyBudget() {
    return this._yearlyBudget;
  }

  @computed get autoBudgetDistribute() {
    return this._autoBudgetDistribute;
  }

  // @computed get campaignMetrics() {
  //   return this._campaignMetrics;
  // }

  get calendar() {
    return this._calendar;
  }

  get user() {
    return this._user;
  }

  @action setOrder = (order: number) => {
    this._order = order;
  };

  protected _builder = (key, value) => {};

  @action setDescription = (value) => {
    this._description = value;
  };

  @action fetchPatch = async (data: IRequestPatchMarketingPillar) => this.fetchPatchBase(data);
}
