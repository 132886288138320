import React, { memo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';

const Grid = ({
  sellQuantity,
  columns,
  rows,
  getRowHeight,
  settings = {},
}: {
  sellQuantity: number;
  columns: any;
  rows: any;
  getRowHeight?: () => number | null | undefined | 'auto';
  settings?: (DataGridProps & React.RefAttributes<HTMLDivElement>) | {};
}) => (
  <DataGrid
    sx={{ backgroundColor: '#fff', borderRadius: '12px' }}
    // autoHeight
    rows={rows}
    getRowHeight={getRowHeight}
    columns={columns} // @ts-ignore
    // pageSize={5} // @ts-ignore
    // rowsPerPageOptions={[5]}
    initialState={{
      pagination: {
        paginationModel: {
          pageSize: sellQuantity,
        },
      },
      sorting: {
        sortModel: [{ field: 'order', sort: 'asc' }],
      },
    }}
    checkboxSelection={false}
    // autoPageSize
    // disableSelectionOnClick
    pageSizeOptions={[sellQuantity, 30, 50, 100]}
    {...settings}
  />
);

export default memo(Grid);
