import { action, computed, observable, runInAction } from 'mobx';
import { LoadingStatus } from '../../interface';
import { snakeToCamel } from '../../utils/stylizedNaming';

export abstract class BaseClass {
  protected _id: number;

  @observable protected _title: string;

  @observable protected _description: string;

  @observable protected _loadingStatus = LoadingStatus.SUCCESS;

  protected readonly _serviceConductor;

  protected abstract _builder;

  protected constructor(serviceConductor) {
    this._serviceConductor = serviceConductor;
  }

  get id() {
    return this._id;
  }

  @computed get title() {
    return this._title || '';
  }

  @computed get description() {
    return this._description || '';
  }

  @computed get loadingStatus() {
    return this._loadingStatus;
  }

  @action protected fetchPatchBase = async (data) => {
    if (!this._serviceConductor) {
      return false;
    }

    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data: responseData } = await this._serviceConductor.patchById(this._id, data);
      runInAction(() => {
        this.snakeToCamelBuilder(responseData);
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action protected snakeToCamelBuilder(object) {
    for (const [key, value] of Object.entries(object)) {
      if (Array.isArray(value) && typeof value[0] === 'object') {
        this._builder(key, value);
      } else {
        this[`_${snakeToCamel(key)}`] = value;
      }
    }
  }
}
