import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { BarDatum } from '@nivo/bar/dist/types/types';
import { formatPrefix } from 'd3-format';

interface IProps {
  data: BarDatum[] | any[];
  keys: string[];
  axisBottomLegend?: string;
  axisLeftLegend?: string;
  settings?: any;
}
const BarChart = ({ data, keys, axisBottomLegend, axisLeftLegend, settings }: IProps) => (
  <>
    {!!data.length && !!keys.length && (
      <ResponsiveBar
        data={data}
        keys={keys}
        margin={{ top: 10, right: 10, bottom: 80, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'set2' }}
        // defs={[
        //   {
        //     id: 'Total Projected Sales',
        //     type: 'patternDots',
        //     background: 'inherit',
        //     color: '#38bcb2',
        //     size: 4,
        //     padding: 1,
        //     stagger: true,
        //   },
        //   {
        //     id: 'Total Projected Sales',
        //     type: 'patternLines',
        //     background: 'inherit',
        //     color: '#eed312',
        //     rotation: -45,
        //     lineWidth: 6,
        //     spacing: 10,
        //   },
        // ]}
        // fill={[
        //   {
        //     match: {
        //       id: 'fries',
        //     },
        //     id: 'Total Projected Sales',
        //   },
        //   {
        //     match: {
        //       id: 'sandwich',
        //     },
        //     id: 'Total Projected Sales',
        //   },
        // ]}
        // borderColor={{
        //   from: 'сolor',
        //   modifiers: [['darker', 1.6]],
        // }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -50,
          legend: axisBottomLegend,
          legendPosition: 'middle',
          legendOffset: 70,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisLeftLegend,
          legendPosition: 'middle',
          legendOffset: -50,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        // legends={[
        //   {
        //     dataFrom: 'keys',
        //     anchor: 'bottom-left',
        //     direction: 'row',
        //     justify: false,
        //     translateX: 120,
        //     translateY: 100,
        //     itemsSpacing: 2,
        //     itemWidth: 180,
        //     itemHeight: 20,
        //     itemDirection: 'left-to-right',
        //     itemOpacity: 0.85,
        //     symbolSize: 20,
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
        role="application"
        // ariaLabel="Nivo bar chart demo"
        motionConfig="gentle"
        // barAriaLabel={(e) => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
        {...settings}
        // indexBy="date"
        label={(e) => `${formatPrefix('.1', e.value)(e.value)}`}
      />
    )}
  </>
);

export default BarChart;
