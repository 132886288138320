import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { PermissionClass } from '../slass/permission/PermissionClass';
import { GroupPermissionClass } from '../slass/permission/GroupPermissionClass';
import {
  getGroupPermissionById,
  getGroupPermissionList,
  getPermissionById,
  getPermissionList,
} from '../service/permissionsService';
import { LoadingStatus } from '../interface';

class PermissionStore {
  @observable private _permissionLoadingStatus: LoadingStatus = LoadingStatus.LOADING;

  @observable private _groupPermissionLoadingStatus: LoadingStatus = LoadingStatus.LOADING;

  @observable private _permissionList: PermissionClass[] = [];

  @observable private _groupPermissionList: GroupPermissionClass[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get permissionList() {
    return this._permissionList;
  }

  @computed
  get groupPermissionList() {
    return this._groupPermissionList;
  }

  @computed
  get groupPermissionLoadingStatus() {
    return this._groupPermissionLoadingStatus;
  }

  @computed
  get permissionLoadingStatus() {
    return this._permissionLoadingStatus;
  }

  @action clear = () => {
    this._permissionLoadingStatus = LoadingStatus.LOADING;
    this._groupPermissionLoadingStatus = LoadingStatus.LOADING;
    this._permissionList = [];
    this._groupPermissionList = [];
  };

  @action
  fetchPermissionList = async () => {
    this._permissionLoadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getPermissionList();
      runInAction(() => {
        this._permissionList = data.map((permission) => new PermissionClass(permission));
        this._permissionLoadingStatus = LoadingStatus.SUCCESS;
      });
    } catch {
      this._permissionLoadingStatus = LoadingStatus.ERROR;
    }
  };

  @action fetchGroupPermissionList = async () => {
    this._groupPermissionLoadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getGroupPermissionList();
      runInAction(() => {
        this._groupPermissionList = data.map((groupPermission) => new GroupPermissionClass(groupPermission));
        this._groupPermissionLoadingStatus = LoadingStatus.SUCCESS;
      });
    } catch {
      this._groupPermissionLoadingStatus = LoadingStatus.ERROR;
    }
  };

  @action fetchPermissionById = async (id: number) => {
    this._permissionLoadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getPermissionById(id);

      const matchedIndex = this._permissionList.findIndex((element) => element.id === data.id);

      runInAction(() => {
        if (~matchedIndex) {
          this._permissionList[matchedIndex] = new PermissionClass(data);
        } else {
          this._permissionList.push(new PermissionClass(data));
        }
        this._permissionLoadingStatus = LoadingStatus.SUCCESS;
      });
      return this._permissionList;
    } catch {
      this._permissionLoadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action fetchGroupPermissionById = async (id: number) => {
    this._groupPermissionLoadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getGroupPermissionById(id);

      const matchedIndex = this._groupPermissionList.findIndex((element) => element.id === data.id);
      runInAction(() => {
        if (~matchedIndex) {
          this._groupPermissionList[matchedIndex] = new GroupPermissionClass(data);
        } else {
          this._groupPermissionList.push(new GroupPermissionClass(data));
        }
        this._groupPermissionLoadingStatus = LoadingStatus.SUCCESS;
      });
      return this._groupPermissionList;
    } catch {
      this._groupPermissionLoadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}

export default PermissionStore;
