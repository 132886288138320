import React, { createContext, useReducer, useState, Dispatch, SetStateAction, useMemo } from 'react';
import { Box, FormControl, FormHelperText, InputAdornment, OutlinedInput } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import TextField from '@mui/material/TextField';
import { LoadingStatus } from '../../interface';
import { IBaseMarketingPillar } from '../../interface/marketingPilarInterface';
import MainStore from '../../store/MainStore';
// eslint-disable-next-line import/no-cycle
import MarketingChannelWrapper from './MarketingChannelWrapper';
import { MarketingPillarClass } from '../../slass/data/marketing-pillars/MarketingPillarClass';
import useCheckUsedTitle from '../../utils/hooks/useCheckUsedTitle';
import validateNumber, { getValidNumber } from '../../utils/validateNumber';

type ChannelContextType = { id?: number; title: string; newChannelPseudoId?: string | number };
export const ChannelContext = createContext<{
  newChannelList: ChannelContextType[];
  setNewChannelList: Dispatch<SetStateAction<ChannelContextType[]>>;
}>(null);

type State = IBaseMarketingPillar;
type ActionType = keyof State;

const marketingReducer = (state: State, action: { type: ActionType; payload: any }): State => {
  const { type, payload } = action;
  switch (type) {
    case 'title':
      return {
        ...state,
        title: payload,
      };
    case 'description':
      return {
        ...state,
        description: payload,
      };
    case 'yearly_budget':
      let yearlyBudget = state.yearly_budget;
      if (validateNumber(payload) || payload === '') {
        yearlyBudget = getValidNumber(payload > 0 ? payload : 0);
      }
      return {
        ...state,
        yearly_budget: yearlyBudget,
      };
    case 'auto_budget_distribute':
      return {
        ...state,
        auto_budget_distribute: payload,
      };
    // case 'campaign_metrics':
    //   return {
    //     ...state,
    //     campaign_metrics: payload,
    //   };
    default:
      return state;
  }
};

interface IProps {
  propsInstance?: MarketingPillarClass;
  onCloseModalHandler: () => void;
  usedMarketingTitle: string[];
}

const MarketingPillarModal = ({ onCloseModalHandler, propsInstance, usedMarketingTitle }: IProps) => {
  let instance = propsInstance;

  const [newChannelList, setNewChannelList] = useState<ChannelContextType[]>(
    instance?.marketingChannels.map((channel) => ({ title: channel.title, id: channel.id })) || [],
  );

  const { fetchCreate: fetchCreateMarketingPilar, loadingStatus } = MainStore.marketingPillarStore;

  // const { dataList: campaignMetricsList } = MainStore.campaignMetricsStore;

  // const { usedElementList, unUsedElementsToOptions, setUnUsedElementsState } = useUsedAndUnUsed(
  //   campaignMetricsList,
  //   instance?.campaignMetrics,
  // );

  const initState: State = {
    title: instance?.title ?? '',
    description: instance?.description ?? '',
    yearly_budget: instance?.yearlyBudget ?? 0,
    auto_budget_distribute: instance?.autoBudgetDistribute ?? true,
    // campaign_metrics: usedElementList.map((campaign) => campaign.id) ?? [],
    is_active: instance?.isActive || false,
    is_private: instance?.isPrivate || false,
  };
  const [state, dispatch] = useReducer(marketingReducer, initState);

  const onHandleChangeInput = (type: ActionType) => (event) => {
    dispatch({
      type,
      payload: event.target.value,
    });
  };

  const onHandleChangeSwitch = (type: ActionType) => (event) => {
    dispatch({
      type,
      payload: event.target.checked,
    });
  };

  const onSaveButtonHandle = () => {
    const fn = instance?.fetchPatch || fetchCreateMarketingPilar;
    fn(state).then((response) => {
      instance = response instanceof MarketingPillarClass ? response : instance;

      const prepareToPromise =
        newChannelList?.map((newChannel) => {
          if (newChannel.id) {
            const foundToUpdate = instance.marketingChannels.find(
              (channel) => channel.id === newChannel.id && channel.title !== newChannel.title,
            );
            if (foundToUpdate) {
              return foundToUpdate.fetchPatch({ title: newChannel.title });
            }
          }
          if (newChannel.newChannelPseudoId) {
            return instance.createMarketingChannel({ title: newChannel.title });
          }
          return true;
        }) || [];

      instance.marketingChannels?.forEach((channel) => {
        const isDelete = !newChannelList?.find((newChannel) => newChannel.id === channel.id);
        if (isDelete) {
          prepareToPromise.push(instance.deleteMarketingChannel(channel.id));
        }
      });

      Promise.all(prepareToPromise).then((isSuccess) => {
        if (isSuccess) {
          onCloseModalHandler();
        }
      });
    });
  };

  const isLoading = (instance?.loadingStatus || loadingStatus) === LoadingStatus.LOADING;

  // const onSelectNewCampaignMetricsHandler = (id) => {
  //   dispatch({
  //     type: 'campaign_metrics',
  //     payload: [...state.campaign_metrics, id],
  //   });
  //   setUnUsedElementsState((prevState) => prevState.filter((campaign) => campaign.id !== id));
  // };

  // const onRemoveCampaignMetricsHandler = (id) => {
  //   dispatch({
  //     type: 'campaign_metrics',
  //     payload: state.campaign_metrics.filter((campaignId) => campaignId !== id),
  //   });
  //   setUnUsedElementsState((prevState) => [...prevState, campaignMetricsList.find((campaign) => campaign.id === id)]);
  // };

  const isAlreadyUsedTitle = useCheckUsedTitle(instance?.title, state.title, usedMarketingTitle);

  const isEmptyNewChannelTitle = useMemo(
    () => ~newChannelList.findIndex((channel) => !channel.title),
    [newChannelList],
  );

  const isEmptyTitle = !Object.keys(state.title).length;
  const isDisabledButton = isEmptyTitle || isAlreadyUsedTitle || isEmptyNewChannelTitle;

  return (
    <>
      <Box sx={{ margin: '25px 0 30px' }}>
        <FormControl error={isDisabledButton} sx={{ width: '100%' }}>
          <TextField
            id="standard-basic"
            placeholder="Title"
            variant="standard"
            sx={{ width: '100%' }}
            value={state.title}
            onChange={onHandleChangeInput('title')}
          />
          {isAlreadyUsedTitle && (
            <FormHelperText
              id="title-error-by-isAlreadyUsedTitle"
              sx={{ position: 'absolute', bottom: '-70%', left: 0 }}
            >
              The title is already in use
            </FormHelperText>
          )}
          {isEmptyTitle && (
            <FormHelperText id="title-error-by-isEmptyTitle" sx={{ position: 'absolute', bottom: '-70%', left: 0 }}>
              The title is required
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box sx={{ margin: '25px 0' }}>
        <TextField
          id="standard-basic"
          label="Description"
          multiline
          variant="outlined"
          rows={4}
          sx={{ width: '100%' }}
          value={state.description}
          onChange={onHandleChangeInput('description')}
        />
      </Box>
      <Box sx={{ margin: '25px 0' }}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <ChannelContext.Provider value={{ newChannelList, setNewChannelList }}>
          <MarketingChannelWrapper usedMarketingTitle={usedMarketingTitle} />
        </ChannelContext.Provider>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '25px 0', gap: '20px' }}>
        <Typography className="labelSetting" component="span">
          Yearly Budget
        </Typography>
        <FormControl sx={{ m: 1 }}>
          <OutlinedInput
            id="standard-adornment-weight"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            aria-describedby="standard-weight-helper-text"
            // type="number"
            sx={{ maxWidth: '150px', height: '40px' }}
            value={state.yearly_budget}
            onChange={onHandleChangeInput('yearly_budget')}
          />
        </FormControl>
      </Box>
      {/*<Box>*/}
      {/*  <FormGroup sx={{ display: 'inline-flex' }}>*/}
      {/*    <FormControlLabel*/}
      {/*      control={*/}
      {/*        <Switch*/}
      {/*          checked={state.auto_budget_distribute}*/}
      {/*          onChange={onHandleChangeSwitch('auto_budget_distribute')}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      labelPlacement="start"*/}
      {/*      label="Auto distribute activities budgets by default"*/}
      {/*      sx={{ marginLeft: 0 }}*/}
      {/*    />*/}
      {/*  </FormGroup>*/}
      {/*</Box>*/}
      <Box sx={{ margin: '25px 0' }}>
        {/*<ModalList*/}
        {/*  label="Campaign metrics"*/}
        {/*  onRemoveElements={onRemoveCampaignMetricsHandler}*/}
        {/*  unUsedElementToOptionList={unUsedElementsToOptions}*/}
        {/*  comparisonList={campaignMetricsList}*/}
        {/*  onSelectElements={onSelectNewCampaignMetricsHandler}*/}
        {/*  usedElementsIdList={state.campaign_metrics}*/}
        {/*/>*/}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModalHandler}>
          Cancel
        </Button>
        <Button variant="solid" onClick={onSaveButtonHandle} disabled={isDisabledButton} loading={isLoading}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default MarketingPillarModal;
