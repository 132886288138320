import React, { useContext, useState } from 'react';
import { Box, FormControl, FormHelperText, ListItem, Typography } from '@mui/material';
import IconButton from '@mui/joy/IconButton';
import TextField from '@mui/material/TextField';
import { ReactComponent as DeleteIcon } from '../../icon/DeleteIcon.svg';
import useCheckUsedTitle from '../../utils/hooks/useCheckUsedTitle';
import { ChannelContext } from './MarketingPillarModal';

interface IProps {
  defaultTitle: string;
  usedMarketingTitle: string[];

  id?: number;
  newChannelPseudoId?: string | number;
}
const MarketingChannel = ({ defaultTitle, usedMarketingTitle, id, newChannelPseudoId }: IProps) => {
  const { setNewChannelList } = useContext(ChannelContext);

  const [title, setTitle] = useState(defaultTitle);
  const [isEdit, setIsEdit] = useState(true);

  const onHandleChangeInput = (event) => {
    const { value } = event.target;
    setTitle(value);
    setNewChannelList((prevState) => {
      const foundIndex = prevState.findIndex((channel) => {
        if (newChannelPseudoId) {
          return channel.newChannelPseudoId === newChannelPseudoId;
        }
        return channel.id === id;
      });
      if (~foundIndex) {
        prevState[foundIndex] = { ...prevState[foundIndex], title: value };
        return [...prevState];
      }
      return [...prevState];
    });
  };

  const onSetIsEdit = (value: boolean) => () => {
    setIsEdit(value);
  };
  const isAlreadyUsedTitle = useCheckUsedTitle(defaultTitle, title, usedMarketingTitle);

  const isError = !title.length || isAlreadyUsedTitle;

  const onDelete = () => {
    setNewChannelList((prevState) => [
      ...prevState.filter((channel) => {
        if (newChannelPseudoId) {
          return channel.newChannelPseudoId !== newChannelPseudoId;
        }
        return channel.id !== id;
      }),
    ]);
  };

  return (
    <ListItem
      sx={{
        // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '2px 0',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '45px',
      }}
    >
      <Box>
        {isEdit ? (
          <FormControl error={isError} sx={{ width: '100%', height: '41px' }}>
            <TextField
              id="standard-basic"
              placeholder="Channel title"
              variant="standard"
              sx={{ width: '470px', padding: 0 }}
              value={title}
              onChange={onHandleChangeInput}
            />
            {isAlreadyUsedTitle && (
              <FormHelperText id="MarketingChannel-title-error-by-isAlreadyUsedTitle">
                The channel title is already in use
              </FormHelperText>
            )}
            {!title.length && (
              <FormHelperText id="MarketingChannel-title-error-by-isEmptyTitle">
                The channel title is required
              </FormHelperText>
            )}
          </FormControl>
        ) : (
          <Box sx={{ maxWidth: '470px' }}>
            <Typography component="p" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {title}
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        {/*{isEdit ? (*/}
        {/*  <IconButton color="primary" disabled={isError} onClick={onSetIsEdit(false)}>*/}
        {/*    <SaveIcon />*/}
        {/*  </IconButton>*/}
        {/*) : (*/}
        {/*  <IconButton color="primary" onClick={onSetIsEdit(true)}>*/}
        {/*    <EditIcon />*/}
        {/*  </IconButton>*/}
        {/*)}*/}

        <IconButton color="danger" variant="plain" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
};

export default MarketingChannel;
