import { useMemo } from 'react';

const useCheckUsedTitle = (instanceTitle, stateTitle, usedTitleList) =>
  useMemo(() => {
    if (instanceTitle) {
      return instanceTitle === stateTitle ? false : usedTitleList.includes(stateTitle);
    }
    return usedTitleList.includes(stateTitle);
  }, [instanceTitle, stateTitle, usedTitleList]);
export default useCheckUsedTitle;
