import dayjs from 'dayjs';

const calculateNumberOfDays = (daysList: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }[], format = 'MM-DD-YYYY') => {
  const dateList = new Set<string>();

  daysList.forEach(({ endDate, startDate }) => {
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateList.add(currentDate.format(format));
      currentDate = currentDate.add(1, 'day');
    }
  });

  return { dateList: Array.from(dateList), count: dateList.size };
};

export default calculateNumberOfDays;
