import { computed, observable } from 'mobx';
import { BaseClass } from '../BaseClass';

export abstract class ProductBaseClass extends BaseClass {
  @observable protected _company: number;

  protected readonly _createdAt: string;

  @observable protected _updatedAt: string;

  protected constructor(serviceConductor?: any) {
    super(serviceConductor);
  }

  @computed get company() {
    return this._company;
  }
}
