import { computed, makeObservable, observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import AuthStore from './AuthStore';
import UserStore from './UserStore';
import MarketingPillarStore from './MarketingPillarStore';
import CampaignMetricsStore from './CampaignMetricsStore';
import CalendarStore from './CalendarStore';
import ProductCategoryStore from './ProductCategoryStore';
import ProductStore from './ProductStore';
import CompanyStore from './CompanyStore';
import CompetitorStore from './CompetitorStore';
import NotificationStore from './NotificationStore';
import CustomFieldsStore from './CustomFieldsStore';
import PermissionStore from './PermissionStore';
// eslint-disable-next-line import/no-cycle

class MainStore {
  @observable private _auth = new AuthStore();

  @observable private _user = new UserStore();

  @observable private _marketingPillarStore = new MarketingPillarStore();

  @observable private _campaignMetricsStore = new CampaignMetricsStore();

  @observable private _customFieldsStore = new CustomFieldsStore();

  @observable private _calendarStore = new CalendarStore();

  @observable private _productCategoryStore = new ProductCategoryStore();

  @observable private _productStore = new ProductStore();

  @observable private _companyStore = new CompanyStore();

  @observable private _competitorStore = new CompetitorStore();

  @observable private _notificationStore = new NotificationStore();

  @observable private _permissionStore = new PermissionStore();

  constructor() {
    makeObservable(this);
  }

  @computed
  get authStore() {
    return this._auth;
  }

  @computed
  get userStore() {
    return this._user;
  }

  @computed
  get marketingPillarStore() {
    return this._marketingPillarStore;
  }

  @computed
  get campaignMetricsStore() {
    return this._campaignMetricsStore;
  }

  @computed
  get customFieldsStore() {
    return this._customFieldsStore;
  }

  @computed
  get calendarStore() {
    return this._calendarStore;
  }

  @computed
  get productCategoryStore() {
    return this._productCategoryStore;
  }

  @computed
  get productStore() {
    return this._productStore;
  }

  @computed
  get companyStore() {
    return this._companyStore;
  }

  @computed
  get competitorStore() {
    return this._competitorStore;
  }

  @computed
  get notificationStore() {
    return this._notificationStore;
  }

  @computed
  get permissionStore() {
    return this._permissionStore;
  }
}

export default new MainStore();
