import { makeObservable } from 'mobx';
import { ProductClass } from '../slass/data/product/ProductClass';
import { BaseStoreAbstract } from './BaseStore.abstract';
import * as productService from '../service/productService';

class ProductStore extends BaseStoreAbstract<ProductClass> {
  protected readonly _mainDataClass = ProductClass;

  // protected _mainDataList: ProductClass[] = [];

  protected readonly _serviceConductor = productService;

  constructor() {
    super();
    makeObservable(this);
  }
}

export default ProductStore;
