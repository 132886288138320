import { BaseMetricsForCalendarClass } from './BaseMetricsForCalendarClass';
import { ICampaignMetric } from '../../interface/calendarInterface';

export class CampaignMetricForCalendarClass extends BaseMetricsForCalendarClass {
  protected _order: number;

  constructor(data: ICampaignMetric) {
    super({ id: data.id, title: data.title });
    this._order = data.order;
  }

  get order() {
    return this._order;
  }
}
