export enum MainRoutesPath {
  LOGIN_PAGE = 'login',
  RESET_PASSWORD_PAGE = 'reset-password',
  HOME_PAGE = '/',
  DASHBOARD_PAGE = 'dashboard',
  PLANNING_PAGE = 'planning',
  CHANGELOG_PAGE = 'changelog',
  COMPETITORS_PAGE = 'competitors',
  SETTINGS_PAGE = 'settings',
  SHARE = 'share',
  NOT_FOUND = '*',
}

export enum PlanningRoutesPath {
  CALENDAR = 'calendar',
  MARKETING_PILLAR = 'marketing-pillars',
  CAMPAIGN_METRICS = 'campaign-metrics',
  CUSTOM_FIELDS = 'custom-fields',
  PRODUCTS = 'products',
}

export enum SettingsRoutesPath {
  COMPANIES = 'companies',
  PERMISSIONS = 'permissions',
  // DATA = 'data',
}

export const addSlash = (str) => `/${str}`;
