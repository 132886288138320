import React from 'react';
import { ModalClose, Sheet, Typography } from '@mui/joy';
import { Box } from '@mui/material';
import Button from '@mui/joy/Button';
import { LoadingStatus } from '../../../interface';

interface IProps<Class> {
  onCloseModalHandler: () => void;
  fetchDelete: (id: number) => Promise<boolean | Class[]>;
  loadingStatus: any;
  id: number;
  title: string;
  onSuccess?: () => void;
}

// eslint-disable-next-line react/function-component-definition
function DeleteModalContent<Class>({
  onCloseModalHandler,
  fetchDelete,
  loadingStatus,
  id,
  title,
  onSuccess,
}: IProps<Class>) {
  const onDeleteHandler = () => {
    const fn = onSuccess || onCloseModalHandler;
    fetchDelete(id).then((isSuccess) => {
      if (isSuccess) {
        fn();
      }
    });
  };

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: 580,
        borderRadius: 'md',
        p: 3,
      }}
    >
      <ModalClose variant="plain" onClick={onCloseModalHandler} />
      <Typography component="h3">
        Delete <b>{title}</b> ?
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModalHandler}>
          Cancel
        </Button>
        <Button
          variant="solid"
          color="danger"
          onClick={onDeleteHandler}
          loading={loadingStatus === LoadingStatus.LOADING}
        >
          Delete
        </Button>
      </Box>
    </Sheet>
  );
}

export default DeleteModalContent;
