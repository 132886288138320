import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Popover } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import { EventImpl } from '@fullcalendar/core/internal';
import { DateSelectArg } from '@fullcalendar/core';
import scss from '../Planning.module.scss';
import { AnnotationValueForCalendarClass } from '../../../slass/calendar/AnnotationValueForCalendarClass';
import { AnnotationForCalendarClass } from '../../../slass/calendar/AnnotationForCalendarClass';
import { matchNextWeekDate } from '../../../component/planning-calendar/utils';
import { DateUtils } from '../../../utils/DateUtils';

interface IProps {
  open: boolean;
  coordinates: { top: number; left: number };
  event: DateSelectArg & EventImpl;
  calendarRef;
  onClose: () => void;
  dateList: string[];
}
const PopoverChangeCustomField = ({ open, onClose, coordinates, event, calendarRef, dateList }: IProps) => {
  const instance: AnnotationValueForCalendarClass | AnnotationForCalendarClass = event?.extendedProps?.instance ?? {};

  const canBeOpen = !!open && !!coordinates;
  const id = canBeOpen ? 'transition-popper' : undefined;

  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState<string | number>('');

  useEffect(() => {
    //todo
    if (instance instanceof AnnotationValueForCalendarClass) {
      setInputValue(instance?.value);
    } else {
      setInputValue('');
    }
  }, [instance.id]);

  const fetchToUpdate = () => {
    if (
      (instance instanceof AnnotationValueForCalendarClass && instance?.value === inputValue) ||
      (!(event instanceof EventImpl) && !`${inputValue}`.length)
    ) {
      setInputValue('');
      onClose();
      return;
    }

    if (event instanceof EventImpl) {
      const eventId = event.id;
      const eventCopyValue = event.title;

      const isHaveValue = !!`${inputValue}`.length;
      const { updateValue, forDate, parentInstance } = instance as AnnotationValueForCalendarClass;

      if (isHaveValue) {
        event.setProp('title', `${inputValue}`);

        updateValue(inputValue).then((result) => {
          if (!result) {
            calendarRef.current.calendar?.getEventById(eventId)?.setProp('title', `${eventCopyValue}`);
          }
        });
      } else {
        //delete from protection against selecting an existing date
        const resource = calendarRef.current.getApi().getResourceById(event.getResources()[0].id);
        const { usedDataList } = resource.extendedProps;
        const foundUsedDataIndex = usedDataList.findIndex((usedData) => usedData === forDate);
        resource.setExtendedProp('usedDataList', usedDataList.slice(foundUsedDataIndex, 1));
        //delete from store
        parentInstance.deleteValue(forDate);
        //delete from calendar
        event.remove();
      }
    } else {
      const { extendedProps, id: resourceId } = (event as DateSelectArg).resource;
      const sortableDateList = DateUtils.findDatesInRange(
        dateList,
        (event as DateSelectArg).startStr,
        (event as DateSelectArg).endStr,
      );

      const newEventList = [];

      Promise.all(
        sortableDateList.map((sortableDate) => {
          const instanceOfNewValue = extendedProps.annotationInstance.pushValue(inputValue, sortableDate);

          const start = sortableDate;
          const end = matchNextWeekDate(sortableDate);

          const newEvent = {
            id: `@_${resourceId}_1`,
            resourceId,
            title: `${inputValue}`,
            start,
            end,
            editable: false,
            extendedProps: {
              instance: instanceOfNewValue,
            },
          };
          // protection against selecting an existing date
          extendedProps.usedDataList.push(start, end);

          newEventList.push(calendarRef.current.calendar.addEvent(newEvent));
          return extendedProps.annotationInstance.createValue(inputValue, sortableDate).then((result) => {
            //abolition
            if (!result) {
              // calendarRef.current.calendar?.getEventById(newEvent.id)?.remove();
              extendedProps.usedDataList.filter(
                (usedDateFromUsedDataList) => usedDateFromUsedDataList !== start && usedDateFromUsedDataList !== end,
              );
            }
          });
        }),
      ).finally(() => {
        // fullcalendar caches new events (addEvent), so data is duplicated when calling refetchEvents
        newEventList.forEach((newEvent) => {
          newEvent?.remove();
        });
        calendarRef.current.getApi().refetchEvents();
      });
    }

    onClose();
    setInputValue('');
  };

  const handleClickOutside = (eventClick) => {
    // debugger;
    eventClick.stopPropagation();
    eventClick.preventDefault();

    if (inputRef.current && !inputRef.current.contains(eventClick.target)) {
      fetchToUpdate();
    }
  };

  const onHandleKeyDown = (keyDownEvent: any) => {
    if (keyDownEvent.key === 'Enter') {
      fetchToUpdate();
    } else if (keyDownEvent.key === 'Escape') {
      setInputValue('');
      onClose();
    }
  };

  const onHandleChangeFieldValue = (inputEvent) => {
    setInputValue(inputEvent.target.value);
  };

  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorReference="anchorPosition"
      anchorPosition={coordinates}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onKeyDown={onHandleKeyDown}
      className={scss.popover}
    >
      <ClickAwayListener onClickAway={handleClickOutside} mouseEvent="onMouseDown" touchEvent="onTouchStart">
        <Box className={scss.popperChangeMetric_wrapper}>
          <TextField
            ref={inputRef}
            value={inputValue}
            variant="standard"
            sx={{ maxWidth: '80px', position: 'relative' }}
            onChange={onHandleChangeFieldValue}
            autoFocus
            type="text"
          />
        </Box>
      </ClickAwayListener>
    </Popover>
  );
};

export default observer(PopoverChangeCustomField);
