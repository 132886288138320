import { Alert, Stack, Typography } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import MainStore from '../../store/MainStore';

const Alerts = () => {
  const { alertsList } = MainStore.notificationStore;
  return (
    <Stack sx={{ width: '100%' }} spacing={1}>
      {alertsList?.length > 0 &&
        alertsList.map((item) => {
          const color =
            (item.message_type === 'warning' && 'warning') ||
            (item.message_type === 'info' && 'info') ||
            (item.message_type === 'success' && 'success') ||
            'error';

          const date = dayjs(item.created_at).format('YYYY-MM-DD HH:mm');
          return (
            <Alert key={item.id} icon={false} severity={color}>
              {item.message}
              <Typography sx={{ mt: 1 }} variant="caption" component="p">
                {date}
              </Typography>
            </Alert>
          );
        })}
    </Stack>
  );
};

export default Alerts;
