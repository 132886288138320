import { DateSelectArg } from '@fullcalendar/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { observer } from 'mobx-react-lite';
import { EventImpl } from '@fullcalendar/core/internal';
import { ModalClose, Sheet } from '@mui/joy';
import { PlanningCalendarModalClass } from '../class/PlanningCalendarModalClass';
import PlanningCalendarModal from './PlanningCalendarModal';
import MainStore from '../../../store/MainStore';
import { LoadingStatus } from '../../../interface';
import Loader from '../../../component/ui/loader/Loader';
import MetricModalSettings from './MetricModalSettings';
import DefaultChildrenWrapperModal from '../../../component/ui/default-modal/DefaultChildrenWrapperModal';
import { ReactComponent as ArrowBackIcon } from '../../../icon/ArrowBackIcon.svg';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';

interface IProps {
  selectInfo: DateSelectArg & EventImpl;
  onCloseModal: () => void;
  onUpdateCalendar: () => void;
  isEditCampaign: boolean;
  calendarInstance: CalendarClass;
}
const PlanningCalendarModalWrapper = ({
  selectInfo,
  onCloseModal,
  onUpdateCalendar,
  isEditCampaign,
  calendarInstance,
}: IProps) => {
  const defaultTitleRef = useRef(selectInfo.title);

  const [metricId, setMetricId] = useState<number | null>(null);

  const wrapperModalRef = useRef(null);

  const usedTitleList = calendarInstance.usedCampaignTitle;
  const { dataList: campaignMetricsList, loadingStatus: campaignMetricsLoadingStatus } = MainStore.campaignMetricsStore;
  const { dataList: productList, loadingStatus: productLoadingStatus } = MainStore.productStore;

  const [modalInstance, setModalInstance] = useState<PlanningCalendarModalClass>(null);

  useEffect(() => {
    if (campaignMetricsLoadingStatus === LoadingStatus.SUCCESS && productLoadingStatus === LoadingStatus.SUCCESS) {
      const instance = new PlanningCalendarModalClass(selectInfo, campaignMetricsList, productList);
      setModalInstance(() => instance);
    }
  }, [selectInfo, campaignMetricsList, campaignMetricsLoadingStatus, productLoadingStatus, productList]);

  const isLoaded =
    campaignMetricsLoadingStatus === LoadingStatus.LOADING ||
    productLoadingStatus === LoadingStatus.LOADING ||
    modalInstance?.loadingInitStatus === LoadingStatus.LOADING ||
    !modalInstance;

  const isSuccess =
    modalInstance &&
    campaignMetricsLoadingStatus === LoadingStatus.SUCCESS &&
    productLoadingStatus === LoadingStatus.SUCCESS &&
    modalInstance?.loadingInitStatus === LoadingStatus?.SUCCESS;

  const addEditCampaignModalTitle = useMemo(() => {
    if (metricId === null || !modalInstance) {
      return isEditCampaign ? 'Edit Campaign' : 'Add Campaign';
    }

    return `Edit ${modalInstance.getUsedCampaignMetricsListById(metricId).title}`;
  }, [isEditCampaign, metricId, modalInstance]);

  const minWrapperHeight = useMemo(() => {
    if (metricId) {
      return wrapperModalRef?.current && `${wrapperModalRef?.current?.getBoundingClientRect()?.height}px`;
    }
    return null;
  }, [metricId]);

  return (
    <Box>
      {metricId !== null ? (
        <Sheet
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 800,
            borderRadius: 'md',
            p: 3,
            maxHeight: '95vh',
            ...(minWrapperHeight ? { minHeight: minWrapperHeight } : {}),
          }}
        >
          <ModalClose variant="plain" onClick={onCloseModal} />
          <Typography
            component="h2"
            id="close-modal-title"
            sx={{ margin: '0 0 25px', fontWeight: 600, fontSize: '20px', lineHeight: '32px' }}
          >
            {addEditCampaignModalTitle}
          </Typography>
          <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <MetricModalSettings instance={modalInstance} id={metricId} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'start',
              marginTop: 'auto',
            }}
          >
            <Button variant="soft" sx={{ borderRadius: '18px' }} size="md" onClick={() => setMetricId(null)}>
              <ArrowBackIcon style={{ fill: '#1976d2' }} />
              <span style={{ transform: 'translateY(1px)' }}>Save & Back to Campaign</span>
            </Button>
          </Box>
        </Sheet>
      ) : (
        <DefaultChildrenWrapperModal
          parentRef={wrapperModalRef}
          modalTitle={addEditCampaignModalTitle}
          onCloseModal={onCloseModal}
          sx={{ width: 800 }}
        >
          <>
            {isLoaded && <Loader />}
            {isSuccess && (
              <PlanningCalendarModal
                usedTitleList={usedTitleList}
                instance={modalInstance}
                onCloseModal={onCloseModal}
                onUpdateCalendar={onUpdateCalendar}
                onOpenElement={setMetricId}
                defaultTitle={defaultTitleRef.current}
                calendarInstance={calendarInstance}
              />
            )}
          </>
        </DefaultChildrenWrapperModal>
      )}
    </Box>
  );
};

export default observer(PlanningCalendarModalWrapper);
