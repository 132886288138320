import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { LoadingStatus } from '../interface';
import { CalendarClass } from '../slass/calendar/CalendarClass';
import { getList, create, deleteById, getSelected } from '../service/competitorService';
import { CompetitorClass } from '../slass/competitor/CompetitorClass';
import { ICreateRequestCompetitor } from '../interface/competitorInterface';
import { CompetitorCalendarClass } from '../slass/calendar/CompetitorCalendarClass';

class CompetitorStore {
  @observable private _competitorList: CompetitorClass[] = [];

  @observable private _selectedCompetitor: CompetitorCalendarClass;

  @observable private _loadingStatus: LoadingStatus = LoadingStatus.LOADING;

  @observable private _loadingCompetitorStatus: LoadingStatus = LoadingStatus.LOADING;

  constructor() {
    makeObservable(this);
  }

  @computed
  get competitorList() {
    return this._competitorList;
  }

  @computed
  get selectedCompetitor() {
    return this._selectedCompetitor;
  }

  @computed
  get loadingStatus() {
    return this._loadingStatus;
  }

  @computed
  get loadingCompetitorStatus() {
    return this._loadingCompetitorStatus;
  }

  @action setLoadingStatus = (value: LoadingStatus) => {
    this._loadingStatus = value;
  };

  @action clear = () => {
    this._competitorList = [];
    this._selectedCompetitor = null;
    this._loadingStatus = LoadingStatus.LOADING;
    this._loadingCompetitorStatus = LoadingStatus.LOADING;
  };

  @action switchOnProcessedStatusCompetitor = (remainSelectedCompetitorId: number) => {
    this._competitorList.forEach((competitor) => {
      if (competitor.id !== remainSelectedCompetitorId && competitor.status === 'SELECTED') {
        competitor.setStatus('PROCESSED');
      }
    });
  };

  @action fetchList = async () => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getList();
      runInAction(() => {
        this._competitorList = data.map((competitor) => new CompetitorClass(competitor)) ?? [];
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
    }
  };

  @action fetchSelectedCompetitor = async () => {
    this._loadingCompetitorStatus = LoadingStatus.LOADING;
    try {
      const { data } = await getSelected();
      runInAction(() => {
        this._selectedCompetitor = new CompetitorCalendarClass(data[0]);
        this._loadingCompetitorStatus = LoadingStatus.SUCCESS;
      });
    } catch (e) {
      this._loadingCompetitorStatus = LoadingStatus.ERROR;
    }
  };

  @action fetchCreate = async (data: ICreateRequestCompetitor): Promise<boolean> => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data: responseData } = await create(data);
      runInAction(() => {
        this._competitorList.push(new CompetitorClass(responseData));
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch (e) {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  @action fetchDelete = async (id: number): Promise<boolean> => {
    try {
      const response = await deleteById(id);
      if (response.status !== 204) {
        throw 'something wrong';
      }
      runInAction(() => {
        this._competitorList = this._competitorList.filter((competitor) => competitor.id !== id);
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}

export default CompetitorStore;
