import { useCallback, useEffect, useState } from 'react';
import {
  findCurrentResource,
  findFirstChildWithClass,
  findNextResource,
} from '../../component/planning-calendar/PlanningCalendar';
import { CampaignMetricForCalendarClass } from '../../slass/calendar/CampaignMetricForCalendarClass';
import { MarketingChannelForCalendarClass } from '../../slass/calendar/MarketingChannelForCalendarClass';
import { getResourceFromLocalStorage, setResourceFromLocalStorage } from '../../component/planning-calendar/utils';
import { CalendarClass } from '../../slass/calendar/CalendarClass';
import marketingChannel from '../../page/marketing-pillars/MarketingChannel';

//
export const refreshResources = (calendarRef) => {
  if (!calendarRef?.current) {
    return;
  }

  calendarRef.current.getApi().refetchResources();

  // calendar caches data, so we need to setAttribute manually (resourceLabelDidMount doesn't trigger on extendedProps)
  calendarRef.current
    .getApi()
    .getResources()
    .forEach((resource) => {
      if (resource.extendedProps?.rowspan) {
        findCurrentResource(resource.id)?.setAttribute('rowspan', resource.extendedProps.rowspan);
      }
      if (resource.extendedProps?.className) {
        findFirstChildWithClass(findNextResource(resource.id))?.classList?.add(resource.extendedProps.className);
      } else {
        findFirstChildWithClass(findNextResource(resource.id))?.classList?.remove('has-hidden-metric');
      }
    });
};

// move to utils
const useCollapseCalendarButton = (calendarRef) => {
  // const [isCollapsed, setIsCollapsed] = useState(localStorage.getItem('isCollapsedCalendar') === 'true');
  // const [isRendered, setIsRendered] = useState(false);
  //
  // // // init change for collapse but only 1 and when we have calendarRef
  // useEffect(() => {
  //   if (!isRendered && calendarRef.current) {
  //     if (isCollapsed) {
  //       wrap();
  //     }
  //
  //     setIsRendered(true);
  //   }
  // });
  //
  // useEffect(() => {
  //   if (!!calendarRef?.current) {
  //     if (!isCollapsed) {
  //       unwrap();
  //       localStorage.setItem('isCollapsedCalendar', 'false');
  //     } else {
  //       wrap();
  //       localStorage.setItem('isCollapsedCalendar', 'true');
  //     }
  //   }
  // }, [isCollapsed]);
  //
  // const unwrap = () => {
  //   calendarRef.current.getApi().refetchResources();
  //
  //   calendarRef.current
  //     .getApi()
  //     .getResources()
  //     .forEach((resource) => {
  //       if (resource.extendedProps?.rowspan) {
  //         findCurrentResource(resource.id)?.setAttribute('rowspan', resource.extendedProps.rowspan);
  //       }
  //     });
  // };
  //
  // const wrap = () => {
  //   calendarRef.current
  //     .getApi()
  //     .getResources()
  //     .forEach((resource) => {
  //       if (
  //         resource.extendedProps?.marketingPillarInstance &&
  //         resource.extendedProps?.marketingPillarInstance instanceof MarketingChannelForCalendarClass
  //       ) {
  //         resource.remove();
  //       } else if (resource.extendedProps?.rowSpanCollapsed) {
  //         findCurrentResource(resource.id)?.setAttribute('rowspan', resource.extendedProps.rowSpanCollapsed);
  //       }
  //     });
  // };

  const onCollapseCallback = useCallback((instance: CalendarClass) => {
    const isCollapsed = localStorage.getItem('isCollapsedCalendar') === 'true';

    getResourceFromLocalStorage();

    const fillMetric = (campaignMetricsList: CampaignMetricForCalendarClass[]) => {
      return campaignMetricsList.reduce((state, campaignMetrics) => {
        state[campaignMetrics.id] = {
          isHidden: !isCollapsed,
        };
        return state;
      }, {});
    };

    const dataForResource = instance.marketingPillars.reduce((state, currentValue) => {
      if (currentValue.hasMarketingChannels) {
        currentValue.marketingChannels.forEach((marketingChannel) => {
          state[marketingChannel.id] = { metricMap: fillMetric(marketingChannel.campaignMetricsList) };
        });
      } else {
        state[currentValue.id] = {
          metricMap: fillMetric(currentValue.campaignMetricsList),
        };
      }

      return state;
    }, {});

    setResourceFromLocalStorage(dataForResource);
    localStorage.setItem('isCollapsedCalendar', `${!isCollapsed}`);
    refreshResources(calendarRef);

    // debugger;
    // setIsCollapsed((prevState) => !prevState);
  }, []);

  // recalculate after reInit calendar
  // const onMountHandler = () => {
  //   // wait when calendarRef is ready
  //   // todo write to the normal logic
  //   setTimeout(() => {
  //     if (isCollapsed && calendarRef.current) {
  //       wrap();
  //     }
  //   }, 0);
  // };

  return { onCollapseCallback };
};

export default useCollapseCalendarButton;
