import React, { useEffect, useReducer, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from '@mui/material';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/joy/CircularProgress';
import { LoadingStatus } from '../../interface';
import {
  EnumUnitOfCampaign,
  ICampaignMetricForCalculation,
  IRequestPatchCampaignMetricProps,
} from '../../interface/campaignMetricInterface';
import MainStore from '../../store/MainStore';
import { CampaignMetricsClass } from '../../slass/data/campaign-metrics/CampaignMetricsClass';
import { getCampaignMetricForCalculation } from '../../service/campaignMetricsService';

type State = Required<IRequestPatchCampaignMetricProps>;
type ActionType = keyof State;

const marketingReducer = (state: State, action: { type: ActionType; payload: any }): State => {
  const { type, payload } = action;
  switch (type) {
    case 'title':
      return {
        ...state,
        title: payload,
      };
    case 'description':
      return {
        ...state,
        description: payload,
      };
    case 'type':
      return {
        ...state,
        type: payload,
      };
    case 'value':
      return {
        ...state,
        value: payload < 0 ? 0 : payload,
      };
    case 'metric_for_calculation':
      return {
        ...state,
        metric_for_calculation: payload,
      };
    case 'unit':
      return {
        ...state,
        unit: payload,
      };
    // case 'channels':
    //   return {
    //     ...state,
    //     channels: payload,
    //   };
    case 'has_total':
      return {
        ...state,
        has_total: payload,
      };
    case 'total_title':
      return {
        ...state,
        total_title: payload,
      };
    default:
      return state;
  }
};

const CampaignMetricModal = ({
  onCloseModalHandler,
  instance,
  dataList,
}: {
  instance?: CampaignMetricsClass;
  onCloseModalHandler: () => void;
  dataList?: CampaignMetricsClass[];
}) => {
  const { fetchCreate: fetchCreateCampaignMetric, loadingStatus } = MainStore.campaignMetricsStore;
  const [loadingStatusByCalculation, setLoadingStatusByCalculation] = useState<LoadingStatus>(LoadingStatus.SUCCESS);
  const [calculatedTypeList, setCalculatedTypeList] = useState<ICampaignMetricForCalculation[]>([]);

  const initState: State = {
    title: instance?.title ?? '',
    description: instance?.description ?? '',
    unit: instance?.unit ?? 'number',
    type: instance?.type ?? 'custom',
    value: instance?.value ?? 0,
    metric_for_calculation: instance?.metricForCalculation ?? null,
    // channels: instance?.channels ?? true,
    has_total: instance?.hasTotal ?? true,
    total_title: instance?.totalTitle ?? '',
  };

  const [state, dispatch] = useReducer(marketingReducer, initState);

  useEffect(() => {
    if (state.type === 'calculated_by_product' && !calculatedTypeList.length) {
      setLoadingStatusByCalculation(LoadingStatus.LOADING);
      getCampaignMetricForCalculation()
        .then(({ data }) => {
          if (instance) {
            const foundMetric = data.find((metric) => metric.id === instance?.metricForCalculation);
            if (!foundMetric) {
              // const matchMetric = dataList.find((metricClass) => metricClass.id);
              // if (matchMetric) data.push({ id: matchMetric.id, title: matchMetric.title, type: matchMetric.type });
              data = data.filter((metric) => metric.id !== instance.id);
            }
          }

          setCalculatedTypeList(data);
          setLoadingStatusByCalculation(LoadingStatus.SUCCESS);
        })
        .catch(() => {
          setLoadingStatusByCalculation(LoadingStatus.ERROR);
        });
    }
  }, [state.type, calculatedTypeList, instance, dataList]);

  const onHandleChangeInput = (type: ActionType) => (event) => {
    dispatch({
      type,
      payload: event.target.value,
    });
  };

  const onHandleChangeSwitch = (type: ActionType) => (event) => {
    dispatch({
      type,
      payload: event.target.checked,
    });
  };

  const onSaveButtonHandle = () => {
    const fn = instance?.fetchPatch || fetchCreateCampaignMetric;
    const stateForRequest: IRequestPatchCampaignMetricProps = {
      title: state.title,
      description: state.description,
      unit: state.unit,
      type: state.type,
      ...(state.type === 'calculated_by_product' ? { metric_for_calculation: state.metric_for_calculation } : {}),
      // ...(state.type === 'system' ? { value: Number(state.value) ?? 0 } : {}),
      // channels: state.channels,
      has_total: state.has_total,
      ...(state.has_total ? { total_title: state.total_title } : {}),
    };
    fn(stateForRequest).then((isSuccess) => {
      if (isSuccess) {
        onCloseModalHandler();
      }
    });
  };

  const isLoading = (instance?.loadingStatus || loadingStatus) === LoadingStatus.LOADING;

  return (
    <>
      <Box sx={{ margin: '25px 0' }}>
        <TextField
          id="standard-basic"
          placeholder="Title"
          variant="standard"
          sx={{ width: '100%' }}
          value={state.title}
          onChange={onHandleChangeInput('title')}
        />
      </Box>
      <Box sx={{ margin: '25px 0' }}>
        <TextField
          id="standard-basic"
          label="Description"
          multiline
          variant="outlined"
          rows={4}
          sx={{ width: '100%' }}
          value={state.description}
          onChange={onHandleChangeInput('description')}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', margin: '25px 0', gap: '40px' }}>
        <Typography className="labelSetting" component="span" sx={{ display: 'inline-block', width: '50px' }}>
          Type
        </Typography>
        <Select
          labelId="demo-simple-select-label-type"
          id="demo-simple-select-type"
          value={state.type}
          onChange={onHandleChangeInput('type')}
          sx={{ width: '150px', height: '40px' }}
          disabled
        >
          <MenuItem value="custom">custom</MenuItem>
          {/*<MenuItem value="system">system</MenuItem>*/}
          <MenuItem value="calculated_by_product">calculated</MenuItem>
        </Select>
        {/*{state.type === 'system' && (*/}
        {/*  <OutlinedInput*/}
        {/*    id="standard-basic"*/}
        {/*    // label="Value"*/}
        {/*    placeholder="Value"*/}
        {/*    type="number"*/}
        {/*    value={state.value}*/}
        {/*    onChange={onHandleChangeInput('value')}*/}
        {/*    sx={{ width: '200px', height: '40px' }}*/}
        {/*  />*/}
        {/*)}*/}
        {state.type === 'calculated_by_product' &&
          loadingStatusByCalculation === LoadingStatus.SUCCESS &&
          !!calculatedTypeList.length && (
            <FormControl required size="small" sx={{ position: 'relative' }}>
              <InputLabel id="CampaignMetricModal_metric_for_calculation-label">Campaign metric</InputLabel>
              <Select
                labelId="CampaignMetricModal_metric_for_calculation-label"
                id="demo-simple-select-type"
                value={state.metric_for_calculation}
                onChange={onHandleChangeInput('metric_for_calculation')}
                sx={{ width: '200px', height: '40px' }}
                disabled
              >
                {calculatedTypeList.map((product) => (
                  <MenuItem key={`CampaignMetricModal_metric_for_calculation_${product.id}`} value={product.id}>
                    {product.title}
                  </MenuItem>
                ))}
              </Select>
              {!state.metric_for_calculation && (
                <FormHelperText sx={{ position: 'absolute', bottom: -20, left: 0 }}>Required</FormHelperText>
              )}
            </FormControl>
          )}
        {state.type === 'calculated_by_product' && loadingStatusByCalculation === LoadingStatus.LOADING && (
          <CircularProgress color="primary" variant="soft" value={30} size="sm" />
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '25px 0', gap: '40px' }}>
        <Typography className="labelSetting" component="span" sx={{ display: 'inline-block', width: '50px' }}>
          Unit
        </Typography>
        <Select
          labelId="demo-simple-select-label-unit"
          id="demo-simple-select-unit"
          value={state.unit}
          onChange={onHandleChangeInput('unit')}
          sx={{ width: '150px', height: '40px' }}
        >
          <MenuItem value="price">{EnumUnitOfCampaign.price}</MenuItem>
          <MenuItem value="number">{EnumUnitOfCampaign.number}</MenuItem>
        </Select>
      </Box>

      {/*<Box sx={{ margin: '25px 0', display: 'flex', alignItems: 'center', height: '40px' }}>*/}
      {/*  <FormGroup sx={{ display: 'inline-flex' }} className="labelSetting">*/}
      {/*    <FormControlLabel*/}
      {/*      control={<Switch checked={state.channels} onChange={onHandleChangeSwitch('channels')} />}*/}
      {/*      labelPlacement="start"*/}
      {/*      label="Channels"*/}
      {/*      sx={{ marginLeft: 0, display: 'flex', gap: '10px' }}*/}
      {/*    />*/}
      {/*  </FormGroup>*/}
      {/*</Box>*/}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '154px', margin: '25px 0', height: '40px' }}>
        <FormGroup sx={{ display: 'inline-flex' }} className="labelSetting">
          <FormControlLabel
            control={<Switch checked={state.has_total} onChange={onHandleChangeSwitch('has_total')} />}
            labelPlacement="start"
            label="Totals"
            sx={{ marginLeft: 0, display: 'flex', gap: '31px' }}
          />
        </FormGroup>
        {state.has_total && (
          <OutlinedInput
            id="has_total-basic"
            // label="Value"
            placeholder="Title"
            value={state.total_title}
            onChange={onHandleChangeInput('total_title')}
            sx={{ maxWidth: '200px', height: '40px' }}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModalHandler}>
          Cancel
        </Button>
        <Button
          variant="solid"
          onClick={onSaveButtonHandle}
          // disabled={!Object.keys(instance.changedFields).length}
          loading={isLoading}
        >
          Save
        </Button>
      </Box>
    </>
  );
};
export default CampaignMetricModal;
