import React, { useState } from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { FormFields, loginValidationSchema } from '../../auth/loginConstant';
import scss from '../SettingsPage.module.scss';
import { ICreateUser } from '../../../interface';
import SelectCompany from './SelectCompany';

const AddUserModal = ({
  onCloseModalHandler,
  fetchCreateUser,
  loadingStatus,
}: {
  onCloseModalHandler: () => void;
  fetchCreateUser: (createUserData: ICreateUser) => Promise<boolean>;
  loadingStatus: any;
}) => {
  const [isBadFetchingResponse, setIsBadFetchingResponse] = useState(false);
  const [loginError, setlLoginError] = useState('');
  const formik = useFormik({
    initialValues: {
      [FormFields.EMAIL]: '',
      [FormFields.PASSWORD]: '',
      first_name: '',
      last_name: '',
      company: null,
      groups: [],
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSubmitHandler(values);
    },
  });

  const onSubmitHandler = (value) => {
    const createUserData: ICreateUser = {
      email: value[FormFields.EMAIL],
      password: value[FormFields.PASSWORD],
      first_name: value.first_name,
      last_name: value.last_name,
      company: value.company,
      groups: value.groups,
    };

    fetchCreateUser(createUserData).then((isSuccess) => {
      if (isSuccess) {
        onCloseModalHandler();
      } else {
        formik.setSubmitting(false);
      }
    });
  };

  const { handleSubmit, values, handleChange, errors, touched } = formik;

  const isEmailError = touched.email && Boolean(errors.email);

  const isPasswordError = touched.password && Boolean(errors.password);

  const buttonDisabled = !values.email.length || isPasswordError || isEmailError;

  const onHandleChange = (e) => {
    handleChange(e);
    setIsBadFetchingResponse(false);
    setlLoginError(null);
  };

  const onHandleChangeSelect = (id: string, name: string) => (e, value) => {
    if (e) {
      handleChange({ target: { id, name, value } });
      setIsBadFetchingResponse(false);
      setlLoginError(null);
    }
  };

  return (
    <>
      {/*<Fade in={!!isBadFetchingResponse}>*/}
      {/*  <Typography color="error" className={styles.errorMessage}>*/}
      {/*    {loginError || 'Something is wrong'}*/}
      {/*  </Typography>*/}
      {/*</Fade>*/}
      <Box sx={{ display: 'flex', width: '100%' }}>
        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '35px' }}>
          <Box sx={{ display: 'flex', width: '100%', gap: '25px' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
              <TextField
                id="first_name"
                name="first_name"
                value={formik.values.first_name}
                label="First name"
                variant="standard"
                sx={{ width: '100%' }}
                onChange={onHandleChange}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
              <TextField
                id="last_name"
                name="last_name"
                value={formik.values.last_name}
                label="Last name"
                variant="standard"
                sx={{ width: '100%', position: 'relative' }}
                onChange={onHandleChange}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', gap: '25px' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
              <TextField
                id={FormFields.EMAIL}
                name={FormFields.EMAIL}
                value={formik.values.email}
                label="Email"
                variant="standard"
                sx={{ width: '100%' }}
                onChange={onHandleChange}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
              <FormControl error={isPasswordError} sx={{ width: '100%' }}>
                <TextField
                  id={FormFields.PASSWORD}
                  name={FormFields.PASSWORD}
                  value={formik.values.password}
                  label="Password"
                  variant="standard"
                  sx={{ width: '100%', position: 'relative' }}
                  onChange={onHandleChange}
                />
                {isPasswordError && (
                  <FormHelperText id="component-error-text" className={scss.formHelperText}>
                    {formik.touched.password && formik.errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>

          <SelectCompany value={formik.values.company} onChange={onHandleChangeSelect('company', 'company')} />
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '46px', gap: '10px' }}>
            <Button
              disabled={buttonDisabled}
              loading={formik.isSubmitting}
              color="primary"
              variant="solid"
              fullWidth
              type="submit"
              size="lg"
            >
              Add User
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddUserModal;
