import React from 'react';
import { observer } from 'mobx-react-lite';
import CampaignMetricsGrid from './CampaignMetricsGrid';

const CampaignMetricsPage = () => (
  <section className="section">
    <CampaignMetricsGrid />
  </section>
);

export default observer(CampaignMetricsPage);
