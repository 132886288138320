import React, { memo } from 'react';
import { DateOrString } from '@nivo/calendar/dist/types/types';
import dayjs from 'dayjs';
import { Box, Paper } from '@mui/material';
import Typography from '@mui/joy/Typography';
import BarBlockWrapper from '../bar/BarBlockWrapper';
import CalendarChart from './CalendarChart';
import scss from '../DashboardPage.module.scss';

interface IProps {
  data: {
    day: string;
    value: number;
    labelList: string[];
  }[];
  from: DateOrString;
  to: DateOrString;
}
const DeadlineChartWrapper = ({ data, from, to }: IProps) => (
    <BarBlockWrapper
      header={
        <Typography level="h6" component="h6" sx={{ fontWeight: 500 }}>
          Deadlines
        </Typography>
      }
      chart={
        <CalendarChart
          data={data}
          from={from}
          to={to}
          settings={{
            tooltip: (props, e) => (
              <Paper elevation={1} className={scss.chartTooltip}>
                <Box className={scss.chartTooltip_titleWrapper}>
                  Date: <span>{dayjs(props.day, 'YYYY-MM-DD').format('MM-DD-YY')}</span>
                </Box>
                <Box />
                {props.data.labelList?.map((propsLabel) => (
                  <span key={`DeadlineChartWrapper_tooltip_label_${propsLabel}`}>{propsLabel}</span>
                ))}
              </Paper>
            ),
          }}
        />
      }
      minWidth="450px"
      selectedTotalOptionList=""
    />
  );

export default memo(DeadlineChartWrapper);
