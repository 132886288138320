import { useCallback, useState } from 'react';
import useResizeWindow from '../../../utils/hooks/useResizeWindow';

const useVisibleCalendar = () => {
  const [visible, setVisible] = useState<Boolean>(true);

  const rebuildCalendar = useCallback(() => {
    setVisible(false);
    setTimeout(() => {
      setVisible(true);
    }, 0);
  }, []);
  useResizeWindow(rebuildCalendar);

  return { visible, setVisible };
};

export default useVisibleCalendar;
