import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import * as NivoColors from '@nivo/colors';
import { Box, Paper, Stack } from '@mui/material';
import Typography from '@mui/joy/Typography';
import { Autocomplete } from '@mui/joy';
import Close from '@mui/icons-material/Close';
import { observer } from 'mobx-react-lite';
import Chip from '@mui/joy/Chip';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';

import BarChart from './BarChart';
import scss from '../DashboardPage.module.scss';
import BarBlockWrapper from './BarBlockWrapper';
import getDataElement from '../../../utils/getDataElementIndexByList';
import getDataElementIndexByList from '../../../utils/getDataElementIndexByList';

const nivoColors = NivoColors.colorSchemes.set2 as string[];
// const color = getColor('category10', index);
// const COLORS = [
//   '#ffa69e',
//   '#f7d1cd',
//   '#a9f0d1',
//   '#abc8c7',
//   '#faa275',
//   '#f4d06f',
//   '#9dd9d2',
//   '#d1b3c4',
//   '#b8e2c8',
//   '#c9a690',
//   '#e0ff4f',
//   '#a3bcf9',
//   '#a6a2a2',
//   '#85b79d',
//   '#78c0e0',
// ];

interface IProps {
  instance: CalendarClass;
}

const TotalsBarWrapper = ({ instance }: IProps) => {
  const [selectedTotalOptionList, setSelectedTotalOptionList] = useState<{ label: string; id: string }[]>([]);

  const autocompleteOptions = useMemo(
    () =>
      instance.totals.reduce((state: { label: string; id: string }[], total) => {
        if (Number(total.id)) {
          state.push({ label: total.title, id: total.id });
        }

        return state;
      }, []),
    [instance],
  );

  const data = useMemo(() => {
    const mapData = instance.totals.reduce(
      (dateState: Map<number, Record<string, number | dayjs.Dayjs | string>>, currentValue, currentValueIndex) => {
        const foundTotalIndex = selectedTotalOptionList.findIndex((totalOption) => totalOption.id === currentValue.id);

        if (~foundTotalIndex) {
          currentValue.values.forEach((totalEvent) => {
            const totalEventDate = dayjs(totalEvent.forDate, 'YYYY-MM-DD');
            const totalEventDateUnix = totalEventDate.unix();
            if (dateState.has(totalEventDateUnix)) {
              dateState.get(totalEventDateUnix)[currentValue.title] = totalEvent.value;
            } else {
              dateState.set(totalEventDateUnix, { [currentValue.title]: totalEvent.value, date: totalEventDate });
            }
          });
        }

        return dateState;
      },
      new Map(),
    );

    return Array.from(mapData)
      .sort(([keyA], [keyB]) => keyA - keyB)
      .map(([_, value]) => {
        value.date = (value.date as dayjs.Dayjs).format('MM-DD-YYYY');
        return value as Record<string, number | string>;
      });
  }, [selectedTotalOptionList, instance]);

  const keys = useMemo(
    () => selectedTotalOptionList.map((selectedTotalOption) => selectedTotalOption.label),
    [selectedTotalOptionList],
  );

  const onChangeAutocomplete = (e, value) => {
    setSelectedTotalOptionList(value);
  };

  return (
    <BarBlockWrapper
      header={
        <Stack spacing={2}>
          <Typography level="h6" component="h6" sx={{ fontWeight: 500 }}>
            Totals
          </Typography>
          <Autocomplete
            multiple
            id="TotalProjectSalesBarWrapper_Autocomplete_tags-Total"
            placeholder="Totals"
            size="sm"
            // defaultValue={[autocompleteOptions?.find((selectedTotalOpt) => selectedTotalOpt.id === '2')]}
            value={selectedTotalOptionList}
            getOptionLabel={(option) => (option as { label: string; id: string }).label}
            options={autocompleteOptions}
            onChange={onChangeAutocomplete}
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => {
                const chipColor = getDataElementIndexByList<string>(index, nivoColors);
                return (
                  <Chip
                    variant="soft"
                    size="sm"
                    className={scss.totalsSelect}
                    sx={{ backgroundColor: `${chipColor}` }}
                    endDecorator={<Close fontSize="medium" />}
                    {...getTagProps({ index })}
                  >
                    {item?.label}
                  </Chip>
                );
              })
            }
          />
        </Stack>
      }
      chart={
        <BarChart
          data={data}
          keys={keys}
          settings={{
            indexBy: 'date',
            tooltip: (props) => (
              <Paper elevation={1} className={scss.chartTooltip}>
                <Box className={scss.chartTooltip_titleWrapper}>
                  <Box className={scss.chartTooltip_color} sx={{ backgroundColor: props.color }} />
                  <span>{props.id}</span>
                </Box>
                <Box>
                  Date: <span>{props.data.date}</span>
                </Box>
                <Box>
                  Value: <span>{props.value}</span>
                </Box>
              </Paper>
            ),
            margin: { top: 10, right: 10, bottom: 60, left: 50 },
          }}
        />
      }
      minWidth="900px"
      selectedTotalOptionList={selectedTotalOptionList}
    />
  );
};

export default observer(TotalsBarWrapper);
