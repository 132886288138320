import React, { useState } from 'react';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import { ModalClose, ModalDialog } from '@mui/joy';
import DefaultModal from '../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../component/ui/default-modal/DefaultChildrenWrapperModal';
import CompetitorModal from './CompetitorModal';

const DataUpload = () => {
  const [isOpenCompetitorUploadModal, seIsOpenCompetitorUploadModal] = useState(false);
  const [isOpenHelp, setIsOpenHelp] = React.useState<boolean>(false);

  const onCompetitorUploadModal = (value: boolean) => () => {
    seIsOpenCompetitorUploadModal(value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography
        component="h2"
        sx={{
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '32px',
          letterSpacing: '0.25px',
        }}
      >
        Upload Competitor`s Historical Sales and Performance
      </Typography>
      <Typography
        component="span"
        sx={
          {
            // fontWeight: 500,
            // fontSize: '20px',
            // lineHeight: '32px',
            // letterSpacing: '0.25px',
          }
        }
      >
        Create competitors historical calendars by uploading all the corresponding data using via CSV file. Use our
        custom{' '}
        <a
          href="/COMPETITOR_IMPORT_TEMPLATE.csv"
          download="Competitor_import_template.csv"
          style={{ textDecoration: 'underline' }}
        >
          CSV file template
        </a>
      </Typography>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Button variant="solid" onClick={onCompetitorUploadModal(true)}>
          Upload
        </Button>
        <Button variant="outlined" onClick={() => setIsOpenHelp(true)}>
          Help
        </Button>
      </Box>
      <DefaultModal open={isOpenCompetitorUploadModal}>
        <DefaultChildrenWrapperModal modalTitle="Upload competitor" onCloseModal={onCompetitorUploadModal(false)}>
          <CompetitorModal onCloseModalHandler={onCompetitorUploadModal(false)} />
        </DefaultChildrenWrapperModal>
      </DefaultModal>
      <Modal open={isOpenHelp} onClose={() => setIsOpenHelp(false)}>
        <ModalDialog size="lg">
          <ModalClose />
          <Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
            Have questions?
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary" level="h5">
            We're here to help.{' '}
            <Link underline="always" href="mailto:support@strataplan.com">
              {' '}
              support@strataplan.com
            </Link>
          </Typography>
        </ModalDialog>
      </Modal>
    </Box>
  );
};

export default DataUpload;
