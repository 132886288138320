import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import cn from 'classnames';
import { passwordResetConfirm, validateTokenResetConfirm } from '../../service/authService';
import ChangePasswordForm from '../../component/auth/ChangePasswordForm';
import scss from './LoginPage.module.scss';
import { addSlash, MainRoutesPath } from '../../interface/router';
import { LoadingStatus } from '../../interface';
import Loader from '../../component/ui/loader/Loader';

const TOKEN = 'token';

const ResetPasswordPage = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);

  useEffect(() => {
    if (!searchParams.has(TOKEN)) {
      navigate(addSlash(MainRoutesPath.NOT_FOUND));
    } else {
      validateTokenResetConfirm(searchParams.get(TOKEN))
        .then(() => {
          setLoadingStatus(LoadingStatus.SUCCESS);
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.ERROR);
        });
    }
  }, [searchParams, navigate]);

  const onSubmitHandler = (value: string) => {
    return passwordResetConfirm({ password: value, token: searchParams.get(TOKEN) }).then((data) => {
      if (data) {
        navigate(addSlash(MainRoutesPath.LOGIN_PAGE));

        return true;
      }
      return false;
    });
  };

  return (
    <main className={scss.main}>
      <section className={scss.wrapper}>
        {loadingStatus === LoadingStatus.LOADING && <Loader />}
        {loadingStatus === LoadingStatus.ERROR && <h1>ERROR 500</h1>}
        {loadingStatus === LoadingStatus.SUCCESS && (
          <Box className={cn(scss.login, scss.login_wrapper)}>
            <h1>RESET PASSWORD</h1>
            <ChangePasswordForm onSubmitHandler={onSubmitHandler} />
          </Box>
        )}
      </section>
    </main>
  );
};

export default ResetPasswordPage;
