import React from 'react';
import ReactJsonViewCompare from 'react-json-view-compare';

interface IProps {
  oldData?: Record<string, any>;
  newData?: Record<string, any>;
}
const JsonViewCompare = ({ oldData = {}, newData = {} }: IProps) => <ReactJsonViewCompare oldData={oldData} newData={newData} />;

export default JsonViewCompare;
