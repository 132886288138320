import { action, computed, makeObservable, observable } from 'mobx';
import { ProductBaseClass } from '../data/product/ProductBaseClass';

type Category = {
  id: number;
  title: string;
};

export class ProductForCalendarClassBase {
  private readonly _categories: Category[];

  private readonly _id: number;

  @observable private _title: string;

  @observable private _description: string;

  constructor(props) {
    makeObservable(this);
    this._id = props.id;
    this._title = props.title;
    this._description = props.description;
    this._categories = props.categories;
  }

  get categories() {
    return this._categories;
  }

  get id() {
    return this._id;
  }

  @computed get description() {
    return this._description;
  }

  @computed get title() {
    return this._title;
  }

  @action setTitle = (value: string) => {
    this._title = value;
  };

  @action setDescription = (value: string) => {
    this._description = value;
  };
}
