import {
  ICalendarResponse,
  IResponseCreateShareLink,
  IResponsePlanningCalendarShareCode,
  StartWeekDay,
} from '../interface/calendarInterface';
import axiosInstance, { IRequest } from './axiosService';

export const getPlanningCalendar = (start_date?: string, end_date?: string): Promise<IRequest<ICalendarResponse>> =>
  axiosInstance.get('/planning-calendar', { params: { start_date, end_date } }).then(({ data }) => data);

export const getSharePlanningCalendar = (shareId: string): Promise<IRequest<ICalendarResponse>> =>
  axiosInstance.get(`/planning-calendar/share/${shareId}/`).then(({ data }) => data);

export const createSharePlanningCalendarLink = (
  startDate: string,
  endDate: string,
): Promise<IRequest<IResponseCreateShareLink>> =>
  axiosInstance.post(`/planning-calendar/share/?start_date=${startDate}&end_date=${endDate}`).then(({ data }) => data);

export const getPlanningCalendarShareCode = (): Promise<IRequest<IResponsePlanningCalendarShareCode[]>> =>
  axiosInstance.get('/planning-calendar/share/').then(({ data }) => data);
