import { makeObservable, computed, observable, action, runInAction } from 'mobx';
import { BaseMetricsForCalendarClass } from './BaseMetricsForCalendarClass';
import { IMarketingCampaign } from '../../interface/calendarInterface';
import { MetricForCalendarClass } from './MetricForCalendarClass';
import { patchById } from '../../service/marketingCampaignService';
import dayjs from 'dayjs';

export class MarketingCampaignForCalendarClass extends BaseMetricsForCalendarClass {
  @observable private _startDate: string;

  @observable private _endDate: string;

  private _deadlineDate: string;

  @observable private _metrics: MetricForCalendarClass[] = [];

  private _budget: number;

  private _calculatedBudget: number;

  constructor(data: IMarketingCampaign) {
    super({ id: data.id, title: data.title });
    makeObservable(this);
    this.setData(data);
  }

  @action private setData = (data: IMarketingCampaign) => {
    this._startDate = data.start_date;
    this._endDate = data.end_date;
    this._budget = data.budget;
    this._calculatedBudget = data.calculated_budget;
    this._deadlineDate = data.deadline_date;
    this._metrics = data.metrics && Object.values(data.metrics).map((metric) => new MetricForCalendarClass(metric));
  };

  @computed
  get metricsList() {
    return this._metrics;
  }

  @computed get endDate() {
    return this._endDate;
  }

  @computed get startDate() {
    return this._startDate;
  }

  get budget() {
    return this._budget;
  }

  get calculatedBudget() {
    return this._calculatedBudget;
  }

  get deadlineDate() {
    return this._deadlineDate;
  }
  @action setEndDate(endDate: string) {
    this._endDate = endDate;
  }

  @action setStartDate(startDate: string) {
    this._startDate = startDate;
  }

  setNewDate = async (startDate: Date, endDate: Date) => {
    const { data } = await patchById(this._id, {
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
      action: 'drag_and_drop',
    });
    runInAction(() => {
      this._startDate = data.start_date;
      this._endDate = data.end_date;
    });
  };
}
