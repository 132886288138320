import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import axiosInstance, { IRequest } from './axiosService';
import { AUTH_ENDPOINT, IUserInfo, REFRESH_ENDPOINT, TokensNameInStorage } from '../interface';

export interface ILoginResponse {
  access: string;
  refresh: string;
}

export interface ILoginAsResponse {
  accessToken: string;
}

export interface IRefresh {
  access: string;
}

const { REACT_APP_BASE_URL } = process.env;

export const login = (email: string, password: string): Promise<IRequest<ILoginResponse>> =>
  axios
    .post(`${REACT_APP_BASE_URL}${AUTH_ENDPOINT}`, {
      email,
      password,
    })
    .then(({ data }) => data);

// axiosInstance
//   .post(
//     AUTH_ENDPOINT,
//     {
//       email,
//       password,
//     },
//     {
//       // headers: {
//       //   'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
//       // },
//       withCredentials: true,
//       headers: {
//         Authorization: '',
//       },
//     },
//   )
//   .then(({ data }) => data);

export const refresh = (): Promise<IRequest<IRefresh>> =>
  axiosInstance
    .post(`${REFRESH_ENDPOINT}`, { refresh: localStorage.getItem(TokensNameInStorage.refresh) })
    .then(({ data }) => data);

export const getUserInfo = (): Promise<IRequest<IUserInfo>> =>
  axiosInstance.get('/user/info/').then(({ data }) => data);

export const loginUserAs = (userId: number | string): Promise<IRequest<ILoginAsResponse>> =>
  axiosInstance.post(`/user/${userId}/login_as/`).then(({ data }) => data);

export const forgotPassword = (email: string): Promise<IRequest<any>> =>
  axiosInstance.post(`/password_reset/`, { email }).then(({ data }) => data);

export const validateTokenResetConfirm = (token: string): Promise<IRequest<any>> =>
  axiosInstance.post(`/password_reset/validate_token/`, { token }).then(({ data }) => data);

export const passwordResetConfirm = (data: { token: string; password: string }): Promise<IRequest<any>> =>
  axiosInstance.post(`/password_reset/confirm/`, data).then(({ data }) => data);
