import React from 'react';
import { observer } from 'mobx-react-lite';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PlanningCalendarModalClass } from '../class/PlanningCalendarModalClass';
import dayjs from 'dayjs';
import { DateUtils } from '../../../utils/DateUtils';

interface IProps {
  instance: PlanningCalendarModalClass;
}

const DatePickerBlock = ({ instance }: IProps) => {
  const { startDate, setStartDate, endDate, setEndDate, deadlineDate, setDeadlineDate } = instance;

  const onChangeStartDate = (newValue: dayjs.Dayjs) => {
    DateUtils.startDateChangeChecker({ newValue, startDate, endDate }, setEndDate);
    setStartDate(newValue);
  };

  const onChangeEndDate = (newValue: dayjs.Dayjs) => {
    DateUtils.endDateChangeChecker({ newValue, startDate, endDate }, setStartDate);
    setEndDate(newValue);
  };

  return (
    <>
      <DatePicker label="Start date" value={startDate} onChange={onChangeStartDate} />
      <DatePicker label="End date" value={endDate} onChange={onChangeEndDate} />
      <DatePicker label="Deadline date" value={deadlineDate} onChange={setDeadlineDate} />
    </>
  );
};

export default observer(DatePickerBlock);
