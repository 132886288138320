import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import useTableHeight from '../../../utils/hooks/useTableHeight';
import useBaseGridBehavior from '../../../utils/hooks/useBaseGridBehavior';
import { ProductCategoryClass } from '../../../slass/data/product/ProductCategoryClass';
import Grid from '../../../component/ui/grid/Grid';
import Loader from '../../../component/ui/loader/Loader';
import DefaultModal from '../../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../../component/ui/default-modal/DefaultChildrenWrapperModal';
import MainStore from '../../../store/MainStore';
import ProductCategoryModal from './ProductCategoryModal';
import { CompanyClass } from '../../../slass/data/company/CompanyClass';
import useColumnsProductCategory from './hook/useColumnsProductCategory';
import DeleteModalContent from '../../../component/ui/delete-modal-content/DeleteModalContent';

interface IProps {
  productCategoryList: ProductCategoryClass[];
  companyList: CompanyClass[];
}

const ProductsCategoryGrid = ({ productCategoryList, companyList }: IProps) => {
  const { loadingStatus, fetchDelete } = MainStore.productCategoryStore;

  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();
  const { state, action } = useBaseGridBehavior();
  const { selectedComponent, isOpenDeleteComponentModal, isOpenNewComponentModal, isOpenModal } = state;
  const { onDeleteComponentModal, onCloseModal, onOpenEditComponentModal, setIsOpenNewComponentModal } = action;

  const columns: GridColDef[] = useColumnsProductCategory({ onDeleteComponentModal, onOpenEditComponentModal });

  const rows = productCategoryList
    .reduce((prevState, currentValue) => {
      const row = {
        id: currentValue.id,
        title: currentValue.title || '',
        // description: currentValue.description,
        company: companyList.find((company) => company.id === currentValue.company)?.title || '',
      };

      prevState.push(row);
      return prevState;
    }, [])
    .sort((a, b) => a.id - b.id);

  const productCategoryInstance = useMemo(
    () => productCategoryList.find((product) => product.id === selectedComponent),
    [productCategoryList, selectedComponent],
  );

  const isOpenEditModal = (selectedComponent === 0 || selectedComponent) && isOpenModal;

  return (
    <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
      {componentHeight ? <Grid sellQuantity={sellQuantity} columns={columns} rows={rows} /> : <Loader />}

      <DefaultModal open={isOpenEditModal || isOpenNewComponentModal}>
        <DefaultChildrenWrapperModal modalTitle="Change Product" onCloseModal={onCloseModal}>
          <ProductCategoryModal instance={productCategoryInstance} onCloseModal={onCloseModal} />
        </DefaultChildrenWrapperModal>
      </DefaultModal>
      <DefaultModal open={isOpenDeleteComponentModal}>
        <DeleteModalContent<ProductCategoryClass>
          onCloseModalHandler={onCloseModal}
          fetchDelete={fetchDelete}
          loadingStatus={loadingStatus}
          id={productCategoryInstance?.id}
          title={productCategoryInstance?.title}
        />
      </DefaultModal>
    </Box>
  );
};

export default observer(ProductsCategoryGrid);
