import React, { useCallback, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { LoadingStatus } from '../../interface';
import Loader from '../../component/ui/loader/Loader';
import MainStore from '../../store/MainStore';
import PlanningCalendar from '../../component/planning-calendar/PlanningCalendar';
import NotFoundPage from '../404/NotFoundPage';
import useVisibleCalendar from '../../component/planning-calendar/hook/useVisibleCalendar';
import useCollapseCalendarButton from '../../utils/hooks/useCollapseCalendarButton';

const ShareCalendarPage = () => {
  const calendarRef = useRef(null);
  const isVisible = useVisibleCalendar();
  const wrapperRef = useRef<HTMLInputElement>();
  const { calendarList, loadingStatus: calendarLoadingStatus } = MainStore.calendarStore;

  const shareId = useMatch('/share/:shareId')?.params?.shareId;

  useEffect(() => {
    MainStore.calendarStore.fetchShareCalendar(shareId);

    return () => {
      MainStore.calendarStore.clear();
    };
  }, [shareId]);

  const { onCollapseCallback } = useCollapseCalendarButton(calendarRef);

  const onMountHandlerCallback = useCallback(() => {
    // onMountHandler();
  }, []);

  if (!shareId || calendarLoadingStatus === LoadingStatus.ERROR) {
    return <NotFoundPage />;
  }

  return (
    <Box ref={wrapperRef} sx={{ height: '100%' }}>
      {calendarLoadingStatus === LoadingStatus.LOADING && <Loader />}
      {calendarLoadingStatus === LoadingStatus.SUCCESS && calendarList[0] && wrapperRef?.current && isVisible && (
        <PlanningCalendar
          instance={calendarList[0]}
          offsetHeight={wrapperRef?.current?.offsetHeight}
          isShare
          datesSet={onMountHandlerCallback}
          calendarRef={calendarRef}
          onCollapse={onCollapseCallback}
        />
      )}
    </Box>
  );
};

export default observer(ShareCalendarPage);
