import { action, computed, makeObservable, observable } from 'mobx';
import { IAlertsData, IChangelogData } from '../interface/notificationInterface';

class NotificationStore {
  @observable private _alertsList: IAlertsData[] = [];

  @observable private _changelogsList: IChangelogData[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  clear = () => {
    this._alertsList = [];
    this._changelogsList = [];
  };

  @computed
  get alertsList() {
    return this._alertsList;
  }

  @computed
  get alertsCount() {
    return this._alertsList.length;
  }

  @computed
  get changelogList() {
    return this._changelogsList;
  }

  @action addAlertItem = (alerts: IAlertsData[]) => {
    const alertsList = this._alertsList.filter((item) => !alerts.some((i) => i.id === item.id));
    this._alertsList = [...alerts, ...alertsList];
  };

  @action removeAlertItem = (ids: number[]) => {
    this._alertsList = this._alertsList.filter((item) => !ids.includes(item.id));
  };

  @action addChangelogItem = (changelogs: IChangelogData[]) => {
    const changelogsList = this._changelogsList.filter((item) => !changelogs.some((i) => i.id === item.id));
    this._changelogsList = [...changelogs, ...changelogsList];
  };

  @action removeChangelogItem = (ids: number[]) => {
    this._changelogsList = this._changelogsList.filter((item) => !ids.includes(item.id));
  };
}

export default NotificationStore;
