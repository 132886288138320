import React, { ReactElement } from 'react';
import scss from './ManagerContentWrapper.module.scss';
import Sidebar from '../sidebar/Sidebar';

interface IProps {
  children: ReactElement;
  style?: Record<any, any>;
}
const ManagerContentWrapper: React.FC<IProps> = ({ children, style = {} }) => (
  <div className={scss.appWrapper}>
    <Sidebar />
    <main className={scss.mainContent} style={style}>
      {children}
    </main>
  </div>
);

export default ManagerContentWrapper;
