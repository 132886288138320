import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import dayjs from 'dayjs';
import { ICalendarResponse, mapHiddenDays, Range, StartWeekDay } from '../../interface/calendarInterface';
import { MarketingPillarForCalendarClass } from './MarketingPillarForCalendarClass';
import { TotalsForCalendarClass } from './TotalsForCalendarClass';
import { AnnotationForCalendarClass } from './AnnotationForCalendarClass';
import { getPlanningCalendar } from '../../service/calendarService';
import { LoadingStatus } from '../../interface';

export class CalendarClass {
  @observable private _updateLoadingStatus: LoadingStatus = LoadingStatus.SUCCESS;

  private _range: Range;

  private _startWeekDay: StartWeekDay;

  private _startDate: string;

  private _endDate: string;

  private _budget: number = 0;

  private _calculatedBudget: number = 0;

  @observable private _datesList: string[];

  @observable private _annotations: AnnotationForCalendarClass[];

  @observable private _marketingPillars: MarketingPillarForCalendarClass[];

  @observable private _totals: TotalsForCalendarClass[];

  private _daysOfWeek = [0, 1, 2, 3, 4, 5, 6];

  @observable private _usedCampaignTitle: string[] = [];

  constructor(data: ICalendarResponse) {
    makeObservable(this);
    this.builder(data);
  }

  @action private builder = (data: ICalendarResponse) => {
    this._range = data.range;
    this._startWeekDay = data.start_week_day;
    this._startDate = data.start_date;
    this._endDate = data.end_date;
    this._datesList = data.dates_list;
    this._budget = data.budget;
    this._calculatedBudget = data.calculated_budget;

    this._marketingPillars =
      (data.marketing_pillars &&
        Object.values(data.marketing_pillars)
          ?.sort((a, b) => a.order - b.order)
          ?.map((marketingPillar) => new MarketingPillarForCalendarClass(marketingPillar))) ??
      [];

    this._totals =
      (data.totals &&
        Object.entries(data.totals)
          ?.sort((a, b) => a[1].order - b[1].order)
          ?.map(([id, total]) => new TotalsForCalendarClass(total, id))) ??
      [];

    this._annotations =
      (data.annotations &&
        Object.values(data.annotations)
          ?.sort((a, b) => a.order - b.order)
          ?.map((annotation) => new AnnotationForCalendarClass(annotation))) ??
      [];
  };

  get range() {
    return this._range;
  }

  get startWeekDay() {
    return this._startWeekDay;
  }

  get startDate() {
    return this._startDate;
  }

  get startDateAsDate() {
    return dayjs(this._startDate);
  }

  get endDateAsDate() {
    return dayjs(this._endDate);
  }

  get endDate() {
    return this._endDate;
  }

  @computed get datesList() {
    return this._datesList;
  }

  @computed get marketingPillars() {
    return this._marketingPillars;
  }

  @computed get annotations() {
    return this._annotations;
  }

  @computed get hiddenDays() {
    const usedDay = mapHiddenDays[this._startWeekDay];
    return this._daysOfWeek.filter((day) => day !== usedDay);
  }

  @computed get totals() {
    return this._totals;
  }

  @computed get usedCampaignTitle() {
    return this._usedCampaignTitle;
  }

  @computed get updateLoadingStatus() {
    return this._updateLoadingStatus;
  }

  get budget() {
    return this._budget;
  }

  get calculatedBudget() {
    return this._calculatedBudget;
  }

  @action setPushUsedCampaignTitle = (title: string) => {
    this._usedCampaignTitle.push(title);
  };

  @action setTotals = () => {
    this._totals = [];
  };

  @action setUpdateLoadingStatus = (loadingStatus: LoadingStatus) => {
    this._updateLoadingStatus = loadingStatus;
  };

  @action setDeleteUsedCampaignTitle = (title: string) => {
    const found = this._usedCampaignTitle.findIndex((campaignTitle) => campaignTitle === title);
    if (~found) {
      this._usedCampaignTitle.splice(found, 1);
    }
  };

  @action updateCalendar = async () => {
    this._updateLoadingStatus = LoadingStatus.LOADING;
    const { data } = await getPlanningCalendar(this._startDate, this._endDate);
    runInAction(() => {
      this.builder(data);
      this._updateLoadingStatus = LoadingStatus.SUCCESS;
    });
  };
}
