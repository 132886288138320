import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Link } from '@mui/material';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { enqueueSnackbar } from 'notistack';
import cn from 'classnames';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MainStore from '../../../store/MainStore';
import { LoadingStatus } from '../../../interface';
import scss from './Header.module.scss';
import { DateUtils } from '../../../utils/DateUtils';

const getUrlFromCode = (shareCode) => `${window.location.origin}/share/${shareCode}`;

const copyUrlToClipboard = async (url: string) => {
  await navigator.clipboard.writeText(url);

  enqueueSnackbar('Link copied', { variant: 'success' });
};

interface IProps {
  onClose: () => void;
}
const ShareCalendarPopup = ({ onClose }: IProps) => {
  const { createShareCalendarLink, shareCodeList, shareLoadingStatus, loadingStatus, calendarList } =
    MainStore.calendarStore;

  const [startDate, setStartDate] = useState<dayjs.Dayjs>();
  const [endDate, setEndDate] = useState<dayjs.Dayjs>();

  useEffect(() => {
    if (!shareCodeList.length) {
      MainStore.calendarStore.fetchPlanningCalendarShareCode();
    }
  }, []);

  useEffect(() => {
    if (calendarList.length && !shareCodeList.length) {
      setStartDate(dayjs(calendarList[0].startDate, 'YYYY-MM-DD'));
      setEndDate(dayjs(calendarList[0].endDate, 'YYYY-MM-DD'));
    }
    if (shareCodeList.length) {
      setStartDate(dayjs(shareCodeList[0].start_date, 'YYYY-MM-DD'));
      setEndDate(dayjs(shareCodeList[0].end_date, 'YYYY-MM-DD'));
    }
  }, [calendarList, shareCodeList]);

  const copyUrlToClipboardAndClose = (link: string) => () => {
    copyUrlToClipboard(link).then(() => onClose());
  };

  const onSaveButtonHandle = () => {
    createShareCalendarLink(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then((respShareCodeList) => {
      if (respShareCodeList) {
        copyUrlToClipboard(getUrlFromCode(respShareCodeList[0].code));
      }
    });
  };

  const sharedUrl = useMemo(() => {
    if (shareCodeList.length) {
      return getUrlFromCode(shareCodeList[0].code);
    }
    return null;
  }, [shareCodeList]);

  const onChangeStartDate = (newValue: dayjs.Dayjs) => {
    DateUtils.startDateChangeChecker({ newValue, startDate, endDate }, setEndDate);
    setStartDate(newValue);
  };

  const onChangeEndDate = (newValue: dayjs.Dayjs) => {
    DateUtils.endDateChangeChecker({ newValue, startDate, endDate }, setStartDate);
    setEndDate(newValue);
  };

  const isLoading =
    shareLoadingStatus === LoadingStatus.LOADING ||
    loadingStatus === LoadingStatus.LOADING ||
    loadingStatus === LoadingStatus.ERROR;

  return (
    <Box sx={{ width: '400px', padding: '15px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <DatePicker
          label="Start date"
          value={startDate}
          onChange={onChangeStartDate}
          className={scss.rangeDatePicker}
          disabled={isLoading}
        />
        <DatePicker
          label="End date"
          value={endDate}
          onChange={onChangeEndDate}
          className={scss.rangeDatePicker}
          disabled={isLoading}
        />
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
        className={cn({ [scss.disabledLink]: shareLoadingStatus !== LoadingStatus.SUCCESS })}
      >
        <Chip
          sx={{
            display: 'inline-flex',
            gap: '10px',
            color: '#1976d2',
            '--Chip-radius': '8px',
            textOverflow: 'ellipsis',
            width: '320px',
          }}
          variant="outlined"
        >
          <Link
            style={{ color: '#1976d2', fontSize: '16px', textOverflow: 'ellipsis' }}
            href={sharedUrl}
            target="_blank"
            rel="noreferrer"
          >
            {sharedUrl}
          </Link>
        </Chip>
        <Button
          disabled={shareLoadingStatus !== LoadingStatus.SUCCESS || !sharedUrl}
          onClick={copyUrlToClipboardAndClose(sharedUrl)}
          variant="plain"
          size="sm"
          sx={{ paddingInline: '0.5rem' }}
        >
          <CopyAllIcon />
        </Button>
      </Box>

      <Box>
        <Button
          variant="solid"
          onClick={onSaveButtonHandle}
          // disabled={!Object.keys(instance.changedFields).length}
          loading={isLoading}
          sx={{ width: '100%' }}
        >
          CREATE NEW SHARE LINK
        </Button>
      </Box>
    </Box>
  );
};

export default observer(ShareCalendarPopup);
