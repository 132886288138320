import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateSelectArg, DateSpanApi, DatesSetArg, EventClickArg, EventDropArg } from '@fullcalendar/core';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { EventImpl } from '@fullcalendar/core/internal';
import { ColorResult } from 'react-color';
import dayjs from 'dayjs';
import { ResourceApi } from '@fullcalendar/resource';
import PlanningCalendar, {
  findCurrentResource,
  findNextResource,
} from '../../component/planning-calendar/PlanningCalendar';
import MainStore from '../../store/MainStore';
import { LoadingStatus } from '../../interface';
import Loader from '../../component/ui/loader/Loader';
import DefaultModal from '../../component/ui/default-modal/DefaultModal';
import PlanningCalendarModalWrapper from './modal/PlanningCalendarModalWrapper';
import PopperChangeMetric from './popover/PopperChangeMetric';
import PopoverCampaignSettings from './popover/PopoverCampaignSettings';
import PopoverResourceSettings from './popover/PopoverResourceSettings';
import PopoverChangeCustomField from './popover/PopoverChangeCustomField';
import findPermission from '../../component/permission/findPermission';
import useVisibleCalendar from '../../component/planning-calendar/hook/useVisibleCalendar';
import PopoverChangeStartDay from './popover/PopoverChangeStartDay';
import { StartWeekDay } from '../../interface/calendarInterface';
import { MetricForCalendarClass } from '../../slass/calendar/MetricForCalendarClass';
import { EventDragStopArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import { matchNextWeekDate, numberToChar } from '../../component/planning-calendar/utils';
import useCollapseCalendarButton, { refreshResources } from '../../utils/hooks/useCollapseCalendarButton';
import { TotalsEventClass } from '../../slass/calendar/TotalsEventClass';

// todo refactor callback logic and write class for state

const PlanningPage = () => {
  const { visible, setVisible } = useVisibleCalendar();
  const calendarRef = useRef(null);
  const wrapperRef = useRef<HTMLInputElement>();
  const { calendarList, loadingStatus: calendarLoadingStatus } = MainStore.calendarStore;

  useEffect(() => {
    MainStore.calendarStore.fetchCalendar();
    if (findPermission(['campaign_metric.view_campaignmetrictotal'], MainStore.authStore.permissions)) {
      MainStore.campaignMetricsStore.fetchList();
    }

    if (findPermission(['product.view_product'], MainStore.authStore.permissions)) {
      MainStore.productStore.fetchList();
    }

    return () => {
      MainStore.calendarStore.clear();
      MainStore.campaignMetricsStore.clear();
      MainStore.productStore.clear();
    };
  }, []);

  const [colorAnchor, setColorAnchor] = useState<PointerEvent>(null);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const [isOpenChangeCustomFieldPopup, setIsOpenChangeCustomFieldPopup] = useState(false);

  const [settingsPopoverCoordinates, setSettingsPopoverCoordinates] = useState<{ top: number; left: number }>(null);

  const [settingsNewCampaign, setSettingsNewCampaign] = useState<DateSelectArg & EventImpl>(null);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const [fieldValue, setFieldValue] = useState<EventImpl>(null);

  const [customFieldEvent, setCustomFieldEvent] = useState<DateSelectArg & EventImpl>(null);

  const [open, setOpen] = React.useState(false);

  const [anchorChangeStartDate, setAnchorChangeStartDate] = React.useState(null);

  const {
    onCollapseCallback: onCollapse,
    // onMountHandler: onMountCollapseHandler,
    // setIsRendered,
  } = useCollapseCalendarButton(calendarRef);

  const { resourceByCalendar, resourceTarget } = useMemo(() => {
    if (colorAnchor) {
      const target = (colorAnchor.target as Element).closest('[data-resource-id]') as HTMLElement;
      const resourceId = target.getAttribute('data-resource-id');

      const resource: ResourceApi | null = calendarRef.current.getApi().getResourceById(resourceId);
      return { resourceByCalendar: resource, resourceTarget: target };
    }
    return { resourceByCalendar: null, resourceTarget: null };
  }, [colorAnchor]);

  const onClickStartDayHeader = useCallback((event) => {
    setAnchorChangeStartDate(event.currentTarget);
  }, []);

  const startDayListener = useCallback(
    (isStartToListen: boolean) => {
      const foundHeader = document.querySelectorAll('[role="columnheader"]')?.[1];
      if (isStartToListen) {
        foundHeader?.classList?.add('columnheaderStartDay');
        foundHeader?.addEventListener('click', onClickStartDayHeader);
      } else {
        foundHeader?.classList?.remove('columnheaderStartDay');
        foundHeader?.removeEventListener('click', onClickStartDayHeader);
      }
    },
    [onClickStartDayHeader],
  );

  const onClickResourceHeader = useCallback((event) => {
    setColorAnchor(event);
  }, []);

  const removeListeners = useCallback(() => {
    document
      .querySelectorAll('.resource-header, .resource-headerChannels')
      .forEach((resourceHeader) => resourceHeader.removeEventListener('click', onClickResourceHeader));
  }, [onClickResourceHeader]);

  useEffect(() => () => removeListeners(), [removeListeners]);

  const setIsCalendarMountedCallback = useCallback(
    (datesSetArg: DatesSetArg) => {
      if (
        datesSetArg.start.getMonth() != calendarList[0].startDateAsDate.month() ||
        datesSetArg.start.getFullYear() != calendarList[0].startDateAsDate.year()
      ) {
        MainStore.calendarStore.fetchCalendar(
          dayjs(datesSetArg.startStr).format('YYYY-MM-DD'),
          dayjs(datesSetArg.endStr).format('YYYY-MM-DD'),
        );
      } else {
        removeListeners();
        document
          .querySelectorAll('.resource-header, .resource-headerChannels')
          .forEach((resourceHeader) => resourceHeader.addEventListener('click', onClickResourceHeader));
      }
      if (MainStore.authStore.groups.includes('admin')) {
        startDayListener(false);
        startDayListener(true);
      }

      // onMountCollapseHandler();
    },
    [onClickResourceHeader, removeListeners, calendarList, startDayListener],
  );

  const onCloseColorPicker = () => {
    setColorAnchor(null);
  };

  const onSelectColor = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    if (colorAnchor) {
      resourceByCalendar.extendedProps.marketingPillar.setBgColor(color.hex);
      resourceTarget.style.backgroundColor = color.hex;
      console.time('startRerender');
      if (calendarRef.current) {
        calendarRef.current.getApi().refetchEvents();
        refreshResources(calendarRef);
      }
      console.timeEnd('startRerender');
      // resourceByCalendar.setProp('eventBackgroundColor', color.hex);
      //
      // resourceByCalendar.getEvents().forEach((calendarEvent) => calendarEvent.setProp('backgroundColor', color.hex));
      //
      resourceByCalendar.extendedProps.marketingPillar.fetchNewColor(color.hex);
      // if (calendarRef.current) {
      //   calendarRef.current.getApi().refetchEvents();
      // }
      //
      // //change color
      // const resourceByCalendarElement = document.querySelector<HTMLElement>(
      //   `[data-resource-id="${resourceByCalendar.id}"].fc-datagrid-cell.fc-resource.resource-headerChannelsWrapper`,
      // );
      // if (resourceByCalendarElement) {
      //   resourceByCalendarElement.style.backgroundColor = color.hex.length === 7 ? `${color.hex}80` : color.hex;
      // }
    }
  };

  const handleEventClick = useCallback((clickInfo: EventClickArg) => {
    //*checking for non-editable fields
    if (clickInfo.event.id.includes('y') || clickInfo.event.id.includes('x')) {
      return;
    }
    //*

    if (clickInfo.event.id.includes('f')) {
      setAnchorEl(clickInfo.jsEvent.target);
      setOpen((previousOpen) => !previousOpen);
      setFieldValue(clickInfo.event);
    } else if (clickInfo.event.id.includes('b') || clickInfo.event.id.includes('d')) {
      setSettingsPopoverCoordinates({ top: clickInfo.jsEvent.clientY, left: clickInfo.jsEvent.clientX });
      setSettingsNewCampaign(clickInfo.event as DateSelectArg & EventImpl);
    } else if (clickInfo.event.id.includes('@')) {
      setCustomFieldEvent(clickInfo.event as DateSelectArg & EventImpl);
      setIsOpenChangeCustomFieldPopup(true);
      setSettingsPopoverCoordinates({ top: clickInfo.jsEvent.clientY, left: clickInfo.jsEvent.clientX });
    }
  }, []);

  const onSelectHandler = useCallback((selectInfo: DateSelectArg) => {
    if (selectInfo.resource.id.includes('@')) {
      setIsOpenChangeCustomFieldPopup(true);
      setCustomFieldEvent(selectInfo as DateSelectArg & EventImpl);
    } else {
      setSettingsNewCampaign(selectInfo as DateSelectArg & EventImpl);
    }

    setSettingsPopoverCoordinates({ top: selectInfo.jsEvent.clientY, left: selectInfo.jsEvent.clientX });
  }, []);

  const onOpenNewCampaign = (selectInfo) => () => {
    setIsOpenEditModal(true);
    setAnchorEl(null);
    setSettingsPopoverCoordinates(null);
  };

  const onCloseAll = useCallback(() => {
    setIsOpenEditModal(false);
    setSettingsNewCampaign(null);
    setAnchorEl(null);
    setOpen(null);
    setIsOpenChangeCustomFieldPopup(false);
    setSettingsPopoverCoordinates(null);
    setFieldValue(null);
    setCustomFieldEvent(null);
    setAnchorChangeStartDate(false);
  }, []);

  const onUpdateCalendarData = () => {
    return MainStore.calendarStore.fetchCalendar(
      calendarList[0].startDateAsDate.format('YYYY-MM-DD'),
      calendarList[0].endDateAsDate.format('YYYY-MM-DD'),
    );
  };

  const onEventDrop = useCallback((eventDropInfo: EventDropArg) => {
    if (eventDropInfo.event?.extendedProps?.marketingPillar) {
      // revert;
      const revertFn = () => {
        const { eventInstanceList, marketingPillar } = eventDropInfo.event.extendedProps;
        eventInstanceList.forEach(({ eventId, instance }: { eventId: string; instance: MetricForCalendarClass }) => {
          // if user came out
          if (!calendarRef?.current) {
            return;
          }
          const foundEvent = calendarRef.current.getApi().getEventById(eventId);
          if (foundEvent) {
            foundEvent.setDates(foundEvent.extendedProps.initDate.start, foundEvent.extendedProps.initDate.end);
          }
        });

        eventDropInfo.revert();
      };

      eventDropInfo.event.extendedProps.marketingPillar
        .setNewDate(eventDropInfo.event.start, eventDropInfo.event.end)
        .then(() => {
          MainStore.calendarStore.calendarList[0].updateCalendar().then(() => {
            console.time('startRerender');

            calendarRef.current.getApi().refetchEvents();
            calendarRef.current.getApi().refetchResources();
            console.timeEnd('startRerender');
          });
        })
        .catch((e) => {
          revertFn();
        });
    }
  }, []);

  const onEventResize = useCallback((eventResizeInfo: EventResizeDoneArg) => {
    if (eventResizeInfo.event?.extendedProps?.marketingPillar) {
      eventResizeInfo.event.extendedProps.marketingPillar
        .setNewDate(eventResizeInfo.event.start, eventResizeInfo.event.end)
        .then(() => {})
        .catch(() => {
          eventResizeInfo.revert();
        });
    }
  }, []);

  const onEventDragStop = useCallback((eventDropInfo: EventDragStopArg) => {
    // const { eventInstanceList, marketingPillar } = eventDropInfo.event.extendedProps;
    // const eventStartDate = new Date(marketingPillar.startDate);
    // const eventEndDate = new Date(marketingPillar.endDate);
    //
    // const dateInRange = (date: Date, startDate: Date, endDate: Date) => {
    //   return date >= startDate && date <= endDate;
    // };
    //
    // let isDragSuccess = true;
    // //
    // // for (let i = 0; i < eventInstanceList.length; i++) {
    // //   // if (!dateInRange(new Date(eventInstanceList[i].instance.forDate), eventStartDate, eventEndDate)) {
    // //   //   console.log(new Date(eventInstanceList[i].instance.forDate), eventStartDate, eventEndDate);
    // //   //   isDragSuccess = false;
    // //   // }
    // //   const foundEvent = calendarRef?.current?.getApi()?.getEventById(eventInstanceList[i].eventId);
    // //
    // //   if (foundEvent) {
    // //     const start = eventInstanceList[i].instance.forDate;
    // //     const end = matchNextWeekDate(eventInstanceList[i].instance.forDate);
    // //     // console.log(start, foundEvent.extendedProps.initDate.end);
    // //     foundEvent.setDates(start, end);
    // //
    // //     // foundEvent.moveDates(timeDiffMS);
    // //   }
    // // }
    // // revert;
    // if (!isDragSuccess) {
    //   eventInstanceList.forEach(({ eventId, instance }: { eventId: string; instance: MetricForCalendarClass }) => {
    //     const foundEvent = calendarRef.current.getApi().getEventById(eventId);
    //
    //     if (foundEvent) {
    //       foundEvent.setDates(foundEvent.extendedProps.initDate.start, foundEvent.extendedProps.initDate.end);
    //     }
    //   });
    // } else {
    //   // foundEvent.extendedProps.initDate.start = foundEvent.dateStr;
    // }
  }, []);

  const onEventAllow = useCallback((dropInfo: DateSpanApi, draggedEvent: EventImpl | null) => {
    if (draggedEvent && draggedEvent.extendedProps.marketingPillar) {
      const oldStartDate = dayjs(draggedEvent.extendedProps.marketingPillar.startDate);
      const newStartDate = dayjs(dropInfo.startStr);
      const timeDiffMS = newStartDate.valueOf() - oldStartDate.valueOf();

      draggedEvent.extendedProps.eventInstanceList.forEach(
        ({ eventId, instance }: { eventId: string; instance: MetricForCalendarClass }) => {
          const newEventDate = dayjs(dayjs(instance.forDate, 'YYYY-MM-DD').valueOf() + timeDiffMS).format('YYYY-MM-DD');

          instance.setForDate(newEventDate);
          const start = newEventDate;
          const end = matchNextWeekDate(newEventDate);
          const foundEvent = calendarRef.current.getApi().getEventById(eventId);

          if (foundEvent) {
            // foundEvent.setDates(start, end);
            // foundEvent.moveDates(timeDiffMS);
            //!
            // foundEvent.extendedProps.initDate.start = start;
            // foundEvent.extendedProps.initDate.start = end;
          }
        },
      );
      draggedEvent.extendedProps.marketingPillar.setStartDate(dropInfo.startStr);
      draggedEvent.extendedProps.marketingPillar.setEndDate(dropInfo.endStr);
    }

    return true;
  }, []);

  const isEditCampaign = !!settingsNewCampaign?.title;

  return (
    <section style={{ paddingLeft: '10px', paddingTop: '10px', height: '100%' }}>
      <Box sx={{ height: '100%', borderRadius: '16px', overflow: 'hidden' }}>
        <Box ref={wrapperRef} sx={{ height: '100%' }}>
          {calendarLoadingStatus === LoadingStatus.LOADING && <Loader />}
          {calendarLoadingStatus === LoadingStatus.SUCCESS && calendarList[0] && wrapperRef?.current && (
            <>
              <PopoverChangeStartDay
                anchorEl={anchorChangeStartDate}
                onClose={onCloseAll}
                instance={calendarList[0]}
                onUpdateCalendar={() => {
                  onCloseAll();
                  return onUpdateCalendarData();
                }}
              />
              <PopoverChangeCustomField
                open={isOpenChangeCustomFieldPopup}
                coordinates={settingsPopoverCoordinates}
                onClose={onCloseAll}
                event={customFieldEvent}
                calendarRef={calendarRef}
                dateList={calendarList[0].datesList}
              />
              <PopperChangeMetric
                open={open}
                anchorEl={anchorEl}
                onClose={onCloseAll}
                event={fieldValue}
                calendarRef={calendarRef}
              />
              <PopoverCampaignSettings
                settingsNewCampaign={settingsNewCampaign}
                onOpenNewCampaign={onOpenNewCampaign}
                isEditCampaign={isEditCampaign}
                coordinates={settingsPopoverCoordinates}
                onCloseNewCampaignModal={onCloseAll}
              />
              <PopoverResourceSettings
                calendarRef={calendarRef}
                resourceByCalendar={resourceByCalendar}
                instance={calendarList[0]}
                colorAnchor={colorAnchor}
                onClose={onCloseColorPicker}
                onSelect={onSelectColor}
                selectedColor={resourceByCalendar?.extendedProps.marketingPillar.bgColor}
              />
              <DefaultModal open={isOpenEditModal}>
                <PlanningCalendarModalWrapper
                  isEditCampaign={isEditCampaign}
                  selectInfo={settingsNewCampaign}
                  onCloseModal={onCloseAll}
                  calendarInstance={calendarList[0]}
                  onUpdateCalendar={() => {
                    if (calendarRef.current) {
                      console.time('startRerender');
                      calendarRef.current.getApi().refetchEvents();
                      refreshResources(calendarRef);
                      console.timeEnd('startRerender');
                    }
                    onCloseAll();
                  }}
                />
              </DefaultModal>

              {visible && (
                <PlanningCalendar
                  calendarRef={calendarRef}
                  handleEventClick={handleEventClick}
                  instance={calendarList[0]}
                  offsetHeight={wrapperRef?.current?.offsetHeight}
                  onSelectHandler={onSelectHandler}
                  datesSet={setIsCalendarMountedCallback}
                  onCollapse={onCollapse}
                  eventDrop={onEventDrop}
                  eventDragStop={onEventDragStop}
                  eventResize={onEventResize}
                  eventAllow={onEventAllow}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </section>
  );
};

export default observer(PlanningPage);
