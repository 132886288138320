import { action, computed, makeObservable, observable } from 'mobx';
import { BaseClass } from '../BaseClass';
import { Company, Parent, Slug } from '../../../interface/productCategoryInterface';

export class ProductCategoryClass extends BaseClass {
  @observable protected _company: Company;

  @observable protected _parent: Parent;

  @observable protected _slug: Slug;

  private readonly _createdAt: string;

  @observable protected _updatedAt: string;

  private readonly _deletedAt: string;

  constructor(props, serviceConductor) {
    super(serviceConductor);
    this.snakeToCamelBuilder(props);
    makeObservable(this);
  }

  @computed get company() {
    return this._company;
  }

  @computed get parent() {
    return this._parent;
  }

  @computed get slug() {
    return this._slug;
  }

  @action protected _builder = (key, value) => {};

  @action fetchPatch = async (data) => this.fetchPatchBase(data);
}
