import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Paper, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { formatPrefix } from 'd3-format';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MainStore from '../../store/MainStore';
import { LoadingStatus } from '../../interface';

import TotalProjectSalesBarWrapper from './bar/TotalsBarWrapper';
import scss from './DashboardPage.module.scss';
import CampaignBudgetWrapper from './bar/CampaignBudgetWrapper';
import calculateBudgetDeadlineData, { IReturn } from './utils/calculateBudgetDeadlineData';
import DeadlineChartWrapper from './calendar/DeadlineChartWrapper';
import CountChart from './count/CountChart';
import CountDaysWrapper from './pie/CountDaysWrapper';
import MetricsWeeklyPerformanceChart from './bar/MetricsWeeklyPerformanceChart';
import LoaderBlock from './LoaderBlock';
import calculateNumberOfDays from '../../utils/calculateNumberOfDays';
import TotalWeeklySalesLiftWrapper from './line/TotalWeeklySalesLiftWrapper';
import useTotalWeeklySalesData from './line/hook/useTotalWeeklySalesData';
import { DateUtils } from '../../utils/DateUtils';

const DashboardPage = () => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs().startOf('year'));
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs().endOf('year'));

  const { calendarList, loadingStatus } = MainStore.calendarStore;
  useEffect(() => {
    MainStore.calendarStore.fetchCalendar(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    return () => {
      MainStore.calendarStore.clear();
    };
  }, [startDate, endDate]);

  const budgetDeadlineDate = useMemo(() => {
    if (calendarList.length) {
      return calculateBudgetDeadlineData(calendarList[0]);
    }
    return { budget: [], deadline: [], budgetTreemap: {}, actualBudget: 0 };
  }, [calendarList]);

  const diffYear = useMemo(() => endDate.diff(startDate, 'year'), [endDate, startDate]);

  const totalCampaignDays = useMemo(() => {
    if (budgetDeadlineDate.budget.length) {
      const daysList = (budgetDeadlineDate.budget as IReturn['budget']).map((budgetEl) => ({
        startDate: budgetEl.startDay,
        endDate: budgetEl.endDay,
      }));

      return calculateNumberOfDays(daysList).count;
    }
    return 1;
  }, [budgetDeadlineDate]);

  const { data: totalWeeklySalesData, totalWeeklySalesDays } = useTotalWeeklySalesData(calendarList?.[0]);

  const disabledDatePicker = loadingStatus === LoadingStatus.LOADING;

  const averageDailyCosts = budgetDeadlineDate.actualBudget / totalCampaignDays;

  const onChangeStartDate = (newValue: dayjs.Dayjs) => {
    DateUtils.startDateChangeChecker({ newValue, startDate, endDate }, setEndDate);
    setStartDate(newValue);
  };

  const onChangeEndDate = (newValue: dayjs.Dayjs) => {
    DateUtils.endDateChangeChecker({ newValue, startDate, endDate }, setStartDate);
    setEndDate(newValue);
  };

  return (
    <section className="section">
      <Box sx={{ height: '100%' }}>
        <Paper elevation={2} sx={{ padding: '18px', width: 'auto', marginBottom: '18px', display: 'inline-block' }}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="Start date"
              value={startDate}
              onChange={onChangeStartDate}
              disabled={disabledDatePicker}
              className={scss.rangeDatePicker}
            />
            <DatePicker
              label="End date"
              value={endDate}
              onChange={onChangeEndDate}
              disabled={disabledDatePicker}
              className={scss.rangeDatePicker}
            />
          </Stack>
        </Paper>

        {loadingStatus === LoadingStatus.ERROR && <Box sx={{ height: '100%' }}>Error</Box>}
        <Box className={scss.gridContainer}>
          <Paper
            elevation={2}
            sx={{ height: '600px', padding: '18px', overflowX: 'auto', overflowY: 'visible' }}
            className={scss.item1}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={<TotalProjectSalesBarWrapper instance={calendarList[0]} />}
            />
          </Paper>

          <Paper
            elevation={2}
            sx={{ height: '600px', padding: '18px', overflowX: 'auto', overflowY: 'visible' }}
            className={scss.item2}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={<MetricsWeeklyPerformanceChart instance={calendarList[0]} />}
            />
          </Paper>

          <Paper
            elevation={2}
            sx={{
              height: '100%',
              // minWidth: '210px',
              padding: '18px',
              overflowX: 'auto',
              overflowY: 'visible',
            }}
            className={scss.item3}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={
                <CountChart
                  value={budgetDeadlineDate.budget.length}
                  title="Total of campaigns"
                  color="rgb(188, 128, 189)"
                />
              }
            />
          </Paper>
          <Paper
            elevation={2}
            sx={{
              height: 'auto',
              minHeight: '210px',
              // minWidth: '210px',
              padding: '18px',
              overflowX: 'auto',
              overflowY: 'visible',
            }}
            className={scss.item4}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={
                <CountChart
                  value={totalWeeklySalesDays ? formatPrefix('$.1', totalWeeklySalesDays)(totalWeeklySalesDays) : 0}
                  title="Average Weekly Sales Performance Lift"
                  color="rgb(128, 177, 211)"
                />
              }
            />
          </Paper>
          <Paper
            elevation={2}
            sx={{
              height: 'auto',
              minHeight: '210px',
              // minWidth: '210px',
              padding: '18px',
              overflowX: 'auto',
              overflowY: 'visible',
            }}
            className={scss.item5}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={
                <CountChart
                  value={averageDailyCosts ? formatPrefix('$.1', averageDailyCosts)(averageDailyCosts) : 0}
                  title="Average Daily Media Spend"
                  color="rgb(251, 128, 114)"
                />
              }
            />
          </Paper>
          <Paper
            elevation={2}
            sx={{
              minHeight: `${210 + 140 * diffYear}px`, // 240 350px
              // minWidth: '450px',
              padding: '18px',
              // overflowX: 'auto',
              overflowY: 'visible',
            }}
            className={scss.item6}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={
                <DeadlineChartWrapper
                  data={budgetDeadlineDate.deadline}
                  from={startDate.format('YYYY-MM-DD')}
                  to={endDate.format('YYYY-MM-DD')}
                />
              }
            />
          </Paper>
          {/*<Paper*/}
          {/*  elevation={2}*/}
          {/*  sx={{*/}
          {/*    height: '600px',*/}
          {/*    // minWidth: '450px',*/}
          {/*    padding: '10px',*/}
          {/*    // overflowX: 'auto',*/}
          {/*    overflowY: 'visible',*/}
          {/*  }}*/}
          {/*  className={scss.item7}*/}
          {/*>*/}
          {/*  <LoaderBlock*/}
          {/*    dataLength={calendarList.length}*/}
          {/*    loadingStatus={loadingStatus}*/}
          {/*    child={*/}
          {/*      <BudgetChartWrapper*/}
          {/*        data={budgetDeadlineDate.budgetTreemap}*/}
          {/*        title="Predicted Budget"*/}
          {/*        valueLabel="predicted"*/}
          {/*      />*/}
          {/*    }*/}
          {/*  />*/}
          {/*</Paper>*/}

          <Paper
            elevation={2}
            sx={{
              height: '600px',
              // minWidth: '450px',
              padding: '18px',
              // overflowX: 'auto',
              overflowY: 'visible',
            }}
            className={scss.item7}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={<CampaignBudgetWrapper data={budgetDeadlineDate.budget} instance={calendarList[0]} />}
            />
          </Paper>
          <Paper
            elevation={2}
            sx={{
              height: '600px',
              // minWidth: '450px',
              padding: '18px',
              // overflowX: 'auto',
              overflowY: 'visible',
            }}
            className={scss.item8}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={<CountDaysWrapper data={budgetDeadlineDate.budget} />}
            />
          </Paper>
          <Paper
            elevation={2}
            sx={{
              height: '600px',
              // minWidth: '450px',
              padding: '18px',
              // overflowX: 'auto',
              overflowY: 'visible',
            }}
            className={scss.item9}
          >
            <LoaderBlock
              dataLength={calendarList.length}
              loadingStatus={loadingStatus}
              child={
                // <BudgetChartWrapper data={budgetDeadlineDate.budgetTreemap} title="Actual Budget" valueLabel="actual" />
                <TotalWeeklySalesLiftWrapper data={totalWeeklySalesData} />
              }
            />
          </Paper>
        </Box>
      </Box>
    </section>
  );
};

export default observer(DashboardPage);
