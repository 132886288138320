import { action, computed, makeObservable, observable } from 'mobx';
import { IPatchValueResponseCustomField } from '../../interface/customFieldInterface';
import { patchValueById } from '../../service/customFieldService';
import { AnnotationForCalendarClass } from './AnnotationForCalendarClass';

export class AnnotationValueForCalendarClass {
  private readonly _parentInstance: AnnotationForCalendarClass;

  private readonly _forDate: string;

  @observable private _value: string | number;

  constructor(data: IPatchValueResponseCustomField, parentInstance: AnnotationForCalendarClass) {
    makeObservable(this);
    this._forDate = data.for_date;
    this._value = data.value;
    this._parentInstance = parentInstance;
  }

  @computed get value() {
    return this._value;
  }

  get forDate() {
    return this._forDate;
  }

  get id() {
    return this._parentInstance.id;
  }

  get parentInstance() {
    return this._parentInstance;
  }

  @action setValue = (newValue) => {
    this._value = newValue;
  };

  @action updateValue = async (newValue) => {
    const oldValue = this._value;
    this.setValue(newValue);
    try {
      await patchValueById(this.id, { value: this._value, for_date: this._forDate });
      return true;
    } catch {
      this._value = oldValue;
      return false;
    }
  };
}
