import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LoadingStatus } from '../../interface';
import Grid from '../../component/ui/grid/Grid';
import Loader from '../../component/ui/loader/Loader';
import useTableHeight from '../../utils/hooks/useTableHeight';
import MainStore from '../../store/MainStore';
import useBaseGridBehavior from '../../utils/hooks/useBaseGridBehavior';
import useColumns from './hook/useColumns';
import DefaultModal from '../../component/ui/default-modal/DefaultModal';
import DefaultChildrenWrapperModal from '../../component/ui/default-modal/DefaultChildrenWrapperModal';
import DeleteModalContent from '../../component/ui/delete-modal-content/DeleteModalContent';
import { CampaignMetricsClass } from '../../slass/data/campaign-metrics/CampaignMetricsClass';
import ListOfCalendarModal from './ListOfCalendarModal';
import Modal from '@mui/joy/Modal';
import { ModalClose, ModalDialog } from '@mui/joy';
import CompetitorCalendarPreview from './CurrentCalendarPage';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';

import { ReactComponent as ArrowBackIcon } from '../../icon/ArrowBackIcon.svg';

const ListOfCalendarGrid = () => {
  const [viewModalId, setViewModalId] = useState(null);
  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();

  const { state, action } = useBaseGridBehavior();
  const { selectedComponent, isOpenDeleteComponentModal, isOpenNewComponentModal, isOpenModal } = state;
  const { onDeleteComponentModal, onCloseModal, onOpenEditComponentModal } = action;

  const { loadingStatus, competitorList, fetchDelete } = MainStore.competitorStore;

  useEffect(() => {
    MainStore.competitorStore.fetchList();
    return () => MainStore.competitorStore.clear();
  }, []);

  const row = competitorList.reduce((prevState, currentValue) => {
    const currentRow = {
      id: currentValue.id,
      title: currentValue.title,
      description: currentValue.description,
      source: currentValue.source,
      lastModifiedDate: currentValue.updatedAt,
      status: currentValue.status,
      download: { content: currentValue.content, title: currentValue.title },
    };
    prevState.push(currentRow);
    return prevState;
  }, []);
  const competitorInstance = useMemo(
    () => competitorList?.find((competitor) => competitor.id === selectedComponent),
    [competitorList, selectedComponent],
  );

  const viewModalTitle = useMemo(
    () => competitorList?.find((competitor) => competitor.id === viewModalId)?.title || '',
    [competitorList, viewModalId],
  );

  const onOpenViewModal = useCallback(
    (id) => () => {
      setViewModalId(id);
    },
    [],
  );
  // instance
  //   ?.fetchPatch({
  //     ...(title !== instance.title ? { title } : {}),
  //     ...(description !== instance.description ? { description } : {}),
  //     // ...(isSelected ? { status: 'SELECTED' } : { status: 'PROCESSED' }),
  //   })

  const columns = useColumns({ onDeleteComponentModal, onOpenEditComponentModal, onOpenViewModal });

  const isOpenEditModal = (selectedComponent === 0 || selectedComponent) && isOpenModal;

  const modalTitle = 'Edit Competitor Calendar';

  return (
    <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
      {componentHeight && loadingStatus !== LoadingStatus.LOADING ? (
        <Grid sellQuantity={sellQuantity} rows={row} columns={columns} />
      ) : (
        <Loader />
      )}
      <DefaultModal open={isOpenEditModal || isOpenNewComponentModal}>
        <DefaultChildrenWrapperModal modalTitle={modalTitle} onCloseModal={onCloseModal}>
          <ListOfCalendarModal instance={competitorInstance} onCloseModalHandler={onCloseModal} />
        </DefaultChildrenWrapperModal>
      </DefaultModal>

      <DefaultModal open={isOpenDeleteComponentModal && !!competitorInstance}>
        <DeleteModalContent<CampaignMetricsClass>
          onCloseModalHandler={onCloseModal}
          fetchDelete={fetchDelete}
          loadingStatus={loadingStatus}
          id={competitorInstance?.id}
          title={competitorInstance?.title}
        />
      </DefaultModal>

      <Modal open={!!viewModalId} onClose={onOpenViewModal(null)}>
        <ModalDialog
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout="fullscreen"
          sx={{ padding: 0 }}
        >
          {' '}
          <ModalClose />
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              padding: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button variant="soft" sx={{ borderRadius: '18px' }} size="md" onClick={onOpenViewModal(null)}>
              <ArrowBackIcon style={{ fill: '#1976d2' }} />
              <span style={{ transform: 'translateY(1px)' }}>Back to List</span>
            </Button>
            <Typography id="CompetitorCalendarPreview_layout-modal-title" component="h2" sx={{ fontSize: '1.4em' }}>
              {viewModalTitle}
            </Typography>
            <Box sx={{ width: '130px' }}></Box>
          </Box>
          <CompetitorCalendarPreview id={viewModalId} />
        </ModalDialog>
      </Modal>
    </Box>
  );
};

export default observer(ListOfCalendarGrid);
