import React, { useState } from 'react';
import { Box, FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import { CompetitorClass } from '../../slass/competitor/CompetitorClass';
import onHandleChangeInput from '../../utils/onHandleChangeInput';
import { LoadingStatus } from '../../interface';
import MainStore from '../../store/MainStore';

interface IProps {
  instance?: CompetitorClass;
  onCloseModalHandler: () => void;
}
const ListOfCalendarModal = ({ onCloseModalHandler, instance }: IProps) => {
  const [title, setTitle] = useState(instance.title);
  const [description, setDescription] = useState(instance.description);
  // const [isSelected, setIsSelected] = useState(instance.status === 'SELECTED');
  const onSaveButtonHandle = () => {
    instance
      ?.fetchPatch({
        ...(title !== instance.title ? { title } : {}),
        ...(description !== instance.description ? { description } : {}),
        // ...(isSelected ? { status: 'SELECTED' } : { status: 'PROCESSED' }),
      })
      .then((isSuccess) => {
        if (isSuccess) {
          onCloseModalHandler();
        }
      });
  };

  const isEmptyTitle = !title.length;
  return (
    <>
      <Box sx={{ margin: '25px 0 30px' }}>
        <FormControl error={isEmptyTitle} sx={{ width: '100%' }}>
          <TextField
            id="Title-standard-basic"
            placeholder="Title"
            variant="standard"
            sx={{ width: '100%' }}
            value={title}
            onChange={onHandleChangeInput(setTitle)}
          />
          {isEmptyTitle && (
            <FormHelperText id="title-error-by-isEmptyTitle" sx={{ position: 'absolute', bottom: '-70%', left: 0 }}>
              The title is required
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box sx={{ margin: '25px 0' }}>
        <TextField
          id="Description-standard-basic"
          label="Description"
          multiline
          variant="outlined"
          rows={4}
          sx={{ width: '100%' }}
          value={description}
          onChange={onHandleChangeInput(setDescription)}
        />
      </Box>

      {/*<Box sx={{ margin: '25px 0' }}>*/}
      {/*  <FormControlLabel*/}
      {/*    control={<Switch checked={isSelected} onChange={(e, value) => setIsSelected(value)} />}*/}
      {/*    labelPlacement="start"*/}
      {/*    label="Selected"*/}
      {/*    sx={{ marginLeft: 0 }}*/}
      {/*  />*/}
      {/*</Box>*/}

      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModalHandler}>
          Cancel
        </Button>
        <Button variant="solid" onClick={onSaveButtonHandle} loading={instance.loadingStatus === LoadingStatus.LOADING}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default observer(ListOfCalendarModal);
