import React, { useState } from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import { PlanningCalendarModalClass } from '../class/PlanningCalendarModalClass';
import { CampaignMetricsClass } from '../../../slass/data/campaign-metrics/CampaignMetricsClass';
import { LoadingStatus } from '../../../interface';
import DefaultModal from '../../../component/ui/default-modal/DefaultModal';
import DeleteModalContent from '../../../component/ui/delete-modal-content/DeleteModalContent';
import onHandleChangeInput from '../../../utils/onHandleChangeInput';
import UploadModalContent from './UploadModalContent';
import UsedProductBlock from './UsedProductBlock';
import CampaignBudgetBlock from './CampaignBudgetBlock';
import DatePickerBlock from './DatePickerBlock';
import MetricModalListBlock from './MetricModalListBlock';
import UploadModalContentBlock from './UploadModalContentBlock';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';

interface IProps {
  instance: PlanningCalendarModalClass;
  onCloseModal: () => void;
  onUpdateCalendar: () => void;
  usedTitleList: string[];
  onOpenElement: (id: number) => void;
  defaultTitle: string;
  calendarInstance: CalendarClass;
}
const PlanningCalendarModal = ({
  instance,
  onCloseModal,
  onUpdateCalendar,
  calendarInstance,
  usedTitleList,
  onOpenElement,
  defaultTitle,
}: IProps) => {
  const {
    id,
    title,
    setTitle,
    description,
    setDescription,
    save,
    loadingStatus,
    isHasDeletedGlobalProduct,
    isHasDataForUpdate,
    isHasDeletedMetric,
    fetchDelete,
    newFileList,
    newLinkList,
    deletedAttachmentIdList,
    isHasDeletedMetricsProduct,
  } = instance;
  const [isOpenDeleteComponentModal, setIsOpenDeleteComponentModal] = useState(false);

  // const [isOpenUpload, setIsOpenUpload] = useState(true);

  // const isAlreadyUsedTitle = useCheckUsedTitle(defaultTitle, title, usedTitleList);

  const updateCalendar = () => {
    calendarInstance
      .updateCalendar()
      .then(() => {
        onUpdateCalendar();
      })
      .catch(() => {});
  };

  const onSaveButtonHandle = () => {
    save().then(() => {
      updateCalendar();
    });
  };

  const onDeleteModalHandler = (value: boolean) => () => {
    setIsOpenDeleteComponentModal(value);
  };
  // const onSetIsOpenUpload = (value: boolean) => () => {
  //   setIsOpenUpload(value);
  // };

  const isEmptyTitle = !title.length;
  const isTitleError = isEmptyTitle;
  // || isAlreadyUsedTitle;

  const isEdit = defaultTitle?.length;

  const isDisabledSubmitButton = isEdit
    ? !(
        isHasDataForUpdate ||
        isHasDeletedGlobalProduct ||
        isHasDeletedMetric ||
        newFileList.length ||
        newLinkList.length ||
        deletedAttachmentIdList.length ||
        isHasDeletedMetricsProduct
      ) || isTitleError
    : isTitleError;

  return (
    <>
      <Box sx={{ margin: '10px 0 30px', display: 'flex', gap: '20px', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column', width: '100%' }}>
          <Box>
            <FormControl error={isTitleError} sx={{ width: '100%' }}>
              <TextField
                id="standard-basic"
                placeholder="Title"
                variant="standard"
                sx={{ width: '100%' }}
                value={title}
                onChange={onHandleChangeInput(setTitle)}
              />
              {isEmptyTitle && (
                <FormHelperText id="title-error-by-isEmptyTitle" sx={{ position: 'absolute', bottom: '-70%', left: 0 }}>
                  The title is required
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box>
            <TextField
              id="standard-basic"
              label="Description"
              multiline
              variant="outlined"
              rows={5}
              sx={{ width: '100%', height: '100%' }}
              value={description}
              onChange={onHandleChangeInput(setDescription)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '15px',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <DatePickerBlock instance={instance} />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', margin: '30px 0' }}>
        <CampaignBudgetBlock instance={instance} />
      </Box>
      <Box sx={{ margin: '25px 0' }}>
        <MetricModalListBlock instance={instance} onOpenElement={onOpenElement} />
      </Box>
      <Box sx={{ margin: '25px 0' }}>
        <UsedProductBlock instance={instance} />
      </Box>

      <Box sx={{ margin: '25px 0' }}>
        <UploadModalContentBlock instance={instance} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          marginTop: '36px',
          gap: '10px',
        }}
      >
        <Button
          variant="plain"
          color="danger"
          onClick={onDeleteModalHandler(true)}
          disabled={
            loadingStatus === LoadingStatus.LOADING ||
            !isEdit ||
            calendarInstance.updateLoadingStatus === LoadingStatus.LOADING
          }
        >
          Delete
        </Button>

        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <Button variant="outlined" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant="solid"
            onClick={onSaveButtonHandle}
            disabled={isDisabledSubmitButton}
            loading={
              loadingStatus === LoadingStatus.LOADING || calendarInstance.updateLoadingStatus === LoadingStatus.LOADING
            }
          >
            Save
          </Button>
        </Box>
      </Box>
      <DefaultModal open={isOpenDeleteComponentModal && !!instance}>
        <DeleteModalContent<CampaignMetricsClass>
          onCloseModalHandler={onDeleteModalHandler(false)}
          onSuccess={updateCalendar}
          fetchDelete={fetchDelete}
          loadingStatus={
            loadingStatus === LoadingStatus.LOADING || calendarInstance.updateLoadingStatus === LoadingStatus.LOADING
              ? LoadingStatus.LOADING
              : LoadingStatus.SUCCESS
          }
          id={id}
          title={title}
        />
      </DefaultModal>
      {/*<DefaultModal open={isOpenUpload && !!instance}>*/}
      {/*  <DeleteModalContent<CampaignMetricsClass>*/}
      {/*    onCloseModalHandler={onSetIsOpenUpload(false)}*/}
      {/*    onSuccess={onUpdateCalendar}*/}
      {/*    fetchDelete={fetchDelete}*/}
      {/*    loadingStatus={loadingStatus}*/}
      {/*    id={id}*/}
      {/*    title={title}*/}
      {/*  />*/}
      {/*</DefaultModal>*/}
    </>
  );
};
export default observer(PlanningCalendarModal);
