import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { historyPush } from '../utils/history';
import { LoadingStatus, TokensNameInStorage } from '../interface';
import { addSlash, MainRoutesPath } from '../interface/router';
import { getUserInfo } from '../service/authService';
import { PermissionType } from '../interface/permissionInterface';

type IUserId = number;

class AuthStore {
  @observable private _loadingStatus: LoadingStatus = LoadingStatus.LOADING;

  @observable private _id: IUserId = null;

  @observable private _isAuth = null;

  @observable private _currentCompanyId: { id: number; title: string } = null;

  @observable private _currentCalendarId: number;

  @observable private _email: string;

  @observable private _firstName: string;

  @observable private _lastName: string;

  @observable private _lastLogin: string;

  @observable private _isSuperuser: boolean;

  @observable private _isLoginAsUserAccess: boolean = false;

  @observable private _groups: string[];

  @observable private _permissions: PermissionType[];

  constructor() {
    makeObservable(this);
  }

  _getAccess() {
    try {
      const loginAsUserAccessLocalStorage = localStorage.getItem(TokensNameInStorage.loginAsUserAccess);
      const loginAsUserAccessSessionStorage = sessionStorage.getItem(TokensNameInStorage.loginAsUserAccess);
      const loginAsUserAccess =
        loginAsUserAccessLocalStorage !== 'undefined' && !!loginAsUserAccessLocalStorage
          ? loginAsUserAccessLocalStorage
          : loginAsUserAccessSessionStorage !== 'undefined' && !!loginAsUserAccessSessionStorage
          ? loginAsUserAccessSessionStorage
          : null;

      if (loginAsUserAccess) {
        sessionStorage.setItem(TokensNameInStorage.loginAsUserAccess, loginAsUserAccess);
        localStorage.removeItem(TokensNameInStorage.loginAsUserAccess);
        runInAction(() => {
          this._isLoginAsUserAccess = true;
        });
        return loginAsUserAccess;
      }
      runInAction(() => {
        this._isLoginAsUserAccess = false;
      });
      return localStorage.getItem(TokensNameInStorage.access);
    } catch {}
  }

  @action init = async () => {
    this._loadingStatus = LoadingStatus.LOADING;

    try {
      const access = this._getAccess();
      if (access !== 'undefined' && !!access) {
        const { data } = await getUserInfo();
        runInAction(() => {
          this.setAllParams(data);
        });
      } else {
        this.unauthenticated();
      }
    } catch (e) {
      this.unauthenticated();
    }
  };

  @action clear = () => {
    this._loadingStatus = LoadingStatus.SUCCESS;
    this._id = null;
    this._isAuth = null;
    this._currentCompanyId = null;
    this._currentCalendarId = null;
    this._email = null;
    this._firstName = null;
    this._lastName = null;
    this._lastLogin = null;
    this._isSuperuser = null;
    this._groups = [];
    this._permissions = [];
    this._isLoginAsUserAccess = false;
  };

  @action setAllParams = (data) => {
    this._id = data.id;
    this._isAuth = true;
    this._currentCompanyId = data.current_company_id;
    this._currentCalendarId = data.current_calendar_id;
    this._email = data.email;
    this._firstName = data.first_name;
    this._lastName = data.last_name;
    this._lastLogin = data.last_login;
    this._isSuperuser = data.is_superuser;
    this._groups = data.groups;
    this._permissions = data.permissions;
    this._loadingStatus = LoadingStatus.SUCCESS;
  };

  @action setAuth = (bool: boolean) => {
    this._isAuth = bool;
  };

  @action setUserId = (user: IUserId) => {
    this._id = user;
  };

  clearStorage = () => {
    localStorage.removeItem(TokensNameInStorage.access);
    localStorage.removeItem(TokensNameInStorage.refresh);
    sessionStorage.removeItem(TokensNameInStorage.loginAsUserAccess);
  };

  @action unauthenticated = () => {
    this.clearStorage();
    this.clear();
    historyPush(addSlash(MainRoutesPath.LOGIN_PAGE));
  };

  @computed
  get isAuth() {
    return this._isAuth;
  }

  @computed
  get isLoginAsUserAccess() {
    return this._isLoginAsUserAccess;
  }

  @computed
  get id() {
    return this._id;
  }

  @computed
  get currentCompanyId() {
    return this._currentCompanyId;
  }

  @computed
  get currentCalendarId() {
    return this._currentCalendarId;
  }

  @computed
  get email() {
    return this._email;
  }

  @computed
  get isSuperuser() {
    return this._isSuperuser;
  }

  @computed
  get firstName() {
    return this._firstName;
  }

  @computed
  get lastLogin() {
    return this._lastLogin;
  }

  @computed
  get groups() {
    return this._groups;
  }

  @computed
  get permissions() {
    return this._permissions;
  }

  @computed
  get loadingStatus() {
    return this._loadingStatus;
  }
}
export default AuthStore;
