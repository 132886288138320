import React from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/joy/Typography';
import { InputAdornment, OutlinedInput } from '@mui/material';
import scss from './MetricModalSettings.module.scss';
import onHandleChangeInput from '../../../utils/onHandleChangeInput';
import { PlanningCalendarModalClass } from '../class/PlanningCalendarModalClass';

interface IProps {
  instance: PlanningCalendarModalClass;
}

const CampaignBudgetBlock = ({ instance }: IProps) => {
  return (
    <>
      <Typography component="span" id="PlanningCalendarModal_Campaign_Budget">
        Campaign Budget
      </Typography>
      <OutlinedInput
        className={scss.campaignBudget}
        sx={{ padding: 0, height: '40px', maxWidth: '180px' }}
        value={instance.budget}
        onChange={onHandleChangeInput(instance.setBudget)}
        startAdornment={<InputAdornment position="end">$</InputAdornment>}
      />
    </>
  );
};

export default observer(CampaignBudgetBlock);
