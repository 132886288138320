import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';

import {
  IResponseMarketingCampaign,
  ICreateRequestDataMarketingCampaign,
  IUpdateRequestDataValue,
  IUpdateResponseDataValue,
  IUpdateRequestMarketingCampaignValue,
  IUpdateResponseMarketingCampaignValue,
  IResponseCreateFile,
  IUpdateResponseProductsDataValue,
  IUpdateRequestProductsDataValue,
} from '../interface/marketingCampaignInterface';

export const create = (
  requestData: ICreateRequestDataMarketingCampaign,
): Promise<IRequest<IResponseMarketingCampaign>> =>
  axiosInstance.post('/marketing-campaign/', requestData).then(({ data }) => data);

// export const getList = (): Promise<IRequest<IResponseProductCategory[]>> =>
//   axiosInstance.get('/product-category/').then(({ data }) => data);
//
export const getById = (id: number): Promise<IRequest<IResponseMarketingCampaign>> =>
  axiosInstance.get(`/marketing-campaign/${id}/`).then(({ data }) => data);

export const patchById = (
  id: number,
  requestData: IUpdateRequestMarketingCampaignValue,
): Promise<IRequest<IUpdateResponseMarketingCampaignValue>> =>
  axiosInstance.patch(`/marketing-campaign/${id}/`, requestData).then(({ data }) => data);
//
export const deleteById = (id: number): AxiosPromise => axiosInstance.delete(`/marketing-campaign/${id}/`);

export const deleteProductById = (id: number): AxiosPromise =>
  axiosInstance.delete(`/marketing-campaign/product/${id}/`);

export const deleteMetricById = (id: number): AxiosPromise => axiosInstance.delete(`/marketing-campaign/metric/${id}/`);

export const deleteAttachmentById = (id: number): AxiosPromise =>
  axiosInstance.delete(`/marketing-campaign/attachment/${id}/`);

export const deleteProductFromMetricById = (id: number): AxiosPromise =>
  axiosInstance.delete(`/marketing-campaign/metric/product/${id}/`);

export const patchDataValueById = (
  id: number,
  requestData: IUpdateRequestDataValue,
): Promise<IRequest<IUpdateResponseDataValue>> =>
  axiosInstance.patch(`/marketing-campaign/metric-value/${id}/`, requestData).then(({ data }) => data);

export const patchProductsDataValueById = (
  requestData: IUpdateRequestProductsDataValue[],
): Promise<IRequest<IUpdateResponseProductsDataValue>> =>
  axiosInstance.patch('/marketing-campaign/metric/product/value/', requestData).then(({ data }) => data);

export const uploadFile = ({
  marketingCampaignId,
  file,
  url,
  description,
}: {
  marketingCampaignId: number;
  file?: File[];
  url?: string;
  description: string;
}): Promise<IRequest<IResponseCreateFile>> => {
  const bodyFormData = new FormData();
  bodyFormData.append('marketing_campaign', marketingCampaignId.toString());
  if (file) {
    const blob = new Blob(file, { type: file[0].type });
    const newFileByBlob = new File([blob], file[0].name);
    bodyFormData.append('type', 'file');
    bodyFormData.append('file', newFileByBlob);
  } else {
    bodyFormData.append('type', 'embed');
    bodyFormData.append('url', url);
  }

  bodyFormData.append('description', description);

  return axiosInstance
    .post('/marketing-campaign/attachment/', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);
};
