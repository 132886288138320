import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IResponseGroupPermission } from '../../interface/permissionInterface';
import { patchGroupPermissionById } from '../../service/permissionsService';
import { LoadingStatus } from '../../interface';

export class GroupPermissionClass {
  @observable private _loadingStatus: LoadingStatus = LoadingStatus.SUCCESS;

  @observable protected _id: number;

  @observable protected _name: string;

  @observable protected _permissionList: number[] = [];

  constructor(data: IResponseGroupPermission) {
    makeObservable(this);
    this._id = data.id;
    this._name = data.name;
    this._permissionList = data.permissions;
  }

  @computed
  get id() {
    return this._id;
  }

  @computed
  get name() {
    return this._name || '';
  }

  @computed
  get permissionList() {
    return this._permissionList;
  }

  @action
  setPermissionList = (permissionList: number[]) => {
    this._permissionList = permissionList;
  };

  @action
  pushPermissionList = (id: number) => {
    this._permissionList.push(id);
  };

  @action
  deletePermissionList = (id: number) => {
    const index = this._permissionList.indexOf(id);
    if (~index) {
      this._permissionList.splice(index, 1);
    }
  };

  @action patchGroupPermissionById = async () => {
    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await patchGroupPermissionById(this._id, { permissions: this._permissionList });
      runInAction(() => {
        this._id = data.id;
        this._name = data.name;
        this._permissionList = data.permissions;
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return true;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}
