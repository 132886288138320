import { useEffect, useRef, useState } from 'react';

const PADDING = 18;
const PAGINATION_HEIGHT = 54;
const HEADER_CELL_HEIGHT = 50;
// const SCROLL_HEIGHT = 8;
const ROW_HEIGHT = 52;

const useTableHeight = (withoutPadding = false) => {
  const homeSvgViewerRef = useRef(null);
  const [componentHeight, setComponentHeight] = useState(null);

  useEffect(() => {
    const top = homeSvgViewerRef?.current?.getBoundingClientRect()?.top;

    const height = window.innerHeight - top - (withoutPadding ? 0 : PADDING);

    if (!!top && height !== componentHeight) {
      setComponentHeight(height);
    }
  }, [componentHeight, homeSvgViewerRef?.current, setComponentHeight]);
  // 5???
  const sellQuantity = Math.floor((componentHeight - PAGINATION_HEIGHT - PADDING - HEADER_CELL_HEIGHT) / ROW_HEIGHT);

  return { sellQuantity, homeSvgViewerRef, componentHeight };
};

export default useTableHeight;
