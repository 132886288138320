import { PermissionType } from './permissionInterface';

export enum HttpResponseStatus {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

export enum LoadingStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const REFRESH_ENDPOINT = '/auth/token/refresh/';
export const AUTH_ENDPOINT = '/auth/token/obtain/';

export enum TokensNameInStorage {
  refresh = 'bearerTokenRefresh',
  access = 'bearerTokenAccess',
  loginAsUserAccess = 'loginAsUserAccess',
}

export interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_login: string;
  is_superuser: boolean;
  is_active: boolean;
  date_joined: string;
  company: { id: number; title: string } | null;
  groups: number[];
}

export interface ICreateUser {
  first_name?: string;
  last_name?: string;
  email: string;
  company: number[];
  groups?: number[];
  password: string;
}

export interface IUserInfo {
  id: number;
  current_company_id: { id: number; title: string };
  current_calendar_id: number;
  email: string;
  first_name: string;
  last_name: string;
  last_login: string;
  is_superuser: boolean;
  groups: string[];
  permissions: PermissionType[];
}
