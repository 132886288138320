import React, { useMemo, useReducer, useRef } from 'react';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import { IBaseProduct } from '../../../interface/productInterface';
import { ProductClass } from '../../../slass/data/product/ProductClass';
import useUsedAndUnUsed from '../../../component/ui/modal-list/hook/useUsedAndUnUsed';
import MainStore from '../../../store/MainStore';
import ModalList from '../../../component/ui/modal-list/ModalList';
import { LoadingStatus } from '../../../interface';

type State = Omit<IBaseProduct, 'company' | 'id'>;
type ActionType = keyof State;
const productReducer = (state: State, action: { type: ActionType; payload: any }): State => {
  const { type, payload } = action;
  switch (type) {
    case 'title':
      return {
        ...state,
        title: payload,
      };
    case 'categories':
      return {
        ...state,
        categories: payload,
      };
    default:
      return state;
  }
};

interface IProps {
  onCloseModal: () => void;
  instance?: ProductClass;
}
const ProductModal = ({ onCloseModal, instance }: IProps) => {
  const isEditableModeRef = useRef(!!instance);
  const { fetchCreate: fetchCreateProduct, loadingStatus } = MainStore.productStore;
  const { dataList: categoryList } = MainStore.productCategoryStore;

  const { usedElementList, unUsedElementsToOptions, setUnUsedElementsState } = useUsedAndUnUsed(
    categoryList,
    instance?.categories,
  );

  const initState: State = useMemo(
    () => ({
      title: instance?.title ?? '',
      description: instance?.description ?? '',
      categories: usedElementList.map((campaign) => campaign.id) ?? [],
    }),
    [instance, usedElementList],
  );

  const [state, dispatch] = useReducer(productReducer, initState);

  const onHandleChangeInput = (type: ActionType) => (event) => {
    dispatch({
      type,
      payload: event.target.value,
    });
  };

  const onSaveButtonHandle = () => {
    const fn = instance?.fetchPatch || fetchCreateProduct;
    fn(state).then((isSuccess) => {
      if (isSuccess) {
        onCloseModal();
      }
    });
  };

  const onSelectNewCategoriesHandler = (id) => {
    dispatch({
      type: 'categories',
      payload: [...state.categories, id],
    });
    setUnUsedElementsState((prevState) => prevState.filter((campaign) => campaign.id !== id));
  };

  const onRemoveCategoriesHandler = (id: number | string) => {
    dispatch({
      type: 'categories',
      payload: state.categories.filter((categoryId) => categoryId !== id),
    });
    setUnUsedElementsState((prevState) => [...prevState, categoryList.find((campaign) => campaign.id === id)]);
  };

  const isLoading = (instance?.loadingStatus || loadingStatus) === LoadingStatus.LOADING;

  return (
    <>
      <Box sx={{ margin: '25px 0' }}>
        <TextField
          id="standard-basic"
          placeholder="Title"
          variant="standard"
          sx={{ width: '100%' }}
          value={state.title}
          onChange={onHandleChangeInput('title')}
        />
      </Box>
      <Box sx={{ margin: '25px 0' }}>
        <ModalList
          label="Product categories"
          onRemoveElements={onRemoveCategoriesHandler}
          unUsedElementToOptionList={unUsedElementsToOptions}
          comparisonList={categoryList}
          onSelectElements={onSelectNewCategoriesHandler}
          usedElementsIdList={state.categories}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button variant="solid" onClick={onSaveButtonHandle} loading={isLoading}>
          Save
        </Button>
      </Box>
    </>
  );
};
export default ProductModal;
