import { useEffect } from 'react';

const useResizeWindow = (fn: (width?: number, height?: number) => void) => {
  useEffect(() => {
    const resizeHandler = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      if (fn) fn(windowWidth, windowHeight);
    };
    window.removeEventListener('resize', resizeHandler);
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, [fn]);
};

export default useResizeWindow;
