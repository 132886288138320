import React, { useMemo, useState } from 'react';
import { Box, Chip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import { GridRenderCellParams } from '@mui/x-data-grid';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Grid from '../../../component/ui/grid/Grid';
import Loader from '../../../component/ui/loader/Loader';
import useTableHeight from '../../../utils/hooks/useTableHeight';
import MainStore from '../../../store/MainStore';
import UserModal from './UserModalWrapper';
import { ReactComponent as EditIcon } from '../../../icon/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../icon/DeleteIcon.svg';
import DeleteUserModal from './DeleteUserModal';
import { UserClass } from '../../../slass/user/UserClass';
import DefaultModal from '../../../component/ui/default-modal/DefaultModal';
import useBaseGridBehavior from '../../../utils/hooks/useBaseGridBehavior';
import findPermission from '../../../component/permission/findPermission';
import { loginUserAs } from '../../../service/authService';
import { MainRoutesPath } from '../../../interface/router';
import { TokensNameInStorage } from '../../../interface';
import { CompanyClass } from '../../../slass/data/company/CompanyClass';
import BooleanRenderCell from '../../../component/ui/grid/BooleanRenderCell';

const AsUserButton = ({ id }: { id: number | string }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickAsUser = async () => {
    setIsLoading(true);
    try {
      const { data } = await loginUserAs(id);
      localStorage.setItem(TokensNameInStorage.loginAsUserAccess, data.accessToken);
      window.open(`${window.location.origin}/${MainRoutesPath.DASHBOARD_PAGE}`);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button color="primary" loading={isLoading} variant="plain" size="sm" onClick={onClickAsUser}>
      <ArrowOutwardIcon />
    </Button>
  );
};

interface IProps {
  userList: UserClass[];
  companyList?: CompanyClass[];
}

const UsersGrid = ({ userList, companyList }: IProps) => {
  const { permissions, isSuperuser } = MainStore.authStore;
  const { componentHeight, homeSvgViewerRef, sellQuantity } = useTableHeight();

  const { state, action } = useBaseGridBehavior();
  const { selectedComponent, isOpenDeleteComponentModal, isOpenNewComponentModal, isOpenModal } = state;
  const { onDeleteComponentModal, onCloseModal, onOpenEditComponentModal } = action;

  const { loadingStatus, fetchDeleteUser } = MainStore.userStore;

  const columns = useMemo(
    // : GridColDef[]
    () => [
      // { field: 'id', headerName: 'ID', hideable: false },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        field: 'company',
        headerName: 'Company',
        flex: 1,
        minWidth: 50,
        // maxWidth: 150,
      },
      {
        field: 'is_active',
        headerName: 'isActive',
        flex: 1,
        minWidth: 50,
        maxWidth: 100,
        renderCell: (params) => <BooleanRenderCell value={params.value} />,
      },
      isSuperuser
        ? {
            field: 'is_superuser',
            headerName: 'isSuperuser',
            minWidth: 50,
            renderCell: (params) => <BooleanRenderCell value={params.value} />,
          }
        : {},
      {
        field: 'groupList',
        headerName: 'Groups',
        flex: 1,
        minWidth: 50,
        renderCell: (params: GridRenderCellParams) => (
          <Box sx={{ display: 'flex', gap: '10px', maxWidth: '300px', flexWrap: 'wrap', margin: '10px 0' }}>
            {params.value.map((elId) => (
              <Chip key={`groupList_${elId}`} label={elId === 1 ? 'admin' : 'manager'} /> //todo change label logic
            ))}
          </Box>
        ),
      },
      isSuperuser
        ? {
            field: 'loginAsUser',
            headerName: 'Login as User',
            minWidth: 120,
            type: 'actions',
            renderCell: (params: GridRenderCellParams) => (
              <>{!params.row.is_superuser && <AsUserButton id={params.id} />}</>
            ),
          }
        : {},
      {
        field: 'editUser',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params) => (
          <>
            {findPermission(['user.change_user'], permissions) ? (
              <IconButton color="primary" variant="plain" onClick={onOpenEditComponentModal(params.id)}>
                <EditIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        field: 'deleteUser',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params) => (
          <>
            {findPermission(['user.delete_user'], permissions) ? (
              <IconButton color="danger" variant="plain" onClick={onDeleteComponentModal(params.id)}>
                <DeleteIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        ),
      },
    ],
    [],
  );

  const row = userList
    .reduce((prevState, currentValue) => {
      const currentRow = {
        id: currentValue.id,
        name: `${currentValue.firstName} ${currentValue.lastName}`,
        email: currentValue.email,
        is_superuser: currentValue.isSuperuser,
        is_active: currentValue.isActive,
        groupList: currentValue.groupList,
        ...(companyList ? { company: companyList.find((company) => company.id === currentValue.company)?.title } : {}),
      };
      prevState.push(currentRow);
      return prevState;
    }, [])
    .sort((a, b) => a.id - b.id);

  const userInstance = useMemo(
    () => userList.find((user) => user.id === selectedComponent),
    [userList, selectedComponent],
  );

  const isOpenEditModal = (selectedComponent === 0 || selectedComponent) && isOpenModal;

  return (
    <Box sx={{ height: componentHeight, width: '100%' }} ref={homeSvgViewerRef}>
      {componentHeight ? <Grid sellQuantity={sellQuantity} rows={row} columns={columns} /> : <Loader />}

      <DefaultModal open={isOpenEditModal || isOpenNewComponentModal}>
        <UserModal userInstance={userInstance} onCloseModalHandler={onCloseModal} />
      </DefaultModal>
      <DefaultModal open={isOpenDeleteComponentModal}>
        <DeleteUserModal
          onCloseModalHandler={onCloseModal}
          fetchDeleteUser={fetchDeleteUser}
          loadingStatus={loadingStatus}
          userInstance={userInstance}
        />
      </DefaultModal>
    </Box>
  );
};
export default observer(UsersGrid);
