import { makeObservable } from 'mobx';
import { BaseMarketingClass } from './BaseMarketingClass';

export class MarketingChannelClass extends BaseMarketingClass {
  constructor(props, serviceConductor) {
    super(serviceConductor);
    this.snakeToCamelBuilder(props);
    makeObservable(this);
  }
}
