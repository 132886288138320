import { CalendarClass } from './CalendarClass';

export class CompetitorCalendarClass extends CalendarClass {
  private readonly _id: number;

  private readonly _company: number;

  private readonly _user: number;

  private readonly _title: string;

  private readonly _description: string;

  private readonly _content: string;

  //todo type
  constructor(data) {
    super(data.content_json);
    this._id = data.id;
    this._company = data.company;
    this._user = data.user;
    this._title = data.title;
    this._description = data.description;
    this._content = data.content;
  }

  get id() {
    return this._id;
  }

  get company() {
    return this._company;
  }

  get user() {
    return this._user;
  }

  get title() {
    return this._title;
  }

  get description() {
    return this._description;
  }

  get content() {
    return this._content;
  }
}
