import * as yup from 'yup';

export enum FormFields {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export const MIN_PASSWORD_LENGTH = 8;

export const confirmPassword = yup.string().oneOf([yup.ref(FormFields.PASSWORD), null], 'Passwords must match');

export const passwordValidation = yup
  .string()
  .min(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters`)
  .matches(/^(?=.*[a-zA-Z])(?=.*\d).+$/, 'Must Contain Number and Letter')
  .required('Password is required');

export const loginValidationSchema = yup.object({
  [FormFields.CONFIRM_PASSWORD]: confirmPassword,
  [FormFields.PASSWORD]: passwordValidation,
});
