import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  InputAdornment,
} from '@mui/material';
import Button from '@mui/joy/Button';
import { observer } from 'mobx-react-lite';
import onHandleChangeInput from '../../../utils/onHandleChangeInput';
import { ProductForCalendarClass } from '../../../slass/calendar/ProductForCalendarClass';
import { CampaignMetricsClass } from '../../../slass/data/campaign-metrics/CampaignMetricsClass';
import { MetricForCalendarClass } from '../../../slass/calendar/MetricForCalendarClass';

const HEIGHT = 48;
const COEFFICIENT = 4.5;
const ITEM_HEIGHT = HEIGHT * COEFFICIENT;

interface IProps<Class> {
  header: { title: string; slug: string }[];
  unUsedElementList: Class[];
  usedElementList: Class[];
  onRemoveElements: (id: number) => void;
  onSelectElements: (id: number) => void;
  metric: CampaignMetricsClass | MetricForCalendarClass;
}

// eslint-disable-next-line react/function-component-definition
function PromotedProductModalList<Class extends ProductForCalendarClass>({
  header,
  unUsedElementList,
  usedElementList,
  onRemoveElements,
  onSelectElements,
  metric,
}: IProps<Class>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [IsOpenUnUsed, setIsOpenUnUsed] = useState(false);

  const onOpenAddHandler = (value: boolean) => (event) => {
    setIsOpenUnUsed(value);
    if (value) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const onSelectElementsHandler = (id: number) => () => {
    onOpenAddHandler(false)(null);
    onSelectElements(id);
  };

  const onRemoveElementsHandler = (id: number) => () => {
    onOpenAddHandler(false)(null);
    onRemoveElements(id);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map((head) => (
                <TableCell
                  key={`TableHead_${head.slug}_${head.title}`}
                  sx={{ padding: head.slug === 'title' ? '0 16px 0 0' : '6px 16px', fontWeight: 600 }}
                >
                  {head.title}
                </TableCell>
              ))}

              <TableCell align="right" sx={{ padding: '0 0 0 16px' }}>
                <Button
                  sx={{
                    fontWeight: 700,
                    fontSize: '22px',
                    lineHeight: '20px',
                  }}
                  size="md"
                  variant="plain"
                  onClick={onOpenAddHandler(true)}
                  disabled={!unUsedElementList.length}
                >
                  +
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usedElementList.map((row) => (
              <TableRow
                key={`PromotedProductModalList_TableBody_${row.product.id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ padding: '0 16px 0 0' }}>
                  {row.product.title}
                </TableCell>

                <TableCell component="th" scope="row" sx={{ padding: '0 16px' }}>
                  <Switch checked={row.autoDistribute} onChange={(e, value) => row.setAutoDistribute(value)} />
                </TableCell>
                <TableCell component="th" scope="row" sx={{ padding: '0 16px', maxWidth: '180px' }}>
                  <Input
                    disabled={!row.autoDistribute}
                    id={`TextField_TableBody_price_${row.product.title}`}
                    value={row.amount}
                    onChange={onHandleChangeInput(row.setAmount)}
                  />
                </TableCell>
                <TableCell component="th" scope="row" sx={{ padding: '0 16px', maxWidth: '180px' }}>
                  <Input
                    // id={`TextFieldTableBody_${row.title}`}
                    value={row.price}
                    onChange={onHandleChangeInput(row.setPrice)}
                    startAdornment={
                      metric.unit === 'price' ? <InputAdornment position="start">$</InputAdornment> : null
                    }
                  />
                </TableCell>

                <TableCell align="right" sx={{ padding: '0 0 0 16px' }}>
                  <Button
                    sx={{
                      fontWeight: 700,
                      fontSize: '22px',
                      lineHeight: '20px',
                      width: '44px',
                    }}
                    variant="plain"
                    color="danger"
                    size="md"
                    onClick={onRemoveElementsHandler(row.product.id)}
                  >
                    -
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="Button-on-OpenAddCampaignMetricsHandler-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={IsOpenUnUsed}
        onClose={onOpenAddHandler(false)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT,
          },
        }}
      >
        {unUsedElementList.map((option) => (
          <MenuItem
            key={`MenuItem_${option.id}_${option.product.title}`}
            onClick={onSelectElementsHandler(option.product.id)}
          >
            {option.product.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default observer(PromotedProductModalList);
