import React, { useState } from 'react';
import { Sheet } from '@mui/joy';

import { observer } from 'mobx-react-lite';
import { UserClass } from '../../../slass/user/UserClass';
import UserModalMainContent from './UserModalMainContent';
import UserModalChangePassword from './UserModalChangePassword';

const UserModalWrapper = ({
  userInstance,
  onCloseModalHandler,
}: {
  userInstance: UserClass;
  onCloseModalHandler: () => void;
}) => {
  const [isChangePassword, setIsChangePassword] = useState(false);

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: 580,
        borderRadius: 'md',
        p: 3,
      }}
    >
      {isChangePassword ? (
        <UserModalChangePassword
          userInstance={userInstance}
          onCloseModalHandler={onCloseModalHandler}
          setIsChangePassword={setIsChangePassword}
        />
      ) : (
        <UserModalMainContent
          userInstance={userInstance}
          onCloseModalHandler={onCloseModalHandler}
          setIsChangePassword={setIsChangePassword}
        />
      )}
    </Sheet>
  );
};

export default observer(UserModalWrapper);
