import React, { memo } from 'react';
import { Box, Modal } from '@mui/material';

interface IProps {
  open: boolean;
  children: JSX.Element;
}
const DefaultModal = ({ open, children }: IProps) => (
  <Modal
    aria-labelledby="close-modal-title"
    open={open}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // maxHeight: '95vh',
    }}
  >
    {children}
  </Modal>
);
export default memo(DefaultModal);
