import axiosInstance, { IRequest } from './axiosService';
import {
  IResponsePermission,
  IResponseGroupPermission,
  IUpdateRequestGroupPermission,
} from '../interface/permissionInterface';

export const getPermissionList = (): Promise<IRequest<IResponsePermission[]>> =>
  axiosInstance.get('/auth_permission/').then(({ data }) => data);

export const getPermissionById = (id: number): Promise<IRequest<IResponsePermission>> =>
  axiosInstance.get(`/auth_permission/${id}/`).then(({ data }) => data);

export const getGroupPermissionList = (): Promise<IRequest<IResponseGroupPermission[]>> =>
  axiosInstance.get('/auth_group/').then(({ data }) => data);

export const getGroupPermissionById = (id: number): Promise<IRequest<IResponseGroupPermission>> =>
  axiosInstance.get(`/auth_group/${id}/`).then(({ data }) => data);

export const patchGroupPermissionById = (
  id: number,
  data: IUpdateRequestGroupPermission,
): Promise<IRequest<IResponseGroupPermission>> =>
  axiosInstance.patch(`/auth_group/${id}/`, data).then(({ data }) => data);
