import dayjs from 'dayjs';

interface ICheckerValue {
  newValue: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
}
type CheckerCallback = (value: dayjs.Dayjs) => void;

export class DateUtils {
  public static startDateChangeChecker({ newValue, endDate, startDate }: ICheckerValue, callback: CheckerCallback) {
    if (newValue.isAfter(endDate)) {
      callback(newValue.add(endDate.diff(startDate, 'day'), 'day'));
    }
  }

  public static endDateChangeChecker({ newValue, endDate, startDate }: ICheckerValue, callback: CheckerCallback) {
    if (newValue.isBefore(startDate)) {
      callback(newValue.subtract(endDate.diff(startDate, 'day'), 'day'));
    }
  }

  public static findDatesInRange(
    dates: string[],
    startDateStr: string | dayjs.Dayjs | Date,
    endDateStr: string | dayjs.Dayjs | Date,
  ) {
    const result: string[] = [];

    const startDateUTC = this.formatterToDayjs(startDateStr).unix();

    const endDateUTC = this.formatterToDayjs(endDateStr).unix();

    for (const dateStr of dates) {
      const date = dayjs(dateStr, 'YYYY-MM-DD');
      if (startDateUTC <= date.unix() && date.unix() <= endDateUTC) {
        result.push(date.format('YYYY-MM-DD'));
      }
    }

    return result;
  }

  public static formatterToDayjs(date: string | dayjs.Dayjs | Date, format = 'YYYY-MM-DD') {
    return typeof date === 'object' && date instanceof dayjs.Dayjs ? date : dayjs(date, format);
  }
}
