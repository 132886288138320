import React from 'react';
import { observer } from 'mobx-react-lite';
import ChangelogGrid from './ChangelogGrid';

const Changelog = () => (
  <section className="section">
    <ChangelogGrid />
  </section>
);

export default observer(Changelog);
