import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';
import {
  ICreatedResponseMarketingPillar,
  ICreateRequestDataMarketingPillar,
  ICreateRequestMarketingChannel,
  ICreateResponseMarketingChannel,
  IResponseMarketingPillar,
  IResponsePatchMarketingPillar,
  TRequestSortingUpdate,
  TResponseSortingUpdate,
} from '../interface/marketingPilarInterface';

export const create = (
  requestData: ICreateRequestDataMarketingPillar,
): Promise<IRequest<ICreatedResponseMarketingPillar>> =>
  axiosInstance.post('/marketing-pillar/', requestData).then(({ data }) => data);

export const getList = (): Promise<IRequest<IResponseMarketingPillar[]>> =>
  axiosInstance.get('/marketing-pillar/').then(({ data }) => data);

export const getById = (id: number): Promise<IRequest<IResponseMarketingPillar>> =>
  axiosInstance.get(`/marketing-pillar/${id}/`).then(({ data }) => data);

export const patchById = (
  id: number,
  requestData: Record<string, any>,
): Promise<IRequest<IResponsePatchMarketingPillar>> =>
  axiosInstance.patch(`/marketing-pillar/${id}/`, requestData).then(({ data }) => data);

export const deleteById = (id: number): AxiosPromise => axiosInstance.delete(`/marketing-pillar/${id}/`);

export const createMarketingChannel = (
  requestData: ICreateRequestMarketingChannel,
): Promise<IRequest<ICreateResponseMarketingChannel>> =>
  axiosInstance.post('/marketing-pillar/', requestData).then(({ data }) => data);

export const sorting = (requestData: TRequestSortingUpdate): Promise<IRequest<TResponseSortingUpdate>> =>
  axiosInstance.post('/marketing-pillar/sorting_update/', requestData).then(({ data }) => data);
