import { TypeOfCampaign, UnitOfCampaign } from './campaignMetricInterface';
import { IPatchValueResponseCustomField } from './customFieldInterface';

export interface IProductValueForMetric {
  id: number;
  product: {
    id: number;
    title: string;
  };
  value: 213;
}

export interface IMetric {
  id: number;
  campaign_metric_id: number;
  value: number;
  for_date: string;
  type: TypeOfCampaign;
  unit: UnitOfCampaign;
  product_values: IProductValueForMetric[];
}
export interface IMarketingCampaign {
  id: number;
  title: string;
  start_date: string;
  end_date: string;
  deadline_date: string;
  metrics: IMetric[];
  budget: number;
  calculated_budget: number; //(metrics sum)
}

export interface ICampaignMetric {
  id: number;
  title: string;
  order: number;
}

export interface IMarketingChannels {
  id: number;
  title: string;
  campaign_metrics: Record<ICampaignMetric['id'], ICampaignMetric>;
  marketing_campaigns: Record<IMarketingCampaign['id'], IMarketingCampaign>;
  bg_color?: string;
  budget: number;
  calculated_budget: number;
  order: number;
}
export interface IMarketingPillar extends IMarketingChannels {
  has_marketing_channels: boolean;
  marketing_channels: Record<IMarketingChannels['id'], IMarketingChannels>;
  bg_color?: string;
}

export type Range = 'week' | 'month';

export enum StartWeekDay {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export const startWeekDayTitleMap = {
  [StartWeekDay.MONDAY]: 'Monday',
  [StartWeekDay.TUESDAY]: 'Tuesday',
  [StartWeekDay.WEDNESDAY]: 'Wednesday',
  [StartWeekDay.THURSDAY]: 'Thursday',
  [StartWeekDay.FRIDAY]: 'Friday',
  [StartWeekDay.SATURDAY]: 'Saturday',
  [StartWeekDay.SUNDAY]: 'Sunday',
};

export const mapHiddenDays = {
  [StartWeekDay.MONDAY]: 1,
  [StartWeekDay.TUESDAY]: 2,
  [StartWeekDay.WEDNESDAY]: 3,
  [StartWeekDay.THURSDAY]: 4,
  [StartWeekDay.FRIDAY]: 5,
  [StartWeekDay.SATURDAY]: 6,
  [StartWeekDay.SUNDAY]: 0,
};

export interface ITotalValue {
  for_date: string;
  value: number;
  id: number;
}

export type ITotalCompetitorsValue = Record<string, Record<'value', number>>;

export interface ITotal {
  title: string;
  values: ITotalValue[] | ITotalCompetitorsValue;
  predicted_values?: ITotalValue[];
  order: number;
}

export interface IAnnotations {
  id: number;
  title: string;
  values: IPatchValueResponseCustomField[];
  order: number;
}

export interface ICalendarResponse {
  range: Range;
  start_week_day: StartWeekDay;
  start_date: string;
  end_date: string;
  dates_list: string[];
  annotations: IAnnotations[];
  marketing_pillars: Record<IMarketingPillar['id'], IMarketingPillar>;
  totals: Record<string, ITotal>;
  budget: number;
  calculated_budget: number;
}

export interface IResponseCreateShareLink {
  code: string;
}

export interface IResponsePlanningCalendarShareCode {
  id: number;
  code: string;
  start_date: string;
  end_date: string;
}
