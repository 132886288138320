import React, { lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MainStore from '../store/MainStore';
import { LoadingStatus } from '../interface';
import Loader from '../component/ui/loader/Loader';
import Header from '../component/layout/header/Header';
import GlobalWrapper from '../component/layout/global_wrapper/GlobalWrapper';
import ManagerContentWrapper from '../component/layout/manager_content_wrapper/ManagerContentWrapper';
import PlanningPage from '../page/planning/PlanningPage';
import ProtectedRoute from './ProtectedRoute';
import { addSlash, MainRoutesPath, PlanningRoutesPath, SettingsRoutesPath } from '../interface/router';
import SettingsPage from '../page/settings/SettingsPage';
import ProductsPage from '../page/settings/products/ProductsPage';
import UsersPage from '../page/settings/users/UsersPage';
import MarketingPillarsPage from '../page/marketing-pillars/MarketingPillarsPage';
import CampaignMetricsPage from '../page/campaign-metrics/CampaignMetricsPage';
import ListOfCalendarPage from '../page/competitors/ListOfCalendarPage';
import Changelog from '../page/changelog/ChangelogPage';
import AlertsWebSockets from '../component/webSockets/AlertsWebSockets';
import CustomFieldsPage from '../page/custom-fields/CustomFieldsPage';
import ShareCalendarPage from '../page/share/ShareCalendarPage';
import DashboardPage from '../page/dashboard/DashboardPage';
import CanWithAlert from '../component/permission/CanWithAlert';
import { routerPermissionMap } from '../interface/permissionInterface';
import PermissionsPage from '../page/settings/permissions/PermissionsPage';
import NotFoundPage from '../page/404/NotFoundPage';
import ResetPasswordPage from '../page/auth/ResetPasswordPage';

const LoginPage = lazy(() => import('../page/auth/LoginPage'));

const RouterComponent = () => {
  return (
    <>
      <AlertsWebSockets />
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path={MainRoutesPath.HOME_PAGE}
            element={<Navigate to={`${MainRoutesPath.PLANNING_PAGE}${addSlash(PlanningRoutesPath.CALENDAR)}`} />}
          />

          <Route path={MainRoutesPath.DASHBOARD_PAGE} element={<ProtectedRoute />}>
            <Route
              path=""
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[MainRoutesPath.DASHBOARD_PAGE]}>
                        <DashboardPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
          </Route>
          {/*<Route path={addSlash(MainRoutesPath.PLANNING_PAGE)} element={<ProtectedRoute />}>*/}
          <Route path={MainRoutesPath.PLANNING_PAGE} element={<ProtectedRoute />}>
            <Route
              path=""
              element={
                <GlobalWrapper>
                  <>
                    <Header isShowShare />
                    <ManagerContentWrapper style={{ overflow: 'visible' }}>
                      <CanWithAlert permissionList={routerPermissionMap[PlanningRoutesPath.CALENDAR]}>
                        <PlanningPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
            <Route
              path={PlanningRoutesPath.CALENDAR}
              element={
                <GlobalWrapper>
                  <>
                    <Header isShowShare />
                    <ManagerContentWrapper style={{ overflow: 'visible' }}>
                      <CanWithAlert permissionList={routerPermissionMap[PlanningRoutesPath.CALENDAR]}>
                        <PlanningPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
            <Route
              path={PlanningRoutesPath.MARKETING_PILLAR}
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[PlanningRoutesPath.MARKETING_PILLAR]}>
                        <MarketingPillarsPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
            <Route
              path={PlanningRoutesPath.CAMPAIGN_METRICS}
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[PlanningRoutesPath.CAMPAIGN_METRICS]}>
                        <CampaignMetricsPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
            <Route
              path={PlanningRoutesPath.CUSTOM_FIELDS}
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[PlanningRoutesPath.CUSTOM_FIELDS]}>
                        <CustomFieldsPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
            <Route
              path={PlanningRoutesPath.PRODUCTS}
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[PlanningRoutesPath.PRODUCTS]}>
                        <ProductsPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
          </Route>
          <Route
            path={MainRoutesPath.CHANGELOG_PAGE}
            element={
              <GlobalWrapper>
                <>
                  <Header />
                  <ManagerContentWrapper>
                    <CanWithAlert permissionList={routerPermissionMap[MainRoutesPath.CHANGELOG_PAGE]}>
                      <Changelog />
                    </CanWithAlert>
                  </ManagerContentWrapper>
                </>
              </GlobalWrapper>
            }
          />
          <Route path={MainRoutesPath.COMPETITORS_PAGE} element={<ProtectedRoute />}>
            <Route
              path=""
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[MainRoutesPath.COMPETITORS_PAGE]}>
                        <ListOfCalendarPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
          </Route>
          <Route path={MainRoutesPath.SETTINGS_PAGE} element={<ProtectedRoute />}>
            <Route
              path=""
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[SettingsRoutesPath.COMPANIES]}>
                        <UsersPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
            <Route
              path={SettingsRoutesPath.COMPANIES}
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[SettingsRoutesPath.COMPANIES]}>
                        <UsersPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
            <Route
              path={SettingsRoutesPath.PERMISSIONS}
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <CanWithAlert permissionList={routerPermissionMap[SettingsRoutesPath.PERMISSIONS]}>
                        <PermissionsPage />
                      </CanWithAlert>
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
              // SettingsRoutesPath.PERMISSIONS
            />
            <Route
              path="*"
              element={
                <GlobalWrapper>
                  <>
                    <Header />
                    <ManagerContentWrapper>
                      <SettingsPage />
                    </ManagerContentWrapper>
                  </>
                </GlobalWrapper>
              }
            />
          </Route>
          <Route path={MainRoutesPath.LOGIN_PAGE} element={<LoginPage />} />
          <Route path={`${MainRoutesPath.RESET_PASSWORD_PAGE}/`} element={<ResetPasswordPage />} />
          <Route path={`${MainRoutesPath.SHARE}/:shareId`} element={<ShareCalendarPage />} />
          <Route path={MainRoutesPath.NOT_FOUND} element={<NotFoundPage />} />
        </Routes>
      </React.Suspense>
    </>
  );
};

export default observer(RouterComponent);
