import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as NivoColors from '@nivo/colors';
import { Autocomplete } from '@mui/joy';
import Chip from '@mui/joy/Chip';
import { Box, Paper, Stack } from '@mui/material';
import Typography from '@mui/joy/Typography';
import BarBlockWrapper from './BarBlockWrapper';
import getDataElementIndexByList from '../../../utils/getDataElementIndexByList';
import scss from '../DashboardPage.module.scss';
import BarChart from './BarChart';
import { CalendarClass } from '../../../slass/calendar/CalendarClass';
import { MarketingPillarForCalendarClass } from '../../../slass/calendar/MarketingPillarForCalendarClass';
import { MarketingChannelForCalendarClass } from '../../../slass/calendar/MarketingChannelForCalendarClass';

const nivoColors = NivoColors.colorSchemes.set2 as string[];

interface IPreDataValue {
  title: string;
  id: number;
  metric: Record<string, any> | {}; // { [title]: number; date: string }
}

interface IProps {
  instance: CalendarClass;
}

const MetricsWeeklyPerformanceChart = ({ instance }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<{ label: string; id: number }>(null);

  const preData: Record<string, IPreDataValue> = useMemo(
    () =>
      instance.marketingPillars.reduce((state: Record<number, IPreDataValue>, marketingPillar) => {
        const builder = (pillar: MarketingPillarForCalendarClass | MarketingChannelForCalendarClass) => {
          // set campaignMetric
          pillar.campaignMetricsList.forEach((campaignMetric) => {
            if (!state[campaignMetric.id]) {
              state[campaignMetric.id] = {
                id: campaignMetric.id,
                title: campaignMetric.title,
                metric: {},
              };
            }
          });

          // set data
          pillar.marketingCampaignsList.forEach((marketingCampaign) => {
            marketingCampaign.metricsList.forEach((metric) => {
              const campaignMetric = state[metric.campaignMetricId];
              const metricDate = dayjs(metric.forDate, 'YYYY-MM-DD');
              const unixMetricDate = metricDate.unix();
              const campaignMetricDate = campaignMetric.metric[unixMetricDate];

              const metricTitle = `${pillar.title}-${marketingCampaign.title}(${dayjs(
                marketingCampaign.startDate,
                'YYYY-MM-DD',
              ).format('M/D')}-${dayjs(marketingCampaign.endDate, 'YYYY-MM-DD').format('M/D')})`;
              const metricValue = metric.value;
              if (campaignMetricDate) {
                campaignMetricDate[metricTitle] = metricValue;
              } else {
                campaignMetric.metric[unixMetricDate] = { date: metricDate, [metricTitle]: metricValue };
              }
            });
          });
        };

        if (marketingPillar.hasMarketingChannels) {
          marketingPillar.marketingChannels.forEach((marketingChannel) => {
            builder(marketingChannel);
          });
        } else {
          builder(marketingPillar);
        }

        return state;
      }, {}),
    [instance],
  );

  const autocompleteOptions = useMemo(
    () => Object.values(preData).map((preDataValue) => ({ id: preDataValue.id, label: preDataValue.title })),
    [preData],
  );

  const dataObj = useMemo(() => {
    const data = [];
    const keys: Set<string> = new Set();
    if (selectedOption?.id && preData) {
      Object.entries(preData[selectedOption.id].metric) // @ts-ignore
        .sort(([dateA], [dateB]) => dateA.date - dateB.date)
        .forEach(([key, value]) => {
          // @ts-ignore
          data.push({ ...value, date: value.date.format('MM-DD-YYYY') });
          (({ date, ...o }) => Object.keys(o))(value).forEach((campaignName) => keys.add(campaignName));
        });
    }
    return { keys: Array.from(keys), data };
  }, [selectedOption, preData]);

  const onChangeAutocomplete = (e, value) => {
    setSelectedOption(value);
  };

  return (
    <BarBlockWrapper
      header={
        <Stack spacing={2}>
          <Typography level="h6" component="h6" sx={{ fontWeight: 500 }}>
            Metrics Weekly Performance
          </Typography>
          <Autocomplete
            id="TotalProjectSalesBarWrapper_Autocomplete_tags-Total"
            placeholder="Metric"
            size="sm"
            value={selectedOption as unknown as (string | { label: string; id: number })[]}
            getOptionLabel={(option) => (option as unknown as { label: string; id: number }).label}
            options={autocompleteOptions}
            onChange={onChangeAutocomplete}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
            {dataObj.keys.map((key, index) => {
              const chipColor = getDataElementIndexByList<string>(index, nivoColors);
              return (
                <Chip
                  key={`MetricsWeeklyPerformanceChart_legend_${key}_${chipColor}`}
                  variant="soft"
                  size="sm"
                  className={scss.totalsSelect}
                  sx={{ backgroundColor: `${chipColor}` }}
                >
                  {key}
                </Chip>
              );
            })}
          </Box>
        </Stack>
      }
      chart={
        <BarChart
          data={dataObj.data}
          keys={dataObj.keys}
          // axisBottomLegend="Date"
          // axisLeftLegend="Count"
          settings={{
            indexBy: 'date',
            tooltip: (props) => (
              <Paper elevation={1} className={scss.chartTooltip}>
                <Box className={scss.chartTooltip_titleWrapper}>
                  <Box className={scss.chartTooltip_color} sx={{ backgroundColor: props.color }} />
                  <span>{props.id}</span>
                </Box>
                <Box>
                  Date: <span>{props.data.date}</span>
                </Box>
                <Box>
                  Value: <span>{props.value}</span>
                </Box>
              </Paper>
            ),
            margin: { top: 10, right: 10, bottom: 60, left: 50 },
          }}
        />
      }
      minWidth="900px"
      selectedTotalOptionList={selectedOption}
    />
  );
};
export default observer(MetricsWeeklyPerformanceChart);
