import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { LoadingStatus } from '../../interface';
import Loader from '../../component/ui/loader/Loader';

const LoaderBlock = ({
  dataLength,
  loadingStatus,
  child,
}: {
  dataLength: number;
  loadingStatus: LoadingStatus;
  child: ReactElement;
}) => (
    <>
      {loadingStatus === LoadingStatus.LOADING ? (
        <Box sx={{ height: '100%' }}>
          <Loader />
        </Box>
      ) : (
        <>{loadingStatus === LoadingStatus.SUCCESS && !!dataLength && child}</>
      )}
    </>
  );

export default LoaderBlock;
