import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { LoadingStatus } from '../../interface';
import Loader from '../../component/ui/loader/Loader';
import PlanningCalendar from '../../component/planning-calendar/PlanningCalendar';
import MainStore from '../../store/MainStore';
import { CompetitorClass } from '../../slass/competitor/CompetitorClass';
import useIsMounted from '../../utils/hooks/useIsMounted';
import useVisibleCalendar from '../../component/planning-calendar/hook/useVisibleCalendar';
import useCollapseCalendarButton from '../../utils/hooks/useCollapseCalendarButton';

interface IProps {
  id: number;
}
const CompetitorCalendarPreview = ({ id }: IProps) => {
  const calendarRef = useRef(null);
  const isVisible = useVisibleCalendar();
  const isMounted = useIsMounted();
  const [instanceLoadingStatus, setInstanceLoadingStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [curretId, setCurretId] = useState(null);
  const wrapperRef = useRef<HTMLInputElement>();
  const { competitorList, selectedCompetitor, loadingCompetitorStatus } = MainStore.competitorStore;

  useEffect(() => {
    const isCanFetch = !!id && id !== curretId && selectedCompetitor?.id !== id && isMounted();

    if (isCanFetch) {
      const fn = async (instance: CompetitorClass) => {
        setCurretId(curretId);
        setInstanceLoadingStatus(LoadingStatus.LOADING);
        try {
          const isSuccess = await instance.fetchPatch({ status: 'SELECTED' });
          if (isSuccess) {
            if (isMounted()) {
              setInstanceLoadingStatus(LoadingStatus.SUCCESS);
              MainStore.competitorStore.switchOnProcessedStatusCompetitor(id);
              await MainStore.competitorStore.fetchSelectedCompetitor();
            }
          } else {
            setInstanceLoadingStatus(LoadingStatus.ERROR);
          }
        } catch {
          setInstanceLoadingStatus(LoadingStatus.ERROR);
        }
      };

      const foundInstance = competitorList.find((competitor) => competitor.id === id);
      if (foundInstance) {
        fn(foundInstance);
      }
    } else {
      setInstanceLoadingStatus(LoadingStatus.SUCCESS);
    }
  }, [id, curretId, competitorList, selectedCompetitor, isMounted]);

  const { onCollapseCallback } = useCollapseCalendarButton(calendarRef);

  const onMountHandlerCallback = useCallback(() => {
    // onMountHandler();
  }, []);

  return (
    <>
      <Box ref={wrapperRef} sx={{ height: '100%' }}>
        {(loadingCompetitorStatus === LoadingStatus.LOADING || instanceLoadingStatus === LoadingStatus.LOADING) && (
          <Loader />
        )}
        {(loadingCompetitorStatus === LoadingStatus.ERROR || instanceLoadingStatus === LoadingStatus.ERROR) && (
          <div>ERROR</div>
        )}
        {loadingCompetitorStatus === LoadingStatus.SUCCESS &&
          instanceLoadingStatus === LoadingStatus.SUCCESS &&
          selectedCompetitor &&
          wrapperRef?.current &&
          isVisible && (
            <PlanningCalendar
              instance={selectedCompetitor}
              offsetHeight={wrapperRef?.current?.offsetHeight}
              calendarRef={calendarRef}
              datesSet={onMountHandlerCallback}
              onCollapse={onCollapseCallback}
            />
          )}
      </Box>
    </>
  );
};

export default observer(CompetitorCalendarPreview);
