import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';

import {
  ICreateRequestProductCategory,
  ICreateResponseProductCategory,
  IRequestPatchProductCategory,
  IResponsePatchProductCategory,
  IResponseProductCategory,
} from '../interface/productCategoryInterface';

export const create = (requestData: ICreateRequestProductCategory): Promise<IRequest<ICreateResponseProductCategory>> =>
  axiosInstance.post('/product-category/', requestData).then(({ data }) => data);

export const getList = (): Promise<IRequest<IResponseProductCategory[]>> =>
  axiosInstance.get('/product-category/').then(({ data }) => data);

export const getById = (id: number): Promise<IRequest<IResponseProductCategory>> =>
  axiosInstance.get(`/product-category/${id}/`).then(({ data }) => data);

export const patchById = (
  id: number,
  requestData: IRequestPatchProductCategory,
): Promise<IRequest<IResponsePatchProductCategory>> =>
  axiosInstance.patch(`/product-category/${id}/`, requestData).then(({ data }) => data);

export const deleteById = (id: number): AxiosPromise => axiosInstance.delete(`/product-category/${id}/`);
