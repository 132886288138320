import { action, makeObservable, runInAction } from 'mobx';
import { BaseStoreAbstract } from './BaseStore.abstract';
import { CustomFieldClass } from '../slass/data/custom-fields/CustomFieldClass';
import * as customFieldService from '../service/customFieldService';
import { TRequestSortingUpdate } from '../interface/customFieldInterface';
import { LoadingStatus } from '../interface';

class CustomFieldsStore extends BaseStoreAbstract<CustomFieldClass> {
  protected _mainDataClass = CustomFieldClass;

  protected _serviceConductor = customFieldService;

  constructor() {
    super();
    makeObservable(this);
  }

  @action changeOrder = async (orders: { order: number; id: number }[]) => {
    orders.forEach((orderObj) => {
      this._mainDataList.find((marketingPillar) => marketingPillar.id === orderObj.id).setOrder(orderObj.order);
    });
    const prepareData: TRequestSortingUpdate = orders.map((order) => ({
      order: order.order,
      annotation: order.id,
    }));
    this._mainDataList = this._mainDataList.sort((a, b) => a.order - b.order);

    try {
      await this._serviceConductor.sorting(prepareData);
      runInAction(() => {
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return this._mainDataList;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}

export default CustomFieldsStore;
