import { AxiosPromise } from 'axios';
import axiosInstance, { IRequest } from './axiosService';
import {
  ICreateRequestCustomField,
  ICreateResponseCustomField,
  IPatchRequestCustomField,
  IPatchResponseCustomField,
  IPatchValueRequestCustomField,
  IPatchValueResponseCustomField,
  TRequestSortingUpdate,
  TResponseSortingUpdate,
} from '../interface/customFieldInterface';

export const create = (requestData: ICreateRequestCustomField): Promise<IRequest<ICreateResponseCustomField>> =>
  axiosInstance.post('/planning-calendar/annotation/', requestData).then(({ data }) => data);

export const getList = (): Promise<IRequest<ICreateResponseCustomField[]>> =>
  axiosInstance.get('/planning-calendar/annotation/').then(({ data }) => data);

export const getById = (id: number): Promise<IRequest<ICreateResponseCustomField>> =>
  axiosInstance.get(`/planning-calendar/annotation/${id}/`).then(({ data }) => data);

export const patchById = (
  id: number,
  requestData: IPatchRequestCustomField,
): Promise<IRequest<IPatchResponseCustomField>> =>
  axiosInstance.patch(`/planning-calendar/annotation/${id}/`, requestData).then(({ data }) => data);

export const deleteById = (id: number): AxiosPromise => axiosInstance.delete(`/planning-calendar/annotation/${id}/`);

export const patchValueById = (
  id: number,
  requestData: IPatchValueRequestCustomField,
): Promise<IRequest<IPatchValueResponseCustomField>> =>
  axiosInstance.patch(`/planning-calendar/annotation/${id}/update_value/`, requestData).then(({ data }) => data);

export const sorting = (requestData: TRequestSortingUpdate): Promise<IRequest<TResponseSortingUpdate>> =>
  axiosInstance.post('/planning-calendar/annotation/sorting_update/', requestData).then(({ data }) => data);
