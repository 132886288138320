import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography } from '@mui/material';
import { PermissionType } from '../../interface/permissionInterface';
import MainStore from '../../store/MainStore';
import findPermission from './findPermission';

interface IProps {
  children: ReactNode;
  permissionList: PermissionType[];
}

const CanWithAlert = ({ children, permissionList }: IProps) => {
  const { permissions } = MainStore.authStore;

  const foundPermission = findPermission(permissionList, permissions);

  if (foundPermission) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        color: '#D3232F',
        display: 'flex',
        flexAlign: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5" component="h5">
        YOU HAVE NO ACCESS
      </Typography>
    </Box>
  );
};

export default observer(CanWithAlert);
