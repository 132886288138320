import { GridColDef } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import IconButton from '@mui/joy/IconButton';
import { ReactComponent as EditIcon } from '../../../../icon/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../icon/DeleteIcon.svg';

const useColumnsProduct = ({
  onDeleteComponentModal,
  onOpenEditComponentModal,
}: {
  onDeleteComponentModal: (id: string | number) => () => void;
  onOpenEditComponentModal: (id: string | number) => () => void;
}): GridColDef[] =>
  useMemo(
    () => [
      // {
      //   field: 'id',
      //   headerName: '№',
      //   maxWidth: 50,
      // },
      {
        field: 'title',
        headerName: 'Name',
        flex: 1,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        field: 'company',
        headerName: 'Company',
        minWidth: 50,
        flex: 1,
        editable: false,
      },
      {
        field: 'edit',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params) => (
          <IconButton color="primary" variant="plain" disabled={false} onClick={onOpenEditComponentModal(params.id)}>
            <EditIcon />
          </IconButton>
        ),
      },
      {
        field: 'delete',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params) => (
          <IconButton color="danger" variant="plain" disabled={false} onClick={onDeleteComponentModal(params.id)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    ],
    [onDeleteComponentModal, onOpenEditComponentModal],
  );

export default useColumnsProduct;
