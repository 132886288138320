import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseMetricsForCalendarClass } from './BaseMetricsForCalendarClass';
import { IMarketingChannels } from '../../interface/calendarInterface';
import { CampaignMetricForCalendarClass } from './CampaignMetricForCalendarClass';
import { MarketingCampaignForCalendarClass } from './MarketingCampaignForCalendarClass';
import { patchById } from '../../service/marketingPillarService';

export class MarketingChannelForCalendarClass extends BaseMetricsForCalendarClass {
  // for numberIdBuilder
  private _idForcalendar: number;

  @observable private _campaignMetrics: CampaignMetricForCalendarClass[];

  @observable private _marketingCampaigns: MarketingCampaignForCalendarClass[];

  @observable private _bgColor: string;

  private readonly _budget: number;

  private readonly _calculatedBudget: number;

  protected _order: number;

  constructor(data: IMarketingChannels) {
    super({ id: data.id, title: data.title });
    makeObservable(this);
    this._order = data.order;
    this._bgColor = data.bg_color ?? '#d9d9d9';
    this._budget = data.budget;
    this._calculatedBudget = data.calculated_budget;
    this._campaignMetrics =
      data.campaign_metrics &&
      Object.values(data.campaign_metrics)
        ?.sort((a, b) => a.order - b.order)
        ?.map((campaignMetric) => new CampaignMetricForCalendarClass(campaignMetric));
    this._marketingCampaigns =
      data.marketing_campaigns &&
      Object.values(data.marketing_campaigns)?.map(
        (marketingCampaign) => new MarketingCampaignForCalendarClass(marketingCampaign),
      );
  }

  @computed get campaignMetricsList() {
    return this._campaignMetrics;
  }

  @computed get marketingCampaignsList() {
    return this._marketingCampaigns;
  }

  @computed get bgColor() {
    return this._bgColor;
  }

  get budget() {
    return this._budget;
  }

  get order() {
    return this._order;
  }

  get calculatedBudget() {
    return this._calculatedBudget;
  }

  get idForcalendar() {
    return this._idForcalendar;
  }

  setIdForcalendar = (id: number) => {
    this._idForcalendar = id;
  };

  @action setBgColor = (color: string) => {
    this._bgColor = color;
  };

  @action fetchNewColor = async (color: string) => {
    try {
      await patchById(this._id, { bg_color: color });
      runInAction(() => {
        this._bgColor = color;
      });
    } catch {
      //
    }
  };
}
