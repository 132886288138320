import React, { useMemo } from 'react';
import IconButton from '@mui/joy/IconButton';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import Chip from '@mui/joy/Chip';
import { BaseActivityLogInterface } from '../../../interface/activityLogInterface';

const useColumns = ({
  onReadMore,
}: {
  onReadMore: (prevObjectData: Record<string, any>, objectData: Record<string, any>) => () => void;
}): GridColDef[] =>
  useMemo(
    () => [
      {
        field: 'action',
        headerName: 'Action',
        flex: 1,
        minWidth: 40,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const color =
            (params.value as BaseActivityLogInterface['action']) === 'Create'
              ? 'success'
              : (params.value as BaseActivityLogInterface['action']) === 'Update'
              ? 'warning'
              : 'danger';

          return (
            <Chip
              variant="soft"
              color={color}
              sx={{
                '--Chip-radius': '4px',
                '--Chip-paddingInline': '8px',
              }}
            >
              {params.value.toUpperCase()}
            </Chip>
          );
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        minWidth: 120,
        flex: 1,
      },

      {
        field: 'date',
        headerName: 'Date',
        minWidth: 155,
        flex: 1,
      },
      {
        field: 'edit',
        headerName: '',
        width: 40,
        type: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <IconButton
            color="info"
            variant="plain"
            onClick={onReadMore(params.row?.prevObjectData, params.row?.objectData)}
          >
            <ReadMoreIcon />
          </IconButton>
        ),
      },
    ],
    [],
  );
export default useColumns;
