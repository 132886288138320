import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { patchUser } from '../../service/userServise';
import { IUser, LoadingStatus } from '../../interface';
import { camelToSnake } from '../../utils/stylizedNaming';

export class UserClass {
  private readonly _id: IUser['id'] = null;

  @observable private _firstName: IUser['first_name'] = null;

  @observable private _lastName: IUser['last_name'] = null;

  @observable private _email: IUser['email'] = null;

  private readonly _lastLogin: IUser['last_login'] = null;

  @observable private _isSuperuser: IUser['is_superuser'] = null;

  private readonly _isActive: IUser['is_active'] = null;

  private readonly _dateJoined: IUser['date_joined'] = null;

  @observable private _groups: IUser['groups'] = [];

  @observable private _company: number = null;

  @observable private _memento = null;

  @observable private _loadingStatus = LoadingStatus.SUCCESS;

  constructor(props) {
    makeObservable(this);
    this._id = props.id;
    this._firstName = props.first_name;
    this._lastName = props.last_name;
    this._email = props.email;
    this._lastLogin = props.last_login;
    this._isSuperuser = props.is_superuser;
    this._isActive = props.is_active;
    this._dateJoined = props.date_joined;
    this._groups = props.groups;
    this._company = props.company?.id || props.company;
    this._doMemento();
  }

  get id() {
    return this._id;
  }

  @computed
  get firstName() {
    return this._firstName;
  }

  @computed
  get lastName() {
    return this._lastName;
  }

  @computed
  get email() {
    return this._email;
  }

  get lastLogin() {
    return this._lastLogin;
  }

  @computed
  get isSuperuser() {
    return this._isSuperuser;
  }

  get isActive() {
    return this._isActive;
  }

  get dateJoined() {
    return this._dateJoined;
  }

  @computed
  get groupList() {
    return this._groups;
  }

  @computed
  get company() {
    return this._company;
  }

  @computed
  get loadingStatus() {
    return this._loadingStatus;
  }

  @action
  setFirstName = (value) => {
    this._firstName = value;
  };

  @action
  setLastName = (value) => {
    this._lastName = value;
  };

  @action
  setEmail = (value) => {
    this._email = value;
  };

  @action
  setGroup = (value: IUser['groups']) => {
    this._groups = value;
  };

  @action
  setIsSuperuser = (value) => {
    this._isSuperuser = value;
  };

  @action
  setCompany = (value: number) => {
    this._company = value;
  };

  @action private _doMemento = () => {
    this._memento = {
      _firstName: this._firstName,
      _lastName: this._lastName,
      _email: this._email,
      _isSuperuser: this._isSuperuser,
      _groups: this._groups,
      _company: this._company,
    };
  };

  @action restore = () => {
    for (const [key, value] of Object.entries(this._memento)) {
      this[key] = value;
    }
  };

  @computed
  get changedFields() {
    const newUserData = {};
    for (const [key, value] of Object.entries(this._memento)) {
      if (this[key] !== value) {
        newUserData[camelToSnake(key.substring(1))] = this[key];
      }
    }
    return newUserData;
  }

  @action fetchPatchUser = async () => {
    const newUserData = this.changedFields;
    if (!Object.keys(newUserData).length) {
      return false;
    }

    this._loadingStatus = LoadingStatus.LOADING;
    try {
      const { data } = await patchUser(this._id, newUserData);
      runInAction(() => {
        this._firstName = data.first_name;
        this._lastName = data.last_name;
        this._email = data.email;
        this._isSuperuser = data.is_superuser;
        this._groups = data.groups;
        this._company = data.company as unknown as number;
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      this._doMemento();
      return true;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };

  fetchChangePassword = async (password: string) => {
    try {
      await patchUser(this._id, { password });
      return true;
    } catch {
      return false;
    }
  };
}
