import { action, makeObservable, runInAction } from 'mobx';
import * as marketingPillarService from '../service/marketingPillarService';
import { BaseStoreAbstract } from './BaseStore.abstract';
import { MarketingPillarClass } from '../slass/data/marketing-pillars/MarketingPillarClass';
import { LoadingStatus } from '../interface';
import { TRequestSortingUpdate } from '../interface/marketingPilarInterface';

class MarketingPillarStore extends BaseStoreAbstract<MarketingPillarClass> {
  protected readonly _mainDataClass = MarketingPillarClass;

  // protected _mainDataList: MarketingPillarClass[] = [];

  protected readonly _serviceConductor = marketingPillarService;

  constructor() {
    super();
    makeObservable(this);
  }

  @action changeOrder = async (orders: { order: number; id: number }[]) => {
    orders.forEach((orderObj) => {
      this._mainDataList.find((marketingPillar) => marketingPillar.id === orderObj.id).setOrder(orderObj.order);
    });
    const prepareData: TRequestSortingUpdate = orders.map((order) => ({
      order: order.order,
      marketing_pillar: order.id,
    }));

    this._mainDataList = this._mainDataList.sort((a, b) => a.order - b.order);

    try {
      await this._serviceConductor.sorting(prepareData);
      runInAction(() => {
        this._loadingStatus = LoadingStatus.SUCCESS;
      });
      return this._mainDataList;
    } catch {
      this._loadingStatus = LoadingStatus.ERROR;
      return false;
    }
  };
}

export default MarketingPillarStore;
