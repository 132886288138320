import React, { useCallback } from 'react';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ModalClose } from '@mui/joy';
import { UserClass } from '../../../slass/user/UserClass';
import MainStore from '../../../store/MainStore';
import Can from '../../../component/permission/Can';
import UserGroups from './UserGroups';
import SelectCompany from './SelectCompany';
import { ReactComponent as MailIcon } from '../../../icon/MailIcon.svg';
import { LoadingStatus } from '../../../interface';

const UserModalMainContent = ({
  userInstance,
  onCloseModalHandler,
  setIsChangePassword,
}: {
  userInstance: UserClass;
  onCloseModalHandler: () => void;
  setIsChangePassword: (value: boolean) => void;
}) => {
  const { isSuperuser, groups, id } = MainStore.authStore;

  const isCanChangePassword = groups.includes('admin') || id === userInstance.id || isSuperuser;

  const onChangeSelect = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null, value: number) => {
      userInstance.setCompany(value);
    },
    [userInstance],
  );

  const onHandleChange = (fn) => (event) => {
    fn(event.target.value);
  };

  const onCloseButtonHandle = (fn) => () => {
    fn();
    onCloseModalHandler();
  };

  const onSaveButtonHandle = (fn) => () => {
    fn().then((isSuccess) => {
      if (isSuccess) {
        onCloseModalHandler();
      }
    });
  };
  const onChangePassword = () => {
    setIsChangePassword(true);
  };

  return (
    <>
      <ModalClose variant="plain" onClick={onCloseButtonHandle(userInstance.restore)} />
      <Typography
        component="h3"
        id="close-modal-title"
        sx={{ margin: '0 0 25px', fontWeight: 600, fontSize: '20px', lineHeight: '32px' }}
      >
        User Profile
      </Typography>
      <Box sx={{ display: 'flex', width: '100%', gap: '25px', marginTop: '16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
          <TextField
            id="standard-basic"
            label="First Name"
            variant="standard"
            sx={{ width: '100%' }}
            value={userInstance?.firstName || ''}
            onChange={onHandleChange(userInstance?.setFirstName)}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
          <TextField
            id="standard-basic"
            label="Last Name"
            variant="standard"
            sx={{ width: '100%' }}
            value={userInstance?.lastName || ''}
            onChange={onHandleChange(userInstance?.setLastName)}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: '25px', marginTop: '26px' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1, gap: '10px' }}>
          <MailIcon />
          <TextField
            id="input-with-sx"
            label="Email"
            variant="standard"
            sx={{ width: '100%' }}
            value={userInstance?.email || ''}
            onChange={onHandleChange(userInstance?.setEmail)}
          />
        </Box>
      </Box>
      <Can permissionList={['auth.change_group']}>
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '25px 0', gap: '40px' }}>
          <UserGroups instance={userInstance} />
        </Box>
      </Can>
      {isSuperuser && (
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '25px 0', gap: '40px' }}>
          <SelectCompany value={userInstance.company} onChange={onChangeSelect} />
        </Box>
      )}

      {isCanChangePassword && (
        <Button onClick={onChangePassword} variant="plain" color="warning" sx={{ margin: '25px 0 0 0' }}>
          Change Password
        </Button>
      )}

      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', marginTop: '36px', gap: '10px' }}>
        <Button variant="outlined" onClick={onCloseButtonHandle(userInstance.restore)}>
          Cancel
        </Button>
        <Button
          variant="solid"
          onClick={onSaveButtonHandle(userInstance.fetchPatchUser)}
          disabled={!Object.keys(userInstance.changedFields).length}
          loading={userInstance?.loadingStatus === LoadingStatus.LOADING}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default UserModalMainContent;
