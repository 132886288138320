import { useMemo } from 'react';
import dayjs from 'dayjs';
import { CalendarClass } from '../../../../slass/calendar/CalendarClass';
import { TotalsEventClass } from '../../../../slass/calendar/TotalsEventClass';

const useTotalWeeklySalesData = (instance: CalendarClass) => {
  return useMemo(() => {
    if (!instance) {
      return { data: [], totalWeeklySalesDays: null };
    }
    const dateSet = new Set<string>();
    let totalPredictedValue = 0;
    let totalValues = 0;

    const data = instance.totals.reduce((state, currentTotal) => {
      const buildData = (values: TotalsEventClass[], id: 'Sales Lift' | 'Incremental Sales') => {
        return values
          .map((values) => {
            const date = dayjs(values.forDate, 'YYYY-MM-DD');
            const x = date.format('MM-DD-YYYY');
            const y = values.value;
            dateSet.add(x);
            if (id === 'Sales Lift') {
              totalPredictedValue += y;
            } else {
              totalValues += y;
            }

            return {
              y,
              x,
              date,
            };
          }) // @ts-ignore
          .sort((a, b) => a.date - b.date);
      };

      if (currentTotal.predictedValues.length) {
        state.push({
          id: 'Sales Lift',
          data: buildData(currentTotal.predictedValues, 'Sales Lift'),
        });
        state.push({
          id: 'Incremental Sales',
          data: buildData(currentTotal.values, 'Incremental Sales'),
        });
      }

      return state;
    }, []);

    const totalWeeklySalesDays = (totalPredictedValue - totalValues) / dateSet.size;

    return { data, totalWeeklySalesDays };
  }, [instance]);
};

export default useTotalWeeklySalesData;
